import React, {  useContext, useEffect, useState } from 'react';  
import Notify from 'src/notification/Notify';
import Axios from 'axios'; 
import ImagingProcessedDetails from './ImagingProcessedDetails';
import ImagingProcessedAttachment from './ImagingProcessedAttachment';
import Badge from '@material-ui/core/Badge'
import { PatientsUnRead } from 'src/ContextAPI';
import { Dialog, DialogTitle, DialogContent, Box, IconButton, Card, CardContent, Typography, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ImagingOngoing = ({ patient_id, imagingType }) =>{

    const user_id =  localStorage.getItem('user_id')
    const token =  localStorage.getItem('token') 

    const [imaging, setImaging] = useState({ data: [], ready: false }) 

    const [selectedOrder, setSelectedOrder] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedDialog, setSelectedDialog] = useState('details') 

    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, department, order_id ) =>{

        
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;

        if(imagingType === 'local-imaging'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id && category === xx[i].category && department === xx[i].department && order_id === xx[i].order_id){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department && order_id === yy[i].order_id){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    const getOngoingProcess = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', imagingType === 'virtual-imaging' ? 'online' : 'local')
        
        Axios.post('imaging/doctor/patient/imaging-ongoingorder', formdata )
        .then( (response) => { 
            const data = response.data;       
            setImaging({ data: data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const closeDialog = () =>{
        setSelectedOrder(null)
        setDialogOpen(false)  
    }

    useEffect(() =>{
        
        getOngoingProcess()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[patient_id, imagingType])

    return(
        <> 
            <Box> 
                {
                    imaging.ready ? 
                        imaging.data.length > 0 ?
                            imaging.data.map((data, index) =>{
                                return(
                                    <Card 
                                        component={Box}
                                        borderLeft={5}
                                        borderColor={`#ffc107`}
                                        key={index}  
                                        mb={2}
                                    >  
                                        <CardContent>
                                            <Box mb={1}>
                                                <Typography variant="subtitle1">
                                                    <Badge 
                                                        color="secondary"
                                                        invisible= { ! Boolean(parseInt(checkPatientUnreadNotif('imaging', 'ongoing', data.imaging_center_id))) }
                                                        badgeContent={`new`}
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
                                                    >
                                                        { data.imaging_order }
                                                    </Badge>
                                                </Typography>
                                            </Box> 
                                            
                                            <Box display="flex" justifyContent="flex-start">
                                                <Box>
                                                    <Button
                                                        size="small" 
                                                        color="primary"
                                                        imagingid={data.imaging_center_id} 
                                                        onClick={ ()=> {
                                                            setSelectedOrder(data)
                                                            setSelectedDialog('details')
                                                            setDialogOpen(true)  
                                                        }}
                                                    >
                                                        details
                                                    </Button>
                                                </Box>

                                                <Box>
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        imagingid={data.imaging_center_id} 
                                                        onClick={() =>{
                                                            setSelectedOrder(data.imaging_result_attachment )
                                                            setSelectedDialog('attachment')
                                                            setDialogOpen(true)  
                                                        }}
                                                    >
                                                        attachment
                                                    </Button>
                                                </Box>

                                                <Box mt={0.7} ml={2}>
                                                    <Typography color="textSecondary" variant="caption" className="gtc-uppercase">
                                                        processed on { data.start_time === null ? 'not-set' : Notify.createdAt(data.start_time) } 
                                                    </Typography>
                                                </Box>
                                            </Box> 

                                        </CardContent> 
                                    </Card>
                                )
                            })
                        :   <Card> <CardContent> { Notify.noRecord() } </CardContent> </Card> 
                    :   <Card> <CardContent> { Notify.loading() } </CardContent> </Card> 
                } 
            </Box>

            {/* Details Dialog */}  
            <Dialog 
                open={ dialogOpen } 
                onClose={ closeDialog }
                fullWidth
                maxWidth="sm"
            >
                <Box display="flex">
                    <Box flexGrow={1}> 
                        <DialogTitle> 
                            Order Information 
                        </DialogTitle>
                    </Box>
                    <Box mr={2} mt={1}>
                        <IconButton 
                            onClick={ closeDialog }
                            color="secondary"
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContent dividers>
                    {  selectedOrder !==null && selectedDialog === 'details' && ( <ImagingProcessedDetails imagingType ={ imagingType } details = { selectedOrder } /> ) }
                    {  selectedOrder !==null && selectedDialog === 'attachment' && ( <ImagingProcessedAttachment imagingType ={ imagingType }  details = { selectedOrder } />   ) } 
                </DialogContent>
            </Dialog> 
        </>
    )
}

export default ImagingOngoing; 