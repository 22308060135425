import React from 'react'
import { 
    TableContainer, 
    Table, 
    TableBody, 
    TableCell,  
    TableHead,
    TableRow,
    // IconButton,
    // Typography
} from '@material-ui/core';
// import EditIcons from '@material-ui/icons/Edit';
// import TrashIcon from '@material-ui/icons/Delete';

const DoctorsServices = ({ services }) =>{
    return(
        <> 
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell> <b> Service </b> </TableCell>
                            <TableCell> <b> Credit </b> </TableCell>
                            {/* <TableCell align="center"> <b> Action </b> </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { 
                            services.map((data, index) =>(
                                <TableRow
                                    key={index}
                                >
                                    <TableCell> { data.services } </TableCell>
                                    <TableCell align="right"> { data.amount } </TableCell>
                                    {/* <TableCell align="center"> 
                                        <IconButton>
                                            <EditIcons />
                                        </IconButton>

                                        <IconButton>
                                            <TrashIcon />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            )) 
                        } 
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DoctorsServices;