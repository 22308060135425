import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  TablePagination,
  TextField,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
  Button,
  makeStyles,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import NewPatient from "./NewPatient";
import PatientDetails from "./PatientDetails";
import SearchIcon from "@material-ui/icons/Search";
import Container from "src/layout/Container";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: 7,
  },
}));

const Patients = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");

  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const [search, setSearch] = useState("");

  const getDoctorsPatient = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    Axios.post("encoder/patients/getpatient-list", formdata)
      .then((response) => {
        const data = response.data;
        setPatient({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getDoctorsPatient();
  }, [getDoctorsPatient]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patients",
          items: [{ name: "Dashboard", path: "/clinic/app/encoder" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}> Patients </Box>
            <Box hidden={selectedPatient === "new-patient"}>
              <Button
                color="primary"
                onClick={() => setSelectedPatient("new-patient")}
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
              >
                New Patient
              </Button>
            </Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title="Patient List" />
              <CardContent>
                <List component="div">
                  <Box mb={2}>
                    <TextField
                      label="Search"
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {patient.ready
                    ? patient.data.length > 0
                      ? searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <ListItem
                              key={index}
                              button
                              className="mb-2"
                              onClick={() =>
                                setSelectedPatient(data.patient_id)
                              }
                              selected={selectedPatient === data.patient_id}
                            >
                              <ListItemIcon>
                                {data.image === null ? (
                                  <Avatar className={classes.primary}>
                                    {data.lastname.charAt().toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    className={classes.primary}
                                    src={`${imageLocation}patients/${data.image}`}
                                    alt=""
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={`${data.lastname}, ${data.firstname} ${data.middle} `}
                              />
                            </ListItem>
                          ))
                      : Notify.noRecord()
                    : Notify.loading()}
                </List>
                <TablePagination
                  component="div"
                  count={patient.data.length}
                  rowsPerPageOptions={[
                    10,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={8}>
            {selectedPatient === "new-patient" && (
              <NewPatient getDoctorsPatient={getDoctorsPatient} />
            )}
            {selectedPatient !== "new-patient" && (
              <PatientDetails patient_id={selectedPatient} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Patients;
