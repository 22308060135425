import {
  Box,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import AddManagementDialog from "./AddManagementDialog";
import ManagementInformation from "./ManagementInformation";

const Management = () => {
  const token = localStorage.getItem("token");
  const [list, setList] = useState({ data: [], ready: false });
  const [dialog, setDialog] = useState(false);
  const [selectedManagement, setSelectedManagement] = useState({});

  const getManagementList = React.useCallback(async () => {
    try {
      let response = await Axios.get("gtcadmin/management/list-management", {
        params: {
          token: token,
        },
      });

      let result = response.data;
      setList({ data: result, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token]);

  const handleNewManagement = async (e) => {
    try {
      e.preventDefault();
      e.persist();
      var formdata = new FormData(e.target);
      formdata.set("token", token);

      let response = await Axios.post(
        "/gtcadmin/management/new-management",
        formdata
      );
      let result = response.data;
      if (result.message === "success") {
        setDialog(false);
        Notify.successRequest("managmen");
      }
    } catch (error) {
      Notify.requestError(error);
    }
  };

  useEffect(() => {
    getManagementList();
  }, [getManagementList]);

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Paper>
            <Box p={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6" color="textSecondary">
                    Management List
                  </Typography>
                </Box>
              </Box>

              <List>
                <ListItem button onClick={() => setDialog(true)}>
                  <ListItemText
                    primary="New Management"
                    primaryTypographyProps={{
                      color: "primary",
                    }}
                    secondary="Create new management"
                  />
                </ListItem>
                {list.data.length > 0 &&
                  list.data.map((data, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => setSelectedManagement(data)}
                    >
                      <ListItemText
                        primary={data.name}
                        secondary={data.address}
                      />
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={9}>
          {Object.keys(selectedManagement).length > 0 ? (
            <ManagementInformation details={selectedManagement} />
          ) : (
            <NoSelectedManagement />
          )}
        </Grid>
      </Grid>

      {/* add management dialog */}
      <AddManagementDialog
        open={dialog}
        close={() => setDialog(false)}
        handleNewManagement={handleNewManagement}
      />
    </Box>
  );
};

export default Management;

const NoSelectedManagement = () => (
  <Box align="center">
    <Typography variant="h6">MANAGEMENT</Typography>

    <Typography variant="subtitle2">
      SELECT MANAGMENT TO DISPLAY INFORMATION
    </Typography>
  </Box>
);
