 
import React from 'react';
export default class Diagnosis extends React.Component{
    static list = () => {
        var diseases = [
            { 
                label: "Abdominal aortic aneurysm", 
                value: "Abdominal aortic aneurysm"
            },
            { 
                label: "Acne", 
                value: "Acne"
            },
            { 
                label: "Acute cholecystitis", 
                value: "Acute cholecystitis"
            },
            { 
                label: "Acute lymphoblastic leukaemia", 
                value: "Acute lymphoblastic leukaemia"
            },
            { 
                label: "Acute myeloid leukaemia", 
                value: "Acute myeloid leukaemia"
            },
            { 
                label: "Acute pancreatitis", 
                value: "Acute pancreatitis"
            },
            { 
                label: "Addison’s disease", 
                value: "Addison’s disease"
            },
            { 
                label: "Alcohol misuse", 
                value: "Alcohol misuse"
            },
            { 
                label: "Alcohol poisoning", 
                value: "Alcohol poisoning"
            },
            { 
                label: "Alcohol-related liver disease", 
                value: "Alcohol-related liver disease"
            },
            { 
                label: "Allergic rhinitis", 
                value: "Allergic rhinitis"
            },
            { 
                label: "Allergies", 
                value: "Allergies"
            },
            { 
                label: "Alzheimer’s disease", 
                value: "Alzheimer’s disease"
            },
            { 
                label: "Anal cancer", 
                value: "Anal cancer"
            },
            { 
                label: "Anaphylaxis", 
                value: "Anaphylaxis"
            },
            { 
                label: "Angioedema", 
                value: "Angioedema"
            },
            { 
                label: "Ankylosing spondylitis", 
                value: "Ankylosing spondylitis"
            },
            { 
                label: " Anorexia nervosa", 
                value: " Anorexia nervosa"
            },
            { 
                label: "Anxiety", 
                value: "Anxiety"
            },
            { 
                label: "Appendicitis", 
                value: "Appendicitis"
            },
            { 
                label: "Arthritis", 
                value: "Arthritis"
            },
            { 
                label: "Asbestosis", 
                value: "Asbestosis"
            },
            { 
                label: "Asthma", 
                value: "Asthma"
            },
            { 
                label: "Atopic eczema", 
                value: "Atopic eczema"
            },
            { 
                label: "Attention deficit hyperactivity disorder (ADHD)", 
                value: "Attention deficit hyperactivity disorder (ADHD)"
            },
            { 
                label: "Autistic spectrum disorder (ASD)", 
                value: "Autistic spectrum disorder (ASD)"
            },
            { 
                label: "Bacterial vaginosis", 
                value: "Bacterial vaginosis"
            },
            { 
                label: "Benign prostate enlargement", 
                value: "Benign prostate enlargement"
            },
            { 
                label: "Bile duct cancer (cholangiocarcinoma)", 
                value: "Bile duct cancer (cholangiocarcinoma)"
            },
            { 
                label: "Binge eating", 
                value: "Binge eating"
            },
            { 
                label: " Bipolar disorder", 
                value: " Bipolar disorder"
            },
            { 
                label: "Bladder cancer", 
                value: "Bladder cancer"
            },
            { 
                label: "Blood poisoning (sepsis)", 
                value: "Blood poisoning (sepsis)"
            },
            { 
                label: "Bone cancer", 
                value: "Bone cancer"
            },
            { 
                label: "Bowel cancer", 
                value: "Bowel cancer"
            },
            { 
                label: "Bowel incontinence", 
                value: "Bowel incontinence"
            },
            { 
                label: "Bowel polyps", 
                value: "Bowel polyps"
            },
            { 
                label: "Brain stem death", 
                value: "Brain stem death"
            },
            { 
                label: "Brain tumours", 
                value: "Brain tumours"
            },
            { 
                label: "Breast cancer", 
                value: "Breast cancer"
            },
            { 
                label: "Bronchiectasis", 
                value: "Bronchiectasis"
            },
            { 
                label: "Bronchitis", 
                value: "Bronchitis"
            },
            { 
                label: "Bulimia", 
                value: "Bulimia"
            },
            { 
                label: "Bunion", 
                value: "Bunion"
            },
            { 
                label: "Bursitis", 
                value: "Bursitis"
            },
            { 
                label: "Carcinoid syndrome", 
                value: "Carcinoid syndrome"
            },
            { 
                label: "Carcinoid tumours", 
                value: "Carcinoid tumours"
            },
            { 
                label: "Catarrh", 
                value: "Catarrh"
            },
            { 
                label: "Cellulitis", 
                value: "Cellulitis"
            },
            { 
                label: "Cervical cancer", 
                value: "Cervical cancer"
            },
            { 
                label: "Chest infection", 
                value: "Chest infection"
            },
            { 
                label: "Chest pain", 
                value: "Chest pain"
            },
            { 
                label: "Chickenpox", 
                value: "Chickenpox"
            },
            { 
                label: "Chilblains", 
                value: "Chilblains"
            },
            { 
                label: "Chronic fatigue syndrome", 
                value: "Chronic fatigue syndrome"
            },
            { 
                label: "Chronic kidney disease", 
                value: "Chronic kidney disease"
            },
            { 
                label: "Chronic lymphocytis", 
                value: "Chronic lymphocytis"
            },
            { 
                label: "leukaemia", 
                value: "leukaemia"
            },
            { 
                label: "Chronic myeloid", 
                value: "Chronic myeloid"
            },
            { 
                label: "leukaemia", 
                value: "leukaemia"
            },
            { 
                label: "Chronic obstructive pulmonary disease", 
                value: "Chronic obstructive pulmonary disease"
            },
            { 
                label: "Chronic pancreatitis", 
                value: "Chronic pancreatitis"
            },
            { 
                label: "Cirrhosis", 
                value: "Cirrhosis"
            },
            { 
                label: "Clostridium difficile", 
                value: "Clostridium difficile"
            },
            { 
                label: "Coeliac disease", 
                value: "Coeliac disease"
            },
            { 
                label: "Cold sore", 
                value: "Cold sore"
            },
            { 
                label: "Coma", 
                value: "Coma"
            },
            { 
                label: " Common cold", 
                value: " Common cold"
            },
            { 
                label: "Common heart conditions", 
                value: "Common heart conditions"
            },
            { 
                label: "Congenital heart disease", 
                value: "Congenital heart disease"
            },
            { 
                label: "Conjunctivitis", 
                value: "Conjunctivitis"
            },
            { 
                label: "Constipation", 
                value: "Constipation"
            },
            { 
                label: "Costochondritis", 
                value: "Costochondritis"
            },
            { 
                label: "Cough", 
                value: "Cough"
            },
            { 
                label: "Crohns disease", 
                value: "Crohns disease"
            },
            { 
                label: "Croup", 
                value: "Croup"
            },
            { 
                label: "Cystic fibrosis", 
                value: "Cystic fibrosis"
            },
            { 
                label: "Cystitis", 
                value: "Cystitis"
            },
            { 
                label: "Deafblindness", 
                value: "Deafblindness"
            },
            { 
                label: "Deep vein thrombosis", 
                value: "Deep vein thrombosis"
            },
            { 
                label: "Dehydration", 
                value: "Dehydration"
            },
            { 
                label: "Dementia", 
                value: "Dementia"
            },
            { 
                label: "Dental abscess", 
                value: "Dental abscess"
            },
            { 
                label: "Depression", 
                value: "Depression"
            },
            { 
                label: "Dermatitis herpetiformis", 
                value: "Dermatitis herpetiformis"
            },
            { 
                label: "Diabetic retinopathy", 
                value: "Diabetic retinopathy"
            },
            { 
                label: "Diarrhea", 
                value: "Diarrhea"
            },
            { 
                label: "Discoid eczema", 
                value: "Discoid eczema"
            },
            { 
                label: "Diverticular disease", 
                value: "Diverticular disease"
            },
            { 
                label: "diverticulitis", 
                value: "diverticulitis"
            },
            { 
                label: "Dizziness", 
                value: "Dizziness"
            },
            { 
                label: "Downs syndrome", 
                value: "Downs syndrome"
            },
            { 
                label: "Dry mouth", 
                value: "Dry mouth"
            },
            { 
                label: "Dysphagia", 
                value: "Dysphagia"
            },
            { 
                label: "Dystonia", 
                value: "Dystonia"
            },
            { 
                label: "Earache", 
                value: "Earache"
            },
            { 
                label: "Earwax build-up", 
                value: "Earwax build-up"
            },
            { 
                label: "Ebola virus disease", 
                value: "Ebola virus disease"
            },
            { 
                label: " Ectopic pregnancy", 
                value: " Ectopic pregnancy"
            },
            { 
                label: "Endometriosis", 
                value: "Endometriosis"
            },
            { 
                label: "Epilepsy", 
                value: "Epilepsy"
            },
            { 
                label: "Erectile dysfunction (impotence)", 
                value: "Erectile dysfunction (impotence)"
            },
            { 
                label: "Escherichia coli (E.coli)", 
                value: "Escherichia coli (E.coli)"
            },
            { 
                label: "Ewing sarcoma", 
                value: "Ewing sarcoma"
            },
            { 
                label: "Eye cancer", 
                value: "Eye cancer"
            },
            { 
                label: "Febrile seizures", 
                value: "Febrile seizures"
            },
            { 
                label: "Fever", 
                value: "Fever"
            },
            { 
                label: "Fibroids", 
                value: "Fibroids"
            },
            { 
                label: "Fibromyalgia", 
                value: "Fibromyalgia"
            },
            { 
                label: "Flatulence", 
                value: "Flatulence"
            },
            { 
                label: "Flu", 
                value: "Flu"
            },
            { 
                label: "Foetal alcohol syndrome", 
                value: "Foetal alcohol syndrome"
            },
            { 
                label: "Foods poisoning", 
                value: "Foods poisoning"
            },
            { 
                label: "Fungal nail infection", 
                value: "Fungal nail infection"
            },
            { 
                label: "Gallbladder cancer", 
                value: "Gallbladder cancer"
            },
            { 
                label: "Gallstones", 
                value: "Gallstones"
            },
            { 
                label: "Ganglion cyst", 
                value: "Ganglion cyst"
            },
            { 
                label: "Gastroenteritis", 
                value: "Gastroenteritis"
            },
            { 
                label: "Gastro-oesophageal reflux disease (GORD)", 
                value: "Gastro-oesophageal reflux disease (GORD)"
            },
            { 
                label: "Genital herpes", 
                value: "Genital herpes"
            },
            { 
                label: " Genital warts", 
                value: " Genital warts"
            },
            { 
                label: "Germ cell tumours", 
                value: "Germ cell tumours"
            },
            { 
                label: "Glandular fever", 
                value: "Glandular fever"
            },
            { 
                label: "Gout", 
                value: "Gout"
            },
            { 
                label: "Gum disease", 
                value: "Gum disease"
            },
            { 
                label: "Haemorrhoids (piles)", 
                value: "Haemorrhoids (piles)"
            },
            { 
                label: " Hairy cell leukaemia", 
                value: " Hairy cell leukaemia"
            },
            { 
                label: " Hand/foot/ mouth disease", 
                value: " Hand/foot/ mouth disease"
            },
            { 
                label: "Hay fever", 
                value: "Hay fever"
            },
            { 
                label: "Head and neck cancer", 
                value: "Head and neck cancer"
            },
            { 
                label: "Head lice and nits", 
                value: "Head lice and nits"
            },
            { 
                label: "Headaches", 
                value: "Headaches"
            },
            { 
                label: "Hearing loss", 
                value: "Hearing loss"
            },
            { 
                label: "Heart failure", 
                value: "Heart failure"
            },
            { 
                label: "Hepatitis A", 
                value: "Hepatitis A"
            },
            { 
                label: "Hepatitis B", 
                value: "Hepatitis B"
            },
            { 
                label: "Hepatitis C", 
                value: "Hepatitis C"
            },
            { 
                label: "Hiatus hernia", 
                value: "Hiatus hernia"
            },
            { 
                label: "High cholesterol", 
                value: "High cholesterol"
            },
            { 
                label: "HIV", 
                value: "HIV"
            },
            { 
                label: "Hodgkin lymphoma", 
                value: "Hodgkin lymphoma"
            },
            { 
                label: " Huntingtons disease", 
                value: " Huntingtons disease"
            },
            { 
                label: "Hyperglycaemia (high blood sugar)", 
                value: "Hyperglycaemia (high blood sugar)"
            },
            { 
                label: "Hyperhidrosis", 
                value: "Hyperhidrosis"
            },
            { 
                label: "Hypoglycaemia (low blood sugar)", 
                value: "Hypoglycaemia (low blood sugar)"
            },
            { 
                label: "Idiopathic pulmonary fibrosis", 
                value: "Idiopathic pulmonary fibrosis"
            },
            { 
                label: "Impetigo", 
                value: "Impetigo"
            },
            { 
                label: "Indigestion", 
                value: "Indigestion"
            },
            { 
                label: "Ingrown toenail", 
                value: "Ingrown toenail"
            },
            { 
                label: "Inherited heart conditions", 
                value: "Inherited heart conditions"
            },
            { 
                label: "Insomnia", 
                value: "Insomnia"
            },
            { 
                label: "Iron deficiency anaemia", 
                value: "Iron deficiency anaemia"
            },
            { 
                label: "Irritable bowel syndrome (IBS)", 
                value: "Irritable bowel syndrome (IBS)"
            },
            { 
                label: "Irritable hip", 
                value: "Irritable hip"
            },
            { 
                label: "Itching", 
                value: "Itching"
            },
            { 
                label: "Itchy bottom", 
                value: "Itchy bottom"
            },
            { 
                label: "Kaposis sarcoma", 
                value: "Kaposis sarcoma"
            },
            { 
                label: "Kidney cancer", 
                value: "Kidney cancer"
            },
            { 
                label: " Kidney infection", 
                value: " Kidney infection"
            },
            { 
                label: " Kidney stones", 
                value: " Kidney stones"
            },
            { 
                label: "Labyrinthitis", 
                value: "Labyrinthitis"
            },
            { 
                label: "Lactose intolerance", 
                value: "Lactose intolerance"
            },
            { 
                label: "Langerhans cell histiocytosis", 
                value: "Langerhans cell histiocytosis"
            },
            { 
                label: " Laryngeal (larynx) cancer", 
                value: " Laryngeal (larynx) cancer"
            },
            { 
                label: "Laryngitis", 
                value: "Laryngitis"
            },
            { 
                label: "Leg cramps", 
                value: "Leg cramps"
            },
            { 
                label: "Lichen planus", 
                value: "Lichen planus"
            },
            { 
                label: "Liver cancer", 
                value: "Liver cancer"
            },
            { 
                label: "Liver disease", 
                value: "Liver disease"
            },
            { 
                label: "Liver tumours", 
                value: "Liver tumours"
            },
            { 
                label: "Lung cancer", 
                value: "Lung cancer"
            },
            { 
                label: "Lupus", 
                value: "Lupus"
            },
            { 
                label: "Lyme disease", 
                value: "Lyme disease"
            },
            { 
                label: "Lymphoedema", 
                value: "Lymphoedema"
            },
            { 
                label: "Malaria", 
                value: "Malaria"
            },
            { 
                label: "Malignant brain tumour (cancerous)", 
                value: "Malignant brain tumour (cancerous)"
            },
            { 
                label: "Malnutrition", 
                value: "Malnutrition"
            },
            { 
                label: "Measles", 
                value: "Measles"
            },
            { 
                label: "Meningitis", 
                value: "Meningitis"
            },
            { 
                label: "Menopause", 
                value: "Menopause"
            },
            { 
                label: "Mesothelioma", 
                value: "Mesothelioma"
            },
            { 
                label: "Middle ear infection (otitis media)", 
                value: "Middle ear infection (otitis media)"
            },
            { 
                label: "Migraine", 
                value: "Migraine"
            },
            { 
                label: "Miscarriage", 
                value: "Miscarriage"
            },
            { 
                label: "Motor neurone disease (MND)", 
                value: "Motor neurone disease (MND)"
            },
            { 
                label: "Mouth cancer", 
                value: "Mouth cancer"
            },
            { 
                label: "Mouth ulcer", 
                value: "Mouth ulcer"
            },
            { 
                label: "Multiple myeloma", 
                value: "Multiple myeloma"
            },
            { 
                label: " Multiple sclerosis (MS)", 
                value: " Multiple sclerosis (MS)"
            },
            { 
                label: "Mumps", 
                value: "Mumps"
            },
            { 
                label: "Menieres disease", 
                value: "Menieres disease"
            },
            { 
                label: "Nasal and sinus cancer", 
                value: "Nasal and sinus cancer"
            },
            { 
                label: "Nasopharyngeal cancer", 
                value: "Nasopharyngeal cancer"
            },
            { 
                label: "Neuroblastoma", 
                value: "Neuroblastoma"
            },
            { 
                label: "New or worsening back pain", 
                value: "New or worsening back pain"
            },
            { 
                label: "Non-alcoholic fatty liver disease (NAFLD)", 
                value: "Non-alcoholic fatty liver disease (NAFLD)"
            },
            { 
                label: "Non-Hodgkin lymphoma", 
                value: "Non-Hodgkin lymphoma"
            },
            { 
                label: "Norovirus", 
                value: "Norovirus"
            },
            { 
                label: "Nosebleed", 
                value: "Nosebleed"
            },
            { 
                label: "Obesity", 
                value: "Obesity"
            },
            { 
                label: " Obsessive compulsive disorder (OCD)", 
                value: " Obsessive compulsive disorder (OCD)"
            },
            { 
                label: "Obstructive sleep apnoea", 
                value: "Obstructive sleep apnoea"
            },
            { 
                label: "Oesophageal cancer", 
                value: "Oesophageal cancer"
            },
            { 
                label: " Oral thrust", 
                value: " Oral thrust"
            },
            { 
                label: "Osteoarthritis", 
                value: "Osteoarthritis"
            },
            { 
                label: "Osteoporosis", 
                value: "Osteoporosis"
            },
            { 
                label: "Osteosarcoma", 
                value: "Osteosarcoma"
            },
            { 
                label: "Otitis externa", 
                value: "Otitis externa"
            },
            { 
                label: "Ovarian cancer", 
                value: "Ovarian cancer"
            },
            { 
                label: "Ovarian cyst", 
                value: "Ovarian cyst"
            },
            { 
                label: "Overactive thyroid", 
                value: "Overactive thyroid"
            },
            { 
                label: "Pagets disease of the nipple", 
                value: "Pagets disease of the nipple"
            },
            { 
                label: "Pancreatic cancer", 
                value: "Pancreatic cancer"
            },
            { 
                label: " Panic disorder", 
                value: " Panic disorder"
            },
            { 
                label: "Parkinsons disease", 
                value: "Parkinsons disease"
            },
            { 
                label: "Pelvic organ prolapse", 
                value: "Pelvic organ prolapse"
            },
            { 
                label: " Penile cancer", 
                value: " Penile cancer"
            },
            { 
                label: "Peripheral neuropathy", 
                value: "Peripheral neuropathy"
            },
            { 
                label: "Personality disorder", 
                value: "Personality disorder"
            },
            { 
                label: "Pleurisy", 
                value: "Pleurisy"
            },
            { 
                label: "Pneumonia", 
                value: "Pneumonia"
            },
            { 
                label: "Polymyalgia rheumatic", 
                value: "Polymyalgia rheumatic"
            },
            { 
                label: "Post-traumatic stress disorder (PTSD)", 
                value: "Post-traumatic stress disorder (PTSD)"
            },
            { 
                label: "Postnatal depression", 
                value: "Postnatal depression"
            },
            { 
                label: "Pregnancy and baby", 
                value: "Pregnancy and baby"
            },
            { 
                label: "Pressure ulcers", 
                value: "Pressure ulcers"
            },
            { 
                label: "Prostate cancer", 
                value: "Prostate cancer"
            },
            { 
                label: "Psoriasis", 
                value: "Psoriasis"
            },
            { 
                label: "Psoriatic arthritis", 
                value: "Psoriatic arthritis"
            },
            { 
                label: "Psychosis", 
                value: "Psychosis"
            },
            { 
                label: "Rare tumours", 
                value: "Rare tumours"
            },
            { 
                label: "Raynauds phenomenon", 
                value: "Raynauds phenomenon"
            },
            { 
                label: "Reactive arthritis", 
                value: "Reactive arthritis"
            },
            { 
                label: "Restless legs syndrome", 
                value: "Restless legs syndrome"
            },
            { 
                label: "Retinoblastoma", 
                value: "Retinoblastoma"
            },
            { 
                label: "Rhabdomyosarcoma", 
                value: "Rhabdomyosarcoma"
            },
            { 
                label: "Rheumatoid arthritis", 
                value: "Rheumatoid arthritis"
            },
            { 
                label: "Ringworm", 
                value: "Ringworm"
            },
            { 
                label: "fungal infections", 
                value: "fungal infections"
            },
            { 
                label: "Rosacea", 
                value: "Rosacea"
            },
            { 
                label: "Scabies", 
                value: "Scabies"
            },
            { 
                label: "Scarlet fever", 
                value: "Scarlet fever"
            },
            { 
                label: "Schizophrenia", 
                value: "Schizophrenia"
            },
            { 
                label: "Scoliosis", 
                value: "Scoliosis"
            },
            { 
                label: "Septic shock", 
                value: "Septic shock"
            },
            { 
                label: "Sexually transmitted infections (STIs)", 
                value: "Sexually transmitted infections (STIs)"
            },
            { 
                label: "Shingles", 
                value: "Shingles"
            },
            { 
                label: "Shortness of breath", 
                value: "Shortness of breath"
            },
            { 
                label: "Sickle cell disease", 
                value: "Sickle cell disease"
            },
            { 
                label: "Sinusitis", 
                value: "Sinusitis"
            },
            { 
                label: "Sjogrens syndrome", 
                value: "Sjogrens syndrome"
            },
            { 
                label: "Skin cancer (melanoma)", 
                value: "Skin cancer (melanoma)"
            },
            { 
                label: "Skin cancer (non-melanoma)", 
                value: "Skin cancer (non-melanoma)"
            },
            { 
                label: "Slapped cheek syndrome", 
                value: "Slapped cheek syndrome"
            },
            { 
                label: " Soft tissue sarcomas", 
                value: " Soft tissue sarcomas"
            },
            { 
                label: "Sore throat", 
                value: "Sore throat"
            },
            { 
                label: "Spleen problem", 
                value: "Spleen problem"
            },
            { 
                label: "Spleen removal", 
                value: "Spleen removal"
            },
            { 
                label: "Stillbirth", 
                value: "Stillbirth"
            },
            { 
                label: "Stomach ache", 
                value: "Stomach ache"
            },
            { 
                label: "Abdominal pain", 
                value: "Abdominal pain"
            },
            { 
                label: "Stomach cancer", 
                value: "Stomach cancer"
            },
            { 
                label: "Stomach ulcer", 
                value: "Stomach ulcer"
            },
            { 
                label: " Stress/Anxiety", 
                value: " Stress/Anxiety"
            },
            { 
                label: "Low mood", 
                value: "Low mood"
            },
            { 
                label: "Stroke", 
                value: "Stroke"
            },
            { 
                label: "Sudden infant death syndrome (SIDS)", 
                value: "Sudden infant death syndrome (SIDS)"
            },
            { 
                label: "Suicide", 
                value: "Suicide"
            },
            { 
                label: "Sunburn", 
                value: "Sunburn"
            },
            { 
                label: "Swollen glands", 
                value: "Swollen glands"
            },
            { 
                label: "Testicular cancer", 
                value: "Testicular cancer"
            },
            { 
                label: "Testicular lumps", 
                value: "Testicular lumps"
            },
            { 
                label: "swellings", 
                value: "swellings"
            },
            { 
                label: "Thirst", 
                value: "Thirst"
            },
            { 
                label: "Threadworms", 
                value: "Threadworms"
            },
            { 
                label: "Thrust in men", 
                value: "Thrust in men"
            },
            { 
                label: "Thyroid cancer", 
                value: "Thyroid cancer"
            },
            { 
                label: "Tinnitus", 
                value: "Tinnitus"
            },
            { 
                label: "Tonsillitis", 
                value: "Tonsillitis"
            },
            { 
                label: "Tooth decay", 
                value: "Tooth decay"
            },
            { 
                label: "Toothache", 
                value: "Toothache"
            },
            { 
                label: "Transient ischaemic attack (TIA)", 
                value: "Transient ischaemic attack (TIA)"
            },
            { 
                label: "Trigeminal neuralgia", 
                value: "Trigeminal neuralgia"
            },
            { 
                label: "Tuberculosis (TB)", 
                value: "Tuberculosis (TB)"
            },
            { 
                label: "Type 1 diabetes", 
                value: "Type 1 diabetes"
            },
            { 
                label: "Type 2 diabetes", 
                value: "Type 2 diabetes"
            },
            { 
                label: "Ulcerative colitis", 
                value: "Ulcerative colitis"
            },
            { 
                label: "Underactive thyroid", 
                value: "Underactive thyroid"
            },
            { 
                label: "Urinary incontinence", 
                value: "Urinary incontinence"
            },
            { 
                label: "Urinary tract infection (UTI)", 
                value: "Urinary tract infection (UTI)"
            },
            { 
                label: "Urticaria (hives)", 
                value: "Urticaria (hives)"
            },
            { 
                label: "Vaginal cancer", 
                value: "Vaginal cancer"
            },
            { 
                label: "Vaginal thrust", 
                value: "Vaginal thrust"
            },
            { 
                label: "Varicose eczema", 
                value: "Varicose eczema"
            },
            { 
                label: "Varicose veins", 
                value: "Varicose veins"
            },
            { 
                label: "Venous leg ulcer", 
                value: "Venous leg ulcer"
            },
            { 
                label: "Vertigo", 
                value: "Vertigo"
            },
            { 
                label: "Vitamin B12", 
                value: "Vitamin B12"
            },
            { 
                label: "Folate deficiency anaemia", 
                value: "Folate deficiency anaemia"
            },
            { 
                label: "Vomiting", 
                value: "Vomiting"
            },
            { 
                label: "Vulval cancer", 
                value: "Vulval cancer"
            },
            { 
                label: "Warts", 
                value: "Warts"
            },
            { 
                label: "verrucas", 
                value: "verrucas"
            },
            { 
                label: "Whooping cough", 
                value: "Whooping cough"
            },
            { 
                label: "Wilms tumour", 
                value: "Wilms tumour"
            },
            { 
                label: "Womb (uterus) cancer", 
                value: "Womb (uterus) cancer"
            }
        ];

        return diseases
    }
}


