import { Box, Tabs, Tab, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PatientDetailsHeader from "./PatientDetailsHeader";
import TabPanel from "src/utils/TabPanel";
import TodayIcon from "@material-ui/icons/Today";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
// import ChildCare from "@material-ui/icons/ChildCare";
import PatientDetailsAppointment from "./PatientDetailsAppointment";
import PatientDetailsProfile from "./PatientDetailsProfile";
import AdmittingOrder from "./admittingorder/AdmittingOrder";

import Pediatrics from "./childs";
// import AttachFile from "@material-ui/icons/AttachFile";
import { Lock } from "react-feather";
import Attachments from "./attchments";
import PatientDetailsSentAccount from "./PatientDetailsSentAccount";

const PatientDetails = ({ patient_id }) => {
  const [category, setCategory] = useState("appointment");
  const [isRerender, setIsRerender] = useState(new Date().getTime());

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {}, [patient_id]);

  return (
    <>
      <PatientDetailsHeader patient_id={patient_id} isRerender={isRerender} />

      <Box mt={2} component={Paper}>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab
            wrapped
            value="appointment"
            label="Appointment"
            icon={<TodayIcon />}
          />
          <Tab
            wrapped
            value="profile"
            label="Profile"
            icon={<AssignmentIndIcon />}
          />
          {/* <Tab wrapped value="pedia" label="Pediatrics" icon={<ChildCare />} />
          <Tab
            wrapped
            value="admitting-order"
            label="Admitting Order"
            icon={<FilePlus />}
          />
          <Tab
            wrapped
            value="attachment"
            label="Attachment"
            icon={<AttachFile />}
          /> */}

          <Tab
            wrapped
            value="send-account"
            label="Send Account"
            icon={<Lock size={22} />}
          />
        </Tabs>
      </Box>

      <TabPanel value={category} index="appointment">
        <PatientDetailsAppointment patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index="profile">
        <PatientDetailsProfile patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index="pedia">
        <Pediatrics patient_id={patient_id} setIsRerender={setIsRerender} />
      </TabPanel>

      <TabPanel value={category} index="admitting-order">
        <AdmittingOrder patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index="attachment">
        <Attachments patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index="send-account">
        <PatientDetailsSentAccount patient_id={patient_id} />
      </TabPanel>
    </>
  );
};

export default PatientDetails;
