
import Notify from 'src/notification/Notify'

const FormvalidationSorology = (fieldData, formField) =>{ 
    var error  = [];  
    
    if(fieldData.hbsag){
        if(formField.get('hbsag').length === 0 || formField.get('hbsag').trim() === ''){
            Notify.fieldRequired('hbsag')
            error = 'error';
        }
    } 

    if(fieldData.hav){
        if(formField.get('hav').length === 0 || formField.get('hav').trim() === ''){
            Notify.fieldRequired('hav')
            error = 'error';
        }
    } 

    if(fieldData.hcv){
        if(formField.get('hcv').length === 0 || formField.get('hcv').trim() === ''){
            Notify.fieldRequired('hcv')
            error = 'error';
        }
    } 

    if(fieldData.vdrl_rpr){
        if(formField.get('vdrl_rpr').length === 0 || formField.get('vdrl_rpr').trim() === ''){
            Notify.fieldRequired('vdrl_rpr')
            error = 'error';
        }
    } 

    return error;
}

export default FormvalidationSorology;