import { Avatar, Card, CardHeader, Grid, IconButton, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import React, { Fragment } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';
import Container from 'src/layout/Container';

const ProfileDropdown = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'profile',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="My Profile"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        Vi
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/vitals');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Vitals"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        Al
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/allergies');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Allergies"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        Dia
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/diagnosis');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Diagnosis"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        Me
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/medication');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Medication"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        Di
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/diet');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Diet"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        FH
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/family-history');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Family History"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="profile" className={classes.avatar}>
                                        Hi
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="profilesettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/profile/history');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="History"
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default ProfileDropdown;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    avatar: {
        backgroundColor: blue[600],
    },
}));