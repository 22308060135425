import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';

class sorologyRef {
    normal = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    NON-REACTIVE
                </Typography>
            </Box> 
        </Box>
    ) 
}

const SorologyRef = new sorologyRef();
export default SorologyRef;