import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { TimePicker, DatePicker } from "@material-ui/pickers";

import Notify from "../../notification/Notify";
import Axios from "axios";

function Reschedule({
  open,
  closeDialog,
  appid,
  connection,
  appdate,
  getAppointmentDetails,
}) {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [reschedappdate, setreschedappdate] = useState(new Date(appdate));
  const [isreschedprocess, setisreschedprocess] = useState(false);

  const handleAppRescheduleLocal = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("appid", appid);
    formdata.set("connection", connection);

    var error = [];
    if (
      formdata.get("resched_date").length === 0 ||
      formdata.get("resched_date").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("reschedule date");
    }

    if (
      formdata.get("resched_time").length === 0 ||
      formdata.get("resched_time").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("reschedule time");
    }

    if (
      formdata.get("resched_reason").length === 0 ||
      formdata.get("resched_reason").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("rescheduel reason");
    }

    if (error.length > 0) {
      console.warn("form has an error. watch out.");
    } else {
      setisreschedprocess(true);
      Axios.post("doctors/appointment/fullcalendar/reschedule", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            closeDialog();
            getAppointmentDetails();
            Notify.successRequest("set reschedule");
          }
          if (data === "db-error") {
            Notify.warnRequest("set reschedule");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setisreschedprocess(false);
        });
    }
  };
  return (
    <Fragment>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Reschedule Appointment</DialogTitle>
        <form onSubmit={handleAppRescheduleLocal}>
          <DialogContent dividers>
            <Box mb={1} mt={1}>
              <DatePicker
                disablePast
                fullWidth
                margin="normal"
                label="Re-shedule Appointment Date"
                format="MM/dd/yyyy"
                value={reschedappdate}
                onChange={(date) => setreschedappdate(date)}
                inputVariant="outlined"
                name="resched_date"
              />
            </Box>

            <Box mb={1}>
              <TimePicker
                fullWidth
                margin="normal"
                label="Re-shedule Time"
                value={reschedappdate}
                inputVariant="outlined"
                onChange={(date) => setreschedappdate(date)}
                name="resched_time"
              />
            </Box>

            <TextField
              fullWidth
              label="Reason of Re-shedule"
              name="resched_reason"
              margin="normal"
              variant="outlined"
              rows={4}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Box ml={2}>
              <Button
                startIcon={<HighlightOffIcon />}
                variant="contained"
                color="default"
                onClick={closeDialog}
              >
                {" "}
                No{" "}
              </Button>
            </Box>
            <Button
              type="submit"
              disabled={isreschedprocess}
              startIcon={
                isreschedprocess ? (
                  <CircularProgress size={15} color="inherit" />
                ) : (
                  <CheckCircleOutlineIcon />
                )
              }
              variant="contained"
              color="primary"
            >
              {" "}
              Save{" "}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
}

export default Reschedule;
