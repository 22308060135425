import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Collapse,
  TableFooter,
  TablePagination,
  useTheme,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import { Fragment } from "react";
import Notify from "src/notification/Notify";
import Search from "@material-ui/icons/Search";

const StockRoom = () => {
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const [list, setList] = useState({ data: [], ready: false });
  const [selectedProductId, setSelectedProductId] = useState(null);
  const mounted = IsMountedRef();
  const [batches, setBatches] = useState([]);
  let total = 0;
  const theme = useTheme();

  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const getStockroomMonitoring = React.useCallback(async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/getinventory-list",
      {
        params: {
          token: token,
          management_id: management_id,
        },
      }
    );

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [token, management_id, mounted]);

  const getProductBatchesId = async (product_id) => {
    if (selectedProductId && selectedProductId === product_id) {
      console.log("Product id is currently selected.");
      return;
    }

    let response = await Axios.get(
      "pharmacy/warehouse/inventory/product-batches",
      {
        params: {
          product_id: product_id,
          management_id: management_id,
          token: token,
        },
      }
    );

    const data = response.data;
    setBatches(data);
    setSelectedProductId(product_id);
  };

  const calculateTotal = (srp, qty) => {
    let x = qty * srp;

    total += x;

    return Notify.convertToNumber(x);
  };

  const handleQtyColor = (qty) => {
    let qtx = parseInt(qty);

    if (qtx <= 50) {
      return theme.palette.warning.light;
    }
    if (qtx >= 50 && qtx <= 100) {
      return theme.palette.error.light;
    }

    return "";
  };

  const handleSearch = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const filteredList = searchList(list.data, query);
  const paginatedProduct = applyPagination(filteredList, page, limit);

  useEffect(() => {
    getStockroomMonitoring();
  }, [getStockroomMonitoring]);

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>Stockroom Monitoring</Box>
            <Box>
              <TextField
                label="Search brand, generic"
                variant="outlined"
                onChange={handleSearch}
                value={query}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> Brand </TableCell>
                <TableCell> Generic </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> MSRP </TableCell>
                <TableCell> Qty In </TableCell>
                <TableCell> Qty Out </TableCell>
                <TableCell> Available Qty </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.ready ? (
                paginatedProduct.length > 0 ? (
                  paginatedProduct.map((data, index) => (
                    <Fragment key={index}>
                      <TableRow
                        style={{
                          backgroundColor: handleQtyColor(
                            data._total_available_qty
                          ),
                        }}
                      >
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => getProductBatchesId(data.product_id)}
                          >
                            {selectedProductId &&
                            selectedProductId === data.product_id ? (
                              <KeyboardArrowDown />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell> {data.product_name} </TableCell>
                        <TableCell> {data.product_generic} </TableCell>
                        <TableCell> {data.unit} </TableCell>
                        <TableCell align="right"> {data.msrp} </TableCell>
                        <TableCell align="right">
                          {data._total_in_qty}
                        </TableCell>
                        <TableCell align="right">
                          {data._total_out_qty}
                        </TableCell>
                        <TableCell align="right">
                          {data._total_available_qty}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={8}
                        >
                          <Collapse
                            in={
                              selectedProductId === data.product_id
                                ? true
                                : false
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Box>
                                <Typography variant="h6">
                                  Product Batches
                                </Typography>
                              </Box>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell> Batch Number </TableCell>
                                    <TableCell> Invoice Number </TableCell>
                                    <TableCell> Qty. In </TableCell>
                                    <TableCell> Qty. Out </TableCell>
                                    <TableCell> Avaible Qty </TableCell>
                                    <TableCell> Srp </TableCell>
                                    <TableCell> Amount </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {batches.length > 0 ? (
                                    batches.map((data, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          {data.batch_number}
                                        </TableCell>
                                        <TableCell>
                                          {data.invoice_number}
                                        </TableCell>
                                        <TableCell align="right">
                                          {data._total_in_qty}
                                        </TableCell>
                                        <TableCell align="right">
                                          {data._total_out_qty}
                                        </TableCell>
                                        <TableCell align="right">
                                          {data._total_available_qty}
                                        </TableCell>
                                        <TableCell align="right">
                                          {data.srp}
                                        </TableCell>
                                        <TableCell align="right">
                                          {calculateTotal(
                                            data.srp,
                                            data._total_available_qty
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={7}>
                                        <Typography color="secondary">
                                          No record found.
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>

                                <TableFooter>
                                  <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> Total </TableCell>
                                    <TableCell align="right">
                                      {Notify.convertToNumber(total)}{" "}
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography color="primary">
                      Loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component={"div"}
            count={filteredList.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default StockRoom;

const searchList = (list, query) => {
  return list.filter((xdata) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      if (
        xdata.product_name.toLowerCase().includes(query.toLowerCase()) ||
        xdata.product_generic.toLowerCase().includes(query.toLowerCase())
      ) {
        containsQuery = true;
      }

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (xdata, page, limit) => {
  return xdata.slice(page * limit, page * limit + limit);
};
