
 
import React from 'react'
import CanvasDraw from "react-canvas-draw"; 

const CanvasDrawing = () =>{

    const canvasRef = React.useRef(null) 
    const finalCanvasRef = React.useRef(null)  


    const saveCanvas = () =>{ 
        
        let imageURL = canvasRef.current.canvasContainer.childNodes[1].toDataURL()
        // let downloadBtnRef = finalCanvasRef.current
        // downloadBtnRef.src = imageURL
        renderImageINFinalCanvas(imageURL)   

        // console.log(imageURL)
    }


    const renderImageINFinalCanvas = (data)=>{ 
        
        var ctx = finalCanvasRef.current.getContext('2d');  
        
        var image = new Image();  
        image.src = data
        finalCanvasRef.current.width = 1000;
        finalCanvasRef.current.height = 500; 

        ctx.fillStyle = "#fff";  //fill background with color. 
        ctx.fillRect(0, 0, finalCanvasRef.current.width, finalCanvasRef.current.height);

        image.onload = () =>{  
            console.log(finalCanvasRef.current.width)
            ctx.drawImage(image, 0, 0);
        } 
        
    }

    const saveCanvasToDb = () =>{
        var dataURL = finalCanvasRef.current.toDataURL() 
            console.log(dataURL)
    }

    return(
        <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
            <CanvasDraw 
                ref={ canvasRef }
                brushColor={'red'} 
                canvasWidth = { 1000 }
                canvasHeight = { 500 }
                lazyRadius =  {4}
                brushRadius =  {1} 
            />
            <button
                onClick={ saveCanvas }
            >
                Save
            </button>
 

            <button
                onClick={ saveCanvasToDb }
            >
                Save TO
            </button>

            {/* { localStorage.getItem("savecanvas") &&(
                <CanvasDraw
                    disabled  
                    hideGrid
                    canvasWidth = { 1000 }
                    canvasHeight = { 500 } 
                    saveData={localStorage.getItem("savecanvas")}
                    immediateLoading={true} 
                    hideInterface={ true }
                />
            )} 
             */}

             <br />

            <canvas ref={ finalCanvasRef }/>
            {/* <img ref={ finalCanvasRef }  /> */}
        </>
    )
}

export default CanvasDrawing;