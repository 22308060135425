
import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { grey } from '@material-ui/core/colors';
import Notify from '../notification/Notify';
import Axios from 'axios';
import RequestMessages from './RequestMessages';
import { UnreadContext } from '../ContextAPI';

const RequestMessagesForm = ({ patient_id, sender }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [process, setProcess] = useState(false)

    const unreadContext = useContext(UnreadContext)

    var newmessageInterval = null;
    const [messages, setMessages] = useState({
        data: [],
        ready: false
    })

    console.log('message:L', unreadContext)

    const handleReplyInquiries = (e) =>{
        e.preventDefault()
        e.persist()
 
        var formdata = new FormData(e.target)
            formdata.set('token', token)
            formdata.set('patient_id', patient_id) 
            formdata.set('user_id', user_id) 
            formdata.set('send_by', 'Clinic')
        var error = [];

        if(formdata.get('message').length === 0 || formdata.get('message').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('message'); 
        }

        if(error.length > 0){
            console.warn("Form has an error.")
        }else{
            setProcess(true)
            Axios
            .post('clinic/secretary/inquiry/patientinquiry-send', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'success'){
                    e.target.reset()  
                    
                    unreadContext.updateUnreadInq([{patientId: patient_id}])
                    getInquiryMessages()
                    Notify.successRequest('Inquiry sent!')
                }
            }).catch(error => { 
                Notify.requestError(error) 
            }).finally(() =>{
                setProcess(false)
            })
        }
    }  

    const getInquiryMessages =() =>{ 
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('patient_id', patient_id)
            formdata.set('user_id', user_id)  
        Axios
        .post('clinic/secretary/inquiry/patientinquiry-getmessages', formdata)
        .then((response) =>{
            const data = response.data 
            getNewMessage(data.length > 0 ? data[data.length - 1].id : 0) 
            setMessages({ data: data, ready: true })  
        }).catch(error => { 
            Notify.requestError(error) 
        }) 
    } 

    const getNewMessage = (lastMsgId) =>{  
        newmessageInterval = setInterval(() => {
            var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('patient_id', patient_id)
            formdata.set('user_id', user_id)  
            formdata.set('lastmessage_id', lastMsgId)
            Axios
            .post('clinic/secretary/inquiry/patientinquiry-newmessages', formdata)
            .then((response) =>{
                const data = response.data
                if(data.length > 0) { 
                    getInquiryMessages() 
                    clearInterval(newmessageInterval)
                }
            }).catch(error => { 
                Notify.requestError(error) 
            })  
        }, 9000);  
    }

    useEffect(() =>{

        getInquiryMessages() 
        return () =>{ clearInterval(newmessageInterval) }

        // eslint-disable-next-line
    },[patient_id])

    return(
        <Box
            border={1}
            borderColor={grey[300]}
            bgcolor="white"
            p={2}
        >  
            {/* messages */}
            <Box>
                <Typography
                    variant="subtitle2"
                    color="primary"
                    className={`gtc-uppercase`}
                > { sender } MESSAGES </Typography>
            </Box> 

            <RequestMessages messages ={ messages } patient_id ={ patient_id } />

            <Box mt={2}> 
                <form onSubmit = { handleReplyInquiries }>  
                    <FormControl  
                        variant="outlined"
                        fullWidth 
                    >
                        <InputLabel htmlFor="message"> Message </InputLabel>
                        <OutlinedInput
                            id="message"  
                            name="message"
                            autoComplete="off" 
                            labelWidth={70} 
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility" 
                                        color="primary"
                                        edge="end"
                                        type="submit"
                                        disabled = { process }
                                    >
                                        { process ? <CircularProgress size={20} /> : <SendIcon/> }
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </form>
            </Box>
        </Box>
    )
}

export default RequestMessagesForm;