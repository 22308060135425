import React from 'react'
import Snackbar from "@material-ui/core/Snackbar";
import  Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import WifiOffIcon from '@material-ui/icons/WifiOff';

const NoInternet = () => ( 
    <Snackbar 
        open={true}
        anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
        }}
    >
        <Alert severity="error" variant="filled" icon={ <WifiOffIcon /> }>
            <AlertTitle> No internet connection </AlertTitle>
            Check your connection and reload this page.
        </Alert>
    </Snackbar>
)   

export default NoInternet;