import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Button,
  Paper,
  DialogActions,
  TextField,
  DialogContent,
  CircularProgress,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Notify from "src/notification/Notify";
import moment from "moment";
import Axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";

const immunization = [
  { name: "BCG", value: "bcg" },
  { name: "Hepatitis B", value: "hepatitis-b" },
  { name: "DPT Polio", value: "dpt-polio" },
  { name: "HiB", value: "hib" },
  { name: "Rotavirus", value: "rotavirus" },
  { name: "PCV", value: "pcv" },
  { name: "Measles", value: "measles" },
  { name: "MMR", value: "mmr" },
  { name: "Varicella", value: "varicella" },
  { name: "Hepatitis A", value: "hepatitis-a" },
  {
    name: "Pneumococcal Polysaccharide Vaccine",
    value: "pneumococcal-polysaccharide-vaccine",
  },
  { name: "Influenza Vaccine", value: "influenza-vaccine" },
  { name: "Meningococcal Vaccine", value: "meningococcal-vaccine" },
  { name: "Jap B encephalilis vaccine (IMOJEV)", value: "jap-b" },
  { name: "Typhoid Vaccine", value: "typhoid-vaccine" },
  { name: "Dengue", value: "dengue" },
  { name: "dtaP", value: "dtap" },
];

const doses = [
  { name: "1st Dose", value: "1st-dose" },
  { name: "2nd Dose", value: "2nd-dose" },
  { name: "3rd Dose", value: "3rd-dose" },
  { name: "4th Dose", value: "4th-dose" },
  { name: "5th Dose", value: "5th-dose" },
];

const Immunization = ({ patient_id, connection }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [list, setList] = useState([]);
  const mounted = IsMountedRef();
  const tableRef = useRef();

  const getImmunization = React.useCallback(async () => {
    let response = await Axios.get(
      "doctors/patients/childs/immunization-list",
      {
        params: {
          token,
          patient_id,
          connection,
        },
      }
    );

    const data = response.data;

    if (mounted.current) {
      setList(data);
    }
  }, [token, patient_id, connection, mounted]);

  useEffect(() => {
    getImmunization();
  }, [getImmunization]);

  const renderDataFromList = () => {
    for (let i = 0; i < list.length; i++) {
      let vacineRow = document.querySelector(
        `.vaccinerow-${list[i].immunization}`
      );

      if (vacineRow) {
        let doseRow = document.querySelector(
          `.d-${list[i].immunization}-${list[i].dose}`
        );

        doseRow.innerHTML = `<input type='radio' checked/> <br /> <small>  ${list[i].taken_on} </small>`;
      }
    }
  };

  return (
    <Box mt={2} component={Paper}>
      <Box p={2} display="flex">
        <Box flexGrow={1}>
          <Typography color="textSecondary" variant="h6">
            Immunization Record
          </Typography>
        </Box>
        <Box>
          <Button color="primary" onClick={() => setDialog(true)}>
            Update
          </Button>
        </Box>
      </Box>

      <Box>
        <TableContainer>
          <Table ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell> Vaccine </TableCell>
                <TableCell> 1st Dose </TableCell>
                <TableCell> 2nd Dose </TableCell>
                <TableCell> 3rd Dose </TableCell>
                <TableCell>
                  4th Dose <br /> (booster 1)
                </TableCell>
                <TableCell>
                  5th Dose <br /> (booster 2)
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {immunization.map((immu, xd) => {
                return (
                  <TableRow key={xd} className={`vaccinerow-${immu.value}`}>
                    <TableCell className={`vacine-${immu.value}`}>
                      {immu.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`d-${immu.value}-1st-dose`}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={`d-${immu.value}-2nd-dose`}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={`d-${immu.value}-3rd-dose`}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={`d-${immu.value}-4th-dose`}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={`d-${immu.value}-5th-dose`}
                    ></TableCell>
                  </TableRow>
                );
              })}
              {renderDataFromList()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* new dose dialog */}
      <NewVaccineRecord
        patient_id={patient_id}
        connection={connection}
        open={dialog}
        close={() => setDialog(false)}
        action={() => getImmunization()}
      />
    </Box>
  );
};

export default Immunization;

const NewVaccineRecord = ({ patient_id, connection, open, close, action }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth>
      <DialogTitle> Immunization Record </DialogTitle>
      <Formik
        initialValues={{
          user_id: user_id,
          token: token,
          patient_id: patient_id,
          connection: connection,
          immunization: "",
          doses: "",
          date: moment(new Date()).format("YYYY-MM-DD"),
        }}
        validationSchema={Yup.object().shape({
          immunization: Yup.string().required(),
          doses: Yup.string().required(),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "encoder/patients/childs/immunization-new",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("new immunization");
              resetForm();
              close();
              action();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  label="Immunization"
                  variant="outlined"
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.immunization && errors.immunization)}
                  helperText={touched.immunization && errors.immunization}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.immunization}
                  name="immunization"
                  required
                >
                  <option value=""> Select </option>
                  {immunization.map((data, index) => {
                    return (
                      <option key={index} value={data.value}>
                        {data.name}
                      </option>
                    );
                  })}
                </TextField>
              </Box>

              <Box my={2}>
                <TextField
                  label="Doses"
                  variant="outlined"
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.doses && errors.doses)}
                  helperText={touched.doses && errors.doses}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.doses}
                  name="doses"
                  required
                >
                  <option value=""> Select </option>
                  {doses.map((data, index) => {
                    return (
                      <option key={index} value={data.value}>
                        {data.name}
                      </option>
                    );
                  })}
                </TextField>
              </Box>

              <Box my={2}>
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="date"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<HighlightOff />}
              >
                No
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
