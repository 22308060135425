import React, { useState, Fragment } from "react";
import {
  Box,
  Typography,
  Button,
  Collapse,
  TextField,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PersonalHistoryFamilyForm from "./PersonalHistoryFamilyForm";
import Axios from "axios";
import Notify from "../../notification/Notify";
import EditIcon from "@material-ui/icons/Edit";
import PersonalHistoryFamilyEditDialog from "./PersonalHistoryFamilyEditDialog";
import Container from "src/layout/Container";

const PersonalHistoryFamily = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [category, setCategory] = useState("details-category");
  const [histories, setHistories] = useState([]);
  const [historiesReady, setHistoriesReady] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [selectedTitle, setselectedTitle] = useState(null);

  const openEditDialog = (e) => {
    setOpenDialog(true);
    setselectedId(e.currentTarget.id);
    setselectedTitle(e.currentTarget.title);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setselectedId(null);
    setselectedTitle(null);
  };

  const getHistories = () => {
    var params = {
      token: token,
      management_id: management_id,
      user_id: user_id,
    };
    Axios.get("patient/information/personal/family-history", { params })
      .then((response) => {
        const data = response.data;
        setHistories(data);
        setHistoriesReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useState(() => {
    getHistories();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "family history",
          items: [
            { name: "Dashboard", path: "/clinic/app/patient" },
            { name: "Profile", path: "/clinic/app/patient/profile" },
          ],
        }}
        title="My Family History"
      >
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box display="flex">
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<PostAddIcon />}
                    onClick={() => setCategory("add-history")}
                    hidden={
                      histories.length > 0
                        ? true
                        : category === "add-history"
                        ? true
                        : false
                    }
                  >
                    Add Family History
                  </Button>
                </Box>
              </Box>

              <Box>
                <Collapse
                  in={category === "add-history" ? true : false}
                  timeout={600}
                >
                  <PersonalHistoryFamilyForm
                    cancel={() => setCategory("details-category")}
                    getHistories={getHistories}
                  />
                </Collapse>

                <Collapse
                  in={category === "details-category" ? true : false}
                  timeout={600}
                >
                  {historiesReady
                    ? histories.length > 0
                      ? histories.map((data, index) => (
                          <Box
                            key={index}
                            my={1}
                            className="gtc-textfield-noborder"
                          >
                            {/* fathers information */}
                            <Box
                              mb={2}
                              hidden={data.category === "Father" ? false : true}
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography
                                    color="primary"
                                    variant="caption"
                                    className={`gtc-uppercase`}
                                  >
                                    {" "}
                                    Father Information{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <IconButton
                                    color="primary"
                                    id={data.pfh_id}
                                    title={data.category}
                                    onClick={openEditDialog}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Fullname"
                                      value={data.name}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: { readOnly: true },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Address"
                                      value={data.address}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Birthday"
                                      value={data.birthday}
                                      margin="dense"
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Occupation"
                                      value={data.occupation}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Health Status"
                                      value={data.health_status}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Is alive"
                                      value={
                                        data.is_deceased === 0
                                          ? "Alive"
                                          : "Deceased"
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box
                                mb={1}
                                hidden={data.is_deceased === 0 ? true : false}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Date of death"
                                      value={
                                        data.is_deceased_date === null
                                          ? ""
                                          : data.is_deceased_date
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Cause of death"
                                      value={
                                        data.is_deceased_reason === null
                                          ? ""
                                          : data.is_deceased_reason
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                            {/* father s information end */}

                            {/* mothers information  */}
                            <Box
                              mb={2}
                              hidden={data.category === "Mother" ? false : true}
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography
                                    color="primary"
                                    variant="caption"
                                    className={`gtc-uppercase`}
                                  >
                                    {" "}
                                    Mother Information{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <IconButton
                                    color="primary"
                                    id={data.pfh_id}
                                    title={data.category}
                                    onClick={openEditDialog}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Fullname"
                                      value={data.name}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Address"
                                      value={data.address}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Birthday"
                                      value={data.birthday}
                                      margin="dense"
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Occupation"
                                      value={data.occupation}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Health Status"
                                      value={data.health_status}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Is alive"
                                      value={
                                        data.is_deceased === 0
                                          ? "Alive"
                                          : "Deceased"
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box
                                mb={1}
                                hidden={data.is_deceased === 0 ? true : false}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Date of death"
                                      value={
                                        data.is_deceased_date === null
                                          ? ""
                                          : data.is_deceased_date
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Cause of death"
                                      value={
                                        data.is_deceased_reason === null
                                          ? ""
                                          : data.is_deceased_reason
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                            {/* mothers information end */}

                            {/* childrens information  */}
                            <Box
                              mb={2}
                              hidden={
                                data.category === "children" ? false : true
                              }
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography
                                    color="primary"
                                    variant="caption"
                                    className={`gtc-uppercase`}
                                  >
                                    {" "}
                                    Children {index - 2 + 1} Information{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <IconButton
                                    color="primary"
                                    id={data.pfh_id}
                                    title={data.category}
                                    onClick={openEditDialog}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Fullname"
                                      value={data.name}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Address"
                                      value={data.address}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Birthday"
                                      value={data.birthday}
                                      margin="dense"
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Occupation"
                                      value={data.occupation}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Health Status"
                                      value={data.health_status}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Is alive"
                                      value={
                                        data.is_deceased === 0
                                          ? "Alive"
                                          : "Deceased"
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box
                                mb={1}
                                hidden={data.is_deceased === 0 ? true : false}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="Date of death"
                                      value={
                                        data.is_deceased_date === null
                                          ? ""
                                          : data.is_deceased_date
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Cause of death"
                                      value={
                                        data.is_deceased_reason === null
                                          ? ""
                                          : data.is_deceased_reason
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        inputProps: {
                                          readOnly: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                            {/* childrens information end */}
                          </Box>
                        ))
                      : Notify.noRecord()
                    : Notify.loading()}
                </Collapse>

                {/* edit dialogsss */}
                <PersonalHistoryFamilyEditDialog
                  open={openDialog}
                  close={closeDialog}
                  selectedId={selectedId}
                  title={selectedTitle}
                  history={getHistories}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PersonalHistoryFamily;
