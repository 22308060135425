import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const SearchAppointmentDocList = () => {
    const token = localStorage.getItem('token')
    const [doctorList, setDoctorList] = useState([]);
    const history = useHistory()

    const fetchAllDoctor = () => {
        var params = {
            token: token,
        }
        Axios.get('appointment/doctors/doctors-listbysearch', { params })
            .then((response) => {
                const data = response.data;
                setDoctorList(data);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {

        fetchAllDoctor();

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    return (

        <Autocomplete
            id="combo-box-demo"
            onChange={(e, data) => {
                history.push('/clinic/app/patient/appointment/doctor/' + data.user_id)
            }}
            options={doctorList}
            getOptionLabel={(option) => option.name}
            style={{ width: isMobile ? '100%' : 300 }}
            renderInput={(params) =>
                <TextField {...params} label="Search Doctors" variant="outlined" />}
        />
    )
}

export default SearchAppointmentDocList;