import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react'
import Container from 'src/layout/Container';
import BasicInfo from './BasicInfo';
import Password from './Password';
import Username from './Username';

const DoctorsAccount = () =>{

    return(
        <>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'Account',
                    items:[
                        { name: 'Dashboard', path: '/clinic/app/doctor' }
                    ]
                }} 
                title={ `Account Information` }
            >
                
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                        <Paper 
                            elevation={2} 
                            component={Box} 
                            p={2}
                        > 
                            <Box>
                                <BasicInfo />
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Paper 
                            elevation={2} 
                            component={Box} 
                            p={2}
                        > 
                            <Box>
                                <Username />
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Paper 
                            elevation={2} 
                            component={Box} 
                            p={2}
                        > 
                            <Box>
                                <Password />
                            </Box>
                        </Paper>
                    </Grid> 
                </Grid>

            </Container>
        </>
    )
} 

export default DoctorsAccount;