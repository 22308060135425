
import Notify from 'src/notification/Notify'

const FormvalidationClinicChemistry = (fieldData, formField) => {
    var error = [];

    if (fieldData.glucose) {
        if (formField.get('glucose').length === 0 || formField.get('glucose').trim() === '') {
            Notify.fieldRequired('glucose')
            error = 'error';
        }
    }

    if (fieldData.creatinine) {
        if (formField.get('creatinine').length === 0 || formField.get('creatinine').trim() === '') {
            Notify.fieldRequired('creatinine')
            error = 'error';
        }
    }

    if (fieldData.uric_acid) {
        if (formField.get('uric_acid').length === 0 || formField.get('uric_acid').trim() === '') {
            Notify.fieldRequired('uric acid')
            error = 'error';
        }
    }

    if (fieldData.cholesterol) {
        if (formField.get('cholesterol').length === 0 || formField.get('cholesterol').trim() === '') {
            Notify.fieldRequired('cholesterol')
            error = 'error';
        }
    }

    if (fieldData.triglyceride) {
        if (formField.get('triglyceride').length === 0 || formField.get('triglyceride').trim() === '') {
            Notify.fieldRequired('triglyceride')
            error = 'error';
        }
    }

    if (fieldData.hdl_cholesterol) {
        if (formField.get('hdl_cholesterol').length === 0 || formField.get('hdl_cholesterol').trim() === '') {
            Notify.fieldRequired('hdl cholesterol')
            error = 'error';
        }
    }

    if (fieldData.ldl_cholesterol) {
        if (formField.get('ldl_cholesterol').length === 0 || formField.get('ldl_cholesterol').trim() === '') {
            Notify.fieldRequired('ldl cholesterol')
            error = 'error';
        }
    }

    if (fieldData.sgot) {
        if (formField.get('sgot').length === 0 || formField.get('sgot').trim() === '') {
            Notify.fieldRequired('SGOT')
            error = 'error';
        }
    }

    if (fieldData.sgpt) {
        if (formField.get('sgpt').length === 0 || formField.get('sgpt').trim() === '') {
            Notify.fieldRequired('SGPT')
            error = 'error';
        }
    }

    return error;
}

export default FormvalidationClinicChemistry;