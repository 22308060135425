import axios from "axios";
import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Notify from "src/notification/Notify";
import { CircularProgress, FormControlLabel, Hidden, Paper, useTheme } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Tag, Tag as BoxIcon } from "react-feather";
import { Line } from 'react-chartjs-2'; 
import { amber, blue, deepOrange, green, indigo, red, purple, teal, yellow, grey } from "@material-ui/core/colors";
import IsMountedRef from "src/utils/IsMountedRef";
import Container from 'src/layout/Container';

const GraphClinicalMicroscopy = () => {
    const themes = useTheme()
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [ready, setReady] = useState(false)
    const [label, setLabel] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);

    const [pH, setPH] = useState([])
    const [specificGravity, setSpecificGravity] = useState([])
    const [pusCell, setPusCell] = useState([])
    const [redBloodCell, setRedBloodCell] = useState([])
    const [hyalineCast, setHyalineCast] = useState([])
    const [wBCCast, setWBCCast] = useState([])
    const [rBCCast, setRBCCast] = useState([])
    const [fineGranularCast, setFineGranularCast] = useState([])
    const [coarseGranularCast, setCoarseGranularCast] = useState([])

    const chartRef = useRef()
    const ismounted = IsMountedRef()

    var containerRef = useRef(null);
    var interval = null;

    const getClinicalMicroscopyRecord = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
            }
            const response = await axios.get('patient/order/graph/order-id-micro', { params })
            if (ismounted.current) {
                pushDataToChart(response.data)
                setReady(true)
            }
        } catch (error) {
            console.log(error)
        }
    }, [user_id, token, ismounted])

    const pushDataToChart = (data) => {

        for (let i = 0; i < data.length; i++) {
            setPH((chemical_test_ph) => chemical_test_ph.concat(data[i].chemical_test_ph === null ? 0 : data[i].chemical_test_ph))
            setSpecificGravity((chemical_test_spicific_gravity) => chemical_test_spicific_gravity.concat(data[i].chemical_test_spicific_gravity === null ? 0 : data[i].chemical_test_spicific_gravity))
            setPusCell((microscopic_test_pus) => microscopic_test_pus.concat(data[i].microscopic_test_pus === null ? 0 : data[i].microscopic_test_pus))
            setRedBloodCell((microscopic_test_redblood) => microscopic_test_redblood.concat(data[i].microscopic_test_redblood === null ? 0 : data[i].microscopic_test_redblood))
            setHyalineCast((microscopic_test_hyaline) => microscopic_test_hyaline.concat(data[i].microscopic_test_hyaline === null ? 0 : data[i].microscopic_test_hyaline))
            setWBCCast((microscopic_test_wbc) => microscopic_test_wbc.concat(data[i].microscopic_test_wbc === null ? 0 : data[i].microscopic_test_wbc))
            setRBCCast((microscopic_test_rbc) => microscopic_test_rbc.concat(data[i].microscopic_test_rbc === null ? 0 : data[i].microscopic_test_rbc))
            setFineGranularCast((microscopic_test_fine_granular) => microscopic_test_fine_granular.concat(data[i].microscopic_test_fine_granular === null ? 0 : data[i].microscopic_test_fine_granular))
            setCoarseGranularCast((microscopic_test_coarse_granular) => microscopic_test_coarse_granular.concat(data[i].microscopic_test_coarse_granular === null ? 0 : data[i].microscopic_test_coarse_granular))

            setLabel((label) => label.concat(Notify.dateTimeConvert(data[i].created_at)))
        }
    }

    const initializeChartWidth = () => {
        chartRef.current.chartInstance.canvas.parentNode.style.width = `${label.length > 10 ? 2000 : containerRef.current && containerRef.current.clientWidth - 47}px`;
        chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(85vh - 150px)`;

        interval = setInterval(() => {
            chartRef.current.chartInstance.canvas.parentNode.style.width = `${label.length > 10 ? 2000 : containerRef.current && containerRef.current.clientWidth - 47}px`;
            chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(85vh - 150px)`;
        }, 5000);
    }

    useEffect(() => {
        if (chartRef.current) {
            getClinicalMicroscopyRecord()
            initializeChartWidth()
        }

        return (() => clearInterval(interval))

        //eslint-disable-next-line
    }, [getClinicalMicroscopyRecord])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHideLegend = (type, key) => {
        if (type === 'all') {
            chartRef.current.chartInstance.data.datasets.forEach(function (ds) {
                ds.hidden = !ds.hidden;
            });
        } else {
            chartRef.current.chartInstance.data.datasets[key].hidden = !chartRef.current.chartInstance.data.datasets[key].hidden;
        }
        chartRef.current.chartInstance.update();
    }

    const data = {
        labels: label,
        datasets: [
            {
                label: 'PH',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: green[600],
                borderColor: green[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: pH
            },
            {
                label: 'Specific Gravity',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: blue[600],
                borderColor: blue[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: specificGravity
            },
            {
                label: 'Pus Cell',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: purple[600],
                borderColor: purple[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: pusCell
            },
            {
                label: 'Red Blood Cell',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: indigo[600],
                borderColor: indigo[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: redBloodCell
            },
            {
                label: 'Hyaline Cast',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: red[600],
                borderColor: red[500],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: hyalineCast
            },
            {
                label: 'WBC Cast',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: teal[600],
                borderColor: teal[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: wBCCast
            },
            {
                label: 'RBC Cast',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: amber[600],
                borderColor: amber[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: rBCCast
            },
            {
                label: 'Fine Granular Cast',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: deepOrange[600],
                borderColor: deepOrange[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: fineGranularCast
            },
            {
                label: 'Coarse Granular Cast',
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: yellow[600],
                borderColor: yellow[600],
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5,
                lineTension: 0,
                data: coarseGranularCast
            }
        ]
    }

    const options = {
        animationEnabled: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
            cursor: 'pointer',
            display: false,
        },
        tooltips: {
            enabled: true, 
            backgroundColor: themes.palette.primary.main 
        },
        animation: {
            onComplete: () => {
                const copyWidth = chartRef.current.chartInstance.scales['y-axis-0'].width - 10;
                const copyHeight = chartRef.current.chartInstance.scales['y-axis-0'].height + chartRef.current.chartInstance.scales['y-axis-0'].top + 10;
                const targetCtx = document.getElementById("myChartAxis").getContext("2d");
                targetCtx.canvas.width = copyWidth;
                targetCtx.drawImage(chartRef.current.chartInstance.canvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
            }
        }
    }

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'clinical microscopy',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Laboratory', path: '/clinic/app/patient/laboratory' }
                    ]
                }}
                title="Clinical Microscopy"
            >
                <Box component={Paper} padding={2} ref={containerRef}>
                    <ChartHeader handleHideLegend={handleHideLegend} handleClick={handleClick} />

                    <Box display="flex">

                        <Box flexGrow={1} >
                            {!ready &&
                                <Box align="center">
                                    <CircularProgress size={25} />
                                    <Typography color="primary" variant="subtitle2" >
                                        fetching clinical microscopy records...
                                    </Typography>
                                </Box>
                            }

                            <div className="chartWrapper">
                                <div
                                    style={{
                                        width: `${containerRef.current && containerRef.current.clientWidth - 30}px`,
                                        overflowX: 'auto'
                                    }}
                                >
                                    <div>
                                        <Line
                                            ref={chartRef}
                                            data={chartRef && data}
                                            options={chartRef && options}
                                        />
                                    </div>
                                </div>
                                <canvas style={{ display: 'none' }} id="myChartAxis" height="100%" width="0"></canvas>
                            </div>
                        </Box>

                        <Box ml={2}>
                            {chartRef && (
                                <RenderHemaOptions
                                    anchorEl={anchorEl}
                                    handleClose={handleClose}
                                    handleClick={handleClick}
                                    chartJs={chartRef.current}
                                    handleHideLegend={handleHideLegend}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Fragment>
    );
}

export default GraphClinicalMicroscopy;

const ChartHeader = ({ handleClick, handleHideLegend }) => (
    <Box display="flex" mb={2}>
        <Box flexGrow={1} />
        <Box mx={2}>
            <Button startIcon={<Tag size={15} />} variant="outlined" size="large" color="primary" onClick={handleClick}>
                <Hidden xsDown> Graph </Hidden> Legend
            </Button>
        </Box>
    </Box>
)

const RenderHemaOptions = ({ anchorEl, handleClose, chartJs, handleHideLegend }) => {

    const [hideAll, setHideAll] = useState(false)

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: 300,
                    overflow: ' none',
                }
            }}
        >
            <MenuItem>
                <FormControlLabel
                    className={`gtc-uppercase`}
                    control={
                        <Checkbox
                            checked={!hideAll}
                            onChange={() => {
                                handleHideLegend('all', 0)
                                setHideAll(!hideAll)
                            }}
                        />
                    }
                    label={`Hide All`}
                />
            </MenuItem>

            { chartJs && chartJs.chartInstance && (
                chartJs.chartInstance.legend.legendItems.map((data, index) => (
                    <MenuItem
                        key={index}
                    >
                        <FormControlLabel
                            onClick={() => handleHideLegend('byone', index)}
                            className={`gtc-uppercase`}
                            control={
                                <Box ml={1} mr={2}>  <BoxIcon color={data.hidden ? grey[200] : data.strokeStyle} /> </Box>
                            }
                            label={data.text}
                        />
                    </MenuItem>
                ))
            )}
        </Menu>
    );
}
