
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import 'firebase/messaging';   // for cloud messaging

const config = {
    apiKey: "AIzaSyAwHDzrOpSrLTPhD0xo4osWyqBtUrRWv2M",
    authDomain: "gtcsystem-1558318747006.firebaseapp.com",
    databaseURL: "https://gtcsystem-1558318747006-default-rtdb.firebaseio.com",
    projectId: "gtcsystem-1558318747006",
    storageBucket: "gtcsystem-1558318747006.appspot.com",
    messagingSenderId: "949315632600",
    appId: "1:949315632600:web:36fd85d566a7bd8f2006ad",
    measurementId: "G-9EL0FBEXFH"
}


firebase.initializeApp(config); 

export default firebase;