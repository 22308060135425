import React from "react";
import { Box, Button, Typography } from "@material-ui/core"; 
import Axios from "axios";
import Notify from "src/notification/Notify"; 

const token = localStorage.getItem("token");
const user_id = localStorage.getItem("user_id"); 

const RemoveService = ({ open, details, close, getServiceList }) => {
  const handleRemoveItem = async () => {
    try {
      var formdata = new FormData();
      formdata.set("token", token);
      formdata.set("user_id", user_id);
      formdata.set("service_id", details.service_id);

      const request = await Axios.post(
        "doctors/services/remove-service",
        formdata
      );

      if (request.data.message === "success") {
        Notify.successRequest("update service");
        getServiceList();
        close();
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      console.log("message:", message);
    }
  };

  return (
    <Box>
      <Box>
        <Typography>Are you sure to remove this service?</Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Box mr={1}>
          <Button color="default" variant="contained" onClick={() => close()}>
            No
          </Button>
        </Box>

        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleRemoveItem()}
        >
          Yes
        </Button>
      </Box>
    </Box>
  );
};

export default RemoveService;
