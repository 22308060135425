import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const CBCOrder = ({ formheader, cbcHemaOrderDetails }) => {
  return (
    <>
      {cbcHemaOrderDetails.ready ? (
        cbcHemaOrderDetails.data.length > 0 ? (
          cbcHemaOrderDetails.data.map((data, key) => (
            <Fragment key={key}>
              <Card
                variant="outlined"
                component={Box}
                mb={5}
                className="pagebreaker-before"
              >
                <Box display="flex" justifyContent="center">
                  <Box mt={2}>
                    {formheader && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                      />
                    )}
                  </Box>
                  <Box>
                    <CardHeader
                      component={Box}
                      align="center"
                      title={formheader && formheader.name}
                      subheader={formheader && formheader.address}
                    />
                  </Box>
                </Box>

                <CardContent>
                  <Header details={cbcHemaOrderDetails} />
                </CardContent>

                <CardContent>
                  <Box>
                    {Boolean(parseInt(data.cbc)) && (
                      <Box>
                        <Box display="flex" my={2}>
                          <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                              <b> HEMATOLOGY </b>
                            </Typography>

                            <Typography variant="body1" align="center">
                              <b> COMPLETE BLOOD COUNT </b>
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> WBC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.wbc} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> LYM : </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    {" "}
                                    <b> {data.lym} </b>{" "}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MID : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mid} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> NEUT : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.neut} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography> RBC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.rbc} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> HGB : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.hgb} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> HTC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.hct} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MCV : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mcv} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MCH : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mch} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MCHC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mchc} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> RDW-SD : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.rdw_sd} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> RDW-CV : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.rdw_cv} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MPV : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mpv} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> PDW : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.pdw} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> PCT : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.pct} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography> P-LCR : </Typography>
                            </Box>
                            <Box>
                              <Typography align="center">
                                <b> {data.p_lcr} </b>
                              </Typography>
                            </Box>
                          </Box>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography> REMARKS : </Typography>
                            </Box>
                            <Box>
                              <Typography align="center">
                                <b> {data.remarks} </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {Boolean(parseInt(data.cbc_platelet)) && (
                      <Box my={3}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                              <b> HEMATOLOGY </b>
                            </Typography>

                            <Typography variant="body1" align="center">
                              <b> COMPLETE BLOOD COUNT WITH PLATELET </b>
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          mt={3}
                          hidden={!Boolean(parseInt(data.is_processed))}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> WBC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.wbc} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> LYM : </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    {" "}
                                    <b> {data.lym} </b>{" "}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MID : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mid} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> NEUT : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.neut} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> RBC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.rbc} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> HGB : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.hgb} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> HTC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.hct} </b>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MCV : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mcv} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MCH : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mch} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MCHC : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mchc} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> RDW-SD : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.rdw_sd} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> RDW-CV : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.rdw_cv} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> MPV : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.mpv} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> PDW : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.pdw} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography> PCT : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.pct} </b>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box mt={2} display="flex">
                                <Box width={90}>
                                  <Typography> PLT : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.plt} </b>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mt={2} display="flex">
                                <Box width={90}>
                                  <Typography> P-LCR : </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    <b> {data.p_lcr} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography> REMARKS : </Typography>
                            </Box>
                            <Box>
                              <Typography align="center">
                                <b> {data.remarks} </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box>
                    <Footer formheader={formheader} />
                  </Box>
                </CardContent>
              </Card>

              <div className="pagebreaker-before" />
            </Fragment>
          ))
        ) : (
          <Typography color="secondary" align="center">
            No record found.
          </Typography>
        )
      ) : (
        <Typography color="primary" align="center">
          loading, please wait...
        </Typography>
      )}
    </>
  );
};

export default CBCOrder;
