import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Grid,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import PatientDetails from "./PatientDetails";
import Notify from "src/notification/Notify";
import Label from "src/utils/Label";
import RescheduleAppointment from "./RescheduleAppointment";
import ContactInformation from "./ContactInformation";
import MessagePatient from "./MessagePatient";
import { Book, Clock, Mail, XCircle } from "react-feather";

const OnlineAppointmentDetails = ({ app, getOnlineAppointment }) => {
  const [reshedApp, setReschedApp] = useState({
    data: null,
    open: false,
  });

  const [contactDialog, setContactDialog] = useState({
    data: null,
    open: false,
  });

  const [msgDialog, setMsgDialog] = useState({
    data: null,
    open: false,
  });

  return (
    <>
      <Box mb={2}>
        <PatientDetails patient_id={app.patient_id} />
      </Box>

      <Box component={Paper} p={2}>
        <Box display="flex">
          <Box flexGrow={1} mt={1}>
            <Typography variant="h5"> Details </Typography>
          </Box>

          <Box>
            <Tooltip arrow title="Reschedule">
              <IconButton
                color="primary"
                onClick={() =>
                  setReschedApp({
                    data: app,
                    open: true,
                  })
                }
              >
                <Clock />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Message">
              <IconButton
                color="primary"
                onClick={() =>
                  setMsgDialog({
                    data: app,
                    open: true,
                  })
                }
              >
                <Mail />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Contact Information">
              <IconButton
                color="primary"
                onClick={() =>
                  setContactDialog({
                    data: app,
                    open: true,
                  })
                }
              >
                <Book />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box className={`gtc-textfield-noborder`} mt={2}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Appointment Date"
              InputProps={{
                readOnly: true,
              }}
              value={Notify.dateTimeConvert(app.appointment_date)}
              variant="outlined"
            />
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              label="Appointment Reason"
              InputProps={{
                readOnly: true,
              }}
              value={app.appointment_reason}
              multiline
              variant="outlined"
            />
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              label="Appointment Service"
              InputProps={{
                readOnly: true,
              }}
              value={app.doctors_service}
              variant="outlined"
            />
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              label="Appointment Fee"
              InputProps={{
                readOnly: true,
              }}
              value={app.doctors_service_amount}
              variant="outlined"
            />
          </Box>

          {Boolean(app.is_reschedule) && (
            <>
              <Box my={1}>
                <Label
                  color={Boolean(app.is_reschedule) ? "success" : "primary"}
                >
                  RESCHEDULE : {Boolean(app.is_reschedule) ? "Yes" : "No"}
                </Label>
              </Box>

              <Grid item xs={12} sm={12}>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    label={"Reschedule Date"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    defaultValue={Notify.dateTimeConvert(
                      app.is_reschedule_date
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    fullWidth
                    label={"Reschedule Reason"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    defaultValue={app.is_reschedule_reason}
                    multiline
                  />
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>

      {/* dialog reschedule online app */}
      {/* appointment reshedule */}
      <Dialog
        open={reshedApp.open}
        onClose={() => setReschedApp({ data: null, open: false })}
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth="xs"
        scroll="body"
      >
        {reshedApp.data && reshedApp.open && (
          <>
            <DialogTitle>Reschedule Appointment</DialogTitle>

            <RescheduleAppointment
              appid={reshedApp.data.appointment_id}
              appdate={
                Boolean(reshedApp.data.is_reschedule)
                  ? reshedApp.data.appointment_dateonline
                  : reshedApp.data.appointment_date
              }
              closeDialog={() => setReschedApp({ data: null, open: false })}
              getOnlineAppointment={getOnlineAppointment}
            />
          </>
        )}
      </Dialog>

      {/* contactinfo dialog */}
      <Dialog
        open={contactDialog.open}
        onClose={() => setContactDialog({ data: null, open: false })}
        scroll="body"
      >
        {contactDialog.open && contactDialog.data && (
          <>
            <DialogTitle>
              <Box display="flex">
                <Box flexGrow={1}> Contacts </Box>
                <Box>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setContactDialog({ data: null, open: false })
                    }
                  >
                    <XCircle />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <ContactInformation patient_id={contactDialog.data.patient_id} />
          </>
        )}
      </Dialog>

      {/* patient_id
            appid
            closeDialog */}

      {/* messsage patient dialog */}
      <Dialog
        open={msgDialog.open}
        onClose={() => setMsgDialog({ data: null, open: false })}
        scroll="body"
      >
        {msgDialog.data && msgDialog.open && (
          <>
            <DialogTitle>Message Patient</DialogTitle>
            <MessagePatient
              patient_id={msgDialog.data.ppp_id}
              appid={msgDialog.data.appointment_id}
              closeDialog={() => setMsgDialog({ data: null, open: false })}
            />
          </>
        )}
      </Dialog>
    </>
  );
};

export default OnlineAppointmentDetails;
