import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Male from "./Male";
import Female from "./Female";
import { Formik } from "formik";
import * as Yup from "yup";
import Notify from "src/notification/Notify";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import IsMountedRef from "src/utils/IsMountedRef";

const ChildWeight = ({ info, patient_id, connection }) => {
  const [dialog, setDialog] = useState(false);
  const [list, setList] = useState([]);
  const mounted = IsMountedRef();
  const token = localStorage.getItem("token");

  const getGrowthDataByPatient = React.useCallback(async () => {
    let response = await Axios.get("doctors/patients/childs/get-growthlist", {
      params: {
        token,
        patient_id,
        type: "weight",
      },
    });
    if (mounted.current) {
      let data = response.data;
      setList(data);
    }
  }, [mounted, token, patient_id]);

  useEffect(() => {
    getGrowthDataByPatient();
  }, [getGrowthDataByPatient]);

  return (
    <Paper component={Box} mt={2}>
      <Box p={2} display="flex">
        <Box flexGrow={1}>
          <Typography color="textSecondary" variant="h6">
            Weight
          </Typography>
        </Box>
        <Box>
          <Button color="primary" onClick={() => setDialog(true)}>
            Update
          </Button>
        </Box>
      </Box>

      <Box p={2}>
        {info.length > 0 ? (
          info[0].gender && info[0].gender.toLowerCase() === "male" ? (
            <Male list={list} />
          ) : (
            <Female list={list} />
          )
        ) : (
          "Patient gender not set."
        )}
      </Box>

      {/*  */}
      <NewGrowthRecord
        patient_id={patient_id}
        connection={connection}
        open={dialog}
        close={() => setDialog(false)}
        action={() => getGrowthDataByPatient()}
      />
    </Paper>
  );
};

export default ChildWeight;

const NewGrowthRecord = ({ patient_id, connection, open, close, action }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const year = [
    {
      label: "Birth",
      value: "birth",
    },
    {
      label: "1 Year",
      value: "first-year",
    },

    {
      label: "2 Year",
      value: "second-year",
    },

    {
      label: "3 Year",
      value: "third-year",
    },

    {
      label: "4 Year",
      value: "fourth-year",
    },

    {
      label: "5 Year",
      value: "fifth-year",
    },
  ];

  const months = [
    {
      label: 0,
      value: 0,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 6,
      value: 6,
    },
    {
      label: 8,
      value: 8,
    },
    {
      label: 10,
      value: 10,
    },
  ];

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth>
      <DialogTitle> New Weight </DialogTitle>
      <Formik
        initialValues={{
          user_id: user_id,
          token: token,
          patient_id: patient_id,
          connection: connection,
          year: "",
          scale: "",
          month: "0",
          type: "weight",
        }}
        validationSchema={Yup.object().shape({
          year: Yup.string().required(),
          month: Yup.string().required(),
          scale: Yup.string().required(),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
          try {
            const request = await Axios.post(
              "doctors/patients/childs/new-growth",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("new growth");
              resetForm();
              close();
              action();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  label="Year"
                  variant="outlined"
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.year && errors.year)}
                  helperText={touched.year && errors.year}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.year}
                  name="year"
                  required
                >
                  <option value=""> Select </option>
                  {year.map((data, index) => {
                    return (
                      <option key={index} value={data.value}>
                        {data.label}
                      </option>
                    );
                  })}
                </TextField>
              </Box>

              <Box my={2}>
                {values.year === "fifth-year" && (
                  <TextField
                    label="Weight"
                    fullWidth
                    variant="outlined"
                    name="scale"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 200,
                      },
                    }}
                    error={Boolean(touched.scale && errors.scale)}
                    helperText={touched.scale && errors.scale}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.scale}
                    required
                  />
                )}
                {values.year !== "fifth-year" && (
                  <>
                    <TextField
                      label="Months"
                      variant="outlined"
                      fullWidth
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      name="month"
                      error={Boolean(touched.month && errors.month)}
                      helperText={touched.month && errors.month}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.month}
                      required
                    >
                      <option value=""> Select </option>
                      {months.map((data, index) => {
                        return (
                          <option key={index} value={data.value}>
                            {data.label}
                          </option>
                        );
                      })}
                    </TextField>
                    <Box my={2}>
                      <TextField
                        label="Height/Length(cm)"
                        variant="outlined"
                        fullWidth
                        name="scale"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 200,
                          },
                        }}
                        error={Boolean(touched.scale && errors.scale)}
                        helperText={touched.scale && errors.scale}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.scale}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<HighlightOff />}
              >
                No
              </Button>

              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
