import React, { useEffect, useState } from 'react';  
import Notify from 'src/notification/Notify';
import Axios from 'axios';   
import { Button, Box, Typography, Paper, TextField, CircularProgress, Badge } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'; 
// import CheckingConnection from 'src/CheckInternet';
// import NoInternet from 'src/NoInternet';
// import { Autocomplete } from '@material-ui/lab'; 
import { MenuOutlined } from '@material-ui/icons';
import ProcessUnsaveOrder from './ProcessUnsaveOrder';

const CreateOrder = ({ 
    patient_id, 
    // getCountImaging
 }) =>{  
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const management_id = localStorage.getItem('management_id')

    const [imaging, setImaging] = useState({ data: [] , ready: false })
    const [exams, setExams] = useState({ data: [] , ready: false })
    const [examsDetails, setExamsDetails] = useState({ data: [] , ready: false })
    
    // const [selectedImagingManagementId, setSelectedImagingManagementId] = useState(null)  
    const isProcess = false; 

    // const [selectedOrder, setSelectedOrder] = useState([])

    const [ unsaveList, setUnsateList ] = useState([])
    const [ unsaveDialog, setUnsaveDialog] = useState(false)
 
    const getImagingByManagementId = () =>{
        var formdata = new FormData();   
        formdata.set('user_id', user_id);    
        formdata.set('token', token);    
        formdata.set('management_id', management_id);     

        Axios.post('imaging/imaging-details', formdata )
        .then( (response) => {  
            const data = response.data;    
            setImaging({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const getImagingExams = (e) =>{
        var vmanagementId = e.target.value;
        var formdata = new FormData();   
        formdata.set('user_id', user_id);    
        formdata.set('token', token);     
        formdata.set('vmanagementId', vmanagementId);     

        Axios.post('doctor/imaging/local/imaging-orderlist', formdata )
        .then( (response) => {  
            const data = response.data;    
            setExams({ data, ready: true }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const getImagingExamsDetails = (e) =>{
        var order_id = e.target.value;
        var formdata = new FormData();   
        formdata.set('user_id', user_id);    
        formdata.set('token', token);    
        formdata.set('order_id', order_id);     

        Axios.post('doctor/imaging/local/imaging-orderlist/details', formdata )
        .then( (response) => {  
            const data = response.data;    
            setExamsDetails({ data, ready: true }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const getUnsaveList = () => { 
        var formdata = new FormData();   
        formdata.set('token', token);      
        formdata.set('user_id', user_id);       
        formdata.set('management_id', management_id);    
        formdata.set('patient_id', patient_id);       

        Axios.post('doctor/imaging/local/imaging-orderlist/unsave', formdata )
        .then( (response) => {  
            const data = response.data;    
            setUnsateList(data)
            setExamsDetails({ data: [], ready: false })
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const handleAddOrder = (e) => {
        e.preventDefault()
        e.persist()
 
        var formdata = new FormData(e.target);   
        formdata.set('token', token);      
        formdata.set('user_id', user_id);       
        formdata.set('management_id', management_id);     
        formdata.set('patient_id', patient_id);     

        Axios.post('doctor/imaging/local/order-add', formdata )
        .then( (response) => {  
            const data = response.data;    
            if(data === 'success'){
                Notify.successRequest('order added')
                getUnsaveList()
                e.target.reset()
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });   
    }

    useEffect(() =>{ 
        
        getImagingByManagementId()
        getUnsaveList()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[patient_id])

    return(
        <>
            <Paper elevation={2} component={Box} p={2}>
                <Box>
                    <Typography variant="h6" color="textSecondary"> 
                        Create Local Order
                    </Typography>
                </Box> 

                <Box>
                    <form onSubmit = { handleAddOrder }>  
                        <Box my={3}>  
                            <TextField 
                                select
                                label="Imaging Center" 
                                onChange={ (e) => getImagingExams(e) }
                                SelectProps={{ native: true }} 
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                fullWidth
                                name="imaging_center"
                            >
                                <option value=""> Select </option>
                                { 
                                    imaging.ready ?
                                        imaging.data.length > 0 ?
                                            imaging.data.map((data, index) => (
                                                <option key={index} value={ data.management_id } > { data.name } </option> 
                                            ))
                                        : <option value="" disabled> No imaging found </option>
                                    : <option value="" disabled> please wait... </option>
                                }
                            </TextField>
                        </Box> 

                        <Box my={3}>  
                            <TextField 
                                select
                                label="Imaging Order" 
                                onChange={ (e) => getImagingExamsDetails(e) }
                                SelectProps={{ native: true }} 
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                fullWidth
                                name="imaging_order_id"
                            >
                                <option value=""> Select </option>
                                { 
                                    exams.ready ?
                                        exams.data.length > 0 ?
                                            exams.data.map((data, index) => (
                                                <option key={index} value={ data.order_id } > { data.order_desc } </option> 
                                            ))
                                        : <option value="" disabled> No imaging order </option>
                                    : <option value="" disabled> please wait... </option>
                                }
                            </TextField>
                        </Box>  
                        
                        {
                            examsDetails.ready && Object.values(examsDetails.data).length > 0 && (
                                <>
                                    <Box mb={3}>
                                        <TextField 
                                            name="order"
                                            label="Order"
                                            variant="outlined"
                                            fullWidth
                                            hidden
                                            defaultValue={examsDetails.data.order_desc} 
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Box>  

                                    <Box mb={3}>
                                        <TextField 
                                            name="amount"
                                            label="Amount"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={examsDetails.data.order_cost} 
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Box>   
                                </>
                            )
                        }

                        <Box mb={3}>
                            <TextField 
                                name="remarks"
                                label="Remarks"
                                variant="outlined"
                                fullWidth
                                rows={4}
                            />
                        </Box>   
                        
                        <Box display="flex">
                            <Box mr={2}>
                                <Button 
                                    variant="contained"
                                    startIcon={ <HighlightOffIcon /> }
                                    color="default"
                                    type="reset"
                                > 
                                    No 
                                </Button>
                            </Box>

                            <Box mr={2}>
                                <Button 
                                    type="submit"
                                    disabled={imaging.data.length > 0 ? isProcess ? true :false : true}
                                    variant="contained"
                                    startIcon={ isProcess ? <CircularProgress size={20} color="inherit" /> : <CheckCircleOutlineIcon /> }
                                    color="primary"
                                >
                                    Add Order 
                                </Button> 
                            </Box> 

                            <Badge badgeContent={unsaveList.length} color="primary">
                                <Button 
                                    type="button" 
                                    variant="contained"
                                    startIcon={ isProcess ? <CircularProgress size={20} color="inherit" /> : <MenuOutlined /> }
                                    color="secondary" 
                                    onClick={() => setUnsaveDialog(true) }
                                    disabled={ ! unsaveDialog && unsaveList.length === 0 }
                                >
                                    Unsave Order 
                                </Button>  
                            </Badge>
                        </Box>
                    </form>
                </Box> 

                {/* dialog  unsave list */}
                { unsaveDialog && unsaveList.length > 0 && (
                    <ProcessUnsaveOrder 
                        unsaveList = { unsaveList }
                        open = { unsaveDialog } 
                        close = { () => setUnsaveDialog(false) }
                        getUnsaveList = { () => getUnsaveList() }
                        patient_id = { patient_id }
                    />
                )}

            </Paper> 
        </>
    )
}

export default CreateOrder; 