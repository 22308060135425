import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";

import axios from "axios";
import { Clipboard, Database, Home, Truck, ShoppingCart } from "react-feather";
import Logout from "src/welcome/Logout";
import Sidebar from "src/layout/Sidebar";
import PharmacyWarehouse from "../pharmacy_warehouse";
import PharmacyWarehouseProducts from "src/pharmacy_warehouse/products";
import PharmacyWarehouseInventory from "src/pharmacy_warehouse/inventory";
import PharmacyWarehouseNewInvoice from "src/pharmacy_warehouse/inventory/PharmacyWarehouseNewInvoice";
import PharmacyWarehouseAccounts from "src/pharmacy_warehouse/accounts";
import DeliveredProducts from "src/pharmacy_warehouse/delivery/DeliveredProducts";
import DrProductByAccount from "src/pharmacy_warehouse/accounts/DrProductByAccount";

const RoutePharmacyWarehouse = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [sidebarHeader, setSidebarHeader] = useState([]);
  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get("pharmacy/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app",
      subitem: [],
    },
    {
      name: "Products",
      show: true,
      icon: <Clipboard color="white" />,
      path: "/clinic/app/warehouse/products",
      subitem: [],
    },

    {
      name: "Inventory",
      show: true,
      icon: <Database color="white" />,
      path: "/clinic/app/warehouse/inventory",
      subitem: [],
    },

    {
      name: "DR Products",
      show: true,
      icon: <ShoppingCart color="white" />,
      path: "/clinic/app/warehouse/delivered",
      subitem: [],
    },

    {
      name: "DR Accounts",
      show: true,
      icon: <Truck color="white" />,
      path: "/clinic/app/warehouse/accounts",
      subitem: [],
    },
  ];

  useEffect(() => {
    getSidebarHeaderInfo();

    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <BrowserRouter>
        <Sidebar
          notification={{
            enable: true,
            local: [],
            virtual: [],
            owner: "warehouse",
          }}
          header={sidebarHeader}
          routes={sidebarRoute}
          module={"stockroom"}
        />

        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app/warehouse" />}
          />
          <Route
            exact
            path="/clinic/app"
            component={() => <Redirect to="/clinic/app/warehouse" />}
          />
          <Route
            exact
            path="/clinic/app/warehouse"
            component={PharmacyWarehouse}
          />
          <Route
            exact
            path="/clinic/app/warehouse/products"
            component={PharmacyWarehouseProducts}
          />
          <Route
            exact
            path="/clinic/app/warehouse/inventory"
            component={PharmacyWarehouseInventory}
          />
          <Route
            exact
            path="/clinic/app/warehouse/delivered"
            component={DeliveredProducts}
          />
          <Route
            exact
            path="/clinic/app/warehouse/accounts"
            component={PharmacyWarehouseAccounts}
          />

          <Route
            exact
            path="/clinic/app/warehouse/inventory/new-invoice"
            component={PharmacyWarehouseNewInvoice}
          />

          <Route
            exact
            path="/clinic/app/warehouse/accounts/:account_id"
            component={DrProductByAccount}
          />

          <Route exact path="/clinic/app/logout" component={Logout} />
          <Route render={() => <PageNotFound title="Page not found" />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default RoutePharmacyWarehouse;
