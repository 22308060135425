import React, { useEffect, Fragment, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  makeStyles,
  Paper,
  Dialog,
  DialogContent,
} from "@material-ui/core";

import TabPanel from "src/utils/TabPanel";
import ReferralLetter from "./ReferralLetter";
import MedicalCert from "./MedicalCert";
const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

const DoctorLetters = (props) => {
  const classes = useStyle();
  const [category, setCategory] = useState("referral");
  const [printDialog, setPrintDialog] = useState(false);

  useEffect(() => {}, [props.patient_id]);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
          <Grid item xs={12} sm={12} md={10}>
            <Box>
              <TabPanel value={category} index={"referral"}>
                <ReferralLetter
                  patient_id={props.patient_id}
                  setPrintDialog={setPrintDialog}
                  printDialog={printDialog}
                />
              </TabPanel>

              <TabPanel value={category} index={"medicalcert"}>
                <MedicalCert
                  patient_id={props.patient_id}
                  setPrintDialog={setPrintDialog}
                  printDialog={printDialog}
                />
              </TabPanel>
            </Box>
          </Grid>
        </Box>
        <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
          <Grid item xs={12} sm={12} md={2}>
            <Paper variant="outlined">
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                variant="scrollable"
                orientation={
                  window.innerWidth > 959 ? "vertical" : "horizontal"
                }
                classes={{
                  indicator: classes.indicatorLeft,
                }}
              >
                <Tab wrapped value="referral" label="Refferal Letter" />
                <Tab wrapped value="medicalcert" label="Medical Certificate" />
              </Tabs>
            </Paper>
          </Grid>
        </Box>
      </Grid>

      <RenderPrintDialog
        printDialog={printDialog}
        patient_id={props.patient_id}
        setPrintDialog={setPrintDialog}
        category={category}
      />
    </Fragment>
  );
};

export default DoctorLetters;

const RenderPrintDialog = ({
  printDialog,
  patient_id,
  setPrintDialog,
  category,
}) => (
  <Dialog fullScreen open={printDialog} onClose={() => setPrintDialog(false)}>
    <DialogContent>
      {category === "referral" ? (
        <ReferralLetter
          patient_id={patient_id}
          setPrintDialog={setPrintDialog}
          printDialog={printDialog}
        />
      ) : (
        <MedicalCert
          patient_id={patient_id}
          setPrintDialog={setPrintDialog}
          printDialog={printDialog}
        />
      )}
    </DialogContent>
  </Dialog>
);
