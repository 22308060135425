
import React, {  useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, FormHelperText, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, TextField, Tooltip, Typography, Badge } from "@material-ui/core";     
import Notify from 'src/notification/Notify';
import { useHistory, useParams } from 'react-router-dom';
import { blue, deepOrange, green,  red } from '@material-ui/core/colors';
import { Alert, AlertTitle } from '@material-ui/lab';
import OnlinechecupChat from 'src/oncheckupchat/OnlinechecupChat';
import PermissionRequest from './PermissionRequest';
// import PatientHeader from '../patients/PatientHeader';
import ChatIcon from '@material-ui/icons/QuestionAnswer';
import ProfileIcon from '@material-ui/icons/PermContactCalendar'; 
import HighlightOff from '@material-ui/icons/HighlightOff' 
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import axios from 'axios';
import * as Yup from 'yup'; 
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff';  
import VcallPatientHeader from './VcallPatientHeader';


const RTCMultiConnection = require('rtcmulticonnection');

const CreateRoomOLd = () => { 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const history = useHistory()

    let myvideoRef = useRef(null)  
    let clientvideoRef = useRef(null) 

    const [ validreq, setValidReq] = useState(false)
    const [ prepared, setPrepared] = useState(false) 

    const [callTimeMinutes, setCallTimeMinutes] = useState(0)
    const [callTimeSeconds, setCallTimeSeconds] = useState(0)

    const [refDetails, setRefDetails] = useState(null)
    const [drawer, setDrawer] = useState('') 

    const [hasPermit, setHasPermitted] = useState(null)
    const [doneDialog, setDoneDialog] = useState(false)
    const [buttonExitDisabled, setButtonExitDisabled] = useState(true)

    const [ redial, setRedial ] = useState(false)


    const [ exitDialog, setExitDialog] = useState(false)

    const [remoteId, setRemoteId] = useState(null)
    const [con, setCon] = useState(null)
    const { ref_number } = useParams()  

    const [unreadMsg, setUnreadMsg] = useState(0)

    const room_number = Notify.randomizeString(ref_number)  

    var timeInterval = null; 
    var msgInterval = null; 
    var connection = null;

    const [ myvideoIsLoaded, setMyvideoIsLoaded ] = useState(false)
    const [ myClientvideoIsLoaded, setClientMyvideoIsLoaded ] = useState(false) 


    const getRefDetails = () =>{ 
        var formdata = new FormData();   
            formdata.append('ref_number' , ref_number)
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/appointment/getrefdetails', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(Object.keys(data).length > 0){
                createVCallRoom(data)
                setRefDetails(data)
                intializeGetUnreadMsgInterval(data.patient_id)
            }else{
                history.push('/clinic/app/pagenotfound')
            }
        }).catch(error=>{  
            Notify.requestError(error);
        });
    }

    const createVCallRoom = (details) =>{ 
        var formdata = new FormData();   
            formdata.append('ref_number' , ref_number)
            formdata.append('room_number', room_number)
            formdata.append('token', token)
            formdata.append('doctors_userid', user_id)
            formdata.append('patient_userid', details.patient_id)
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/appointment/vcall/createroom', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(data === 'success'){ 
                checkMicandVideo(details)
            }else{
                history.push('/clinic/app/pagenotfound')
            }
        }).catch(error=>{  
            Notify.requestError(error);
        });
    }

    const checkMicandVideo  =  (details) => { 
        navigator.mediaDevices.getUserMedia({video:true, audio: true})
        .then(() => {
            setValidReq(true)
            setPrepared(true)
            initializeCall()
            getProfilePermission(details) 
        })
        .catch(() =>{ 
            setValidReq(false)
            setPrepared(true)
            Notify.customToast('Camera/Mic Not Found.', 'Enable your camera to continue.')
        }) 
    }   
 
    const initializeCall = () =>{
        connection = new RTCMultiConnection(); 
            connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
            connection.enableLogs = false; 
            connection.userid = `${username}`;
            connection.autoCreateMediaElement = false;
            connection.autoCloseEntireSession = false;
            connection.maxParticipantsAllowed = 2;
            connection.session = {
                audio : true,
                video : true
            }  

            connection.iceServers = []; 
        
            connection.iceServers.push({
                urls: "stun:ss-turn1.xirsys.com",
                url: "stun:ss-turn1.xirsys.com"
            })

            connection.iceServers.push({
                username: "k-Q-ZfWXGqvHPX9tVkr_lE3Lz6jBb6C35rcWuF5u7qi00zlap5EVdHIzpDMi_XWtAAAAAGA1z6NraW5nc2xheWVy",   
                credential: "ff1aeda4-7654-11eb-90f2-0242ac140004",   
                urls: [       
                    "turn:ss-turn1.xirsys.com:80?transport=udp",       
                    "turn:ss-turn1.xirsys.com:3478?transport=udp",       
                    "turn:ss-turn1.xirsys.com:80?transport=tcp",       
                    "turn:ss-turn1.xirsys.com:3478?transport=tcp",       
                    "turns:ss-turn1.xirsys.com:443?transport=tcp",       
                    "turns:ss-turn1.xirsys.com:5349?transport=tcp"   
                ]
            });   
            
            connection.sdpConstraints.mandatory = {
                OfferToReceiveAudio: true,
                OfferToReceiveVideo: true,
            }     
 
            var resoWidth = 1280;
            var resoHeight = 720;

            var supports = navigator.mediaDevices.getSupportedConstraints();

            var constraints = {};
            if (supports.width && supports.height) {
                constraints = {
                    width: resoWidth,
                    height: resoHeight
                };
            }

            connection.applyConstraints({
                video: constraints
            });
 
            connection.onstream = (event) => {    
                if (event.type === 'local') {   
                    setTimeout(() => { 
                        setMyvideoIsLoaded(true)  
                        var video = myvideoRef.current;  
                        video.srcObject = event.stream;
                        video.play()   
                    }, 3000); 
                }
            
                if (event.type === 'remote') {  
                    setTimeout(() => {  
                        setClientMyvideoIsLoaded(true)
                        setButtonExitDisabled(false)
                        var videox = clientvideoRef.current;  
                        videox.srcObject = event.stream;
                        videox.play()  
                        stopCallerBeep() 
                        startCallTimer()  
                        setRemoteId(event.userid)
                    }, 3000);  
                }
            };

            connection.onleave = function(event) {
                var remoteUserId = event.userid;  
                Notify.customToast(`${remoteUserId} is disconnected.`)
                setRedial(true)
                clearInterval(timeInterval)
            }; 
            
            var alreadyAllowed = {};

            connection.onNewParticipant = function(participantId, userPreferences) { 
                if(alreadyAllowed[participantId]) {
                    connection.addParticipationRequest(participantId, userPreferences);  
                    return;
                }

                connection.acceptParticipationRequest(participantId, userPreferences);  
                var message = `${participantId} is joining.`;
                Notify.customToast('Joining', message)    
                setRedial(false)
            };  

        connection.open(room_number);  
        setCon(connection)  
 
    }

    const getProfilePermission = (details) =>{
        var formdata = new FormData();   
            formdata.set('token', token);  
            formdata.set('doctors_id', user_id);    
            formdata.set('patient_id', details.patient_id);    
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/checkup/check-profilepermission', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data.length > 0){ setHasPermitted(true) }
            else{ setHasPermitted(false) }
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    const removeRoom = () => {   
        var formdata = new FormData();   
            formdata.set('token', token);  
            formdata.set('user_id', user_id);    
            formdata.set('app_ref_number', ref_number);    
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/appointment/delete-room', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data === 'success'){  
                console.log('room successfully destroy ')
                // Notify.customToast('Room Deleted', 'Room Successfully Destroy.')
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    } 

    const getLatestMessageFromPatient = (patientidssss) => {
        var formdata = new FormData();
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('senders_id', patientidssss)
        formdata.set('connection', 'online');

        axios.post('doctor/get/all-unread-msg', formdata)
        .then((response) => {
            const data = response.data;
            setUnreadMsg(parseInt(data.length))
        }).catch(error => {
            Notify.requestError(error);
        });
    }

    const intializeGetUnreadMsgInterval = (patientidssss) =>{ 
        msgInterval = setInterval(() => {  
            getLatestMessageFromPatient(patientidssss)
        }, 5000)
    } 

    const endCall = () =>{ 
        
        stopCallerBeep()
        removeRoom()

        if(con){
            var mystream = con.streamEvents.selectFirst({ local: true }); 
            mystream.stream.getVideoTracks().forEach(function(track) { track.stop(); });
            con.deletePeer(remoteId);
            con.closeSocket();
            con.getAllParticipants().forEach(function(participantId) {
                con.disconnectWith( participantId );
            });   
        }  
    } 

    const calculateTime = () =>{
        var x = parseInt(callTimeMinutes) / 60;
        var min = x < 10 ? `0${Math.floor(x)}` : Math.floor(x); 
        return min;
    }

    const startCallTimer = () => {   
        timeInterval = setInterval(() => {    
            setCallTimeSeconds((callTimeSeconds) => callTimeSeconds >= 59 ? 0 : callTimeSeconds + 1)   
            setCallTimeMinutes((callTimeMinutes) => callTimeMinutes + 1)  
        }, 1000); 
    }  

    const  getFormData = (object) => {
        const formData = new FormData(); 

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    } 
    
    const checkProfilePermission = () =>{
        setDrawer('drawer-profile')
        if(refDetails){
            getProfilePermission(refDetails)
        }
    } 

    const stopCallerBeep = () => {
        // if(callerBeepRef.current){ callerBeepRef.current.pause() }
    }

    const handleRecallPatient = () => {
        endCall()
        stopCallerBeep()
        getRefDetails()
        setRedial(false)
        setClientMyvideoIsLoaded(false) 
    }

    useEffect(() =>{        
 
        getRefDetails() 

        return(() => { 
            clearInterval(timeInterval)
            clearInterval(msgInterval)
            stopCallerBeep()
            endCall()
        }) 
        
         // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[ ])    

    return(

        <>     
            <Dialog
                open = { true } 
                disableBackdropClick 
                disableEscapeKeyDown
                fullScreen 
            >
                <DialogContent 
                    style={{ 
                        backgroundColor : '#000'
                    }}
                >         
                    {
                        prepared ? (
                            <>
                                {  validreq && ( 
                                    <> 
                                        {drawer === 'drawer-chat' &&(
                                            <Box>
                                                <Drawer variant="temporary" anchor={'left'} open={drawer === 'drawer-chat' ? true : false } onClose={ () => setDrawer('')}>
                                                    <Box width={ window.innerWidth > 900 ? 400 : window.innerWidth }>
                                                        <OnlinechecupChat connection = "online" client_id = {refDetails && refDetails.patient_id } close={ () => setDrawer('')} />
                                                    </Box>
                                                </Drawer> 
                                            </Box>
                                        )} 

                                        <Box>
                                            <Drawer variant="temporary" anchor={'left'} open={drawer === 'drawer-profile' ? true : false } onClose={ () => setDrawer('')}>
                                                <Box width={ window.innerWidth > 900 ? window.innerWidth - 350 : window.innerWidth }>
                                                    <Box display="flex" mb={1}>
                                                        <Box flexGrow={1} mt={2} ml={2}>
                                                            <Typography variant="h6" color="textSecondary"> 
                                                                Patient Medical Records
                                                            </Typography>
                                                        </Box>
                                                        <Box p={1}>
                                                            <IconButton 
                                                                color="secondary"
                                                                onClick={ () => setDrawer('') }
                                                            >
                                                                <HighlightOff />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>

                                                    {/* doctors has persmission */} 
                                                    { hasPermit && ( 
                                                        <Box p={2}>
                                                            <VcallPatientHeader connection = "online" patient_id = {refDetails && refDetails.patientId} getPatientsList = {() => console.log('console from vall patient header.') }/>
                                                        </Box>
                                                    )} 

                                                    {/* doctors has noooooo persmission */}  
                                                    { !hasPermit && (  
                                                        <Box p={2}>
                                                            <PermissionRequest connection = "online" patient_id = {refDetails && refDetails.patient_id} closeTab = { () => setDrawer('drawer-chat')} /> 
                                                        </Box>
                                                    )}  
                                                </Box>
                                            </Drawer> 
                                        </Box>    
                                            
                                        {/* video holder */} 
                                        <Box>
                                            {/* my video container */}
                                            <Box 
                                                className={`myvideotagOnWeb`}
                                            > 
                                                { ! myvideoIsLoaded && ( 
                                                    <Box  
                                                        className={'myvtagisloading'}
                                                        color="white"
                                                        align="center"
                                                    > <CircularProgress size={20} color="inherit" /> </Box> ) 
                                                }    
                                                <video   
                                                    playsInline 
                                                    ref={ myvideoRef }   
                                                    style={{  
                                                        objectFit: 'contain',   
                                                    }}
                                                    muted  
                                                />
                                            </Box>   

                                            {/* my client container */}
                                            <Box>
                                                <Box
                                                    className={`myClientVideotagOnWeb`}
                                                >    
                                                    { !redial && ! myClientvideoIsLoaded && ( 
                                                        <Box  
                                                            align="center" 
                                                            color="white"  
                                                            className={'myclientvtagisloading'}
                                                        >  <CircularProgress size={20} color="inherit" /> <br /> connecting patients video...</Box> ) 
                                                    }   
                                                    
                                                    <video  
                                                        playsInline  
                                                        ref={ clientvideoRef }  
                                                        style={{ 
                                                            objectFit: 'contain',  
                                                            borderRadius: '20px'
                                                        }} 
                                                    />  
                                                </Box>  

                                                { redial && (
                                                    <Box    
                                                        align="center" 
                                                    >  
                                                        <Typography style={{ color: 'white' }} variant="subtitle2"> Patient is leaving the room.</Typography> 
                                                        <Button size="small" color="primary" variant="contained" onClick={ handleRecallPatient }> Re-call patient </Button>   
                                                    </Box>
                                                )} 
                                            </Box>
                                        </Box>



                                        {/* buttons */} 
                                        { myvideoIsLoaded && (
                                            <>
                                                <Box m={1} mt={2} align="center">
                                                    <Tooltip title="Timer" arrow>
                                                        { window.innerWidth > 600 ?  
                                                                <Typography color="primary" variant="h5"> 
                                                                    { calculateTime() } : {callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} 
                                                                </Typography>
                                                            :   <Typography color="primary" variant="caption"> 
                                                                    { calculateTime() } : {callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} 
                                                                </Typography>
                                                        }
                                                    </Tooltip>
                                                </Box> 

                                            
                                                <Box 
                                                    display="flex" 
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    alignSelf="center" 
                                                    my={2}   
                                                    bottom={1}
                                                >  
                                                    <Box m={1}>
                                                        <Tooltip title="Profile" arrow>
                                                            <Button 
                                                                size={ window.innerWidth > 600 ? 'medium' : 'small'}
                                                                onClick={ checkProfilePermission } 
                                                                style={{ backgroundColor: blue[500], color: '#fff' }}
                                                                startIcon={ <ProfileIcon /> }
                                                            >
                                                                { window.innerWidth > 600 ? 'Profile' : '' }  
                                                            </Button>
                                                        </Tooltip>
                                                    </Box>
        
                                                    <Box m={1}>
                                                        <Tooltip title="Chat" arrow>
                                                            <Button 
                                                                size={ window.innerWidth > 600 ? 'medium' : 'small'}
                                                                style={{ 
                                                                    backgroundColor: deepOrange[500], 
                                                                    color: '#fff' 
                                                                }}
                                                                onClick={ () => setDrawer('drawer-chat') }
                                                                startIcon={ 
                                                                    <Badge badgeContent={unreadMsg} color="secondary">
                                                                        <ChatIcon />
                                                                    </Badge>
                                                                }
                                                            > 
                                                                { window.innerWidth > 600 ? 'Chat' : '' }
                                                            </Button>
                                                        </Tooltip>
                                                    </Box> 
        
                                                    <Box m={1}>
                                                        <Tooltip title="Set appointment as done" arrow>
                                                            <Box>
                                                                <Button 
                                                                    size={ window.innerWidth > 600 ? 'medium' : 'small'}
                                                                    style={{ backgroundColor: green[buttonExitDisabled ? 200 : 500], color: '#fff' }}
                                                                    onClick={ () => setExitDialog(true) }
                                                                    disabled={ buttonExitDisabled }
                                                                    startIcon={ <CheckCircleIcon /> }
                                                                >  
                                                                    { window.innerWidth > 600 ? 'Done' : '' }
                                                                </Button>
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>  
        
                                                    <Box m={1}>
                                                        <Tooltip title="End call and back to home" arrow>
                                                            <Box>
                                                                <Button 
                                                                    size={ window.innerWidth > 600 ? 'medium' : 'small'}  
                                                                    style={{ backgroundColor: red[500], color: '#fff' }}  
                                                                    onClick={ () =>{
                                                                        endCall()
                                                                        setTimeout(() => {
                                                                            history.push('/clinic/app/doctor')
                                                                        }, 2000);
                                                                    }}
                                                                    startIcon={ <HomeOutlined /> }
                                                                > 
                                                                    { window.innerWidth > 600 ? 'Home' : '' } 
                                                                </Button>
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>   
                                                </Box> 
                                            </>
                                        )} 
                                    </> 
                                )}   
                                
                                {  ! validreq && ( 
                                    //  invalid requirements 
                                    <Box
                                        display="flex"
                                        justifyContent="center" 
                                        alignItems="center"
                                        height={'calc(100vh - 50px)'}
                                        border={1}
                                    >    
                                        <Box>
                                            <Alert severity="info"> 
                                                <AlertTitle>
                                                    Camera Or Microphone Error !
                                                </AlertTitle>
                                                Enable your camera and microphone to continue.
                                            </Alert>
                                        </Box>     
                                    </Box>
                                )}
                            </>
                        ) : (
                            //  preparing requirements 
                            <Box
                                display="flex"
                                justifyContent="center" 
                                alignItems="center"
                                height={'calc(100vh - 50px)'}
                                border={1}
                            >    
                                <Box alignItems="center" align="center" color="white">
                                    <CircularProgress size={30} color="inherit" />
                                    <Typography align="center"> please wait... </Typography>
                                </Box>     
                            </Box>
                        )
                    } 
                </DialogContent>  
            </Dialog>

            {/* delete and exit room dialog */}
            <Dialog
                open = { exitDialog } 
                onClose = { () => setExitDialog(false) }
                disableBackdropClick 
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth
            >
                <DialogContent>
                    <Box>
                        <Typography>
                            Are you sure to set this appointment done and delete this room?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="default" 
                        startIcon={ <ClearIcon /> } 
                        onClick={ () => setExitDialog(false)  }
                    >
                        No
                    </Button>  

                    <Button 
                        variant="contained"
                        color="primary"
                        startIcon={ <CheckIcon /> } 
                        onClick={() => { 
                            endCall();
                            setDoneDialog(true)
                            setExitDialog(false)
                        }}
                    >
                        {`Yes`}
                    </Button>  
                </DialogActions>
            </Dialog>

            {/* dialog for setappointment done */}
            <Dialog
                open = { doneDialog } 
                onClose = { () => setDoneDialog(false) }
                disableBackdropClick 
                disableEscapeKeyDown
            >
                <DialogTitle>
                    Set Appointment Done
                </DialogTitle>

                <Formik 
                    initialValues={{
                        username: username,
                        user_id: user_id, 
                        token: token, 
                        app_doneon: new Date().toLocaleString(), 
                        app_consumetime: `${calculateTime()} : ${callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} `, 
                        message: 'Thank you for having an appointment with me.', 
                        app_id: refDetails && refDetails.appointment_id, 
                        app_ref_number: refDetails && refDetails.reference_no, 
                        patient_id: refDetails && refDetails.patientId, 
                        connection: 'online', 
                    }}
                    validationSchema={Yup.object().shape({  
                        app_doneon: Yup.string().trim().required('Appointment done on is required.'), 
                        message: Yup.string().trim().required('Message is required.'), 
                        app_id: Yup.string().trim().required('Appointment id is required.'), 
                        app_ref_number: Yup.string().trim().required('Appointment reference is required.'), 
                    })}
                    onSubmit={async (values, {
                        setErrors, 
                        setSubmitting,
                        resetForm
                    }) => {  
                        try { 
                            const request = await axios.post('doctor/online/appointment/setas-done', getFormData(values))  
                            if(request.data === 'success'){
                                Notify.successRequest('Appointment Done')
                                setDoneDialog(false) 
                                history.push('/clinic/app/doctor')
                                resetForm();  
                            }
                        } catch (error) {
                            const message = error.message || 'Something went wrong';  
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                    >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form
                            noValidate 
                            onSubmit={handleSubmit}  
                        >    
                            <DialogContent dividers>

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        required
                                        error={Boolean(touched.app_doneon && errors.app_doneon)}
                                        helperText={touched.app_doneon && errors.app_doneon}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.app_doneon} 
                                        name="app_doneon"
                                        label="Appointment Done On"
                                        variant="outlined"  
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box> 

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        required
                                        error={Boolean(touched.app_consumetime && errors.app_consumetime)}
                                        helperText={touched.app_consumetime && errors.app_consumetime}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.app_consumetime} 
                                        name="app_consumetime"
                                        label="Call Consumed"
                                        variant="outlined"  
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>  

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        required
                                        error={Boolean(touched.message && errors.message)}
                                        helperText={touched.message && errors.message}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.message} 
                                        name="message"
                                        label="Message"
                                        variant="outlined"   
                                        multiline
                                    />
                                </Box>  

                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                        {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </DialogContent>

                            <DialogActions>   
                                <Button
                                    variant="contained"
                                    color="default" 
                                    startIcon={ <ClearIcon /> } 
                                    onClick={ () =>{
                                        setDoneDialog(false)
                                        setTimeout(() => {
                                            history.push('/clinic/app/doctor')
                                        }, 2000);
                                    }}
                                >
                                    No
                                </Button>  

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={ <CheckIcon /> }
                                    disabled={isSubmitting}  
                                >
                                    {`Save & Exit`}
                                </Button>  
                            </DialogActions> 
                        </form>
                    )}
                </Formik>  
            </Dialog>
        </>
    )
}

export default CreateRoomOLd; 
 