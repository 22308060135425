import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Button,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import Report from "./Report";
import BillingDetails from "./BillingDetails";
import Container from "src/layout/Container";
import { ArrowLeft, FileText } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const Billing = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [displayType, setDisplayType] = useState("view-bills");
  const [unpaid, setUnpaid] = useState({
    data: [],
    ready: false,
  });

  const [selectedUnpaid, setSelectedUnpaid] = useState({
    data: null,
    ready: false,
  });

  const getUnpaidOrder = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/pateint/billing", formdata)
      .then((response) => {
        const data = response.data;
        setUnpaid({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id]);

  useEffect(() => {
    getUnpaidOrder();
  }, [getUnpaidOrder]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "billing",
          items: [{ name: "Dashboard", path: "/clinic/app/encoder" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}> Billing </Box>
            <Box>
              <Button
                variant="contained"
                color={displayType === "view-report" ? "secondary" : "primary"}
                startIcon={
                  displayType === "view-report" ? <ArrowLeft /> : <FileText />
                }
                onClick={() =>
                  setDisplayType(
                    displayType === "view-report" ? "view-bills" : "view-report"
                  )
                }
              >
                {displayType === "view-report" ? "Back" : "Report"}
              </Button>
            </Box>
          </Box>
        }
      >
        {displayType === "view-report" && <Report />}
        {displayType === "view-bills" && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardHeader title="Unpaid Bill" />
                <CardContent>
                  <Box>
                    <List component="div">
                      {unpaid.ready
                        ? unpaid.data.length > 0
                          ? unpaid.data.map((data, index) => (
                              <ListItem
                                button
                                key={index}
                                selected={
                                  selectedUnpaid.data &&
                                  selectedUnpaid.data.id === data.id
                                }
                                onClick={() => {
                                  setSelectedUnpaid({
                                    data: data,
                                    ready: true,
                                  });
                                }}
                              >
                                <ListItemIcon>
                                  {data.image === null ? (
                                    <Avatar className={classes.primary}>
                                      {data.lname.charAt().toUpperCase()}
                                    </Avatar>
                                  ) : (
                                    <Avatar
                                      className={classes.primary}
                                      src={`${imageLocation}patients/${data.image}`}
                                      alt=""
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  className={`gtc-capitalize`}
                                  primary={`${data.lname}, ${data.fname}`}
                                  secondary={Notify.dateTimeConvert(
                                    data.created_at
                                  )}
                                />
                              </ListItem>
                            ))
                          : Notify.noRecord()
                        : Notify.loading()}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={8}>
              {selectedUnpaid.ready && selectedUnpaid.data && (
                <BillingDetails
                  details={selectedUnpaid.data}
                  getUnpaidOrder={getUnpaidOrder}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Billing;
