
import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    Button,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';


const AdditionalItem = ({ vpharmacy, orderno, getOrderAddOnItems }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [process, setProcess] = useState(false)

    const [items, setItems] = useState({
        data: [],
        ready: false,
    })

    const getItemByVPharmacy = useCallback(async () => {
        try {
            var params = {
                token: token,
                vpharmacy: vpharmacy,
            }
            const request = await Axios.get('patient/cart/order/vpharmacay/getitems-list', { params })
            setItems({
                data: request.data,
                ready: true
            });
        } catch (error) {
            Notify.requestError(error)
        }
    }, [vpharmacy, token])

    const addSelectedItem = async (data) => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('product_id', data.product_id)
            formdata.append('product_name', data.product_name)
            formdata.append('price', null)
            // formdata.append('price', data.price)
            formdata.append('quantity', 1)
            formdata.append('order_no', orderno)
            setProcess(true)
            const request = await Axios.post('patient/cart/order/vpharmacay/additem-tocart', formdata)
            if (request.data === 'success') {
                getOrderAddOnItems()
                Notify.successRequest('item added')
            }
            if (request.data === 'item-added') { Notify.customToast('item exist', 'Item already added to cart.') }
            setProcess(false)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    useEffect(() => {

        getItemByVPharmacy()

    }, [getItemByVPharmacy])

    return (
        <>
            <Box>
                <TextField
                    fullWidth
                    label="Search Item"
                    variant="outlined"
                />
            </Box>
            <Box>
                <Box p={1}>
                    {process && (<LinearProgress />)}
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Item
                                </TableCell>
                                <TableCell>
                                    Price
                                </TableCell>
                                <TableCell>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                items.ready ?
                                    items.data.length > 0 ?
                                        items.data.map((data, index) => (
                                            <TableRow
                                                key={index}
                                            >
                                                <TableCell> {data.product_name} </TableCell>
                                                <TableCell> {data.price} </TableCell>
                                                { console.log(data)}
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => addSelectedItem(data)}
                                                    >
                                                        ADD
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                No record
                                        </TableCell>
                                        </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            please wait...
                                    </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default AdditionalItem;