import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import ChemistryRef from "src/laboratory/newlaboratory/references/Ref_Chemistry";
import { useHistory } from "react-router";
import { BarChart2 } from "react-feather";

const ChemistryOrder = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [details, setDetails] = useState([]);
  const history = useHistory();

  const getpaidLaboratoryOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("table", "laboratory_chemistry");
    formdata.set("connection", "local");

    axios
      .post("doctor/patient/laboratory/order/paid-detailsbytable", formdata)
      .then((res) => {
        const data = res.data;
        setDetails(data);
      })
      .catch((er) => Notify.requestError(er));
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trace_number, patient_id]);

  return (
    <>
      {details.length > 0 && (
        <Box my={2} p={2}>
          <Box display="flex" mb={2}>
            <Box flexGrow={1}>
              <Typography variant="h6">
                <strong> Clinical Chemistry Order </strong>
              </Typography>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="text"
                startIcon={<BarChart2 />}
                onClick={() =>
                  history.push(
                    `/clinic/app/doctor/patient/laboratory/chemistry/${patient_id}`
                  )
                }
                className={`d-print-none`}
              >
                Graph
              </Button>
            </Box>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> Test Request </TableCell>
                  <TableCell align="center"> Result </TableCell>
                  <TableCell align="center"> Reference Values </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.data.map((data, key) => (
                  <Fragment key={key}>
                    {data.glucose && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            GLUCOSE
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.glucose}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.glucose()}</TableCell>
                      </TableRow>
                    )}
                    {data.creatinine && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CREATININE
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.creatinine}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.creatinine()}</TableCell>
                      </TableRow>
                    )}
                    {data.uric_acid && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            URIC ACID
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.uric_acid}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.uric()}</TableCell>
                      </TableRow>
                    )}
                    {data.cholesterol && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.cholesterol}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.cholesterol()}</TableCell>
                      </TableRow>
                    )}
                    {data.triglyceride && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            TRIGLYCERIDE
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.triglyceride}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.triglyceride()}</TableCell>
                      </TableRow>
                    )}
                    {data.hdl_cholesterol && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            HDL CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.hdl_cholesterol}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.hdlcholesterol()}</TableCell>
                      </TableRow>
                    )}
                    {data.ldl_cholesterol && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            LDL CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.ldl_cholesterol}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.ldlcholesterol()}</TableCell>
                      </TableRow>
                    )}
                    {data.sgot && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SGOT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.sgot}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.sgot()}</TableCell>
                      </TableRow>
                    )}
                    {data.sgpt && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SGPT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.sgpt}</Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.sgpt()}</TableCell>
                      </TableRow>
                    )}

                    {data.fbs && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            fbs
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.fbs}</Typography>
                        </TableCell>
                        <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                      </TableRow>
                    )}

                    {data.bun && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            bun
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.bun}</Typography>
                        </TableCell>
                        <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                      </TableRow>
                    )}

                    {data.serum_uric_acid && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SERUM URIC ACID
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.serum_uric_acid}</Typography>
                        </TableCell>
                        <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                      </TableRow>
                    )}

                    {data.alkaline_phosphatase && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            alkaline_phosphatase
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.alkaline_phosphatase}</Typography>
                        </TableCell>
                        <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                      </TableRow>
                    )}

                    {data.potassium && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            potassium
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.potassium}</Typography>
                        </TableCell>
                        <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                      </TableRow>
                    )}

                    {data.lipid_profile && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Lipid Profile
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{data.lipid_profile}</Typography>
                        </TableCell>
                        <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default ChemistryOrder;
