import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Container from "src/layout/Container";
import DetailsLocal from "./DetailsLocal";
import DetailsVirtual from "./DetailsVirtual";

const Details = () => {
  const { from, appid } = useParams();
  const history = useHistory();

  if (from !== "local" && from !== "virtual") {
    history.push("/clinic/app/pagenotfound");
  }

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Details",
          items: [
            { name: "Dashboard", path: "/clinic/app/doctor" },
            {
              name: "appointment",
              path: "/clinic/app/doctor/appointment/calendar",
            },
          ],
        }}
        title={`${from} Appointment Details`}
      >
        <>
          {from === "local" && <DetailsLocal appid={appid} />}
          {from === "virtual" && <DetailsVirtual appid={appid} />}
        </>
      </Container>
    </>
  );
};

export default Details;
