import React from 'react'
import {
    Box,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    CardMedia
} from '@material-ui/core';
import Icon from '@material-ui/icons/ArrowRightAlt'
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

const imageLocation = process.env.REACT_APP_API_IMAGE;


const ClinicDoctorsList = ({ doctorsList }) => {
    const history = useHistory();

    return (
        <>
            <Box m={1}>
                <Typography variant={'subtitle2'} color="primary">
                    CLINIC DOCTORS LIST
                </Typography>
            </Box>

            <Grid container spacing={2}>
                {
                    doctorsList.map((data, index) => (
                        <Grid
                            key={index}
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                        >
                            <Card
                                component={Box}
                                p={2}
                                elevation={0}
                                border={1}
                                borderColor={grey[300]}
                            >
                                <CardContent>
                                    <Box align="center" mb={2}>
                                        <CardMedia
                                            alt={'gtc-doctor'}
                                            component="img"
                                            style={{ borderRadius: '50%' }}
                                            src={data.doctors_image === null ? "/no-image.jpg" : `${imageLocation}doctors/${data.doctors_image}`}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography
                                            align="center"
                                            variant="subtitle2"
                                            color="primary"
                                            noWrap
                                            className={`gtc-uppercase`}
                                        >
                                            {data.doctors_name}
                                        </Typography>
                                        <Typography
                                            align="center"
                                            variant="subtitle2"
                                            color="textSecondary"
                                            noWrap
                                        >
                                            {data.doctors_spicialty}
                                        </Typography>

                                        <Typography
                                            align="center"
                                            color="inherit"
                                            variant="subtitle2"
                                            className={`gtc-uppercase`}
                                            noWrap
                                        >
                                            {data.doctor_userid}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        onClick={() => history.push(`/clinic/app/patient/appointment/doctor/${data.doctor_userid}`)}
                                        variant="text"
                                        color="primary"
                                        endIcon={
                                            <Icon />
                                        }
                                    >
                                        Details
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    )
}

export default ClinicDoctorsList;