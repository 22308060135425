class CheckerInternet {
  online = async () => {
    try {
      return await fetch("https://www.google.com", { mode: "no-cors" });
      // return await fetch("https://ipv4.icanhazip.com");
    } catch (error) {
      console.log("no internet connection found.");
    }
  };
}

const CheckInternet = new CheckerInternet();
export default CheckInternet;
