import {
  Box,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
  Button,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { CheckSquare, Crop, XCircle } from "react-feather";
import { DatePicker } from "@material-ui/pickers";
import axios from "axios";
import Notify from "src/notification/Notify";
import { Cropper } from "react-cropper";

const token = localStorage.getItem("token");
const user_id = localStorage.getItem("user_id");

const imageLocation = process.env.REACT_APP_API_IMAGE;

const Profile = ({ detailsRd, details, getProfileInformation }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [isProcess, setIsProcess] = useState(false);

  const imageRef = useRef(null);
  const cropperRef = useRef(null);

  const [openCropImage, setOpenCropImage] = useState(false);

  const handleSelectedProfile = (e) => {
    if (e.target.files.length) {
      let ext = e.target.files[0].name.split(".").pop();

      if (ext === "jpg" || ext === "jpeg" || ext === "png") {
        setOpenCropImage(true);
        var reader = new FileReader();
        reader.onloadend = function () {
          var b64 = reader.result;
          setSelectedImage(b64);
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        Notify.customToast(
          "Invalid Image Format",
          "Selected format is not valid."
        );
        return;
      }
    }
  };

  const handleUpdateProfile = () => {
    var formdata = new FormData();
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("image", image === null ? "" : image);
    setIsProcess(true);
    axios
      .post("encoder/profile/update-image", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          Notify.successRequest("Profile updated");
          getProfileInformation();
        }
      })
      .catch((error) => console.log(error.message || "request has error"))
      .finally(() => {
        setIsProcess(false);
        setOpenCropImage(false);
      });
  };

  const convertFormdata = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box>
      {detailsRd ? (
        Object.keys(details).length > 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box m={2} align="center">
                <CardMedia
                  style={{
                    maxWidth: 300,
                    borderRadius: 5,
                  }}
                  src={
                    details.image === null
                      ? `/no-image.jpg`
                      : `${imageLocation}encoder/${details.image}`
                  }
                  alt=""
                  component="img"
                />
              </Box>

              <Box align="center" mt={2}>
                {/* update selected image */}
                <input
                  type="file"
                  ref={imageRef}
                  onChange={handleSelectedProfile}
                  hidden
                />
                <Button
                  color="primary"
                  onClick={() => imageRef.current.click()}
                  disabled={isProcess}
                >
                  {isProcess ? "Loading..." : "Change Profile"}
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box>
                <Formik
                  initialValues={{
                    user_id: user_id,
                    token: token,
                    password: "",
                    name: details.name === null ? "" : details.name,
                    address: details.address === null ? "" : details.address,
                    gender:
                      details.gender === null
                        ? ""
                        : details.gender.toLowerCase(),
                    email: details.email === null ? "" : details.email,
                    old_email: details.email === null ? "" : details.email,
                    birthday: new Date(),
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    address: Yup.string().required(),
                    gender: Yup.string().required(),
                    // email: Yup.string().required(),
                  })}
                  onSubmit={(values, { setErrors, setSubmitting }) => {
                    setSubmitting(true);
                    axios
                      .post(
                        "encoder/profile/update-information",
                        convertFormdata(values)
                      )
                      .then((response) => {
                        const data = response.data;

                        if (data.message === "email-exist") {
                          setErrors({ email: "Email is not available." });
                        }
                        if (data.message === "success") {
                          Notify.successRequest("Profile updated");
                          getProfileInformation();
                        }
                      })
                      .catch((error) =>
                        console.log("request has error:", error.message)
                      )
                      .finally(() => setSubmitting(false));
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Box my={2}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          fullWidth
                          required
                          name="name"
                          label="Name"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value=""> Select </option>
                          <option value="male"> Male </option>
                          <option value="female"> Female </option>
                        </TextField>
                      </Box>

                      <Box my={2}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          name="email"
                          label="Email"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2}>
                        {/* <TextField
                                        error={Boolean(touched.birthday && errors.birthday)}
                                        helperText={touched.birthday && errors.birthday}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.birthday}
                                        fullWidth
                                        name="birthday"
                                        label="Birthday"
                                        variant="outlined"
                                        /> */}

                        <DatePicker
                          autoOk
                          disableFuture
                          fullWidth
                          label="Birthday"
                          format="MM/dd/yyyy"
                          value={values.birthday}
                          onChange={(date) => setFieldValue("birthday", date)}
                          inputVariant="outlined"
                          name="birthday"
                        />

                        {errors.birthday && (
                          <Box mt={3}>
                            <FormHelperText error>
                              {errors.birthday}
                            </FormHelperText>
                          </Box>
                        )}
                      </Box>

                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Box mb={2} display="flex">
                        <Box mr={2}>
                          <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <CheckSquare />
                              )
                            }
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>

                        <Button
                          size="large"
                          variant="contained"
                          color="default"
                          onClick={resetForm}
                          startIcon={<XCircle />}
                        >
                          Clear
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        ) : (
          Notify.noRecord()
        )
      ) : (
        Notify.loading()
      )}

      {/* crop image dialog  */}
      {/* crop dialog */}
      <Dialog
        open={openCropImage}
        fullScreen
        onClose={() => {
          setOpenCropImage(false);
          setSelectedImage(null);
        }}
      >
        <DialogTitle>Change Profile</DialogTitle>
        <DialogContent>
          <Box className="update-profile">
            <Cropper
              zoomTo={0.5}
              aspectRatio={1}
              src={image === null ? selectedImage : image}
              viewMode={1}
              guides={false}
              background={false}
              responsive={true}
              dragMode={"move"}
              cropBoxResizable={true}
              checkOrientation={false}
              rotatable
              scalable
              ref={cropperRef}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            startIcon={
              isProcess ? (
                <CircularProgress size={12} color="primary" />
              ) : (
                <CheckSquare />
              )
            }
            disabled={image === null ? true : isProcess ? true : false}
            onClick={() => handleUpdateProfile()}
          >
            Save
          </Button>

          <Button
            color="primary"
            variant="contained"
            startIcon={<Crop />}
            onClick={() => {
              const imageElement = cropperRef.current;
              const cropper = imageElement.cropper;
              setImage(cropper.getCroppedCanvas().toDataURL());
              // setOpenCropImage(false);
              // handleUpdatePicture();
            }}
          >
            Crop
          </Button>

          <Button
            color="secondary"
            variant="contained"
            startIcon={<XCircle />}
            onClick={() => {
              setOpenCropImage(false);
              setImage(null);
              setSelectedImage(null);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
