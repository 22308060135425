import { useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import { Line } from "react-chartjs-2";

const Female = ({ list }) => {
  const chartRef = React.useRef();
  const theme = useTheme();

  const label = [
    "Birth", // birth
    "2",
    "4",
    "6",
    "8",
    "10",

    "1 year", // 1year
    "2",
    "4",
    "6",
    "8",
    "10",

    "2 year", // 2 years
    "2",
    "4",
    "6",
    "8",
    "10",

    "3 year", // 3 years
    "2",
    "4",
    "6",
    "8",
    "10",
    "4 year",

    "2", // 4 years
    "4",
    "6",
    "8",
    "10",
    "5 year",
  ];

  const generateChildsGrowth = (list) => {
    let growth = [];

    if (list.length > 0) {
      growth.push(
        list[0]._birth_zero === null ? 0 : list[0]._birth_zero,
        list[0]._birth_two === null ? 0 : list[0]._birth_two,
        list[0]._birth_four === null ? 0 : list[0]._birth_four,
        list[0]._birth_six === null ? 0 : list[0]._birth_six,
        list[0]._birth_eight === null ? 0 : list[0]._birth_eight,

        list[0]._first_ten === null ? 0 : list[0]._first_ten,
        list[0]._first_zero === null ? 0 : list[0]._first_zero,
        list[0]._first_two === null ? 0 : list[0]._first_two,
        list[0]._first_four === null ? 0 : list[0]._first_four,
        list[0]._first_six === null ? 0 : list[0]._first_six,
        list[0]._first_eight === null ? 0 : list[0]._first_eight,

        list[0]._second_ten === null ? 0 : list[0]._second_ten,
        list[0]._second_zero === null ? 0 : list[0]._second_zero,
        list[0]._second_two === null ? 0 : list[0]._second_two,
        list[0]._second_four === null ? 0 : list[0]._second_four,
        list[0]._second_six === null ? 0 : list[0]._second_six,
        list[0]._second_eight === null ? 0 : list[0]._second_eight,

        list[0]._third_ten === null ? 0 : list[0]._third_ten,
        list[0]._third_zero === null ? 0 : list[0]._third_zero,
        list[0]._third_two === null ? 0 : list[0]._third_two,
        list[0]._third_four === null ? 0 : list[0]._third_four,
        list[0]._third_six === null ? 0 : list[0]._third_six,
        list[0]._third_eight === null ? 0 : list[0]._third_eight,

        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,
        list[0]._fourth_zero === null ? 0 : list[0]._fourth_zero,
        list[0]._fourth_two === null ? 0 : list[0]._fourth_two,
        list[0]._fourth_four === null ? 0 : list[0]._fourth_four,
        list[0]._fourth_six === null ? 0 : list[0]._fourth_six,
        list[0]._fourth_eight === null ? 0 : list[0]._fourth_eight,
        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,

        list[0]._fifth_zero === null ? 0 : list[0]._fifth_zero
      );
    } else {
      growth.push(0);
    }

    return growth;
  };

  const defaultData = {
    negativeThree: [
      2,
      3.2,
      4.3,
      5,
      5.5,
      6,
      6.2, // 1 year

      6.5,
      7,
      7.1,
      7.5,
      7.8,
      8, // 2year

      8.2,
      8.6,
      8.9,
      9.1,
      9.3,
      9.5, // 3 year

      9.9,
      10,
      10.2,
      10.5,
      10.8,
      11, // 4 year

      11.1,
      11.2,
      11.5,
      11.7,
      11.8,
      12, // 5 year
    ],
    negativeTwo: [
      2.3,
      4,
      5,
      5.8,
      6.2,
      6.8,
      7, // 1 year

      7.2,
      7.8,
      8,
      8.3,
      8.7,
      9, // 2year

      9.2,
      9.7,
      10,
      10.2,
      10.5,
      10.9, // 3 year

      11,
      11.2,
      11.5,
      11.9,
      12,
      12.3, // 4 year
      12.5,
      12.8,
      13,
      13.1,
      13.5,
      13.8, // 5 year
    ],
    zero: [
      3.2,
      5,
      6.5,
      7.2,
      8,
      8.5,
      9, // 1 year

      9.2,
      9.9,
      10.2,
      10.7,
      11,
      11.4, // 2year

      12,
      12.3,
      12.8,
      13,
      13.5,
      14, // 3 year

      14.2,
      14.5,
      15,
      15.2,
      15.8,
      16, // 4 year

      16.3,
      16.7,
      17,
      17.5,
      17.9,
      18.1, // 5year
    ],
    positiveTwo: [
      4.1,
      6.5,
      8,
      9.3,
      10,
      11,
      11.5, //  1year

      12,
      12.5,
      13.2,
      13.8,
      14.2,
      15, //2 year

      15.3,
      16,
      16.5,
      17,
      17.5,
      18, // 3 year

      18.5,
      19.1,
      19.9,
      20.2,
      20.3,
      21,
      21.5, // 4 year

      22,
      22.5,
      23,
      23.9,
      24.3,
      25, // 5 year
    ],
    positiveThree: [
      5,
      7.4,
      9.2,
      10.5,
      11.5,
      12.3,
      13, // 1 year

      13.9,
      14.2,
      15,
      15.8,
      16.2,
      17, //2 year

      17.7,
      17.6,
      18.2,
      19,
      19.7,
      20.1,
      21, // 3 year

      21.7,
      22.2,
      23,
      23.9,
      24.5,
      25, // 4year

      26,
      26.7,
      27.2,
      28,
      28.9,
      29.5, // 5year
    ],
  };

  const data = {
    labels: label,
    datasets: [
      {
        label: "Weight",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: `${theme.palette.primary.main}`,
        borderColor: `${theme.palette.primary.main}`,
        pointHoverRadius: 1,
        borderWidth: 2,
        data: generateChildsGrowth(list),
      },
      {
        label: "-3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000",
        borderColor: "#000",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: defaultData.negativeThree,
      },
      {
        label: "-2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "red",
        borderColor: "red",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: defaultData.negativeTwo,
      },

      {
        label: "0",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "green",
        borderColor: "green",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: defaultData.zero,
      },

      {
        label: "2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "red",
        borderColor: "red",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: defaultData.positiveTwo,
      },

      {
        label: "3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000",
        borderColor: "#000",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: defaultData.positiveThree,
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      position: "bottom",
      cursor: "pointer",
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Weight(kg)",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Age(Completed months and years)",
          },
        },
      ],
    },
  };

  return (
    <Box>
      <Line ref={chartRef} data={data} options={options} />
    </Box>
  );
};

export default Female;
