import {
  Box,
  useTheme,
  IconButton,
  Typography,
  TextField,
  Slide,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import Axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { ArrowLeft } from "react-feather";
import Notify from "src/notification/Notify";
import Convo from "./Convo";

const Message = ({
  senderDetails,
  setSenderDetails,
  setRerender,
  rerender,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const theme = useTheme();
  const [newMessage, setNewMessage] = useState("");
  const [hideScrollBtn, setHideScollBtn] = useState(true);
  let conversationRef = useRef(null);

  const scrollToBottom = () => {
    if (conversationRef.current) {
      let y = conversationRef.current.scrollHeight;
      conversationRef.current.scrollTop = y;
    }
  };

  const handleDetectScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = conversationRef.current;
    if (
      parseInt(scrollTop) + parseInt(clientHeight) ===
      parseInt(scrollHeight)
    ) {
      setHideScollBtn(true);
    } else {
      setHideScollBtn(false);
    }
  };

  const handleNewMessage = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("receivers_id", senderDetails.data.user_id);
    formdata.set("senders_id", user_id);
    formdata.set("_message", newMessage);

    if (newMessage === "") {
      Notify.fieldRequired("message");
      return;
    }

    Axios.post("chat/management/conversation-newmsg", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          setRerender(new Date().getTime());
          setNewMessage("");
        }
      })
      .catch((error) => {
        console.log("request has an error:", error.message);
      });
  };

  const handleSetAsReadMessage = async () => {
    // chat/management/conversation/message/update-toread
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("_myid", user_id);
    formdata.set("senders_id", senderDetails.data.user_id);

    await Axios.post(
      "chat/management/conversation/message/update-toread",
      formdata
    );
    setRerender(new Date().getTime());
  };

  useEffect(() => {
    handleSetAsReadMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderDetails]);

  useEffect(() => {
    if (conversationRef.current) {
      document
        .getElementById("msg-container")
        .addEventListener("touchend", handleDetectScroll);
    }
  }, []);

  return (
    <Box>
      <Box
        bgcolor={theme.palette.primary.main}
        color="white"
        p={1.3}
        width={350}
      >
        <Slide in={true} direction="right" timeout={700}>
          <Box display="flex">
            <Box>
              <IconButton
                onClick={() =>
                  setSenderDetails({
                    open: false,
                    data: null,
                  })
                }
              >
                <ArrowLeft color="white" />
              </IconButton>
            </Box>

            <Box mt={1.7} width={270}>
              <Typography noWrap={true} className="gtc-uppercase">
                {senderDetails.data._account_name}
              </Typography>
            </Box>
          </Box>
        </Slide>
      </Box>

      <Box align="center" height={17}>
        {conversationRef !== null && !hideScrollBtn && (
          <Typography
            color="primary"
            variant="caption"
            onClick={() => scrollToBottom()}
            className="pointer"
          >
            SCROLL BOTTOM
          </Typography>
        )}
      </Box>

      <Box
        p={2}
        width={350}
        height={"calc(60vh - 129px)"}
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          scrollBehavior: "smooth",
        }}
        ref={conversationRef}
        onScroll={handleDetectScroll}
        id="msg-container"
      >
        {conversationRef !== null && (
          <Convo
            sender={senderDetails.data}
            scrollToBottom={() => scrollToBottom()}
            rerender={rerender}
          />
        )}
      </Box>

      <Box m={2}>
        <TextField
          label="Message"
          fullWidth
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={() => handleNewMessage()}>
                <Send />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Message;
