import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Avatar, Box, CircularProgress, Grid, Typography, makeStyles, List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const DoctorsList = ({ specialization }) => {
    const classes = useStyles();
    const history = useHistory()
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const management_id = localStorage.getItem('management_id')

    const [doctors, setDoctors] = useState([])
    const [doctorsReady, setDoctorsReady] = useState(false)

    const getDoctorsBySpecialization = () => {
        var params = {
            token: token,
            management_id: management_id,
            user_id: user_id,
            specialization: specialization,
        }
        Axios.get('appointment/doctors/doctors-listbysprcialization', { params })
            .then((response) => {
                const data = response.data;
                setDoctors(data)
                setDoctorsReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getDoctorsBySpecialization()
        // eslint-disable-next-line
    }, [specialization])
    return (
        <Fragment>
            <Grid container spacing={2}>
                {
                    doctorsReady ?
                        doctors.length > 0 ?
                            doctors.map((data, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                    >
                                        <List
                                            component="nav"
                                            aria-label="main mailbox folders"
                                            onClick={() => history.push('/clinic/app/patient/appointment/doctor/' + data.user_id)}
                                        >
                                            <ListItem button>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={data.image === null ? "/doctorsIcon.png" : imageLocation + 'doctors/' + data.image}
                                                        className={classes.large}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="primary"
                                                            noWrap
                                                        >
                                                            {data.name.toUpperCase()}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="textSecondary"
                                                            noWrap
                                                        >
                                                            {data.address}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                )
                            })
                            : <Box m={2}> <Typography color="secondary"> No doctors available </Typography> </Box>
                        : <Box m={2}>  <CircularProgress size={12} /> <Typography color="primary" variant="caption"> processing... </Typography>  </Box>
                }
            </Grid>
        </Fragment>
    )
}

export default DoctorsList;


const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(3)
    },
}));