import { Box, CardMedia, Grid, Typography, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react'; 
import axios from 'axios';
import Notify from 'src/notification/Notify';
import { grey } from '@material-ui/core/colors';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({ 
    profile: { 
        backgroundColor: theme.palette.primary.main,
        color: '#fff', 
        maxWidth: theme.spacing(23),
        maxHeight: theme.spacing(23), 
        borderRadius: '50%',
        border: `1px solid ${grey[200]}`
    }
}));


const BasicInfo = () =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const classes = useStyles();
    const [details, setDetails] = useState({ data: [], ready: false })
    
    const getBasinInfo = React.useCallback( async () => {
        try { 
            const params = {
                token, user_id
            }
            const response = await axios.get('telerad/sidebar/header-infomartion', { params }) 
            setDetails({ data: response.data, ready: true })

        } catch (error) {
            console.log('unable to get sdiebar header info.', error)
        } 
    } , [token, user_id])

    React.useEffect(() =>{

        getBasinInfo()

    },[ getBasinInfo ])

    return( 
        <>
            <Box mb={2}>
                <Typography variant="h6" color="textSecondary"> 
                    Personal Information
                </Typography>
            </Box>

            {
                details.ready ? 
                    details.data && Object.keys(details.data).length > 0 ? 
                        <Grid container spacing={2}> 
                            <Grid item xs={12} sm={5} md={5} lg={4}> 
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Box> 
                                        <CardMedia 
                                            component="img"
                                            className={ classes.profile }
                                            src={`${imageLocation}telerad/${details.data.image === null ? 'no-image.jpg' : details.data.image}`}
                                            alt="" 
                                        /> 
                                    </Box> 
                                </Box>
                                <Box align="center" mt={2}>
                                    <Typography color="textSecondary" variant="subtitle1">
                                        Profile Picture 
                                    </Typography>
                                </Box>
                            </Grid>
            
            
                            <Grid item xs={12} sm={7} md={7} lg={8}>
                                <Box>
                                    <Box>
                                        <TextField 
                                            label="Name"
                                            fullWidth
                                            variant="outlined"
                                            value={ details.data.name }
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Box>
            
                                    <Box my={3}>
                                        <TextField 
                                            label="Gender"
                                            fullWidth
                                            variant="outlined"
                                            value={ details.data.gender  === null ? 'None' : details.data.gender }
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Box>
            
                                    <Box my={3}>
                                        <TextField 
                                            label="Birthday"
                                            fullWidth
                                            variant="outlined"
                                            value={ details.data.birthday === null ? 'None' : Notify.createdAt(details.data.birthday) }
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Box>
            
                                    <Box>
                                        <TextField 
                                            label="Address"
                                            fullWidth
                                            variant="outlined"
                                            value={ details.data.address === null ? 'None' : details.data.address}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Box> 
                                </Box>
                            </Grid>
                        </Grid>
                    : Notify.noRecord()
                : Notify.loading()
            }
        </>
    )
}

export default BasicInfo;