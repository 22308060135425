import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { blue, purple, red } from '@material-ui/core/colors';
import Notify from 'src/notification/Notify';
import RadioButtonCheckedIcon from '@material-ui/icons/Room';
import CompleteOrder from './CompleteOrder';
import Axios from 'axios';

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

const PickupDetails = ({ selectedOrder, close }) => {

    const [gtcMap, setgtcMap] = useState({
        map: null,
    })

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [start, setStart] = useState([125.5685, 7.0782])

    const [timeTravel, setTimeTravel] = useState(0)
    const [instructions, setInstructions] = useState(null)

    const [instructionReady, setInstructionReady] = useState(false)
    const [category, setCategory] = useState('map-details');

    const getNavLocation = () => {
        navigator.geolocation.getCurrentPosition(function (location) {
            setStart([location.coords.longitude, location.coords.latitude])
        })
    };

    const initializeMap = () => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiamhvbWJhbHoiLCJhIjoiY2tmZHBoMGp6MWtyMTM2bzU4am03eWp6cSJ9.J_8Q-Qci62ZOMc2BBVKxPQ';

        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: start, // starting position
            zoom: 12,
        });

        map.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                }
            })
        );


        new mapboxgl.Marker({
            color: blue[600]
        })
            .setPopup(new mapboxgl.Popup().setHTML(`You're here!`))
            .setLngLat(start)
            .addTo(map);

        setgtcMap({ map: map })

        setTimeout(() => {
            startMap(map)

            getPharmacyCoordinates(map)
        }, 3000);
    }

    const startMap = (mmap) => {
        getRoute(start, mmap);
        mmap.addLayer({
            id: 'point',
            type: 'circle',
            source: {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'Point',
                            coordinates: start
                        }
                    }]
                }
            },
            paint: {
                'circle-radius': 2,
                'circle-color': blue[600]
            }
        });
    }


    const getRouteToDestination = (mmap, lng, lat) => {

        const endPoint = [lng, lat];

        var end = {
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Point',
                    coordinates: endPoint
                }
            }]
        };

        if (mmap.getLayer('end')) {
            mmap.getSource('end').setData(end);
        } else {
            mmap.addLayer({
                id: 'end',
                type: 'circle',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [{
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Point',
                                coordinates: endPoint
                            }
                        }]
                    }
                },
            });
        }

        new mapboxgl.Marker({
            color: red[600]
        })
            .setPopup(new mapboxgl.Popup().setHTML(`You're destination!`))
            .setLngLat(endPoint)
            .addTo(mmap);

        getRoute(endPoint, mmap);
        setInstructionReady(true)
    }

    const getRoute = (end, mmap) => {
        var url = 'https://api.mapbox.com/directions/v5/mapbox/driving/' + start[0] + ',' + start[1] + ';' + end[0] + ',' + end[1] + '?steps=true&geometries=geojson&access_token=' + mapboxgl.accessToken;
        var req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.onload = function () {
            var json = JSON.parse(req.response);
            var data = json.routes[0];
            var route = data.geometry.coordinates;
            var geojson = {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: route
                }
            };
            if (mmap.getSource('route')) {
                mmap.getSource('route').setData(geojson);
            } else {
                mmap.addLayer({
                    id: 'route',
                    type: 'line',
                    source: {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: geojson
                            }
                        }
                    },
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': red[600],
                        'line-width': 2,
                        'line-opacity': 0.80
                    }
                });
            }


            var steps = data.legs[0].steps;
            var time = Math.floor(data.duration / 60);

            setTimeTravel(time)
            setInstructions(steps)
        };
        req.send();
    }

    const handleSelectedInstruction = (ins) => {
        var toink = [ins.maneuver.location[0], ins.maneuver.location[1]]
        new mapboxgl.Marker({
            color: purple[600]
        })
            .setPopup(new mapboxgl.Popup().setHTML(ins.name))
            .setLngLat(toink)
            .addTo(gtcMap.map);
    }

    const getPharmacyCoordinates = async (map) => {
        try {
            var formdata = new FormData();
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('order_no', selectedOrder.order_no)
            const request = await Axios.post('patient/cart/getphar-coordinates', formdata)
            const data = request.data;
            if (data.length > 0) {
                getRouteToDestination(map, data[0].pharm_longitude, data[0].pharm_latitude)
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }


    useEffect(() => {
        getNavLocation()
        initializeMap()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrder])

    return (
        <>
            { category === 'map-details' && (
                <Paper
                    component={Box}
                    variant="outlined"
                    p={2}
                    className={`gtcmap`}
                >
                    <Box display="flex" mb={2}>
                        <Box flexGrow={1}>
                            <Box display="flex">
                                <IconButton
                                    onClick={close}
                                >
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <Box mt={2}>
                                    <Typography
                                        variant="subtitle2"
                                        className={'gtc-uppercase'}
                                    >
                                        Order For Pickup
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                color="primary"
                                onClick={() => setCategory('order-details')}
                            >
                                Order Details
                            </Button>
                        </Box>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box >
                                {
                                    instructionReady ?
                                        <>
                                            <Typography variant="subtitle2" align="center"> <b> TIME NEWS: {timeTravel} MINS </b>  </Typography>
                                            <List id="instructions">
                                                {instructions && (
                                                    instructions.length > 0 && (
                                                        instructions.map((data, index) => (
                                                            <ListItem
                                                                onClick={() => handleSelectedInstruction(data)}
                                                                key={index}
                                                                button
                                                            >
                                                                <ListItemIcon>
                                                                    <RadioButtonCheckedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={data.maneuver.instruction} />
                                                            </ListItem>
                                                        ))
                                                    )
                                                )}
                                            </List>
                                        </>
                                        : <Typography color="primary" align="center"> loading instructions... </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box mt={2} border={3} id='map' />
                        </Grid>
                    </Grid>
                </Paper>
            )}
            { category === 'order-details' && (
                <CompleteOrder selectedOrder={selectedOrder} close={close} />
            )}
        </>
    )
}

export default PickupDetails;