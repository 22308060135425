import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
export default class GTCLogo extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Fragment>
        <Col sm={12} className="mx-auto mb-5 mb-sm-0">
          <img
            // src={
            //   process.env.REACT_APP_API.includes("https://api") ||
            //   process.env.NODE_ENV === "development"
            //     ? `/gtcdoc.png`
            //     : `/clinic/gtcdoc.png`
            // }
            src={"/gtccis.png"}
            alt=""
            className="d-flex col-xs-12 col-sm-10 col-lg-8 mx-auto"
          />
        </Col>
      </Fragment>
    );
  }
}
