import React, { Fragment, useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  CardMedia,
} from "@material-ui/core";
import Container from "src/layout/Container";
import SwipeableViews from "react-swipeable-views";
import Axios from "axios";
import { isMobile } from "react-device-detect";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import NewsInDialog from "src/gtcnews/NewsInDialog";
import CheckInternet from "src/utils/CheckInternet";
import CheckingConnection from "src/CheckInternet";
import { WifiOff } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 30px",
    width: "100%",
  },
  image: {
    // maxWidth: 900,
    marginTop: 25,
    height: isMobile ? 250 : window.innerWidth >= 959 ? 600 : 400,
    border: "1px solid #aaa",
    margin: "auto",
    display: "block",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  stepper: {
    justifyContent: "center",
    width: "100%",
  },
  thumbnailDesc: {
    color: "#fff",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    fontSize: "20px",
    // maxWidth: 900,
    width: "100%",
    height: 70,
    top: 0,
    cursor: "pointer",
    "&:hover": {
      color: "#0078ff",
    },
    backgroundColor: "#000",
    opacity: 1,
    margin: "auto",
    border: "1px solid",
  },
  tag: {
    zIndex: 1,
    position: "absolute",
    paddingTop: 26,
    marginLeft: 38,
  },
  tag_label: {
    backgroundColor: "#00000094",
    color: "#fff",
    padding: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
}));

const GTCNewsFullImage = () => {
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const [current, setCurrent] = useState(0);
  const classes = useStyles();
  const [featured, setFeatured] = useState([]);
  const [featuredRd, setFeaturedRd] = useState(false);
  const limit = 6;
  const [list, setList] = useState([]);
  const [listRd, setListRd] = useState(false);
  const [isloadable, setIsLoadable] = useState(true);
  const [online, setOnline] = useState("checking");

  const checkinternet = () => {
    try {
      CheckInternet.online().then((res) => {
        setOnline("connected");
        getFeaturedNews();
        getNewsList();
      });
    } catch (error) {
      setOnline("disconnected");
      console.log("disconnected");
    }
  };

  const getFeaturedNews = async () => {
    var params = {
      token: token,
      is_featured: 1,
    };
    let response = await Axios.get("gtc/news/featured-list", { params });
    setFeatured(response.data);
    setFeaturedRd(true);
  };

  const getNewsList = async () => {
    try {
      var params = {
        token: token,
        is_featured: 0,
        limit: limit,
      };
      let response = await Axios.get("gtc/news/featured-list", { params });
      if (response.data.length === 0) {
        setIsLoadable(false);
      }
      setList(response.data);
      setListRd(true);
    } catch (error) {
      setListRd(true);
    }
  };

  const getMoreNewsList = async () => {
    var params = {
      token: token,
      is_featured: 0,
      limit: limit,
      lastid: list.length > 0 ? list[list.length - 1].id : 0,
    };
    let response = await Axios.get("gtc/news/featured-list-more", { params });
    if (response.data.length === 0) {
      setIsLoadable(false);
    }
    setList((list) => list.concat(response.data));
  };

  useEffect(() => {
    checkinternet();

    //eslint-disable-next-line
  }, []);

  const length = featured.length;

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: false,
          current: "SAMOPLPERLS PAERL PSE",
          items: [],
        }}
        title={
          <>
            <Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
              Dashboard
            </Typography>
            <Typography variant="subtitle2">
              Welcome back, {username}! 👋
            </Typography>
          </>
        }
      >
        {online === "checking" && <CheckingConnection />}

        {online === "disconnected" && (
          <Box
            height={"calc(90vh - 250px)"}
            display="flex"
            align="center"
            alignItems="center"
            justifyContent="center"
            alignSelf={"center"}
          >
            <Paper component={Box} p={2} color="secondary" noWrap>
              <Box mb={2}>
                <WifiOff size={100} style={{ color: "#f50057" }} />
              </Box>
              <Box>
                <Typography variant="h5" color="secondary">
                  No internet connection, Unable to fetch gtc news.
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}

        {online === "connected" &&
          (listRd &&
          featuredRd &&
          list.length === 0 &&
          featured.length === 0 ? (
            <Box
              height={"calc(90vh - 250px)"}
              display="flex"
              align="center"
              alignItems="center"
              justifyContent="center"
              alignSelf={"center"}
            >
              <Paper component={Box} p={2} color="secondary" noWrap>
                <Box mb={2}>
                  <WifiOff size={100} style={{ color: "#f50057" }} />
                </Box>
                <Box>
                  <Typography variant="h5" color="secondary">
                    No gtc news found. please try again later.
                  </Typography>
                </Box>
              </Paper>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box>
                  {featured.length > 0 && (
                    <div className={classes.tag}>
                      <span className={classes.tag_label}>
                        {`${current + 1} / ${length}`}
                      </span>
                    </div>
                  )}
                  <SwipeableViews
                    className={classes.root}
                    slideStyle={{ padding: "0 8px" }}
                    enableMouseEvents
                    index={current}
                    onChangeIndex={(e) => setCurrent(e)}
                  >
                    {featuredRd ? (
                      featured.length > 0 ? (
                        featured.map((data, index) => (
                          <Box key={index}>
                            <CardMedia
                              component={"img"}
                              alt="gtc-news"
                              src={`${imageLocation}/news/thumbnail/${data.news_thumbnail}`}
                              className={classes.image}
                            />
                            <Box
                              className={classes.thumbnailDesc}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() =>
                                window.open(data.news_url, "_blank")
                              }
                            >
                              <Typography
                                style={{
                                  textIndent: "1em",
                                  padding: 4,
                                  fontSize: isMobile ? 12 : 16,
                                }}
                              >
                                {isMobile
                                  ? data.news_title.length > 80
                                    ? `${data.news_title.substr(0, 80)}...`
                                    : data.news_title
                                  : data.news_title.length > 150
                                  ? `${data.news_title.substr(0, 150)}...`
                                  : data.news_title}
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="h6" color="secondary">
                          No news recorded.
                        </Typography>
                      )
                    ) : (
                      <Typography variant="h6" color="primary">
                        Loading, please wait...
                      </Typography>
                    )}
                  </SwipeableViews>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box
                  mb={1}
                  p={2}
                  className={`pointer`}
                  display="flex"
                  component={Paper}
                  elevation={0}
                >
                  <Box flexGrow={1}>
                    <Typography variant="h5">
                      <strong>Big Medicine Discount</strong>
                    </Typography>
                  </Box>
                  <ArrowForwardIosIcon />
                </Box>

                <Box>
                  {listRd ? (
                    list.length > 0 ? (
                      list.map((data, index) => (
                        <Box
                          mb={1}
                          key={index}
                          p={1}
                          className={`pointer`}
                          component={Paper}
                          elevation={0}
                          onClick={() => window.open(data.news_url, "_blank")}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={12} lg={3}>
                              <CardMedia
                                component={"img"}
                                alt="gtc-news"
                                src={`${imageLocation}/news/thumbnail/${data.news_thumbnail}`}
                                style={{ maxHeight: 180, borderRadius: 2 }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} md={12} lg={9}>
                              <Box>
                                <Typography color="primary" variant="subtitle2">
                                  {data.tag}
                                </Typography>

                                <Typography
                                  variant="subtitle1"
                                  component={Box}
                                  fontWeight={600}
                                >
                                  {data.news_title.length > 150
                                    ? `${data.news_title.substr(0, 150)}...`
                                    : data.news_title}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="h6" color="secondary" align="center">
                        No news record in a list
                      </Typography>
                    )
                  ) : (
                    <Typography variant="h6" color="primary" align="center">
                      Loading, please wait...
                    </Typography>
                  )}

                  <Box>
                    <Button
                      fullWidth
                      color="primary"
                      onClick={getMoreNewsList}
                      hidden={!isloadable || list.length === 0}
                    >
                      Load More News...
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))}

        {/* news in dialog */}
        <NewsInDialog />
      </Container>
    </Fragment>
  );
};

export default GTCNewsFullImage;
