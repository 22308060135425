import React, { useEffect, useState } from 'react'
import { Box, IconButton, CardHeader, CardMedia, Typography, Avatar, Grid, Fab, makeStyles } from '@material-ui/core'
import Axios from 'axios'
import Notify from '../../notification/Notify'
import PatientHemaOrder from './details/PatientHemaOrder'
import PatientSeroOrder from './details/PatientSeroOrder'
import PatientChemOrder from './details/PatientChemOrder'
import PatientMicroOrder from './details/PatientMicroOrder'
import PatientFecalOrder from './details/PatientFecalOrder'
import CancelIcon from '@material-ui/icons/Cancel'
import { Fragment } from 'react'
import PrintIcon from '@material-ui/icons/Print';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((sambo) => ({
    fab: {
        position: 'absolute',
        bottom: sambo.spacing(2),
        right: sambo.spacing(2),
    }
}))

const LaboratoryOrderDetails = ({ orderDetails, close }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const classes = useStyles();

    const [hemaOrderDetails, setHemaOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [serologyOrderDetails, setSerologyOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [chemistryOrderDetails, setChemistryOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [microscopyOrderDetails, setMicroscopyOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [fecalOrderDetails, setFecalOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const updateUnviewOnPrintOrder = () => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderDetails.order_id
        }
        Axios.get('patient/order/ordernew-all/complete/details-print', { params })
            .then((response) => {
                const data = response.data;
                console.log('msg:', data);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getHemaOrderDetails = () => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderDetails.order_id
        }
        Axios.get('patient/order/ordernew-hemathology/complete/details-print', { params })
            .then((response) => {
                const data = response.data;
                setHemaOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getSerologyOrderDetails = () => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderDetails.order_id
        }
        Axios.get('patient/order/ordernew-sorology/complete/details-print', { params })
            .then((response) => {
                const data = response.data;
                setSerologyOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getChemistryOrderDetails = () => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderDetails.order_id
        }
        Axios.get('patient/order/ordernew-chemistry/complete/details-print', { params })
            .then((response) => {
                const data = response.data;
                setChemistryOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getMicroscopyOrderDetails = () => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderDetails.order_id
        }
        Axios.get('patient/order/ordernew-microscopy/complete/details-print', { params })
            .then((response) => {
                const data = response.data;
                setMicroscopyOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getFecalAnalysisOrderDetails = () => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderDetails.order_id
        }
        Axios.get('patient/order/ordernew-fecalanalysis/complete/details-print', { params })
            .then((response) => {
                const data = response.data;
                setFecalOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        updateUnviewOnPrintOrder()
        getHemaOrderDetails()
        getSerologyOrderDetails()
        getChemistryOrderDetails()
        getMicroscopyOrderDetails()
        getFecalAnalysisOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDetails.order_id])

    return (
        <Fragment>
            <Box display="flex" className="d-print-none" >
                <Box flexGrow={1} />
                <IconButton
                    variant="outlined"
                    color={'secondary'}
                    onClick={close}
                >
                    <CancelIcon />
                </IconButton>
            </Box>
            <Box className="d-print-block" mt={2} />
            <Box display="flex" justifyContent="center">
                <CardHeader
                    avatar={
                        <Fragment>
                            {
                                orderDetails.formheader ?
                                    < CardMedia
                                        style={{ width: 70 }}
                                        component={'img'}
                                        src={`${imageLocation}laboratory/logo/${orderDetails.formheader.logo}`}
                                    />
                                    : <Avatar
                                        style={{ width: 70, height: 70 }}
                                    />
                            }
                        </Fragment>
                    }
                    style={{ textAlign: 'center' }}
                    title={orderDetails.formheader && (orderDetails.formheader.name)}
                    subheader={orderDetails.formheader && (orderDetails.formheader.address)}
                />
            </Box>
            <Box>
                {hemaOrderDetails.data && Object.keys(hemaOrderDetails.data).length > 0 && hemaOrderDetails.ready && <PatientHemaOrder hemaOrderDetails={hemaOrderDetails} />}
            </Box>

            <Box>
                {serologyOrderDetails.data && Object.keys(serologyOrderDetails.data).length > 0 && serologyOrderDetails.ready && <PatientSeroOrder serologyOrderDetails={serologyOrderDetails} />}
            </Box>

            <Box>
                {chemistryOrderDetails.data && Object.keys(chemistryOrderDetails.data).length > 0 && chemistryOrderDetails.ready && <PatientChemOrder chemistryOrderDetails={chemistryOrderDetails} />}
            </Box>

            <Box>
                {microscopyOrderDetails.data && Object.keys(microscopyOrderDetails.data).length > 0 && microscopyOrderDetails.ready && <PatientMicroOrder microscopyOrderDetails={microscopyOrderDetails} />}
            </Box>

            <Box>
                {fecalOrderDetails.data && Object.keys(fecalOrderDetails.data).length > 0 && fecalOrderDetails.ready && <PatientFecalOrder fecalOrderDetails={fecalOrderDetails} />}
            </Box>
            <Box m={2}>
                <Grid container>
                    <Grid item xs={6}>

                        <Box width="100%" display="flex" justifyContent="center">
                            {orderDetails.formheader && (
                                <CardMedia
                                    component="img"
                                    src={`${imageLocation}laboratory/pathologist/${orderDetails.formheader.pathologist_signature}`}
                                    style={{
                                        width: 100,
                                        position: 'relative',
                                    }}
                                />
                            )}
                        </Box>
                        <Box width="100%" display="flex" justifyContent="center">
                            <Typography style={{ textDecoration: "underline" }} className={`gtc-uppercase`} variant="subtitle2">  {orderDetails.formheader && (orderDetails.formheader.pathologist)} </Typography>
                        </Box>
                        <Box width="100%" display="flex" justifyContent="center">
                            <Box>
                                <b> Pathologist (LIC No. {orderDetails.formheader && (orderDetails.formheader.pathologist_lcn)}) </b>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mt={2}>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Typography style={{ textDecoration: "underline" }} className={`gtc-uppercase`} variant="subtitle2"> {orderDetails.formheader && (orderDetails.formheader.medtech)} </Typography>
                            </Box>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Box>
                                    <b> Medical Technologist </b>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="d-print-none">
                <Fab aria-label={'Print'} className={classes.fab} color={'primary'} onClick={() => window.print()}>
                    <PrintIcon />
                </Fab>
            </Box>
        </Fragment>
    )
}

export default LaboratoryOrderDetails;