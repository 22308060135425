import React, { Fragment, useState } from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import {
  TextField,
  Button,
  DialogContent,
  FormHelperText,
  DialogActions,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { CheckSquare, XCircle } from "react-feather";

function PatientDetailsAppointmentResched({ app, close, reload }) {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [selectedDate, setSelectedDate] = useState(
    new Date(Boolean(app.is_reschedule) ? app.is_reschedule_date : app.app_date)
  );

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("app_date", selectedDate.toLocaleString());
    formData.append("appointment_id", app.appointment_id);
    //   formData.append('patient_id', patient_id)

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          username: username,
          user_id: user_id,
          token: token,
          password: "",
          reason: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(),
          reason: Yup.string().required(),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await Axios.post(
              "encoder/patient/appointment/reschedule",
              getFormData(values)
            );
            if (request.data === "pass-invalid") {
              setErrors({ submit: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data === "success") {
              Notify.successRequest("reschedule");
              resetForm();
              close();
              reload();
              setSubmitting(true);
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box mb={1} mt={1}>
                <DatePicker
                  fullWidth
                  disablePast
                  margin="normal"
                  id="date-picker"
                  label="Date Picker"
                  value={selectedDate}
                  inputVariant="outlined"
                  onChange={(date) => setSelectedDate(date)}
                  format="MM/dd/yyyy"
                  name="app_date"
                />
              </Box>

              <Box mb={1}>
                <TimePicker
                  fullWidth
                  margin="normal"
                  id="time-picker"
                  label="Time Picker"
                  value={selectedDate}
                  inputVariant="outlined"
                  onChange={(date) => setSelectedDate(date)}
                  name="app_time"
                />
              </Box>

              <Box mb={1}>
                <TextField
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reason}
                  fullWidth
                  label="Reason of Reschedule"
                  margin="normal"
                  variant="outlined"
                  multiline
                  name="reason"
                />
              </Box>

              <Box mb={1}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  type="password"
                  fullWidth
                  label="Password"
                  margin="normal"
                  variant="outlined"
                  name="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<XCircle />}
              >
                No
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CheckSquare />}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default PatientDetailsAppointmentResched;
