import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Typography,
  Collapse,
} from "@material-ui/core";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, { useEffect, useState, Fragment } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const LaboratoryPackageList = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const mounted = IsMountedRef();
  const [packageList, setPackageList] = useState({ data: [], ready: false });
  const [packageItems, setPackageItems] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const getPackageList = React.useCallback(async () => {
    try {
      const request = await axios.get("laboratory/test/packages-list", {
        params: {
          token,
          user_id,
          management_id,
        },
      });
      if (mounted.current) {
        setPackageList({
          data: request.data,
          ready: true,
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  }, [mounted, user_id, token, management_id]);

  const getPackageListItems = async (data) => {
    if (selectedPackage && selectedPackage === data.package_id) {
      console.log("Package id is currently selected.");
      return;
    }

    try {
      const request = await axios.get("laboratory/test/packages-list-items", {
        params: {
          token,
          user_id,
          management_id,
          package_id: data.package_id,
        },
      });

      if (mounted.current) {
        setPackageItems(request.data);
        setSelectedPackage(data.package_id);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    getPackageList();
  }, [getPackageList]);

  return (
    <Box>
      <Card>
        <CardHeader title="Laboratory Packages" />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Package</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {packageList.ready ? (
                  packageList.data.length > 0 ? (
                    packageList.data.map((data, key) => (
                      <Fragment key={key}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() => getPackageListItems(data)}
                              disabled={
                                selectedPackage &&
                                selectedPackage === data.package_id
                              }
                            >
                              {selectedPackage &&
                              selectedPackage === data.package_id ? (
                                <KeyboardArrowDown />
                              ) : (
                                <KeyboardArrowRight />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>{data.package_name}</TableCell>
                          <TableCell align="right">
                            {data.order_amount}
                          </TableCell>
                        </TableRow>
                        {selectedPackage &&
                          selectedPackage === data.package_id && (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Box>
                                  <Collapse
                                    in={
                                      selectedPackage &&
                                      selectedPackage === data.package_id
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box>
                                      <Box mb={2}>
                                        <Typography
                                          color="textSecondary"
                                          variant="h6"
                                        >
                                          Package Items
                                        </Typography>
                                      </Box>

                                      <Table>
                                        <TableBody>
                                          {packageItems.length > 0 ? (
                                            packageItems.map((data, key) => (
                                              <TableRow key={key}>
                                                <TableCell>
                                                  {data.order_name}
                                                </TableCell>
                                              </TableRow>
                                            ))
                                          ) : (
                                            <TableRow>
                                              <TableCell>
                                                <Typography color="secondary">
                                                  No item found.
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LaboratoryPackageList;
