import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import LaboratoryInformation from "./LaboratoryInformation";

const ManagementLaboratory = ({ details }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [laboratoryList, setLaboratoryList] = useState([]);
  const [selectedLaboratory, setSelectedLaboratory] = useState(null);

  const getLaboratoryList = async () => {
    let response = await Axios.get(
      "gtcadmin/doctors/getmanagement/doctors-listlaboratory",
      {
        params: {
          token: token,
          management_id: details.management_id,
        },
      }
    );
    let result = response.data;

    setLaboratoryList(result);
  };

  const handleNewLaboratory = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);

    try {
      let response = await Axios.post(
        "gtcadmin/doctors/getmanagement/doctors-addlaboratory",
        formdata
      );

      let result = response.data;
      if (result.message === "success") {
        getLaboratoryList();
        setDialog(false);
        Notify.successRequest("laboratory added.");
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    getLaboratoryList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={9}>
          {selectedLaboratory && (
            <LaboratoryInformation labinfo={selectedLaboratory} />
          )}
        </Grid>

        <Grid item xs={12} sm={3}>
          <Paper component={Box} p={2}>
            <Box>
              <Typography variant="h6" color="textSecondary">
                Laboratory Test
              </Typography>
            </Box>
            <Box>
              <List>
                <ListItem onClick={() => setDialog(true)} button>
                  <ListItemText
                    primary="New Laboratory"
                    secondary="Create Laboratory in this management"
                  />
                </ListItem>

                {laboratoryList.length > 0 &&
                  laboratoryList.map((data, index) => (
                    <ListItem button key={index}>
                      <ListItemText
                        primary={data.name}
                        secondary={data.address}
                        onClick={() => setSelectedLaboratory(data)}
                      />
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Add Laboratory */}
      <AddLaboratory
        open={dialog}
        close={() => setDialog(false)}
        handleNewLaboratory={handleNewLaboratory}
      />
    </Box>
  );
};

export default ManagementLaboratory;

const AddLaboratory = ({ open, close, handleNewLaboratory }) => {
  return (
    <Dialog open={open} onClose={close} maxWidth={"xs"} fullWidth>
      <DialogTitle> New Laboratory </DialogTitle>
      <form onSubmit={handleNewLaboratory}>
        <DialogContent dividers>
          <Box mb={2}>
            <TextField label="Name" variant="outlined" fullWidth name="name" />
          </Box>
          <Box mb={2}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="address"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Gender"
              variant="outlined"
              fullWidth
              name="gender"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Birthday"
              variant="outlined"
              fullWidth
              name="birthday"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
          </Box>
          <Box mb={2}>
            <TextField label="Role" variant="outlined" fullWidth name="role" />
          </Box>
          <Box mb={2}>
            <TextField
              label="Laboratory Username"
              variant="outlined"
              fullWidth
              name="lab_username"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Laboratory Role"
              variant="outlined"
              fullWidth
              type="password"
              name="lab_password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            color="default"
            variant="contained"
            onClick={close}
          >
            No
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
