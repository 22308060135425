import React, { Fragment, useState, useEffect } from "react";
import Axios from "axios";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Badge,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import ZoomableImage from "src/utils/ZoomableImage";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AddCircle, KeyboardArrowLeft } from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Notify from "src/notification/Notify";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const LaboratoryAttachments = ({ patient_id, connection }) => {
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [token] = useState(localStorage.getItem("token"));
  const [username] = useState(localStorage.getItem("username"));
  const [shareImages, setShareImages] = useState([]);
  const [docAttachImagesReady, setdocAttachImagesReady] = useState(false);
  const [docAttachDates, setDocAttachDates] = useState([]);
  const [docAttachDatesReady, setDocAttachDatesReady] = useState(false);
  const [fstRotateImg, setFstRotateImg] = useState(0);
  const [secondRotateImg, setsecondRotateImg] = useState(0);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [directViewImage, setDirectViewImage] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [expanded, setExpanded] = React.useState(null);
  const [displayState, setDisplayState] = useState("list-attachment");
  const [files, setFiles] = useState([]);
  const [uploadProcess, setuploadProcess] = useState(false);

  const [selectedImage, setSelectedImage] = useState({
    image: null,
    date: null,
  });
  const [selectedImageSecond, setSelectedImageSecond] = useState({
    image: null,
    date: null,
  });

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDocAttachmentDateInGroup = () => {
    var formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("token", token);
    formdata.append("patient_id", patient_id);
    formdata.append("type", "laboratory-result");
    Axios.post(
      "doctor/laboratory/laboratory-details/doc-attach-dateingroupby",
      formdata
    )
      .then((response) => {
        const data = response.data;
        setDocAttachDates(data);
        setDocAttachDatesReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleSelectedImage = (e) => {
    if (selectedImage.image === null) {
      setSelectedImage({
        image: e.currentTarget.getAttribute("image"),
        date: e.currentTarget.getAttribute("date"),
      });
      Notify.customToast("First Image", "Ready to compare");
    } else if (selectedImageSecond.image === null) {
      setSelectedImageSecond({
        image: e.currentTarget.getAttribute("image"),
        date: e.currentTarget.getAttribute("date"),
      });
      setDialog(true);
      Notify.customToast("Second Image", "Ready to compare");
    } else {
      console.log("max compare enabled.");
    }
  };

  const handleDirectViewImage = (e) => {
    setDirectViewImage(
      directViewImage === e.currentTarget.id ? null : e.currentTarget.id
    );
    setOpenLightbox(!openLightbox);
  };

  const handleCloseDialog = () => {
    setSelectedImage({ image: null, date: null });
    setSelectedImageSecond({ image: null, date: null });
    setFstRotateImg(0);
    setsecondRotateImg(0);
    setDialog(false);
  };

  const rotateFirstImage = () => {
    let newRotation = fstRotateImg + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setFstRotateImg(newRotation);
  };

  const rotateSecondImage = () => {
    let newRotation = secondRotateImg + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setsecondRotateImg(newRotation);
  };

  const handleSelectedDate = (panel) => async (event, isExpanded) => {
    if (isExpanded) {
      try {
        var formdata = new FormData();
        formdata.append("token", token);
        formdata.set("selectedDate", panel);
        formdata.set("patient_id", patient_id);
        formdata.append("type", "laboratory-result");

        const response = await Axios.post(
          "doctor/laboratory/laboratory-details/doc-attach-dateingroupby-details",
          formdata
        );

        const data = response.data;
        setTimeout(() => {
          setShareImages(data);
          setdocAttachImagesReady(true);
        }, 3000);
      } catch (error) {
        Notify.requestError(error);
      }
    }

    setExpanded(isExpanded ? panel : false);
    setdocAttachImagesReady(false);
  };

  const handleUpload = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.append("share_image", files[0]);
    formdata.append("patient_id", patient_id);
    formdata.set("type", "laboratory-result");
    var error = [];

    if (files.length < 1) {
      error = "error";
      Notify.fieldRequired("attachment");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.warn("form has error.");
    } else {
      setuploadProcess(true);
      Axios.post("doctor/shared/images/laboratory-result-new", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("upload");
            getDocAttachmentDateInGroup();
            setDisplayState("list-attachment");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setuploadProcess(false);
        });
    }
  };

  const handleChange = (files) => {
    setFiles(files);
  };

  useEffect(() => {
    getDocAttachmentDateInGroup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <Fragment>
      {displayState === "list-attachment" && (
        <Fragment>
          <Box display="flex">
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                startIcon={
                  displayState === "create-attachment" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <AddCircle />
                  )
                }
                color={
                  displayState === "create-attachment" ? "default" : "primary"
                }
                onClick={() =>
                  setDisplayState(
                    displayState === "create-attachment"
                      ? "list-attachment"
                      : "create-attachment"
                  )
                }
              >
                {displayState === "create-attachment" ? "Back" : "Add"}
              </Button>
            </Box>
          </Box>

          <Box mt={2}>
            {docAttachDatesReady
              ? docAttachDates.length > 0
                ? docAttachDates
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((d, d_index) => {
                      return (
                        <Accordion
                          key={d_index}
                          expanded={
                            expanded === Notify.createdAt(d.doc_attach_date)
                          }
                          onChange={handleSelectedDate(
                            Notify.createdAt(d.doc_attach_date)
                          )}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                              variant="subtitle2"
                              className="gtc-uppercase"
                            >
                              Doctor's attachment last{" "}
                              {Notify.createdAt(d.doc_attach_date)}
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            {docAttachImagesReady
                              ? shareImages.length > 0
                                ? shareImages.map((data, index) => {
                                    return (
                                      <Box p={1} key={index}>
                                        <Badge
                                          overlap="rectangle"
                                          anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                          color="error"
                                          badgeContent={data.category}
                                        >
                                          <Card elevation={0}>
                                            <CardContent>
                                              <Box align="center">
                                                <CardMedia
                                                  style={{
                                                    width: "110px",
                                                    height: "110px",
                                                  }}
                                                  component="img"
                                                  src={
                                                    imageLocation +
                                                    "imaging/doctorattachments/" +
                                                    data.image
                                                  }
                                                />
                                                <Box>
                                                  <Button
                                                    color="secondary"
                                                    size="small"
                                                    onClick={
                                                      handleDirectViewImage
                                                    }
                                                    id={data.image}
                                                  >
                                                    view
                                                  </Button>
                                                </Box>
                                                <Box>
                                                  <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={
                                                      handleSelectedImage
                                                    }
                                                    image={data.image}
                                                    date={data.created_at}
                                                  >
                                                    compare
                                                  </Button>
                                                </Box>
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Badge>
                                      </Box>
                                    );
                                  })
                                : Notify.noRecord()
                              : Notify.loading()}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                : Notify.noRecord()
              : Notify.loading()}
            <TablePagination
              labelRowsPerPage="List"
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={docAttachDates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Fragment>
      )}
      {displayState === "create-attachment" && (
        <Fragment>
          <Box component={Paper} variant="outlined">
            <Box m={2}>
              <form onSubmit={handleUpload} encType="multipart/form-data">
                <Box my={2}>
                  <DropzoneArea
                    filesLimit={1}
                    onChange={handleChange}
                    acceptedFiles={["image/jpeg", "image/png"]}
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    name="password"
                    type="password"
                  />
                </Box>

                <Box display="flex">
                  <Box flexGrow={1} />
                  <Box mr={1}>
                    <Button
                      onClick={() =>
                        setDisplayState(
                          displayState === "create-attachment"
                            ? "list-attachment"
                            : "create-attachment"
                        )
                      }
                      variant="contained"
                      color="default"
                      startIcon={<KeyboardArrowLeft />}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      startIcon={
                        uploadProcess ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <CloudUploadIcon />
                        )
                      }
                      variant="contained"
                      color="primary"
                      autoFocus
                      disabled={uploadProcess}
                    >
                      Upload
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Fragment>
      )}

      <ZoomableImage
        open={openLightbox}
        close={() => {
          setDirectViewImage(null);
          setOpenLightbox(false);
        }}
        title={`Doctor's Attachment`}
        image={`${imageLocation}imaging/doctorattachments/${directViewImage}`}
      />

      <Dialog
        scroll="body"
        fullScreen={true}
        disableBackdropClick={true}
        open={dialog}
        onClose={handleCloseDialog}
      >
        <PerfectScrollbar>
          <Box className="gtc-viewimage">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TransformWrapper>
                  {({ zoomIn, zoomOut }) => (
                    <Fragment>
                      <div className="clearfix border-bottom pl-1">
                        <div className="float-left m-2 ">
                          <Typography color="primary" variant="h6">
                            {" "}
                            FirstImage -{" "}
                            {selectedImage.date &&
                              Notify.dateTimeConvert(selectedImage.date)}{" "}
                          </Typography>
                        </div>
                        <div className="float-right">
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={rotateFirstImage}
                          >
                            <RotateRightIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={zoomIn}
                          >
                            <AddToQueueIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={zoomOut}
                          >
                            <RemoveFromQueueIcon />
                          </IconButton>
                        </div>
                      </div>
                      <TransformComponent>
                        <div>
                          {shareImages.length > 0 ? (
                            <img
                              src={
                                imageLocation +
                                "imaging/doctorattachments/" +
                                selectedImage.image
                              }
                              alt=""
                              style={{
                                transform: `rotate(${fstRotateImg}deg)`,
                              }}
                            />
                          ) : null}
                        </div>
                      </TransformComponent>
                    </Fragment>
                  )}
                </TransformWrapper>
              </Grid>
              <Grid item xs={6}>
                <TransformWrapper>
                  {({ zoomIn, zoomOut }) => (
                    <Fragment>
                      <div className="clearfix border-bottom pl-1">
                        <div className="float-left m-2 ">
                          <Typography color="primary" variant="h6">
                            {" "}
                            SecondImage -{" "}
                            {selectedImageSecond.date &&
                              Notify.dateTimeConvert(
                                selectedImageSecond.date
                              )}{" "}
                          </Typography>
                        </div>
                        <div className="float-right">
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={rotateSecondImage}
                          >
                            <RotateRightIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={zoomIn}
                          >
                            <AddToQueueIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={zoomOut}
                          >
                            <RemoveFromQueueIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={handleCloseDialog}
                          >
                            <CancelPresentationIcon />
                          </IconButton>
                        </div>
                      </div>
                      <TransformComponent>
                        {shareImages.length > 0 ? (
                          <img
                            src={
                              imageLocation +
                              "imaging/doctorattachments/" +
                              selectedImageSecond.image
                            }
                            alt=""
                            style={{
                              transform: `rotate(${secondRotateImg}deg)`,
                            }}
                          />
                        ) : null}
                      </TransformComponent>
                    </Fragment>
                  )}
                </TransformWrapper>
              </Grid>
            </Grid>
          </Box>
        </PerfectScrollbar>
      </Dialog>
    </Fragment>
  );
};

export default LaboratoryAttachments;
