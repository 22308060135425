import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import React, { useState, useCallback, useEffect } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import Axios from "axios";
import Select from "react-select";
import Notify from "src/notification/Notify";

const PharmacyWarehouseDelivery = ({ getTempDrProductsList }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const mounted = IsMountedRef();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [product, setProduct] = useState();
  const [batches, setBatches] = useState([]);
  const [selectedBatchDetails, setSelectedBatchDetails] = useState({});

  const getProductList = useCallback(async () => {
    try {
      let response = await Axios.get(
        "pharmacy/warehouse/new-invoice/get-productlist",
        {
          params: {
            token: token,
            user_id: user_id,
            management_id,
          },
        }
      );
      if (mounted.current) {
        setProduct(response.data);
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id, management_id, mounted]);

  const getProductBatches = async (product_id) => {
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/product-details",
      {
        params: {
          token: token,
          management_id: management_id,
          product_id: product_id,
        },
      }
    );

    setBatches(response.data);
  };

  const getProductBatchesDetails = async (batch_number) => {
    let response = await Axios.get(
      "pharmacy/warehouse/account/getProductBatchesDetails",
      {
        params: {
          token: token,
          management_id: management_id,
          product_id: selectedProduct.product_id,
          batch_number: batch_number,
        },
      }
    );

    if (response.data.length > 0) {
      setSelectedBatchDetails({
        ...selectedBatchDetails,
        batch_number: batch_number,
        exp_date: response.data[0].expiration_date,
        mfg_date: response.data[0].manufactured_date,
        availableQty:
          parseInt(response.data[0]._total_in_qty) -
          parseInt(response.data[0]._total_out_qty) -
          parseInt(response.data[0]._total_out_qty_intemp),
      });
    }
  };

  const handleSelectedProductAdd = async () => {
    try {
      if (
        parseInt(selectedBatchDetails.orderQty) >
        parseInt(selectedBatchDetails.availableQty)
      ) {
        Notify.customToast(
          "Order quantity Invalid.",
          "Order quantity must be less than in available qty."
        );
        return;
      }

      var formdata = new FormData();
      formdata.set("token", token);
      formdata.set("user_id", user_id);
      formdata.set("management_id", management_id);
      formdata.set("product_id", selectedProduct.product_id);
      formdata.set("product_name", selectedProduct.product_name);
      formdata.set("product_generic", selectedProduct.product_generic);
      formdata.set("unit", selectedProduct.unit);
      formdata.set("msrp", selectedProduct.msrp);
      formdata.set("srp", selectedProduct.srp);
      formdata.set("batch_number", selectedBatchDetails.batch_number);
      formdata.set("expiration_date", selectedBatchDetails.exp_date);
      formdata.set("manufactured_date", selectedBatchDetails.mfg_date);
      formdata.set("availableQty", selectedBatchDetails.availableQty);
      formdata.set("orderQty", selectedBatchDetails.orderQty);

      let response = await Axios.post(
        "pharmacy/warehouse/account/deliver/add-producttotemp",
        formdata
      );
      let result = response.data;

      if (result.message === "product-exist") {
        Notify.customToast("Product Exist.", "Product is already added.");
      }
      if (result.message === "success") {
        getTempDrProductsList();
        Notify.successRequest("Product added to temp.");
      }
    } catch (error) {
      Notify.requestError(error);
    }
  };

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  return (
    <Box>
      <Paper component={Box} p={2}>
        <Box mb={2}>
          <Typography variant="h6" color="textSecondary">
            Select item to deliver
          </Typography>
        </Box>

        <Box mb={2}>
          <Select
            options={product}
            onChange={(value) => {
              setSelectedProduct(value);
              getProductBatches(value.product_id);
            }}
            placeholder="Select Product"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "black",
              }),
              control: (base) => ({
                ...base,
                minHeight: 55,
              }),
            }}
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={
              Boolean(Object.keys(selectedProduct).length)
                ? selectedProduct.product_name
                : ""
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Generic"
            variant="outlined"
            fullWidth
            value={
              Boolean(Object.keys(selectedProduct).length)
                ? selectedProduct.product_generic
                : ""
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box>
                <TextField
                  label="Unit"
                  variant="outlined"
                  fullWidth
                  value={
                    Boolean(Object.keys(selectedProduct).length)
                      ? selectedProduct.unit
                      : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box>
                <TextField
                  label="Srp"
                  variant="outlined"
                  fullWidth
                  value={
                    Boolean(Object.keys(selectedProduct).length)
                      ? selectedProduct.srp
                      : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mb={2}>
          <TextField
            label="Select batch"
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              getProductBatchesDetails(e.target.value);
            }}
          >
            <option value=""> Select </option>
            {batches.map((data, index) => (
              <option key={index} value={data.batch_number}>
                {data.batch_number}
              </option>
            ))}
          </TextField>
        </Box>

        <Box mb={2}>
          <TextField
            label="Available Qty"
            variant="outlined"
            fullWidth
            value={
              Object.keys(selectedBatchDetails).length > 0
                ? selectedBatchDetails.availableQty
                : 0
            }
            InputProps={{ disabled: true }}
          />
        </Box>

        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box>
                <TextField
                  label="Mfg. Date"
                  variant="outlined"
                  fullWidth
                  value={
                    Object.keys(selectedBatchDetails).length > 0
                      ? selectedBatchDetails.mfg_date
                      : ""
                  }
                  InputProps={{ disabled: true }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box>
                <TextField
                  label="Exp. Date"
                  variant="outlined"
                  fullWidth
                  value={
                    Object.keys(selectedBatchDetails).length > 0
                      ? selectedBatchDetails.exp_date
                      : ""
                  }
                  InputProps={{ disabled: true }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mb={2}>
          <TextField
            label="Quantity To Deliver"
            variant="outlined"
            fullWidth
            onChange={(e) =>
              setSelectedBatchDetails({
                ...selectedBatchDetails,
                orderQty: e.target.value,
              })
            }
            type="number"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="contained"
              color="default"
              size="large"
              startIcon={<HighlightOff />}
            >
              Clear
            </Button>
          </Box>

          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<CheckCircleOutline />}
            onClick={handleSelectedProductAdd}
          >
            Add
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default PharmacyWarehouseDelivery;
