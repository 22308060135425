import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'; 
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import Box from '@material-ui/core/Box';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { CircularProgress, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';

function PermissionRequest(props) { 

    const [user_id] = useState(localStorage.getItem('user_id'))
    const [token] = useState(localStorage.getItem('token')) 
    const [username] = useState(localStorage.getItem('username')) 
    const [isprocess, setIsProcess] = useState(false)

    const handleRequest = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);  
            formdata.set('token', token);  
            formdata.set('user_id', user_id);   
            formdata.set('username', username);   
            formdata.set('patient_id', props.patient_id);    
            formdata.set('connection', props.connection);  

        var error = [];
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        } 
        if(error.length > 0 ){
            console.warn("Unable to process, Form has an error.")
        }else{
            setIsProcess(true)
            Axios.post('doctor/online/checkup/request-permissiontopatient', formdata )
            .then( (response) => { 
                const data = response.data;     
                if(data === 'success'){
                    props.closeTab()
                    Notify.successRequest('request permission')
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('request invalid')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() => setIsProcess(false))
        }
    }

    return (
        <Fragment>
            <form onSubmit={ handleRequest }>
                <Box 
                    mt={4}
                    mb={2} 
                    align="center"
                >
                    <EnhancedEncryptionIcon color="error" style={{ fontSize: '3rem' }} />
                    <Typography color="error" variant="h5">
                        NO PERMISSION
                    </Typography>
                    <Typography color="textSecondary" variant="caption" className={`gtc-uppercase`}>
                        You have no permission to view health record
                    </Typography>
                </Box> 
                <Box mt={3}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <InputLabel htmlFor="outlined-adornment-password">Enter your password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="password"  
                            name="password"
                            labelWidth={170}
                            endAdornment={
                                <InputAdornment 
                                    position="end"
                                >
                                    <Button 
                                        variant="contained" 
                                        type="submit" 
                                        color="primary"
                                        disabled={isprocess}
                                        startIcon ={isprocess && <CircularProgress color="inherit" size={20} />}
                                    >
                                        Request
                                    </Button> 
                                </InputAdornment>
                            }
                        />
                    </FormControl> 
                </Box>
            </form>
        </Fragment>
    )
}

export default PermissionRequest;
