import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Tabs,
  Tab,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";

import { useHistory } from "react-router-dom";
import Reschedule from "./Reschedule";
import DetailsLocalSetAsComplete from "./DetailsLocalSetAsComplete";
import { DateTimePicker } from "@material-ui/pickers";

import {
  Calendar,
  CheckCircle,
  CheckSquare,
  Trash,
  User,
  XCircle,
} from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";
import EventNote from "@material-ui/icons/EventNote";
import { Receipt } from "@material-ui/icons";
import TabPanel from "src/utils/TabPanel";
import DetailsLocalUnpaid from "./DetailsLocalUnpaid";
import { Alert } from "@material-ui/lab";

const DetailsLocal = ({ appid }) => {
  const ismounted = IsMountedRef();
  const [details, setDetails] = React.useState({ data: [], ready: false });
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [newRate, setNewRate] = useState(0);
  const [category, setCategory] = useState("calendar");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  const getAppointmentDetails = React.useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.set("app_id", appid);
      formdata.set("user_id", user_id);
      formdata.set("token", token);

      const response = await axios.post(
        "doctor/appointment/local/applocal-details",
        formdata
      );
      if (ismounted.current) {
        setDetails({ data: response.data, ready: true });
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [appid, user_id, token, ismounted]);

  useEffect(() => {
    getAppointmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAppointmentDetails]);

  return (
    <>
      <Grid container spacing={2}>
        <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Details
              details={details}
              getAppointmentDetails={getAppointmentDetails}
              newRate={newRate}
              setNewRate={setNewRate}
            />
          </Grid>
        </Box>

        <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box mb={2} alignContent="center">
              {/* <Paper> */}
              <Box display="flex" justifyContent="center">
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab
                    wrapped
                    value="calendar"
                    label="Calendar"
                    icon={<EventNote />}
                  />

                  <Tab
                    wrapped
                    value="unpaid"
                    label="Unpaid Bills"
                    icon={<Receipt />}
                  />
                </Tabs>
              </Box>
              {/* </Paper> */}
            </Box>

            <TabPanel value={category} index={"calendar"}>
              <Box align="center">
                {details.data.length > 0 && (
                  <>
                    <Box>
                      {Boolean(parseInt(details.data[0].is_complete)) && (
                        <Alert variant="filled" color="success">
                          <Typography>APPOINTMENT COMPLETED</Typography>
                        </Alert>
                      )}
                      {!Boolean(parseInt(details.data[0].is_complete)) &&
                        !Boolean(
                          parseInt(details.data[0].is_paid_bysecretary)
                        ) && (
                          <Alert variant="filled" color="success">
                            <Typography>NEW APPOINTMENT</Typography>
                          </Alert>
                        )}
                      {!Boolean(parseInt(details.data[0].is_complete)) &&
                        Boolean(
                          parseInt(details.data[0].is_paid_bysecretary)
                        ) && (
                          <Alert variant="filled" color="info">
                            <Typography>APPOINTMENT APPROVED </Typography>
                          </Alert>
                        )}
                    </Box>

                    <Box my={2}>
                      <Grid container justify="space-around">
                        <DateTimePicker
                          open={true}
                          variant="static"
                          format="MM/dd/yyyy"
                          margin="normal"
                          value={
                            Boolean(parseInt(details.data[0].is_reschedule))
                              ? details.data[0].is_reschedule_date
                              : details.data[0].app_date
                          }
                          onChange={(e) => console.log(e)}
                          InputProps={{ readOnly: true }}
                          disableFuture
                          disablePast
                        />
                      </Grid>
                    </Box>
                  </>
                )}
              </Box>
            </TabPanel>

            <TabPanel value={category} index={"unpaid"}>
              <Box>
                {details.data.length > 0 && (
                  <DetailsLocalUnpaid
                    trace_number={details.data[0].appointment_id}
                    patient_id={details.data[0].patients_id}
                  />
                )}
              </Box>
            </TabPanel>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default DetailsLocal;

const Details = ({ details, getAppointmentDetails, newRate, setNewRate }) => {
  const history = useHistory();
  const [isreschedule, setIsReschedule] = React.useState(false);
  const [iscomplete, setIsComplete] = React.useState(false);
  const [editRate, setEditRate] = React.useState(false);
  const [remarks, setRemarks] = React.useState("");
  const [remove, setRemove] = React.useState({ open: false, appid: null });
  const [isProcessNewrate, setIsProcessNewrate] = useState(false);
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const [isRemoveProcess, setIsRemoveProcess] = useState(false);

  const handleNewRate = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.append(
      "patient_id",
      details.data.length > 0 ? details.data[0].patients_id : ""
    );
    formdata.append(
      "trace_number",
      details.data.length > 0 ? details.data[0].appointment_id : ""
    );
    var error = [];

    if (formdata.get("password").trim() === "") {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (parseFloat(formdata.get("new_rate")) < 1) {
      error = "error";
      Notify.fieldInvalid("new rate");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcessNewrate(true);
      axios
        .post("doctor/patient/appointment/update-consultation-rate", formdata)
        .then((response) => {
          if (response.data.message === "pass-invalid") {
            Notify.fieldInvalid("password");
          }

          if (response.data.message === "success") {
            setEditRate(false);
            getAppointmentDetails();
            setNewRate(0);
            Notify.successRequest("rate updated");
          }
        })
        .catch((error) => console.log("request has error: ", error.message))
        .finally(() => setIsProcessNewrate(false));
    }
  };

  const handleRemoveAppointment = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("trace_number", remove.appid);
    setIsRemoveProcess(true);
    axios
      .post("doctor/patient/appointment/delete-appointment", formdata)
      .then((response) => {
        const data = response.data;

        if (data.message === "success") {
          history.go(-1);
          setRemove({ open: false, appid: null });
          Notify.successRequest("Appointment removed");
        }
      })
      .catch((error) => console.log("request has error:", error.message))
      .finally(() => setIsRemoveProcess(false));
  };

  return (
    <>
      <Card>
        <CardHeader title="Details" />
        <CardContent>
          {details.ready ? (
            details.data.length > 0 ? (
              <>
                <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Patient"}
                    variant={"outlined"}
                    fullWidth
                    value={details.data[0].patients_name}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                {/* <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Service"}
                    variant={"outlined"}
                    fullWidth
                    value={details.data[0].services}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box
                  className={editRate ? "" : "gtc-textfield-noborder"}
                  mb={3}
                >
                  <TextField
                    label={"Rate"}
                    variant={"outlined"}
                    fullWidth
                    value={editRate ? newRate : details.data[0].amount}
                    InputProps={{
                      readOnly: !editRate,
                      endAdornment: (
                        <InputAdornment>
                          {editRate ? (
                            <IconButton
                              color="primary"
                              onClick={() => setEditRate(false)}
                            >
                              <XCircle />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => setEditRate(true)}
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setNewRate(e.target.value)}
                  />
                </Box> */}

                <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Reason"}
                    variant={"outlined"}
                    fullWidth
                    value={
                      details.data[0].app_reason === null
                        ? "None"
                        : details.data[0].app_reason
                    }
                    InputProps={{ readOnly: true }}
                    multiline
                  />
                </Box>

                <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Request Appointment Date"}
                    variant={"outlined"}
                    fullWidth
                    value={
                      details.data[0].app_date === null
                        ? "None"
                        : Notify.createdAt(details.data[0].app_date)
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Request Appointment Time"}
                    variant={"outlined"}
                    fullWidth
                    value={
                      details.data[0].app_date === null
                        ? "None"
                        : Notify.createdTime(details.data[0].app_date)
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Reschedule"}
                    variant={"outlined"}
                    fullWidth
                    value={
                      Boolean(parseInt(details.data[0].is_reschedule))
                        ? "Yes"
                        : "No"
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                {Boolean(parseInt(details.data[0].is_reschedule)) && (
                  <>
                    <Box className="gtc-textfield-noborder" mb={3}>
                      <TextField
                        label={"New Appointment Date"}
                        variant={"outlined"}
                        fullWidth
                        value={
                          details.data[0].is_reschedule_date === null
                            ? "None"
                            : Notify.createdAt(
                                details.data[0].is_reschedule_date
                              )
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>

                    <Box className="gtc-textfield-noborder" mb={3}>
                      <TextField
                        label={"New Appointment Time"}
                        variant={"outlined"}
                        fullWidth
                        value={
                          details.data[0].is_reschedule_date === null
                            ? "None"
                            : Notify.createdTime(
                                details.data[0].is_reschedule_date
                              )
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>

                    <Box className="gtc-textfield-noborder" mb={3}>
                      <TextField
                        label={"New Appointment Reason"}
                        variant={"outlined"}
                        fullWidth
                        value={
                          details.data[0].is_reschedule_reason === null
                            ? "None"
                            : details.data[0].is_reschedule_reason
                        }
                        InputProps={{ readOnly: true }}
                        multiline
                      />
                    </Box>
                  </>
                )}

                <Box className="gtc-textfield-noborder" mb={3}>
                  <TextField
                    label={"Appointment Status"}
                    variant={"outlined"}
                    fullWidth
                    value={
                      Boolean(parseInt(details.data[0].is_complete))
                        ? "Completed"
                        : !Boolean(parseInt(details.data[0].is_complete)) &&
                          Boolean(parseInt(details.data[0].is_paid_bysecretary))
                        ? "Approved"
                        : "New"
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                {!Boolean(parseInt(details.data[0].is_paid_bysecretary)) && (
                  <Box align="center" mb={2}>
                    <Typography variant="body2" color="secondary">
                      * THIS APPOINTMENT IS CURRENTLY UNPAID *
                    </Typography>
                  </Box>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Box>
                      <Button
                        size="large"
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          history.push(
                            `/clinic/app/doctor/patient/details/${details.data[0].patients_id}`
                          )
                        }
                        color="primary"
                        startIcon={<User />}
                      >
                        Profile
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Box>
                      <Tooltip
                        arrow
                        title={
                          Boolean(parseInt(details.data[0].is_complete))
                            ? "Appointment is completed"
                            : "Change appointment date"
                        }
                      >
                        <Box>
                          <Button
                            size="large"
                            fullWidth
                            variant="outlined"
                            disabled={Boolean(
                              parseInt(details.data[0].is_complete)
                            )}
                            color="primary"
                            onClick={() => {
                              setIsReschedule(true);
                            }}
                            startIcon={<Calendar />}
                          >
                            Reschedule
                          </Button>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Box>
                      <Tooltip
                        arrow
                        title={
                          Boolean(parseInt(details.data[0].is_complete))
                            ? "Appointment is completed"
                            : "Set appointment done/completed."
                        }
                      >
                        <Box>
                          <Button
                            size="large"
                            fullWidth
                            disabled={Boolean(
                              parseInt(details.data[0].is_complete)
                            )}
                            variant="contained"
                            color="primary"
                            startIcon={<CheckSquare />}
                            onClick={() => setIsComplete(true)}
                          >
                            Complete
                          </Button>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Box>
                      <Tooltip arrow title={"Cancel Appointment"}>
                        <Box>
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            startIcon={<Trash />}
                            disabled={Boolean(
                              parseInt(details.data[0].is_complete)
                            )}
                            onClick={() =>
                              setRemove({
                                open: true,
                                appid: details.data[0].appointment_id,
                              })
                            }
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>

                <Reschedule
                  open={isreschedule}
                  closeDialog={() => setIsReschedule(false)}
                  appdate={
                    Boolean(parseInt(details.data[0].is_reschedule))
                      ? details.data[0].is_reschedule_date
                      : details.data[0].app_date
                  }
                  appid={details.data[0].appointment_id}
                  getAppointmentDetails={getAppointmentDetails}
                  connection={"local"}
                />

                <DetailsLocalSetAsComplete
                  open={iscomplete}
                  close={() => {
                    setIsComplete(false);
                    getAppointmentDetails();
                  }}
                  appid={details.data[0].appointment_id}
                />
              </>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
        </CardContent>
      </Card>

      {/* dialog edit rate */}
      <Dialog
        open={editRate}
        onClose={() => setEditRate(false)}
        disableBackdropClick
      >
        <DialogTitle> Change Rate </DialogTitle>

        <form onSubmit={handleNewRate}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                label="New Rate"
                value={newRate}
                onChange={(e) => setNewRate(e.target.value)}
                name="new_rate"
                fullWidth
                variant="outlined"
                type="number"
                required
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 99999,
                  },
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                name="new_rate_remarks"
                fullWidth
                variant="outlined"
                required
                multiline
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="Password"
                name="password"
                fullWidth
                variant="outlined"
                type="password"
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="default"
              onClick={() => setEditRate(false)}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={
                isProcessNewrate && (
                  <CircularProgress color="inherit" size={23} />
                )
              }
              disabled={isProcessNewrate}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={remove.open}
        onClose={() => setRemove({ open: false, appid: null })}
      >
        <DialogContent>
          <Typography>Are you to remove this appointment?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setRemove({ open: false, appid: null })}
            startIcon={<XCircle />}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRemoveAppointment()}
            startIcon={
              isRemoveProcess ? (
                <CircularProgress size={23} color="inherit" />
              ) : (
                <CheckCircle />
              )
            }
            disabled={isRemoveProcess}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
