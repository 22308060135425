import React, { Fragment, useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import TablePagination from "@material-ui/core/TablePagination";
import Axios from "axios";
import Notify from "../../notification/Notify";
import CheckInternet from "src/utils/CheckInternet";
import { PatientsUnRead } from "src/ContextAPI";
import Label from "src/utils/Label";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import Scrollbar from "react-perfect-scrollbar";
import NoInternetConnection from "src/NoInternetConnection";

function PatientAppointmentVirtual({ patient_id }) {
  const history = useHistory();
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [virtualapp, setvirtualapp] = useState([]);
  const [virtualappready, setvirtualappready] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [online, setOnline] = useState("checking");
  const unread = useContext(PatientsUnRead);
  const [isOffline, setIsOffline] = useState(false);

  const checkPatientUnreadNotif = (category, department, notifId) => {
    let yy = unread.unviewNotifVirtual;
    let count = 0;

    for (let i = 0; i < yy.length; i++) {
      if (
        patient_id === yy[i].patient_id &&
        category === yy[i].category &&
        department === yy[i].department &&
        notifId === yy[i].order_id
      ) {
        count += 1;
      }
    }

    return count;
  };

  const getappointmentVirtualRecord = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", "online");

    setvirtualappready(false);
    setIsOffline(false);

    Axios.post(
      "doctors/appointment/patients/appointmentrecord-virtual",
      formdata
    )
      .then((response) => {
        const data = response.data;
        if (data.message === "cloud-unreachable") {
          setIsOffline(true);
        }

        if (data.message === "success") {
          setvirtualapp(data.result);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setvirtualappready(true));
  };

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checkinternet = () => {
    CheckInternet.online()
      .then(() => setOnline("connected"))
      .catch(() => setOnline("disconnected"));
  };

  useEffect(() => {
    checkinternet();

    if (online === "connected") {
      getappointmentVirtualRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id, online]);

  return (
    <Fragment>
      {isOffline ? (
        <NoInternetConnection xheight="calc(40vh - 120px)" />
      ) : (
        <Grid item xs={12} sm={12}>
          {virtualappready ? (
            <Paper elevation={2}>
              <TableContainer>
                <Scrollbar>
                  <Box p={2}>
                    <Typography variant="h6" color="textSecondary">
                      Virtual Appointments
                    </Typography>
                  </Box>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Date </TableCell>
                        <TableCell> Service </TableCell>
                        <TableCell> Rate </TableCell>
                        <TableCell> Reason </TableCell>
                        <TableCell> Status </TableCell>
                        <TableCell> Action </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {virtualapp.length > 0 ? (
                        (rowsPerPage > 0
                          ? virtualapp.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : virtualapp
                        ).map((data, index) => (
                          <TableRow hover key={index}>
                            <TableCell>
                              <Badge
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                invisible={
                                  !Boolean(
                                    parseInt(
                                      checkPatientUnreadNotif(
                                        "appointment",
                                        "virtual-appointment",
                                        data.app_id
                                      )
                                    )
                                  )
                                }
                                variant="dot"
                              >
                                {Notify.dateTimeConvert(data.appointment_date)}
                              </Badge>
                            </TableCell>
                            <TableCell> {data.doctors_service} </TableCell>
                            <TableCell>
                              {" "}
                              {data.doctors_service_amount}{" "}
                            </TableCell>
                            <TableCell> {data.appointment_reason} </TableCell>
                            <TableCell>
                              <Label
                                color={
                                  data.appointment_status === "successful"
                                    ? "success"
                                    : data.appointment_status === "approved"
                                    ? "primary"
                                    : "warning"
                                }
                              >
                                {data.appointment_status}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/clinic/app/doctor/appointment/calendar/details/virtual/${data.appointment_id}`
                                  )
                                }
                              >
                                <ArrowForwardIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Typography color="error">
                              No virtual appointment recorded.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    labelRowsPerPage="List"
                    rowsPerPageOptions={[5, 20, 50, 100]}
                    component="div"
                    count={virtualapp.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Scrollbar>
              </TableContainer>
            </Paper>
          ) : (
            Notify.loading()
          )}
        </Grid>
      )}
    </Fragment>
  );
}

export default PatientAppointmentVirtual;
