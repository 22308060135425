import { Tabs, Tab, Paper, Box } from "@material-ui/core";
import React, { useState } from "react";
import TabPanel from "src/utils/TabPanel";
import ImagingAttachments from "./ImagingAttachments";
import LaboratoryAttachments from "./LaboratoryAttachments";

const Attachments = ({ patient_id }) => {
  const [category, setCategory] = useState("laboratory");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <>
      <Paper component={Box} my={2}>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab wrapped value="laboratory" label="Laboratory" />

          <Tab wrapped value="imaging" label="Imaging" />
        </Tabs>
      </Paper>

      <Box>
        <TabPanel value={category} index="laboratory">
          <LaboratoryAttachments patient_id={patient_id} />
        </TabPanel>

        <TabPanel value={category} index="imaging">
          <ImagingAttachments patient_id={patient_id} />
        </TabPanel>
      </Box>
    </>
  );
};

export default Attachments;
