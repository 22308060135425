import {
  Card,
  CardContent,
  CardHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import { DatePicker } from "@material-ui/pickers";
import Notify from "src/notification/Notify";
import { CheckSquare, XCircle } from "react-feather";
import { useReactToPrint } from "react-to-print";
import { Print } from "@material-ui/icons";

const LaboratoryReport = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [list, setList] = useState([]);
  const [listRd, setListRd] = useState(false);
  const mounted = IsMountedRef();
  const [dateFrom, setDateFrom] = useState(new Date("2020-01-01"));
  const [dateTo, setDateTo] = useState(new Date());
  const [orderBy, setOrderBy] = useState("asc");
  const [filterDialog, setFilterDialog] = useState(false);
  var printableRef = useRef();
  var totalAmount = 0;

  const getLaboratoryReport = () => {
    Axios.get("doctors/services/report-list", {
      params: {
        token,
        user_id,
        date_from: dateFrom,
        date_to: dateTo,
        type: "laboratory",
        orderBy: orderBy,
      },
    })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setList(data);
        }
      })
      .catch((error) => {
        console.log("request error", error.message);
      })
      .finally(() => setListRd(true));
  };

  const calculateTotal = (amount) => {
    totalAmount += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  const handleReportByDate = () => {
    if (
      parseInt(new Date(dateTo).getTime()) <
      parseInt(new Date(dateFrom).getTime())
    ) {
      Notify.customToast(
        "Invalid Date To",
        "Date To must be greather than Date From"
      );
    } else {
      setListRd(false);
      getLaboratoryReport();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  useEffect(() => {
    getLaboratoryReport();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card ref={printableRef}>
      <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              Laboratory Report
              <Typography variant="subtitle2">
                From : {Notify.createdAt(dateFrom.toLocaleDateString())} - To :
                {Notify.createdAt(dateTo.toLocaleDateString())}
              </Typography>
            </Box>

            <Box className="d-print-none">
              <Button onClick={() => setFilterDialog(true)} color="primary">
                Filter
              </Button>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Date </TableCell>
                <TableCell> Patient </TableCell>
                <TableCell> Service </TableCell>
                <TableCell> Rate </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listRd ? (
                list.length > 0 ? (
                  list.map((data, key) => (
                    <TableRow key={key}>
                      <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                      <TableCell>{`${data.lastname}, ${data.firstname}`}</TableCell>
                      <TableCell>{data.bill_name}</TableCell>
                      <TableCell>{calculateTotal(data.bill_amount)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                    className="d-print-none"
                    startIcon={<Print />}
                  >
                    Print
                  </Button>
                </TableCell>

                <TableCell colSpan={2} align="center">
                  <b> Total Amount </b>
                </TableCell>
                <TableCell>
                  <Typography>
                    <b> {Notify.convertToNumber(totalAmount)} </b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      {/* Fiter Dialog */}
      <Dialog open={filterDialog} onClose={() => setFilterDialog(false)}>
        <DialogTitle> Filter </DialogTitle>
        <DialogContent dividers>
          <Box>
            <DatePicker
              autoOk
              fullWidth
              label="Date From"
              format="MM/dd/yyyy"
              inputVariant="outlined"
              value={dateFrom}
              onChange={(date) => setDateFrom(date)}
            />
          </Box>

          <Box my={2}>
            <DatePicker
              autoOk
              fullWidth
              label="Date To"
              format="MM/dd/yyyy"
              inputVariant="outlined"
              value={dateTo}
              onChange={(date) => setDateTo(date)}
            />
          </Box>

          <Box my={2}>
            <TextField
              fullWidth
              label="Order By"
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            >
              <option value="asc"> Ascending </option>
              <option value="desc"> Descending </option>
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setFilterDialog(false)}
            startIcon={<XCircle />}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleReportByDate();
              setFilterDialog(false);
            }}
            startIcon={<CheckSquare />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default LaboratoryReport;
