import {
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";
import Notify from "src/notification/Notify";
import Axios from "axios";
import { Send } from "react-feather";

const PatientDetailsSentAccount = ({ patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [patientInfo, setPatientInfo] = useState({});
  const [patientInfoRd, setPatientInfoRd] = useState(false);
  const [isProcess, setIsProcess] = useState(false);

  const getPatientDetails = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);

    Axios.post("encoder/patients/getpatient-info", formdata)
      .then((response) => {
        const data = response.data;
        setPatientInfo(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setPatientInfoRd(true));
  }, [token, user_id, patient_id]);

  const handleSendEmail = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    setIsProcess(true);
    Axios.post("encoder/patient/sent-accountomail", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          Notify.successRequest("Account Send");
        }
      })
      .catch((error) => console.log("error: ", error.message))
      .finally(() => setIsProcess(false));
  };

  useEffect(() => {
    getPatientDetails();
  }, [getPatientDetails]);

  if (!navigator.onLine) {
    return (
      <Box mt={3}>
        <Typography color="secondary" align="center">
          This page requires internet connection to open.
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          <Box mb={3}>
            <Typography>Sent account to email</Typography>
          </Box>

          <Box>
            {patientInfoRd ? (
              Object.keys(patientInfo).length > 0 ? (
                <>
                  <form onSubmit={handleSendEmail}>
                    <Grid container spacing={3} hidden>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="lastname"
                            defaultValue={patientInfo.lastname}
                            variant="outlined"
                            fullWidth
                            name="lastname"
                            // required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="firstname"
                            defaultValue={patientInfo.firstname}
                            variant="outlined"
                            fullWidth
                            name="firstname"
                            // required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="middle"
                            defaultValue={patientInfo.middle}
                            variant="outlined"
                            fullWidth
                            name="middle"
                            // required
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} hidden>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="gender"
                            defaultValue={patientInfo.gender}
                            variant="outlined"
                            fullWidth
                            name="gender"
                            // required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="birthday"
                            defaultValue={patientInfo.birthday}
                            variant="outlined"
                            fullWidth
                            name="birthday"
                            // required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="mobile"
                            defaultValue={patientInfo.mobile}
                            variant="outlined"
                            fullWidth
                            name="mobile"
                            // required
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} hidden>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="street"
                            defaultValue={patientInfo.street}
                            variant="outlined"
                            fullWidth
                            name="street"
                            // required
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="barangay"
                            defaultValue={patientInfo.barangay}
                            variant="outlined"
                            fullWidth
                            name="barangay"
                            // required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <TextField
                            label="city"
                            defaultValue={patientInfo.city}
                            variant="outlined"
                            fullWidth
                            name="city"
                            // required
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box mt={3}>
                      <TextField
                        label="Email"
                        defaultValue={patientInfo.email}
                        variant="outlined"
                        fullWidth
                        name="email"
                        type="email"
                        // required
                      />
                    </Box>

                    <Box mt={3} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={isProcess}
                        startIcon={
                          isProcess ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <Send />
                          )
                        }
                      >
                        Send
                      </Button>
                    </Box>
                  </form>
                </>
              ) : (
                Notify.noRecord()
              )
            ) : (
              Notify.loading()
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PatientDetailsSentAccount;
