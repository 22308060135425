import { Snackbar, Badge, Typography, Button  } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import React from 'react'
import EventIcon from '@material-ui/icons/Event'; 
import Notify from 'src/notification/Notify';
import OnlineAppRequestDetails from './OnlineAppRequestDetails'; 
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const OnlineAppRequest = ({apprequest, getOnlineAppRequest}) => { 
    
    const [dialog, setDialog]  = React.useState({
        open : false,
        data: null
    })

    return(
        <>
            { apprequest.data.length > 0 && (
                apprequest.data.map((data, index) =>( 
                    <Snackbar
                        key={ index }
                        open={ ! dialog.open } 
                        anchorOrigin={{  vertical: 'bottom', horizontal: 'left'  }} 
                    > 
                        <Alert 
                            severity="info" 
                            variant="filled"
                            icon={ <Badge color="secondary" badgeContent={index + 1}> <EventIcon /> </Badge>  } 
                            action={
                                <Button 
                                    onClick={
                                        ()=> setDialog({ data, open: true})
                                    }
                                    variant="contained"
                                    color="secondary"
                                    endIcon={
                                        <ArrowForwardIcon />
                                    }
                                >
                                    view
                                </Button>
                            }
                        >
                            <AlertTitle
                                className={`gtc-uppercase`}
                            > 
                                <Typography variant="caption">
                                    Online Appointment Request
                                </Typography>
                            </AlertTitle>
                            <Typography>
                                On {Notify.dateTimeConvert(data.appointment_date)}
                            </Typography>
                            <Typography variant="caption">
                                {`${data.firstname} ${data.lastname}`} is requesting for an online appointment.
                            </Typography>
                        </Alert>     
                    </Snackbar> 
                ))
            )}  
            
            { dialog.data && dialog.open && (<OnlineAppRequestDetails 
                deeeee ={ dialog } 
                close={ () => setDialog({ open : false, data: null }) } 
                getOnlineAppRequest ={ getOnlineAppRequest }
            />)}
        </>
    )
}

export default OnlineAppRequest;