import React from "react";
import { Box, TextField, Button, FormHelperText } from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { Formik } from "formik";
import * as Yup from "yup";

const RadiologistPatientForReviewFindings = ({
  details,
  resetDisplay,
  close,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const convertFormdata = (object) => {
    const formData = new FormData();
    formData.append("orderId", details.imaging_center_id);

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Formik
        initialValues={{
          user_id: user_id,
          token: token,
          result: "Normal",
          impression: "Normal",
        }}
        validationSchema={Yup.object().shape({
          result: Yup.string().required(),
          impression: Yup.string().required(),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await Axios.post(
              "radiologist/patients/order/order-saveresult",
              convertFormdata(values)
            );
            if (request.data === "success") {
              Notify.successRequest("new doctor");
              resetForm();
              setSubmitting(true);
              resetDisplay();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                error={Boolean(touched.result && errors.result)}
                helperText={touched.result && errors.result}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.result}
                fullWidth
                multiline
                required
                name="result"
                label="Result"
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                required
                multiline
                error={Boolean(touched.impression && errors.impression)}
                helperText={touched.impression && errors.impression}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.impression}
                name="impression"
                label="Impression"
                variant="outlined"
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                size="large"
                onClick={() => {
                  resetForm();
                  close();
                }}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
              <Box ml={2}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RadiologistPatientForReviewFindings;
