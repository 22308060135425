import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardContent,
  IconButton,
  Box,
  Typography,
  Collapse,
  Tooltip,
  Button,
  TablePagination,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Notify from "src/notification/Notify";
import axios from "axios";
import { DatePicker } from "@material-ui/pickers";
import ReactToPrint from "react-to-print";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { KeyboardArrowUp } from "@material-ui/icons";
import IsMountedRef from "src/utils/IsMountedRef";
import Search from "@material-ui/icons/Search";

const ManagementSecretary = ({ selectedManagement }) => {
  const token = localStorage.getItem("token");

  const [selectedId, setSelectedId] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [record, setRecord] = useState({
    data: [],
    ready: false,
  });

  const [details, setDetails] = useState([]);
  const printableRef = React.useRef(null);

  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  var totalpayment = 0;
  var totalrefund = 0;

  const mounted = IsMountedRef();

  const getBillingRecords = React.useCallback(async () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("management_id", selectedManagement.management_id);

    axios
      .post("doctors/management/secretary/billing-report", formdata)
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setRecord({ data: data, ready: true });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [mounted, token, selectedManagement]);

  const getBillingRecordsDetails = (orderid, receiptid) => {
    if (selectedId === receiptid) {
      console.log("receipt id is currently selected");
      return;
    }

    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("management_id", selectedManagement.management_id);
    formdata.set("order_id", orderid);
    formdata.set("receipt_id", receiptid);

    axios
      .post("doctors/management/secretary/billing-report-details", formdata)
      .then((response) => {
        const data = response.data;
        setDetails(data);
        setSelectedId(receiptid);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleBillingReportByDate = () => {
    setRecord({ data: [], ready: false });
    axios
      .get("doctors/management/secretary/billing-report-bydate", {
        params: {
          token,
          management_id: selectedManagement.management_id,
          date_from: selectedDate.from,
          date_to: selectedDate.to,
        },
      })
      .then((response) => {
        setRecord({ ...record, data: response.data });
      })
      .catch((error) => console.log("billing report by date error", error));
  };

  const handleSearch = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const filteredRecord = searchRecord(record.data, query);
  const paginatedProduct = applyPagination(filteredRecord, page, limit);

  useEffect(() => {
    getBillingRecords();
  }, [getBillingRecords]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Card ref={printableRef}>
          <CardContent>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h5">Secretary Billing</Typography>
                <Box className="d-print-none">
                  <TextField
                    label="Search name"
                    variant="outlined"
                    margin="dense"
                    onChange={handleSearch}
                    value={query}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Box className="d-print-none">
                <DatePicker
                  autoOk
                  label="Date From"
                  fullWidth
                  variant={"outlined"}
                  value={selectedDate.from}
                  onChange={(date) =>
                    setSelectedDate({ ...selectedDate, from: date })
                  }
                  inputVariant="outlined"
                />
              </Box>
              <Box mx={2} className="d-print-none">
                <DatePicker
                  autoOk
                  label="Date To"
                  fullWidth
                  variant={"outlined"}
                  inputVariant="outlined"
                  onChange={(date) =>
                    setSelectedDate({ ...selectedDate, to: date })
                  }
                />
              </Box>

              <Box mx={2} mt={1} className="d-print-none">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleBillingReportByDate}
                >
                  Go
                </Button>
              </Box>

              <Box mt={1} className="d-print-none">
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Button variant="contained" size="large" color="primary">
                        Print
                      </Button>
                    );
                  }}
                  content={() => printableRef.current}
                />
              </Box>
            </Box>

            <Box>
              <TableContainer>
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="d-print-none"></TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Patient</TableCell>
                        <TableCell>Bill</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.ready ? (
                        paginatedProduct.length > 0 ? (
                          paginatedProduct.map((data, index) => (
                            <Fragment key={index}>
                              <TableRow hover>
                                <TableCell
                                  align="center"
                                  className="d-print-none"
                                >
                                  <Tooltip title="Details" arrow>
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        getBillingRecordsDetails(
                                          data.order_id,
                                          data.receipt_number
                                        );
                                      }}
                                    >
                                      {selectedId === data.receipt_number ? (
                                        <KeyboardArrowUp />
                                      ) : (
                                        <KeyboardArrowRight />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>

                                <TableCell>
                                  {Notify.dateTimeConvert(data.created_at)}
                                </TableCell>
                                <TableCell>
                                  {` ${data.fname} ${data.lname} `}
                                </TableCell>
                                <TableCell align="center">
                                  <span className="d-none">
                                    {
                                      (totalpayment += parseFloat(
                                        data.totalpayment
                                      ))
                                    }
                                    {
                                      (totalrefund = parseFloat(
                                        data.totalrefund
                                      ))
                                    }
                                  </span>
                                  {Notify.convertToNumber(data.totalpayment)}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={4}
                                >
                                  <Collapse
                                    in={
                                      selectedId &&
                                      selectedId === data.receipt_number
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {selectedId && (
                                      <Box margin={1}>
                                        <Typography
                                          variant="subtitle1"
                                          gutterBottom
                                          color="textSecondary"
                                        >
                                          Order Details
                                        </Typography>
                                        <Table size="small">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Department</TableCell>
                                              <TableCell> Billing </TableCell>
                                              <TableCell align="center">
                                                Amount
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {details.length > 0 ? (
                                              details.map((data, index) => (
                                                <TableRow key={index}>
                                                  <TableCell>
                                                    {data.bill_department}
                                                  </TableCell>
                                                  <TableCell>
                                                    {data.bill_name}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {data.bill_amount}
                                                  </TableCell>
                                                </TableRow>
                                              ))
                                            ) : (
                                              <TableRow>
                                                <TableCell colSpan={3}>
                                                  <Typography color="secondary">
                                                    No details found.
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Typography color="secondary">
                                No record found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography color="primary">
                              please wait...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableBody>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <b> Payment </b>
                        </TableCell>
                        <TableCell>
                          &#8369; {Notify.convertToNumber(totalpayment)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                    <TableBody>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <b> Refund </b>
                        </TableCell>
                        <TableCell>
                          &#8369; {Notify.convertToNumber(totalrefund)}
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableBody>
                    <TableBody>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <b> Total </b>
                        </TableCell>
                        <TableCell>
                          &#8369;
                          {Notify.convertToNumber(
                            parseFloat(totalpayment) - parseFloat(totalrefund)
                          )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </PerfectScrollbar>

                <TablePagination
                  component={"div"}
                  count={filteredRecord.length}
                  labelRowsPerPage="List"
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[10, 50, 100]}
                />
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ManagementSecretary;

const searchRecord = (inventory, query) => {
  return inventory.filter((xdata) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      if (
        xdata.fname.toLowerCase().includes(query.toLowerCase()) ||
        xdata.lname.toLowerCase().includes(query.toLowerCase())
      ) {
        containsQuery = true;
      }

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (xdata, page, limit) => {
  return xdata.slice(page * limit, page * limit + limit);
};
