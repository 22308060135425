import { Paper, Tabs, Tab, Box } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import React, { useState } from "react";
import LaboratoryPackage from "./LaboratoryPackage";
import LaboratoryPackageList from "./LaboratoryPackageList";

const LaboratoryPackageTab = ({ list }) => {
  const [category, setCategory] = useState("unsave-items");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <>
      <Box width={320}>
        <Paper>
          <Tabs
            value={category}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value="unsave-items" label="Create Package" />
            <Tab value="packages-list" label="Packages" />
          </Tabs>
        </Paper>
      </Box>

      <Box mt={2}>
        <TabPanel value={category} index={"unsave-items"}>
          <LaboratoryPackage list={list} />
        </TabPanel>

        <TabPanel value={category} index={"packages-list"}>
          <Box>
            <LaboratoryPackageList />
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};

export default LaboratoryPackageTab;
