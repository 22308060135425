import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Box, Card, CardContent, Grid, Typography, TextField, CardMedia, Paper, makeStyles, Fab, Dialog, DialogContent, Zoom, IconButton, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import { Map, TileLayer, Marker } from "react-leaflet";
import FullscreenControl from 'react-leaflet-fullscreen';
import { grey } from '@material-ui/core/colors';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import ClinicDoctorsList from './ClinicDoctorsList';
import CloseIcon from '@material-ui/icons/Close';
import ForumIcon from '@material-ui/icons/Forum';
import ClinicDetailsInquiryChatbox from './ClinicDetailsInquiryChatbox';
import ClinicDetailsInquiryBigScreenChatbox from './ClinicDetailsInquiryBigScreenChatbox';
import { CancelOutlined } from '@material-ui/icons';
import { isMobile } from "react-device-detect";
import Container from 'src/layout/Container';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const ClinicDetails = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const { clinic_id } = useParams()
    const [opendialog, setOpenDialog] = useState(false)
    const [clinicInfo, setClinicInfo] = useState(null)
    const [clinicInfoRd, setClinicInfoRd] = useState(false)
    const [doctorsList, setDoctorsList] = useState(null)
    const [doctorsListRd, setDoctorsListRd] = useState(false)
    const classes = useStyles();
    const [floatingButton, setFloatingButton] = useState()
    const [clinicName, setClinicName] = useState(null)
    const themes = useTheme();

    const getClinicDetails = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            clinic_id: clinic_id,
        }
        Axios.get('patient/appointment/search/clinic/clinic-details', { params })
            .then((response) => {
                const data = response.data
                setClinicInfo(data)
                setClinicName(data.clinic)
                setClinicInfoRd(true)
            })
            .catch(error => { Notify.requestError(error) })
    }, [token, user_id, clinic_id]);

    const getClinicDoctorsList = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            clinic_id: clinic_id,
        }
        Axios.get('patient/appointment/search/clinic/clinicdoctors-list', { params })
            .then((response) => {
                const data = response.data
                setDoctorsList(data)
                setDoctorsListRd(true)
            })
            .catch(error => { Notify.requestError(error) })
    }, [token, user_id, clinic_id]);

    useEffect(() => {
        getClinicDetails()
        getClinicDoctorsList()
    }, [getClinicDetails, getClinicDoctorsList])
    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'clinic details',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Appointment', path: '/clinic/app/patient/appointment' },
                        { name: 'Make Appointment', path: '/clinic/app/patient/appointment/make-appointment' }
                    ]
                }}
                title="Clinic Details"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        {
                            clinicInfoRd ?
                                Object.keys(clinicInfo).length > 0 ?
                                    <Card
                                        component={Box}
                                        border={1}
                                        borderColor={grey[300]}
                                        elevation={0}
                                    >
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <Box
                                                        border={1}
                                                        borderColor={grey[200]}
                                                    >
                                                        {
                                                            clinicInfo.latitude && clinicInfo.longitude ?
                                                                <Map
                                                                    attributionControl={false}
                                                                    center={[clinicInfo.latitude, clinicInfo.longitude]}
                                                                    zoom={15}
                                                                    style={{ height: 'calc(100vh - 420px)' }}
                                                                >
                                                                    <TileLayer
                                                                        url={process.env.REACT_APP_MAP_URL}
                                                                        attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                                                        maxZoom={19}
                                                                        minZoom={6}
                                                                        crossOrigin={true}
                                                                    />
                                                                    <Marker
                                                                        position={[clinicInfo.latitude, clinicInfo.longitude]}
                                                                    />
                                                                    <FullscreenControl position="topleft" />
                                                                </Map>
                                                                :
                                                                <Box
                                                                    height={'calc(100vh - 180px)'}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    style={{
                                                                        backgroundColor: '#abdafb',
                                                                    }}
                                                                >
                                                                    <CardMedia component="img" src="/icons/no-map.png" />
                                                                </Box>
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <Box className="gtc-textfield-noborder">
                                                        <Box mb={2}>
                                                            <Typography
                                                                variant="h6"
                                                                color="primary"
                                                                noWrap
                                                            >
                                                                {clinicInfo.clinic}
                                                            </Typography>
                                                        </Box>
                                                        <Box mb={3}>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                label="ADDRESS"
                                                                variant="outlined"
                                                                value={clinicInfo.address}
                                                                inputProps={{
                                                                    readOnly: true
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box my={3}>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                label="DAYS OPEN"
                                                                variant="outlined"
                                                                value={clinicInfo.days_open}
                                                                inputProps={{
                                                                    readOnly: true
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box my={3}>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                label="TIME OPEN"
                                                                variant="outlined"
                                                                value={clinicInfo.time_open}
                                                                inputProps={{
                                                                    readOnly: true
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box my={3}>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                label="CONTACTS"
                                                                variant="outlined"
                                                                value={clinicInfo.contact_no}
                                                                inputProps={{
                                                                    readOnly: true
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box my={3}>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                label="CONTACTS"
                                                                variant="outlined"
                                                                value={clinicInfo.remarks}
                                                                inputProps={{
                                                                    readOnly: true
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box my={3}>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                label="DOCTORS"
                                                                variant="outlined"
                                                                value={`has 10 doctors accredited`}
                                                                inputProps={{
                                                                    readOnly: true
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <Box>
                                                        <Fab
                                                            className={classes.fabBottom}
                                                            color={floatingButton || opendialog ? 'secondary' : 'primary'}
                                                            aria-label="message"
                                                            onClick={() => isMobile ?
                                                                setOpenDialog(!opendialog)
                                                                : setFloatingButton(!floatingButton)
                                                            }
                                                        >
                                                            {floatingButton || opendialog ? <CloseIcon color="inherit" /> : <ForumIcon color="inherit" />}
                                                        </Fab>
                                                    </Box>

                                                    {/* small screen */}
                                                    <Dialog
                                                        open={opendialog}
                                                        fullScreen
                                                        TransitionComponent={Zoom}
                                                        transitionDuration={800}
                                                    >
                                                        <Box display="flex" bgcolor={themes.palette.primary.main}>
                                                            <Box flexGrow={1} mt={1}>
                                                                <Typography
                                                                    variant="h6"
                                                                    style={{ color: 'white', textIndent: '0.5em' }}
                                                                    align="justify"
                                                                >
                                                                    {clinicName}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton style={{ color: 'white' }} onClick={() => setOpenDialog(false)}>
                                                                    <CancelOutlined />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <DialogContent>
                                                            <ClinicDetailsInquiryBigScreenChatbox
                                                                clinic_id={clinic_id}
                                                                user_id={user_id}
                                                                close={() => setOpenDialog(false)}
                                                            />
                                                        </DialogContent>
                                                    </Dialog>

                                                    {/* big screen */}
                                                    <Box component={Paper} hidden={!floatingButton || isMobile} width={400} height={530} className={classes.chatbox}>
                                                        <Box display="flex" bgcolor={themes.palette.primary.main} height={46}>
                                                            <Box flexGrow={1} mt={1}>
                                                                <Typography
                                                                    variant="h6"
                                                                    style={{ color: 'white', textIndent: '0.5em' }}
                                                                    align="justify"
                                                                >
                                                                    {clinicName}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton style={{ color: 'white' }} onClick={() => setFloatingButton(false)}>
                                                                    <CancelOutlined fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <ClinicDetailsInquiryChatbox
                                                            clinic_id={clinic_id}
                                                            user_id={user_id}
                                                            close={() => setFloatingButton(false)}
                                                        />
                                                    </Box>

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    : <Grid item xs={12}>
                                        <Box justifyContent="center" >
                                            {Notify.noRecord()}
                                        </Box>
                                    </Grid>
                                :
                                <Grid item xs={12}>
                                    <Box justifyContent="center" >
                                        {Notify.loading()}
                                    </Box>
                                </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {
                            doctorsListRd ?
                                doctorsList.length > 0 && (
                                    <ClinicDoctorsList doctorsList={doctorsList} />
                                )
                                : Notify.loading()
                        }
                    </Grid>
                </Grid >
            </Container>
        </Fragment>
    )
}

export default ClinicDetails;

const useStyles = makeStyles((theme) => ({
    fabBottom: {
        margin: theme.spacing(1),
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1001
    },
    chatbox: {
        margin: theme.spacing(1),
        position: "fixed",
        bottom: theme.spacing(10),
        right: theme.spacing(2),
        zIndex: 1000,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomLeftRadius: 6,
        boxShadow: '6px 6px 2px #aaaaaa',
        border: '1px solid #aaaaaa',
    }
}));