import React, { useState, useEffect, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Box, Grid, Collapse, Typography, IconButton, Avatar, CircularProgress, TextField, Zoom, Badge, TablePagination, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Lightbox } from "react-modal-image";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { DropzoneArea } from 'material-ui-dropzone'
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImagingPrint from './ImagingPrint';
import { grey } from '@material-ui/core/colors';
import Container from 'src/layout/Container';

const imageLocation = process.env.REACT_APP_API_IMAGE;
// const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const Imaging = () => {
    const classes = useStyles();
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [token] = useState(localStorage.getItem('token'))
    const [username] = useState(localStorage.getItem('username'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [imaging, setImaging] = useState([])
    const [imagingReady, setImagingReady] = useState(false)
    const [selectedCollapseId, setselectedCollapseId] = useState(null)
    const [selectedResultImageId, setselectedResultImageId] = useState(null)
    const [uploadProcess, setuploadProcess] = useState(false)
    const [sharedImages, setSharedImages] = useState([])
    const [sharedImagesReady, setsharedImagesReady] = useState(false)
    const [sharedDate, setSharedDate] = useState([])
    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState([])
    const [category, setCategory] = useState('');
    const [openLightbox, setopenLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedImageFrom, setSelectedImageFrom] = useState('imaging')
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [page, setPage] = useState(0);
    const [sharedRowsPerPage, setSharedRowsPerPage] = useState(3)
    const [sharedPage, setSharedPage] = useState(0)

    const [orderPrint, setOrderPrint] = useState({
        open: false,
        doctors_id: null,
        patient_id: null,
        order: null,
        order_date: null
    })

    const [moreAtt, setMoreAtt] = useState({
        dialog: false,
        attach: null
    })

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSharedChangeRowsPerPage = (event) => {
        setSharedRowsPerPage(parseInt(event.target.value, 10))
        setSharedPage(0)
    };

    const handleSharedChangePage = (event, newPage) => {
        event.persist()
        setSharedPage(newPage)
    };

    const bodyparts = [
        { id: 1, value: 'Arm' },
        { id: 2, value: 'Body' },
        { id: 3, value: 'Foot' },
        { id: 4, value: 'Head' },
        { id: 5, value: 'Legs' }
    ]
    const getImagingList = () => {
        var params = {
            token: token,
            management_id: management_id,
            user_id: user_id,
            connection: 'online',
        }
        Axios.get('patient/imaging/getimaging-list', { params })
            .then((response) => {
                const data = response.data;
                setImaging(data)
                setImagingReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getSharedImagesAll = () => {
        var params = {
            token: token,
            user_id: user_id,
            type: 'imaging',
        }
        Axios.get('patient/shared/images/shared-list', { params })
            .then((response) => {
                const data = response.data;
                setSharedImages(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getSharedDateInGroupBy = () => {
        var params = {
            token: token,
            user_id: user_id,
            type: 'imaging',
        }
        Axios.get('patient/shared/images/shared-groupdate', { params })
            .then((response) => {
                const data = response.data;
                setSharedDate(data)
                setsharedImagesReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleUpload = (e) => {
        e.persist()
        e.preventDefault()
        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.append('share_image', files[0]);
        formdata.set('type', 'imaging');
        // for (let i = 0; i < files.length; i++) {
        //     formdata.append('share_image[]', files[i]);    
        // }
        var error = [];

        if (formdata.get('category').length === 0 || formdata.get('category').trim() === '') {
            error = 'error';
            Notify.fieldRequired('category');
        }
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }
        if (error.length > 0) {
            console.warn("form has error.")
        }
        else {
            setuploadProcess(true)
            Axios.post('patient/shared/images/share-new', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('upload')
                        getSharedImagesAll()
                        getSharedDateInGroupBy()
                        setOpen(false)
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setuploadProcess(false)
                });
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (files) => {
        //Saving files to state for further use and closing Modal.  
        setFiles(files)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleOpenlightbox = (e) => {
        setSelectedImage(e.currentTarget.getAttribute('image'))
        setSelectedImageFrom(e.currentTarget.getAttribute('image_from'))
        setopenLightbox(true)
    }

    const handleCloselightbox = (e) => {
        setSelectedImage(null)
        setSelectedImageFrom('imaging')
        setopenLightbox(false)
    }

    const handleMoreAttcmnt = (e) => {
        setMoreAtt({
            dialog: true,
            attach: e.currentTarget.id
        })
    }

    const handleUpdateUnread = (imaging_order) => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('imaging_order', imaging_order)
        formdata.set('connection', 'online')

        Axios.post('patient/notification/update/imaging/unread', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    getImagingList();
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getImagingList()
        getSharedImagesAll()
        getSharedDateInGroupBy()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const paginateImaging = imaging.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const paginateSharedDate = sharedDate.slice(sharedPage * sharedRowsPerPage, sharedPage * sharedRowsPerPage + sharedRowsPerPage);

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'imaging',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="My Imaging"
            >
                <Grid container spacing={2}>
                    <Grid item sm={8} xs={12}>
                        {
                            imagingReady ?
                                imaging.length > 0 ?
                                    paginateImaging.map((data, index) =>
                                        <Box
                                            key={index}
                                            p={2}
                                            border={1}
                                            mb={2}
                                            borderRadius={4}
                                            bgcolor="background.paper"
                                        >
                                            <Box
                                                className="clearfix mb-2"
                                            >
                                                <Box
                                                    className="float-left mr-3"
                                                >
                                                    <Avatar
                                                        className={classes.primarycolor}
                                                    >
                                                        {data.imaging_order.charAt(0).toUpperCase()}
                                                    </Avatar>
                                                </Box>
                                                <Box
                                                    className="float-left"
                                                >
                                                    <Typography>
                                                        <strong className={'text-uppercase'}>
                                                            {parseFloat(data.unreadCount) > 0 && (
                                                                <Badge variant="dot" color="secondary">
                                                                    {data.imaging_order}
                                                                </Badge>
                                                            )}
                                                            {parseFloat(data.unreadCount) === 0 && (
                                                                <Fragment>
                                                                    {data.imaging_order}
                                                                </Fragment>
                                                            )}
                                                        </strong>
                                                    </Typography>

                                                    <Typography>
                                                        <small>
                                                            {Notify.dateTimeConvert(data.created_at)}
                                                        </small>
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    className="float-right"
                                                >
                                                    <IconButton
                                                        variant="outlined"
                                                        color={'primary'}
                                                        onClick={() => setOrderPrint({
                                                            open: true,
                                                            doctors_id: data.doctors_id,
                                                            patient_id: data.patients_id,
                                                            order: data.imaging_order,
                                                            order_date: data.created_at
                                                        })}
                                                    >
                                                        <PrintIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        variant="outlined"
                                                        color={'primary'}
                                                        onClick={() => {
                                                            handleUpdateUnread(data.imaging_order)
                                                            setselectedCollapseId(selectedCollapseId === data.id ? null : data.id)
                                                        }}
                                                    >
                                                        {selectedCollapseId === data.id ? <RemoveCircleOutlineIcon /> : <ControlPointIcon />}
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Collapse in={selectedCollapseId === data.id ? true : false}>
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        md={3}
                                                        sm={5}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="center"
                                                            alignItems="center"
                                                        >
                                                            {
                                                                data.imaging_result_attachment === null ?
                                                                    <Avatar
                                                                        variant="rounded"
                                                                        sizes="sm"
                                                                        style={{
                                                                            width: '160px',
                                                                            height: '160px'
                                                                        }}
                                                                        src={"/no-image.jpg"}
                                                                    />
                                                                    :
                                                                    <Fragment>
                                                                        {
                                                                            data.imaging_result_attachment.split(',').length > 1 ?
                                                                                <Box
                                                                                    display="flex"
                                                                                    justifyContent="center"
                                                                                    alignContent="center"
                                                                                    borderRadius={5}
                                                                                    border={1}
                                                                                    borderColor={grey[300]}
                                                                                    style={{
                                                                                        width: '160px',
                                                                                        height: '160px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                >
                                                                                    <Box mt={3} align="center">
                                                                                        <Box mb={1}>
                                                                                            Multiple Image
                                                                                    </Box>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            variant="contained"
                                                                                            id={data.imaging_result_attachment}
                                                                                            onClick={handleMoreAttcmnt}
                                                                                        >
                                                                                            View
                                                                                    </Button>
                                                                                    </Box>
                                                                                </Box>
                                                                                :
                                                                                <Avatar
                                                                                    variant="rounded"
                                                                                    sizes="sm"
                                                                                    style={{
                                                                                        width: '160px',
                                                                                        height: '160px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => setselectedResultImageId(data.id)}
                                                                                    src={imageLocation + 'imaging/' + data.imaging_result_attachment}
                                                                                />
                                                                        }
                                                                        {
                                                                            selectedResultImageId === data.id ?
                                                                                <Lightbox
                                                                                    large={imageLocation + 'imaging/' + data.imaging_result_attachment}
                                                                                    alt={data.imaging_result_attachment}
                                                                                    onClose={() => setselectedResultImageId(null)}
                                                                                />
                                                                                : null
                                                                        }
                                                                    </Fragment>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={9}
                                                        sm={7}
                                                    >
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> RESULT: </span>
                                                            <Typography align="justify">
                                                                {data.imaging_result === null ? 'No result added.' : data.imaging_result}
                                                            </Typography>
                                                        </Box>
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> RESULT REMARKS: </span>
                                                            <Typography align="justify">
                                                                {data.imaging_results_remarks === null ? 'No result remark added.' : data.imaging_results_remarks}
                                                            </Typography>
                                                        </Box>
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> RESULT DATE: </span>
                                                            <Typography>
                                                                {data.end_time === null ? 'No result date added.' : Notify.dateTimeConvert(data.end_time)}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </Box>
                                    )
                                    : Notify.noRecord()
                                : Notify.loading()
                        }

                        <TablePagination
                            labelRowsPerPage="List"
                            rowsPerPageOptions={[3, 10, 50, { value: -1, label: 'All' }]}
                            component="div"
                            count={imaging.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <Box mb={1} className="clearfix">
                            <Box className="float-left" mt={1}>
                                <Typography variant="h6" color="textSecondary"> Shared Images </Typography>
                            </Box>
                            <Box className="float-right">
                                <IconButton color="primary" onClick={handleOpen}>
                                    <AddAPhotoIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        {
                            sharedImagesReady ?
                                sharedDate.length > 0 ?
                                    paginateSharedDate.map((d, dindex) => {
                                        return (
                                            <Box key={dindex} mb={2}>
                                                <Typography> {Notify.createdAt(d.shared_date)} </Typography>
                                                {/* check if match the date */}
                                                <Box
                                                    display="flex"
                                                    flexWrap="wrap"
                                                >
                                                    {
                                                        sharedImages.length > 0 ?
                                                            sharedImages.map((data, index) => {
                                                                return (
                                                                    <Box
                                                                        p={1}
                                                                        key={index}
                                                                        className="pointer"
                                                                        hidden={Notify.createdAt(d.shared_date) === Notify.createdAt(data.created_at) ? false : true}
                                                                    >
                                                                        <Badge
                                                                            overlap="rectangle"
                                                                            anchorOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            color="error"
                                                                            badgeContent={data.category}
                                                                        >
                                                                            <Avatar
                                                                                onClick={handleOpenlightbox}
                                                                                style={{
                                                                                    width: '100px',
                                                                                    height: '100px'
                                                                                }}
                                                                                image_from={'imaging-shared'}
                                                                                variant="rounded"
                                                                                image={data.image}
                                                                                alt={data.category}
                                                                                title={data.category}
                                                                                src={imageLocation + 'imaging/sharedimages/' + data.image}
                                                                            />
                                                                        </Badge>
                                                                    </Box>
                                                                )
                                                            })
                                                            : Notify.noRecord()
                                                    }
                                                </Box>
                                            </Box>
                                        )
                                    })
                                    : Notify.noRecord()
                                : Notify.loading()

                        }

                        <TablePagination
                            labelRowsPerPage="List"
                            rowsPerPageOptions={[3, 20, 50, 100, { label: 'All', value: -1 }]}
                            component="div"
                            count={sharedDate.length}
                            rowsPerPage={sharedRowsPerPage}
                            page={sharedPage}
                            onChangePage={handleSharedChangePage}
                            onChangeRowsPerPage={handleSharedChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>

                {/* lightbox images */}
                {
                    openLightbox && (
                        <Lightbox
                            large={selectedImageFrom === 'imaging' ? `${imageLocation}imaging/${selectedImage}` : `${imageLocation}imaging/sharedimages/${selectedImage}`}
                            alt={selectedImage}
                            onClose={handleCloselightbox}
                            showRotate={true}
                        />
                    )
                }

                {/* upload dialogs */}
                <Dialog
                    TransitionComponent={Zoom}
                    open={open}
                    onClose={handleClose}
                    disableBackdropClick
                    disableEscapeKeyDown
                    transitionDuration={900}
                >
                    <form onSubmit={handleUpload} encType="multipart/form-data">
                        <DialogTitle id="alert-dialog-title"> Share Image </DialogTitle>
                        <DialogContent dividers>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                margin="dense"
                            >
                                <InputLabel id="demo-simple-select-label"> Category </InputLabel>
                                <Select
                                    label="Category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    name="category"
                                >
                                    {
                                        bodyparts.map((data) => (
                                            <MenuItem value={data.value} key={data.id}> { data.value} </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <Box my={2}>
                                <DropzoneArea
                                    filesLimit={1}
                                    onChange={handleChange}
                                    acceptedFiles={['image/jpeg', 'image/png']}
                                />
                            </Box>

                            <TextField
                                fullWidth
                                label="Password"
                                variant="outlined"
                                margin="dense"
                                name="password"
                                type="password"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setOpen(false)}
                                variant="contained"
                                color="default"
                                startIcon={
                                    <CancelIcon />
                                }
                            >
                                Close
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    uploadProcess ? <CircularProgress size={15} color="inherit" /> : <CloudUploadIcon />
                                }
                                variant="contained"
                                color="primary"
                                autoFocus
                                disabled={uploadProcess}
                            >
                                Upload
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* print laboratory order */}
                <ImagingPrint
                    orderPrint={orderPrint}
                    close={() => setOrderPrint({
                        open: false,
                        doctors_id: null,
                        patient_id: null,
                        order: null,
                        order_date: null
                    })}
                />

                {/* view multiple image sample */}
                <Dialog
                    TransitionComponent={Zoom}
                    open={moreAtt.dialog}
                    onClose={() => setMoreAtt({
                        dialog: false,
                        attach: null
                    })}
                    disableBackdropClick
                    disableEscapeKeyDown
                    transitionDuration={500}
                >
                    <DialogTitle>
                        Imaging Attachment
                </DialogTitle>
                    <DialogContent dividers>
                        <Box>
                            <Grid container spacing={2}>
                                {
                                    moreAtt.attach && (
                                        moreAtt.attach.split(',').map((data) => (
                                            <Grid item xs={12} sm={6} md={4} key={data}>
                                                <Avatar
                                                    alt={data}
                                                    variant="square"
                                                    src={`${imageLocation}imaging/${data}`}
                                                    onClick={handleOpenlightbox}
                                                    image={data}
                                                    image_from={'imaging'}
                                                    title={data}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                            </Grid>
                                        ))
                                    )
                                }
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => setMoreAtt({
                                dialog: false,
                                attach: null
                            })}
                        >
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Fragment>
    )
}

export default Imaging;

const useStyles = makeStyles((theme) => ({
    primarycolor: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
}));