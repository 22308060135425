import React, { useEffect, useState, useCallback } from 'react'
import { Box, Typography, TextField, Grid, CardMedia, Button, FormHelperText } from "@material-ui/core";
import Notify from 'src/notification/Notify';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import Autocomplete from '@material-ui/lab/Autocomplete';

const imageLocation = process.env.REACT_APP_API_IMAGE

const DoctorsDetailsBasic = ({ details, getDocDetails, doctorUserId }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const [profileLabel, setProfileLabel] = useState('Upload Profile');
    const [signLabel, setSignLabel] = useState('Upload Signature');
    const [editComponent, setEditComponent] = useState(false);
    const [special, setSpecial] = useState([]);

    const getFormData = (object) => {
        const formData = new FormData();
        formData.set('doctors_id', doctorUserId);
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const handleUploadImage = async (event) => {
        var ext = event.target.files[0].type.split('/')[1];
        var maxfile = event.target.files[0].size / 1024 / 1024;
        var extArray = ['png', 'jpeg', 'jpg']
        var resetUpload = event.target;
        if (extArray.indexOf(ext) === -1) {
            Notify.customToast('Image Not Allowed', 'Upload PNG and JPEG only.')
            resetUpload.value = '';
        } else if (maxfile.toFixed(2) > 2) {
            Notify.customToast('Image Not Allowed', 'Image size too big, Upload 2mb or less.')
            resetUpload.value = '';
        }
        else {
            setProfileLabel('Uploading...')
            var formdata = new FormData();
            formdata.append('token', token);
            formdata.append('image', event.target.files[0])
            formdata.append('doctors_id', details.doctors_id)
            Axios.post('gtcadmin/doctors/update-profileImage', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'success') {
                        getDocDetails()
                        Notify.successRequest('update profile')
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => {
                    setProfileLabel('Upload Profile')
                })
        }
    }


    const handleUploadSignImage = async (event) => {

        var ext = event.target.files[0].type.split('/')[1];
        var maxfile = event.target.files[0].size / 1024 / 1024;
        var extArray = ['png', 'jpeg', 'jpg']
        var resetUpload = event.target;
        if (extArray.indexOf(ext) === -1) {
            Notify.customToast('Image Not Allowed', 'Upload PNG and JPEG only.')
            resetUpload.value = '';
        } else if (maxfile.toFixed(2) > 2) {
            Notify.customToast('Image Not Allowed', 'Image size too big, Upload 2mb or less.')
            resetUpload.value = '';
        }
        else {
            setSignLabel('Uploading...')
            var formdata = new FormData();
            formdata.append('token', token);
            formdata.append('image', event.target.files[0])
            formdata.append('doctors_id', details.doctors_id)
            Axios.post('gtcadmin/doctors/update-signatureImage', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'success') {
                        getDocDetails()
                        Notify.successRequest('update signature')
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => {
                    setSignLabel('Upload Signature')
                })
        }
    }

    const getSpecializationList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        Axios.post('gtcadmin/doctors/get-all-specialization', formdata)
            .then((response) => {
                const data = response.data;
                setSpecial(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    useEffect(() => {
        getSpecializationList();
    }, [getSpecializationList])

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                sm={7}
                md={9}
            >
                <Box
                    mb={2}
                    display="flex"
                >
                    <Box
                        flexGrow={1}
                    >
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            {editComponent ? 'EDIT INFORMATION' : 'DOCTORS INFORMATION'}
                        </Typography>
                    </Box>

                    <Box>
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={editComponent ? <CloseIcon /> : <EditIcon />}
                            onClick={() => setEditComponent(!editComponent)}
                        >
                            {editComponent ? 'Back' : 'Edit'}
                        </Button>
                    </Box>
                </Box>

                {editComponent ?
                    <Formik
                        initialValues={{
                            user_id: user_id,
                            token: token,
                            username: username,
                            name: details.name,
                            address: details.address,
                            gender: details.gender,
                            birthday: details.birthday,
                            contact_no: details.contact_no,
                            specialization: details.specialization,
                            cil_umn: details.cil_umn,
                            ead_mun: details.ead_mun,
                            online_appointment: details.online_appointment,
                            password: '',
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(),
                            address: Yup.string().required(),
                            gender: Yup.string().required(),
                            birthday: Yup.string().required(),
                            contact_no: Yup.string().required(),
                            specialization: Yup.string().required(),
                            cil_umn: Yup.string().required(),
                            online_appointment: Yup.string().required(),
                            password: Yup.string().required()
                        })}
                        onSubmit={async (values, {
                            setErrors,
                            setSubmitting,
                            resetForm
                        }) => {
                            try {
                                const request = await Axios.post('gtcadmin/doctors/edit-information', getFormData(values))
                                if (request.data === 'pass-invalid') {
                                    setErrors({ submit: 'Password is invalid.' });
                                    Notify.fieldInvalid('password')
                                }
                                if (request.data === 'success') {
                                    Notify.successRequest('new specialization')
                                    resetForm();
                                    setSubmitting(true);
                                    getDocDetails();
                                    setEditComponent(false);
                                }
                            } catch (error) {
                                const message = error.message || 'Something went wrong';
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Box mb={2}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        defaultValue={values.name}
                                        fullWidth
                                        required
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                    />
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        error={Boolean(touched.address && errors.address)}
                                        helperText={touched.address && errors.address}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        defaultValue={values.address}
                                        fullWidth
                                        required
                                        name="address"
                                        label="Address"
                                        variant="outlined"
                                    />
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box mb={2}>
                                            <TextField
                                                select
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                required
                                                fullWidth
                                                error={Boolean(touched.gender && errors.gender)}
                                                helperText={touched.gender && errors.gender}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.gender}
                                                name="gender"
                                                label="Gender"
                                                variant="outlined"
                                            >
                                                <option value={''}> Select </option>
                                                <option value="Male"> Male</option>
                                                <option value="Female"> Female</option>
                                            </TextField>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box mb={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Birthday"
                                                error={Boolean(touched.birthday && errors.birthday)}
                                                helperText={touched.birthday && errors.birthday}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.birthday}
                                                name="birthday"
                                                variant="outlined"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box mb={2}>
                                            <TextField
                                                error={Boolean(touched.contact_no && errors.contact_no)}
                                                helperText={touched.contact_no && errors.contact_no}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                defaultValue={values.contact_no}
                                                fullWidth
                                                required
                                                name="contact_no"
                                                label="Contact"
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box mb={2}>
                                            <Autocomplete
                                                id="sample-id"
                                                onChange={(e, data) => {
                                                    setFieldValue('specialization', data === null ? '' : data)
                                                }}
                                                options={special.map((option) => option.specialization)}
                                                defaultValue={values.specialization}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        required
                                                        fullWidth
                                                        label="Specialization"
                                                        name="specialization"
                                                        variant="outlined"
                                                        error={Boolean(touched.specialization && errors.specialization)}
                                                        helperText={touched.specialization && errors.specialization}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box mb={2}>
                                            <TextField
                                                error={Boolean(touched.cil_umn && errors.cil_umn)}
                                                helperText={touched.cil_umn && errors.cil_umn}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                defaultValue={values.cil_umn}
                                                fullWidth
                                                required
                                                name="cil_umn"
                                                label="License No."
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box mb={2}>
                                            <TextField
                                                error={Boolean(touched.ead_mun && errors.ead_mun)}
                                                helperText={touched.ead_mun && errors.ead_mun}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                defaultValue={values.ead_mun}
                                                fullWidth
                                                required
                                                name="ead_mun"
                                                label="DEA No."
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box mb={2} >
                                    <TextField
                                        select
                                        required
                                        fullWidth
                                        error={Boolean(touched.online_appointment && errors.online_appointment)}
                                        helperText={touched.online_appointment && errors.online_appointment}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.online_appointment}
                                        name="online_appointment"
                                        label="Appointment"
                                        variant="outlined"
                                        SelectProps={{ native: true }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value={''}> Select </option>
                                        <option value={'1'}> Yes </option>
                                        <option value={'0'}> No </option>
                                    </TextField>
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        label="Enter your password"
                                        variant="outlined"
                                        type="password"
                                    />
                                </Box>

                                {errors.submit && (
                                    <Box mt={3} >
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box mb={2} display="flex">
                                    <Box flexGrow={1} />

                                    <Button
                                        variant="contained"
                                        color="default"
                                        type="reset"
                                        startIcon={<ClearIcon />}
                                    >
                                        Clear
                                    </Button>
                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                            disabled={isSubmitting}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                    :
                    <>
                        <Box
                            mb={2}
                        >
                            <TextField
                                multiline
                                fullWidth
                                variant={'outlined'}
                                label="Name"
                                value={details.name}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>

                        <Box
                            mb={2}
                        >
                            <TextField
                                multiline
                                fullWidth
                                variant={'outlined'}
                                label="Address"
                                value={details.address}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>

                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    mb={2}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant={'outlined'}
                                        label="Gender"
                                        value={details.gender === null ? 'no-gender' : details.gender}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    mb={2}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant={'outlined'}
                                        label="Birthday"
                                        value={details.birthday === null ? 'no-birthday' : Notify.birthday(details.birthday)}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    mb={2}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant={'outlined'}
                                        label="Contact"
                                        value={details.contact_no === null ? 'no-contact' : details.contact_no}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    mb={2}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant={'outlined'}
                                        label="Specialization"
                                        value={details.specialization === null ? 'no-specialization' : details.specialization}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    mb={2}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant={'outlined'}
                                        label="License No."
                                        value={details.cil_umn === null ? 'no-license-number' : details.cil_umn}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    mb={2}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        variant={'outlined'}
                                        label="DEA No."
                                        value={details.ead_mun === null ? 'no-dea-number' : details.ead_mun}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box
                            mb={2}
                        >
                            <TextField
                                multiline
                                fullWidth
                                variant={'outlined'}
                                label="Appointment"
                                value={details.online_appointment === null ? 'not-set' : details.online_appointment === 0 ? 'No' : 'Yes'}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </>
                }
            </Grid>
            <Grid
                item
                xs={12}
                sm={5}
                md={3}
            >
                <Box
                    mb={3}
                >
                    <Box
                        mb={1}
                    >
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            DOCTOR PROFILE
                        </Typography>
                    </Box>

                    {details.image === null && (<Typography color="secondary"> No profile. </Typography>)}
                    {details.image && (
                        <CardMedia
                            style={{ borderRadius: 5 }}
                            component="img"
                            title={details.name}
                            src={`${imageLocation}doctors/${details.image}`}
                        />
                    )}
                    <Box
                        mt={3}
                        align="center"
                    >
                        <input
                            accept="image/*"
                            id="profile-image"
                            type="file"
                            onChange={handleUploadImage}
                            hidden
                        />
                        <label
                            htmlFor="profile-image"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                component={Box}
                            >
                                {profileLabel}
                            </Button>
                        </label>
                    </Box>

                </Box>

                <Box
                    mb={2}
                >
                    <Box
                        mb={1}
                    >
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            DOCTOR SIGNATURE
                        </Typography>
                    </Box>

                    {details.image_signature === null && (<Typography color="secondary"> No signature. </Typography>)}
                    {details.image_signature && (
                        <CardMedia
                            style={{ borderRadius: 5 }}
                            component="img"
                            title={details.name}
                            src={`${imageLocation}doctors/doctors_signatures/${details.image_signature}`}
                        />
                    )}

                    <Box
                        mt={3}
                        align="center"
                    >
                        <input
                            accept="image/*"
                            id="signature-image"
                            type="file"
                            hidden
                            onChange={handleUploadSignImage}
                        />
                        <label
                            htmlFor="signature-image"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                component={Box}
                            >
                                {signLabel}
                            </Button>
                        </label>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default DoctorsDetailsBasic;