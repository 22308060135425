import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  DialogTitle,
  Zoom,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Notify from "../../notification/Notify";
import Axios from "axios";
import { grey } from "@material-ui/core/colors";

const HealthDietForm = ({
  patient_id,
  connection,
  open,
  close,
  getSuggestedDietByDate,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");

  const [meals, setMeals] = useState("");
  const [description, setDescription] = useState("");
  const [isprocess, setIsProcess] = useState(false);

  const handleNewDiet = (e) => {
    e.preventDefault();
    e.persist();

    var error = [];
    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("patient_id", patient_id);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("meals", meals);
    formdata.set("connection", connection);
    formdata.set("description", description.replace("<p><br></p>", ""));

    if (
      formdata.get("description").length === 0 ||
      formdata.get("description").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("description");
    }

    if (
      formdata.get("description").length === 0 ||
      formdata.get("description").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("description");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("form has an errrrrrrrror.");
    } else {
      setIsProcess(true);
      Axios.post("doctor/patient/diet/monitoring/new-diet", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("diet");
            close();
            setDescription("");
            setMeals("");
            getSuggestedDietByDate();
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "db-error") {
            Notify.warnRequest("diet");
          }
        })
        .catch((err) => {
          Notify.requestError(err);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  return (
    <Fragment>
      <Dialog
        disableBackdropClick
        open={open}
        onClose={close}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <form onSubmit={handleNewDiet}>
          <DialogTitle>New Diet</DialogTitle>

          <DialogContent dividers>
            <FormControl variant="outlined" fullWidth>
              <InputLabel> Meals </InputLabel>
              <Select
                value={meals}
                onChange={(e) => setMeals(e.target.value)}
                label="Meals"
              >
                <MenuItem value={"Breakfast"}> Breakfast </MenuItem>
                <MenuItem value={"Lunch"}> Lunch </MenuItem>
                <MenuItem value={"Dinner"}> Dinner </MenuItem>
                <MenuItem value={"Other"}> Other </MenuItem>
              </Select>
            </FormControl>

            <Box my={2}>
              <ReactQuill
                name="description"
                theme="snow"
                value={description}
                placeholder="Tell us what you eat."
                onChange={(e) => setDescription(e)}
                style={{
                  backgroundColor: grey[50],
                  color: "#000",
                }}
              />
            </Box>

            <TextField
              label="Enter your password"
              type="password"
              fullWidth
              variant="outlined"
              name="password"
            />
          </DialogContent>

          <DialogActions>
            <Box mr={2}>
              <Button
                variant="contained"
                color="default"
                startIcon={<Cancel />}
                onClick={close}
              >
                No
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isprocess}
              startIcon={
                isprocess ? (
                  <CircularProgress color="inherit" size={15} />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              {" "}
              Save{" "}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default HealthDietForm;
