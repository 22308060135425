import {
  Box,
  TextField,
  FormHelperText,
  CircularProgress,
  Button,
} from "@material-ui/core";
import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { CheckSquare, XCircle } from "react-feather";
import axios from "axios";
import Notify from "src/notification/Notify";

const token = localStorage.getItem("token");
const user_id = localStorage.getItem("user_id");
const username = localStorage.getItem("username");

const Username = ({ detailsRd, details, getProfileInformation }) => {
  const convertFormdata = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box>
      {detailsRd ? (
        Object.keys(details).length > 0 ? (
          <Formik
            initialValues={{
              user_id: user_id,
              token: token,
              username: username,
              new_username: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              new_username: Yup.string()
                .min(5)
                .required("New username is required"),
              password: Yup.string().required(),
            })}
            onSubmit={(values, { resetForm, setFieldValue, setSubmitting }) => {
              setSubmitting(true);
              axios
                .post(
                  "encoder/profile/update-username",
                  convertFormdata(values)
                )
                .then((response) => {
                  const data = response.data;

                  if (data.message === "success") {
                    resetForm();
                    Notify.successRequest("username updated");
                    getProfileInformation();
                    localStorage.setItem("username", values.new_username);
                    setFieldValue("username", values.new_username);
                  }
                })
                .catch((error) =>
                  console.log("request has error:", error.message)
                )
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    required
                    name="username"
                    label="Current Username"
                    variant="outlined"
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    error={Boolean(touched.new_username && errors.new_username)}
                    helperText={touched.new_username && errors.new_username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.new_username}
                    fullWidth
                    required
                    name="new_username"
                    label="New Username"
                    variant="outlined"
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    fullWidth
                    name="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                  />
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box display="flex">
                  <Box mr={2}>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckSquare />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>

                  <Button
                    size="large"
                    variant="contained"
                    color="default"
                    onClick={resetForm}
                    startIcon={<XCircle />}
                  >
                    Clear
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          Notify.noRecord()
        )
      ) : (
        Notify.loading()
      )}
    </Box>
  );
};

export default Username;
