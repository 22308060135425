import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  TablePagination,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState, useCallback } from "react";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Notify from "../../notification/Notify";
import Axios from "axios";
import HealthDietForm from "./HealthDietForm";

const HealthDiet = ({ patient_id, connection }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [newdiet, setNewDiet] = useState(false);

  const [personalDietByDate, setPersonalDietByDate] = useState([]);
  const [personalDietByDateReady, setPersonalDietByDateReady] = useState(false);

  const [suggestedDietByDate, setSuggestedDietByDate] = useState([]);
  const [suggestedDietByDateReady, setSuggestedDietByDateReady] =
    useState(false);
  const [suggestedDetails, setSuggestedDetails] = useState({
    data: [],
    ready: false,
  });

  const [patientDietDetails, setPatientDietDetails] = useState({
    data: [],
    ready: false,
  });

  const [rowsPerPageSuggested, setRowsPerPageSuggested] = useState(10);
  const [pageSuggested, setPageSuggested] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePageSuggested = (event, newPage) => {
    event.persist();
    setPageSuggested(newPage);
  };

  const handleChangeRowsPerPageSuggested = (event) => {
    setRowsPerPageSuggested(parseInt(event.target.value, 10));
    setPageSuggested(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [selectedpid, setSelectedPid] = useState(null);

  const [suggestedpid, setSuggestedPid] = useState(null);

  const getPersonalDietByDate = useCallback(async () => {
    var formdata = new FormData();

    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    await Axios.post(
      "doctor/patient/diet/monitoring/personaldiet-listbydate",
      formdata
    )
      .then((response) => {
        const data = response.data;
        setPersonalDietByDate(data);
        setPersonalDietByDateReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, patient_id, connection]);

  const getSuggestedDietByDate = useCallback(async () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    await Axios.post(
      "doctor/patient/diet/monitoring/suggesteddiet-listbydate",
      formdata
    )
      .then((response) => {
        const data = response.data;
        setSuggestedDietByDate(data);
        setSuggestedDietByDateReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, patient_id, connection]);

  const handleSelectedDietDetails = async (date, is_suggested) => {
    if (date === "match") {
      return null;
    }

    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);
    formdata.set("selected_date", date);
    formdata.set("is_suggested", is_suggested);

    let response = await Axios.post(
      "doctor/patient/diet/monitoring/dietbydate-details",
      formdata
    );
    if (Boolean(is_suggested)) {
      setSuggestedDetails({ data: response.data, ready: true });
    } else {
      setPatientDietDetails({ data: response.data, ready: true });
    }
  };

  useEffect(() => {
    getPersonalDietByDate();
    getSuggestedDietByDate();
  }, [getPersonalDietByDate, getSuggestedDietByDate]);

  return (
    <Fragment>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Box p={2} component={Paper} elevation={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6" color="textSecondary">
                    Suggested Diet
                  </Typography>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    onClick={() => setNewDiet(true)}
                    style={{ top: "-7px" }}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Update
                  </Button>
                </Box>
              </Box>

              <Box p={1}>
                {suggestedDietByDateReady ? (
                  suggestedDietByDate.length > 0 ? (
                    (rowsPerPageSuggested > 0
                      ? suggestedDietByDate.slice(
                          pageSuggested * rowsPerPageSuggested,
                          pageSuggested * rowsPerPageSuggested +
                            rowsPerPageSuggested
                        )
                      : suggestedDietByDate
                    ).map((d, d_index) => {
                      return (
                        <Fragment key={d_index}>
                          <Box mb={2}>
                            <Box display="flex">
                              <IconButton
                                color="primary"
                                id={d.id}
                                onClick={() => {
                                  setSuggestedPid(
                                    suggestedpid === d.id ? null : d.id
                                  );
                                  setSuggestedDetails({
                                    data: [],
                                    ready: false,
                                  });
                                  handleSelectedDietDetails(
                                    suggestedpid === d.id
                                      ? "match"
                                      : d.created_at,
                                    1
                                  );
                                }}
                              >
                                {suggestedpid === d.id ? (
                                  <KeyboardArrowDown fontSize={"small"} />
                                ) : (
                                  <KeyboardArrowRight fontSize={"small"} />
                                )}
                              </IconButton>

                              <Box mt={1.2}>
                                <Typography>
                                  {Notify.createdAt(d.created_at)}
                                </Typography>
                              </Box>
                            </Box>

                            <Collapse in={suggestedpid === d.id ? true : false}>
                              <Box ml={2}>
                                {suggestedDetails.ready
                                  ? suggestedDetails.data.length > 0
                                    ? suggestedDetails.data.map(
                                        (data, index) => (
                                          <Box key={index}>
                                            <Box>
                                              <Typography variant="subtitle1">
                                                {data.meals} added on{" "}
                                                {Notify.createdTime(
                                                  data.created_at
                                                )}
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data.description,
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        )
                                      )
                                    : Notify.noRecord()
                                  : Notify.loading()}
                              </Box>
                            </Collapse>
                          </Box>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Box mt={2}>
                      <Typography color="secondary">
                        No suggested diet
                      </Typography>
                    </Box>
                  )
                ) : (
                  Notify.loading()
                )}
              </Box>

              <TablePagination
                labelRowsPerPage="List"
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={suggestedDietByDate.length}
                rowsPerPage={rowsPerPageSuggested}
                page={pageSuggested}
                onChangePage={handleChangePageSuggested}
                onChangeRowsPerPage={handleChangeRowsPerPageSuggested}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box p={2} component={Paper} elevation={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6" color="textSecondary">
                    Patient Diet
                  </Typography>
                </Box>
              </Box>

              <Box p={1} mt={2}>
                {personalDietByDateReady ? (
                  personalDietByDate.length > 0 ? (
                    (rowsPerPage > 0
                      ? personalDietByDate.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : personalDietByDate
                    ).map((d, d_index) => {
                      return (
                        <Fragment key={d_index}>
                          <Box mb={2}>
                            <Box display="flex">
                              <Box>
                                <IconButton
                                  color="primary"
                                  id={d.id}
                                  onClick={() => {
                                    setSelectedPid(
                                      selectedpid === d.id ? null : d.id
                                    );
                                    setPatientDietDetails({
                                      data: [],
                                      ready: false,
                                    });
                                    handleSelectedDietDetails(
                                      selectedpid === d.id
                                        ? "match"
                                        : d.created_at,
                                      0
                                    );
                                  }}
                                >
                                  {selectedpid === d.id ? (
                                    <KeyboardArrowDown fontSize={"small"} />
                                  ) : (
                                    <KeyboardArrowRight fontSize={"small"} />
                                  )}
                                </IconButton>
                              </Box>

                              <Box mt={1.3}>
                                <Typography>
                                  {Notify.createdAt(d.created_at)}
                                </Typography>
                              </Box>
                            </Box>

                            <Collapse in={selectedpid === d.id ? true : false}>
                              <Box ml={2}>
                                {patientDietDetails.ready
                                  ? patientDietDetails.data.length > 0
                                    ? patientDietDetails.data.map(
                                        (data, index) => (
                                          <Box key={index}>
                                            <Box>
                                              <Typography variant="subtitle1">
                                                {data.meals} added on{" "}
                                                {Notify.createdTime(
                                                  data.created_at
                                                )}
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data.description,
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        )
                                      )
                                    : Notify.noRecord()
                                  : Notify.loading()}
                              </Box>
                            </Collapse>
                          </Box>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Typography color="secondary">No personal diet</Typography>
                  )
                ) : (
                  Notify.loading()
                )}
              </Box>

              <TablePagination
                labelRowsPerPage="List"
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={personalDietByDate.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>

            {/* diet form */}
            <HealthDietForm
              patient_id={patient_id}
              connection={connection}
              open={newdiet}
              close={() => setNewDiet(false)}
              getSuggestedDietByDate={getSuggestedDietByDate}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default HealthDiet;
