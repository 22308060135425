import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListItemText, Box, Avatar, Collapse, Button } from '@material-ui/core';
import { ThemeContext } from 'src/ContextAPI';
import { useHistory } from 'react-router-dom';
import {
    ChevronRight as ExpandLess,
    ChevronDown as ExpandMore,
} from 'react-feather'
import TopbarPatients from './TopbarPatients';
import { isMobile } from "react-device-detect";
import CloseIcon from '@material-ui/icons/Close';
import scrollDetector from 'scroll-detector';


const mobileViewWidth = window.innerWidth;
const computerViewWidth = 280;

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        paddingTop: window.innerWidth > 500 ? 'auto' : 10,
        height: window.innerWidth > 500 ? 'auto' : 80,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    appBarPrimaryColor: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.common.white,
    },

    appBarShift: {
        marginLeft: isMobile && window.innerWidth <= 599 ? mobileViewWidth : computerViewWidth,
        width: isMobile && window.innerWidth <= 599 ? `calc(100% - ${mobileViewWidth}px)` : `calc(100% - ${computerViewWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    appBarShiftColor: {
        backgroundColor: '#f5f7f8',
        color: theme.palette.common.black,
    },

    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: isMobile && window.innerWidth <= 599 ? mobileViewWidth : computerViewWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    drawerPrimaryColor: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.common.white,
    },

    drawerScrollable: {
        height: window.innerHeight - 200,
        overflowY: 'auto'
    },

    drawerScrollablePrimaryColor: {
        backgroundColor: theme.palette.primary.background_light,
    },
    drawerOpen: {
        width: isMobile && window.innerWidth <= 599 ? mobileViewWidth : computerViewWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },

    nested: {
        paddingLeft: theme.spacing(5),
    },
    profileImg: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: theme.palette.primary.dark
    },
    selectedTab: {
        color: '#fff',
        border: '1px solid #fff',
        borderRight: "4px solid #000",
    }
}));

export default function SidebarPatients({ header, routes, module, notification, render }) {
    const username = localStorage.getItem('username')
    const classes = useStyles();
    const themeContext = useContext(ThemeContext)
    const history = useHistory()
    const [isscroll, setIsScroll] = useState(true)
    const [subitem, setSubItem] = useState({
        open: false,
        tag: null,
    })
    // const [selectedSidebar, setSelectedSidebar] = useState('');

    scrollDetector.on('scroll:down', () => {
        setIsScroll(false)
    });

    scrollDetector.on('at:top', () => {
        setIsScroll(true)
    });

    useEffect(() => {
        if (localStorage.getItem('page') === 'Vitals' || localStorage.getItem('page') === 'Allergies' || localStorage.getItem('page') === 'Diagnosis' || localStorage.getItem('page') === 'Medication' || localStorage.getItem('page') === 'Diet' || localStorage.getItem('page') === 'Family History' || localStorage.getItem('page') === 'History') {
            setSubItem({
                open: true,
                tag: 'Profile',
            })
        }
        if (localStorage.getItem('page') === 'Appointment List' || localStorage.getItem('page') === 'Appointment Notif') {
            setSubItem({
                open: true,
                tag: 'Appointment',
            })
        }

    }, [])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                color="default"
                position="fixed"
                elevation={themeContext.sidebar.open ? isscroll ? 0 : 4 : 2}
                className={clsx(classes.appBar, {
                    [classes.appBarPrimaryColor]: !themeContext.sidebar.open && !themeContext.gtcThemeDark,
                    [classes.appBarShift]: themeContext.sidebar.open,
                    [classes.appBarShiftColor]: themeContext.sidebar.open && !themeContext.gtcThemeDark,
                })}
            >
                <TopbarPatients module={module} notification={notification} render={() => render()} />
            </AppBar>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: themeContext.sidebar.open,
                    [classes.drawerClose]: !themeContext.sidebar.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerPrimaryColor]: !themeContext.gtcThemeDark,
                        [classes.drawerOpen]: themeContext.sidebar.open,
                        [classes.drawerClose]: !themeContext.sidebar.open,
                    }),
                }}
            >
                <Box display="flex">
                    <Box flexGrow={1} mt={1}>
                        <Button onClick={() => themeContext.sidebar.toggle(false)}>
                            <CloseIcon style={{ color: 'white' }} />
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" p={1}>
                    <Box flexGrow={1} align="center" className={`gtc-uppercase`}>
                        <Box onClick={() => {
                            history.push('/clinic/app/patient/account');
                            themeContext.sidebar.toggle(false);
                        }}>
                            {Boolean(header.image) ?
                                (
                                    <Avatar
                                        className={classes.profileImg}
                                        src={`${imageLocation}patients/${header.image}`}
                                    />
                                ) : (
                                    <Avatar className={classes.profileImg}>
                                        { Boolean(header.name) ? header.name.charAt() : username.charAt()}
                                    </Avatar>
                                )
                            }
                        </Box>
                        <Box mt={1}>
                            <Typography variant="caption"> {header.name} </Typography>
                        </Box>
                    </Box>
                </Box>

                <Divider />

                <Box
                    className={clsx(classes.drawerScrollable, {
                        [classes.drawerScrollablePrimaryColor]: !themeContext.gtcThemeDark,
                    })}
                >
                    <PerfectScrollbar>
                        <List>
                            {routes.map((data, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                        button={data.show}
                                        className={
                                            !data.show ?
                                                classes.hide
                                                : data.name === localStorage.getItem('page') ?
                                                    classes.selectedTab
                                                    : ''
                                        }
                                        style={{
                                            paddingBottom: 15,
                                            paddingTop: 15,
                                        }}
                                        onClick={() => {
                                            setSubItem({
                                                open: !subitem.open,
                                                tag: data.name
                                            });
                                            localStorage.setItem('page', data.name);
                                            history.push(`${data.path}`);
                                            if (isMobile && data.subitem.length < 1) {
                                                themeContext.sidebar.toggle(false);
                                            }
                                        }}
                                        selected={data.name === localStorage.getItem('page')}
                                    >
                                        <ListItemIcon> {data.icon} </ListItemIcon>

                                        <ListItemText primary={data.name} />

                                        {data.subitem && data.subitem.length > 0 && (
                                            subitem.tag === data.name && subitem.open ? <ExpandMore size={15} color="white" /> : <ExpandLess size={15} color="white" />
                                        )}

                                    </ListItem>

                                    {
                                        data.subitem && data.subitem.length > 0 && data.subitem.map((item, key) => (
                                            <Collapse key={key} in={subitem.tag === data.name && subitem.open} timeout="auto" unmountOnExit>
                                                <List
                                                    component="div"
                                                    disablePadding
                                                    className={
                                                        !item.show ?
                                                            classes.hide
                                                            : item.name === localStorage.getItem('page') ?
                                                                classes.selectedTab
                                                                : ''}
                                                >
                                                    <ListItem
                                                        button
                                                        className={classes.nested}
                                                        onClick={() => {
                                                            history.push(`${item.path}`);
                                                            localStorage.setItem('page', item.name)
                                                            if (isMobile) {
                                                                themeContext.sidebar.toggle(false);
                                                            }
                                                        }}
                                                        selected={item.name === localStorage.getItem('page')}
                                                    >
                                                        <ListItemIcon>
                                                            {item.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.name} />
                                                    </ListItem>
                                                </List>
                                            </Collapse>
                                        ))
                                    }
                                </React.Fragment >
                            ))}
                        </List>
                    </PerfectScrollbar>
                </Box>

                <Divider />

                <Box align={'center'} m={2}>
                    <Typography component={Box} mb={1}> Global Telemedicine Corp </Typography>
                    <Typography> &copy; {new Date().getFullYear()} </Typography>
                </Box>

            </Drawer>
        </div >
    );
}