import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PersonalInfo from "./PersonalInfo";
import PersonalInfoHeader from "./PersonalInfoHeader";
import Health from "./Health";
import Notes from "./Notes";
import PatientHistory from "./PatientHistory";
import Vitals from "./Vitals";
import Imaging from "./imaging/Imaging";
import Laboratory from "./laboratory_new/Laboratory";
// import Laboratory from './laboratory/Laboratory'
import Prescription from "./Prescription";
import { Grid, Paper, Typography, Badge } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LocalHospital from "@material-ui/icons/LocalHospital";
import Whatshot from "@material-ui/icons/Whatshot";
import EventNote from "@material-ui/icons/EventNote";
import HdrWeak from "@material-ui/icons/HdrWeak";
import InvertColors from "@material-ui/icons/InvertColors";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import Timeline from "@material-ui/icons/Timeline";
import TreatmentPlan from "./treatmentplan/TreatmentPlan";
import EventAvailable from "@material-ui/icons/EventAvailable";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import PatientAppointment from "./PatientAppointment";
import TabPanel from "../../utils/TabPanel";
import Notify from "src/notification/Notify";
import Axios from "axios";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { PatientsUnRead } from "src/ContextAPI";
import { useParams } from "react-router-dom";
import Container from "src/layout/Container";
import { FormatSize } from "@material-ui/icons";
import DoctorLetters from "./letters";

function PatientHeader({ connection, getPatientsList }) {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [category, setCategory] = useState("appointment");

  const [permission, setPermission] = useState([]);

  const { patient_id } = useParams();

  const unread = useContext(PatientsUnRead);

  const checkPatientUnreadNotif = (category) => {
    let xx = unread.unviewNotif;
    let yy = unread.unviewNotifVirtual;

    let count = 0;

    for (let i = 0; i < xx.length; i++) {
      if (patient_id === xx[i].patient_id && category === xx[i].category) {
        count += 1;
      }
    }

    for (let i = 0; i < yy.length; i++) {
      if (patient_id === yy[i].patient_id && category === yy[i].category) {
        count += 1;
      }
    }

    return count;
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  const getPermission = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("user_id", user_id);
      formdata.append("patient_id", patient_id);
      formdata.append("connection", "local");

      const request = await Axios.post(
        "doctor/patients/permission/get-permission",
        formdata
      );
      setPermission(request.data);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [patient_id, token, user_id]);

  useEffect(() => {
    getPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPermission, patient_id]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "information",
          items: [
            { name: "Dashboard", path: "/clinic/app/doctor" },
            { name: "Patient", path: "/clinic/app/doctor/patient/list" },
          ],
        }}
        title="Patient Information"
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            <PersonalInfoHeader
              patient_id={patient_id}
              connection={connection}
            />

            <Box m={0} p={0}>
              <Box component={Paper} elevation={1} mb={2}>
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab
                    wrapped
                    label="Appointment"
                    value="appointment"
                    icon={
                      Boolean(
                        parseInt(checkPatientUnreadNotif("appointment"))
                      ) ? (
                        <Badge
                          color="error"
                          badgeContent={checkPatientUnreadNotif("appointment")}
                        >
                          <PermContactCalendar />
                        </Badge>
                      ) : (
                        <PermContactCalendar />
                      )
                    }
                  />
                  <Tab
                    wrapped
                    value="profile"
                    label="Profile"
                    icon={<AccountCircle />}
                  />
                  <Tab
                    wrapped
                    value="health"
                    label="Health"
                    icon={<LocalHospital />}
                  />
                  <Tab
                    wrapped
                    value="vitals"
                    label="Vitals"
                    icon={<Whatshot />}
                  />
                  <Tab
                    wrapped
                    value="notes"
                    label="Notes"
                    icon={<EventNote />}
                  />
                  <Tab
                    wrapped
                    value="prescription"
                    label="Prescription"
                    icon={<HdrWeak />}
                  />
                  {/* need further edit */}
                  <Tab
                    wrapped
                    label="Laboratory"
                    value="laboratory"
                    icon={
                      Boolean(
                        parseInt(checkPatientUnreadNotif("laboratory"))
                      ) ? (
                        <Badge
                          color="error"
                          badgeContent={checkPatientUnreadNotif("laboratory")}
                        >
                          <InvertColors />
                        </Badge>
                      ) : (
                        <InvertColors />
                      )
                    }
                  />

                  <Tab
                    wrapped
                    label="Imaging"
                    value="imaging"
                    icon={
                      Boolean(parseInt(checkPatientUnreadNotif("imaging"))) ? (
                        <Badge
                          color="error"
                          badgeContent={checkPatientUnreadNotif("imaging")}
                        >
                          <VerticalSplit />
                        </Badge>
                      ) : (
                        <VerticalSplit />
                      )
                    }
                  />
                  <Tab
                    wrapped
                    value="treatment"
                    label="Treatment Plan"
                    icon={<EventAvailable />}
                  />

                  <Tab
                    wrapped
                    value="letters"
                    label="Letters"
                    icon={<FormatSize />}
                  />

                  <Tab
                    wrapped
                    value="history"
                    label="History"
                    icon={<Timeline />}
                  />
                </Tabs>
              </Box>

              <TabPanel value={category} index={"appointment"}>
                {permission.length > 0 ? (
                  <PatientAppointment
                    patient_id={patient_id}
                    connection={connection}
                  />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>

              <TabPanel value={category} index={"profile"}>
                <PersonalInfo patient_id={patient_id} connection={connection} />
              </TabPanel>

              <TabPanel value={category} index={"health"}>
                {permission.length > 0 ? (
                  <Health patient_id={patient_id} connection={connection} />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>

              <TabPanel value={category} index={"vitals"}>
                {permission.length > 0 ? (
                  <Vitals patient_id={patient_id} connection={connection} />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>

              <TabPanel value={category} index={"notes"}>
                <Notes patient_id={patient_id} connection={connection} />
              </TabPanel>

              <TabPanel value={category} index={"prescription"}>
                {permission.length > 0 ? (
                  <Prescription
                    patient_id={patient_id}
                    connection={connection}
                  />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>

              <TabPanel value={category} index={"laboratory"}>
                {permission.length > 0 ? (
                  <Laboratory
                    patient_id={patient_id}
                    connection={connection}
                    getPermission={() => getPermission()}
                    getPatientsList={() => getPatientsList()}
                    permission={permission}
                  />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>

              <TabPanel value={category} index={"imaging"}>
                {permission.length > 0 ? (
                  <Imaging patient_id={patient_id} connection={connection} />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>

              <TabPanel value={category} index={"treatment"}>
                <TreatmentPlan
                  patient_id={patient_id}
                  connection={connection}
                />
              </TabPanel>

              <TabPanel value={category} index={"letters"}>
                <DoctorLetters patient_id={patient_id} />
              </TabPanel>

              <TabPanel value={category} index={"history"}>
                {permission.length > 0 ? (
                  <PatientHistory
                    patient_id={patient_id}
                    connection={connection}
                  />
                ) : (
                  <NoPermission />
                )}
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default PatientHeader;

const NoPermission = () => {
  return (
    <Paper component={Box} variant="outlined" p={2} align={"center"}>
      <EnhancedEncryptionIcon color="error" style={{ fontSize: "3rem" }} />
      <Typography color="error" variant="h5">
        NO PERMISSION
      </Typography>
      <Typography
        color="textSecondary"
        variant="caption"
        className={`gtc-uppercase`}
      >
        YOUR PERMISSION IS REMOVE BY PATIENT
      </Typography>
    </Paper>
  );
};
