import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Box, DialogContent, TextField } from '@material-ui/core';
import Axios from 'axios';
import Notify from '../../notification/Notify';

function AppointmentActionContact(props) {
    const [token] = useState(localStorage.getItem("token"))
    const [user_id] = useState(localStorage.getItem("user_id"))

    const [contact, setcontact] = useState({
        data: [],
        ready: false
    })

    const contactInfo = useCallback(() => {
        var formdata = new FormData();
            formdata.set('token', token)
            formdata.set('user_id', user_id)    
            formdata.set('patient_id', props.patient_id) 
            formdata.set('connection', props.connection) 

        Axios.post('doctors/appointment/virtual/patientcontact-info', formdata )
        .then( (response) =>{ 
            const data = response.data;    
            setcontact({
                data: data,
                ready: true
            })
        }).catch(error=>{ 
            Notify.requestError(error);
        })
    },[token, user_id, props.patient_id, props.connection]);

    useEffect(() => {
        contactInfo()
         
    }, [props.patient_id, contactInfo]);
      
    
    return (
        <Fragment>
            <DialogContent dividers>
                {
                    contact.ready ?
                        contact.data.length > 0 ?
                            <Box className="gtc-textfield-noborder">
                                <Box mb={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Email"
                                        value={ contact.data[0].email  === null ? 'None' : contact.data[0].email }
                                        InputProps={{ readOnly: true }}
                                        multiline
                                    /> 
                                </Box> 

                                <Box mb={2}> 
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Mobile"
                                        value={ contact.data[0].mobile  === null ? 'None' : contact.data[0].mobile }
                                        InputProps={{ readOnly: true }}
                                        multiline
                                    /> 
                                </Box> 

                                <Box>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Telephone"
                                        value={ contact.data[0].telephone  === null ? 'None' : contact.data[0].telephone }
                                        InputProps={{ readOnly: true }}
                                        multiline
                                    /> 
                                </Box> 
                            </Box>
                        : 'No contact found.'
                    :Notify.loading()
                }
            </DialogContent> 
        </Fragment>
    )
}

export default AppointmentActionContact;
