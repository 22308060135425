import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./patients/Patients.css";
import "./doctor/Doctor.css";
import "react-quill/dist/quill.snow.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "mapbox-gl/dist/mapbox-gl.css";
import NavbarHome from "./routes/NavbarHome";
import { ToastContainer, Slide } from "react-toastify";
import axios from "axios";
import LoginChecker from "./welcome/LoginChecker";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { UnreadContext, ThemeContext, PushNotification } from "./ContextAPI";

import Cookies from "universal-cookie";
// import SyncData from "./SycnDonascoData";

import {
	BlueTheme,
	DarkTheme,
	GreenTheme,
	PurpleTheme,
	RedTheme,
	IndigoTheme,
	TealTheme,
} from "./layout/GTCThemes";
import AccountLockDialog from "./AccountLockDialog";

import "./style.css";
const cookies = new Cookies();

require("dotenv").config();

axios.defaults.baseURL = process.env.REACT_APP_API;

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_is_login: localStorage.getItem("is_login"),
			_type: localStorage.getItem("type"),
			_registration: false,

			unreadInquiery: [],
			darkmode: cookies.get("gtcTheme") === "dark" ? true : false,
			sidebarOpen: window.innerWidth > 900 ? true : false,
			gtctheme: BlueTheme,

			push_notification: {
				token: null,
				enable: false,
			},
		};

		this.scrollableBottomRef = null;
		this.scrollableBottomeRefMargin = 0;
	}

	componentDidMount() {
		this.updateGTCTheme(cookies.get("gtctheme_mode"));
	}

	updateUnreadInquiery = (data) => {
		this.setState({
			unreadInquiery: data,
		});
	};

	updatePushNotification = (token, enable) => {
		this.setState({
			push_notification: {
				token: token,
				enable: enable,
			},
		});
	};

	handleToggleSidebar = (state) => {
		this.setState({ sidebarOpen: state });
	};

	updateGTCTheme = (data) => {
		switch (data) {
			case "red":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: RedTheme });
				return;
			case "purple":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: PurpleTheme });
				return;
			case "green":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: GreenTheme });
				return;
			case "indigo":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: IndigoTheme });
				return;
			case "teal":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: TealTheme });
				return;
			case "dark":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: DarkTheme });
				return;
			default:
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: BlueTheme });
				return;
		}
	};

	render() {
		return (
			<PushNotification.Provider
				value={{
					token: this.state.push_notification.token,
					enable: this.state.push_notification.enable,
					update: this.updatePushNotification,
				}}
			>
				<ThemeContext.Provider
					value={{
						gtcThemeDark: this.state.darkmode,
						updategtcTheme: this.updateGTCTheme,
						sidebar: {
							open: this.state.sidebarOpen,
							toggle: this.handleToggleSidebar,
						},
					}}
				>
					<UnreadContext.Provider
						value={{
							unreadInq: this.state.unreadInquiery,
							updateUnreadInq: this.updateUnreadInquiery,
						}}
					>
						<ThemeProvider theme={this.state.gtctheme}>
							<CssBaseline />

							{this.state._is_login ? (
								LoginChecker(this.state._type)
							) : (
								<NavbarHome />
							)}

							{/* toastr container */}
							<ToastContainer
								position="bottom-right"
								autoClose={2000}
								hideProgressBar
								closeOnClick
								rtl={false}
								draggable
								pauseOnHover={false}
								transition={Slide}
							/>
						</ThemeProvider>
					</UnreadContext.Provider>
				</ThemeContext.Provider>

				{/* enable syncronize */}
				{/* <SyncData /> */}
				<AccountLockDialog />
			</PushNotification.Provider>
		);
	}
}
