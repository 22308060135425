import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "../../notification/Notify";
import Box from "@material-ui/core/Box"; 
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import Container from 'src/layout/Container';
import { Line } from 'react-chartjs-2';     
import { isMobile, isTablet } from "react-device-detect"; 


const Temperature = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [recordsLength, setRecordsLength] = useState(0)
    const [ready, setReady] = useState(false)

    const [temp, setTemp] = useState([])
    const [label, setLabel] = useState([]) 
    const themes = useTheme()
    let chartRef = React.useRef()  
    

    const getTemperature = () => {
        var params = {
            token: token,
            user_id: user_id
        }
        Axios.get('patient/vitals/graph/get-temperature', { params })
            .then((response) => {
                const data = response.data;
                setRecordsLength(data.length)
                pushDataToChart(data)
                setReady(true)
            })
            .catch(error => {
                Notify.requestError(error);
            })
    } 

    const pushDataToChart = (data) => {   
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {  
                setTemp((temp) => temp.concat(data[i].temp === null ? 0 : data[i].temp)) 
                setLabel((label) => label.concat(Notify.dateTimeConvert(data[i].created_at)))
            } 
        }else{
            setTemp((temp) => temp.concat(0)) 
            setLabel((label) => label.concat('No record'))
        }
         
    }   
    const data = {
        labels: label,
        datasets: [
            {
                label: 'Temperature', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                borderColor: themes.palette.primary.main, 
                pointBackgroundColor: themes.palette.primary.main, 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: temp
            }
        ]
    } 

    const options = {
        animationEnabled: true,  
        maintainAspectRatio: false,     
        legend:{
            position: 'bottom',
            cursor: 'pointer',   
            display: false, 
        }, 
        scales:{
            xAxes: [{
                ticks: {
                    display: ! isMobile  //this will remove only the label
                }
            }]
        },
        tooltips: {   
            enabled: true,   
            backgroundColor: themes.palette.primary.main  
        },
        animation:{
            onComplete: () => {  
                const copyWidth = chartRef.current.chartInstance.scales['y-axis-0'].width - 10;
                const copyHeight = chartRef.current.chartInstance.scales['y-axis-0'].height + chartRef.current.chartInstance.scales['y-axis-0'].top + 10;
                const targetCtx = document.getElementById("myChartAxis").getContext("2d");
                      targetCtx.canvas.width = copyWidth;
                      targetCtx.drawImage(chartRef.current.chartInstance.canvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight); 
            } 
        }
    } 


    const initializeChartWidth = () =>{  
        var canvaswidth = isTablet ? recordsLength > 20 ? '3500px' : '1500px' 
        : isMobile ? recordsLength > 20 ? '3500px' : '1500px' 
        : '3500px'; 
 
        chartRef.current.chartInstance.canvas.parentNode.style.width = canvaswidth;     
        chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(90vh - 120px)`;       
    }

    useEffect(() => {
        
        initializeChartWidth()
        getTemperature()  

        return(() =>{
            setReady(false) 
        })

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: "Temperature",
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Graph', path: '/clinic/app/patient/graph' },
                    ]
                }}
                title="Temperature Monitoring"
            >
                <Paper elevation={2} component={Box} p={2}> 
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>  
                            <Box flexGrow={1} maxWidth={'100%'} overflow={"auto"}> 
                                { ! ready && 
                                    <Box align="center"> 
                                        <Typography color="primary" variant="subtitle2" >
                                            fetching temperature records...
                                        </Typography>
                                    </Box>
                                }  
                                <div className="chartWrapper">  
                                    <div 
                                        style={{
                                            width:'100%', 
                                            overflowX: 'auto',
                                            overflowY: 'hidden'
                                        }}
                                    >   
                                            <Line    
                                                ref = { chartRef }
                                                data = { chartRef && data } 
                                                options={chartRef && options}  
                                            />  
                                    </div> 
                                    <canvas id="myChartAxis" height="100%" width="0"></canvas> 
                                </div>    
                            </Box>   
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Fragment>
    );
};

export default Temperature;