import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import axios from "axios";

const CreateAdmittingOrder = ({
  patient_id,
  connection,
  getAllAdmittingOrders,
}) => {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [is_processed, set_is_processed] = useState(false);

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();

    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("patient_id", patient_id);
    formdata.append("added_by", "encoder");

    var error = [];
    if (
      formdata.get("diagnosis").length === 0 ||
      formdata.get("diagnosis").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("diagnosis");
    }
    if (error.length > 0) {
      Notify.consoleLog("Need to fill all required fields");
    } else {
      set_is_processed(true);
      axios
        .post("doctor/create/admitting-order", formdata)
        .then((response) => {
          const data = response.data;
          if (data.message === "success") {
            getAllAdmittingOrders();
            Notify.successRequest("create admitting order");
            e.target.reset();
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => set_is_processed(false));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <Box mt={2}>
      <Card>
        <CardHeader title="Create Admitting Order" />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Diagnosis"
                    variant="outlined"
                    fullWidth
                    multiline
                    name={"diagnosis"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Diet"
                    variant="outlined"
                    fullWidth
                    name={"diet"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Venoclysis"
                    variant="outlined"
                    fullWidth
                    name={"venoclysis"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Vital Sign Mon. Every"
                        variant="outlined"
                        fullWidth
                        name={"vital_sign_mon"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="I&O Every"
                        variant="outlined"
                        fullWidth
                        name={"i_and_o"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="HGT Monitoring"
                    variant="outlined"
                    fullWidth
                    name={"hgt_monitoring"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Diagnostics"
                    variant="outlined"
                    fullWidth
                    multiline
                    name={"diagnostics"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Therapeutics"
                    variant="outlined"
                    fullWidth
                    multiline
                    name={"therapeutics"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Note"
                    variant="outlined"
                    fullWidth
                    name={"note"}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" mt={2}>
              <Box flexGrow={1} />
              <Box>
                <Button variant="contained" size="large" type="reset">
                  Reset
                </Button>
              </Box>

              <Box ml={1}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={is_processed}
                  startIcon={
                    is_processed && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateAdmittingOrder;
