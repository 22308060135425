import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import RadiologistPatientForReview from "./RadiologistPatientForReview";
import RadiologistPatientDetails from "./RadiologistPatientDetails";
import Container from "src/layout/Container";
import GTCInitialDisplay from "src/GTCInitialDisplay";

const Radiologist = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [patients, setPatients] = useState({
    data: [],
    ready: false,
  });

  var interval = null;

  const [selectedPatient, setSelectedPatient] = useState(null);

  const getPatientForReview = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("user_id", user_id);
      const request = await Axios.post(
        "radiologist/patients/getpatient-forreview",
        formdata
      );
      setPatients({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);
  const initializeInterval = () => {
    interval = setInterval(async () => {
      getPatientForReview();
    }, 15000);
  };

  const updateSelectedPatient = (data) => {
    setSelectedPatient(data);
  };

  useEffect(() => {
    getPatientForReview();

    initializeInterval();
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientForReview]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "patients",
        items: [{ name: "Dashboard", path: "/clinic/app/radiologist" }],
      }}
      title={"radiologist"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Box>
            <RadiologistPatientForReview
              patients={patients}
              selectedPatient={selectedPatient}
              updateSelected={updateSelectedPatient}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={9}>
          <Box>
            {selectedPatient === null && <GTCInitialDisplay />}
            {selectedPatient && (
              <RadiologistPatientDetails
                selectedPatient={selectedPatient}
                resetDisplay={() => {
                  setSelectedPatient(null);
                  getPatientForReview();
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Radiologist;
