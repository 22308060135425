import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, Zoom, Dialog, DialogTitle, DialogContent, TextField, Button, CircularProgress, Paper, List, ListItem, ListItemText, DialogActions, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ActiveSubscription from './ActiveSubscription';

function Subscribes() {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const [pending, setpending] = useState([])
    const [pendingready, setpendingready] = useState(false)

    const [pendingsubsid, setpendingsubsid] = useState(null)
    const [pendingdialog, setpendingdialog] = useState(false)
    const [is_process, setis_process] = useState(false)
    const [is_processapproved, setis_processapproved] = useState(false)


    const [approvedsubsid, setapprovedsubsid] = useState(null)
    const [approvedsubslength, setapprovedsubslength] = useState(null)


    const [forapproved, setforapproved] = useState([])
    const [forapprovedready, setforapprovedready] = useState(false)

    const [forapprovaldialog, setforapprovaldialog] = useState(false)

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getAllPendingSubscription = useCallback(() => {

        var formdata = new FormData();
        formdata.set('token', token);

        Axios.post('gtcadmin/subscription/getpending-list', formdata)
            .then((response) => {
                const data = response.data;
                setpending(data)
                setpendingready(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token]);

    const getAllForApprovedSubscription = useCallback(() => {

        var formdata = new FormData();
        formdata.set('token', token);

        Axios.post('gtcadmin/subscription/getapproved-list', formdata)
            .then((response) => {
                const data = response.data;
                setforapproved(data)
                setforapprovedready(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token]);

    const handleSelectedPending = (e) => {
        setpendingsubsid(e.currentTarget.id)
        setpendingdialog(true)
    }

    const handleSelectedApproval = (e) => {
        setapprovedsubsid(e.currentTarget.id)
        setapprovedsubslength(e.currentTarget.getAttribute('subscription_length'))
        setforapprovaldialog(true)
    }

    const handleSendPaymentLink = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('subscription_id', pendingsubsid);

        var error = [];
        if (formdata.get('payment_link').length === 0 || formdata.get('payment_link').trim() === '') {
            error = 'error';
            Notify.fieldRequired('payment link');
        }
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }
        if (error.length > 0) {
            console.warn("form has an error")
        } else {
            setis_process(true)
            Axios.post('gtcadmin/subscription/sentlink-forpayment', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        getAllPendingSubscription()
                        getAllForApprovedSubscription()
                        setpendingdialog(false)
                        Notify.successRequest('payment link')
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setis_process(false)
                })
        }
    }

    const handleApproveSubscription = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('subscription_id', approvedsubsid);
        formdata.set('subscription_length', approvedsubslength);

        var error = [];
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }
        if (error.length > 0) {
            console.warn("form has an error")
        } else {
            setis_processapproved(true)
            Axios.post('gtcadmin/subscription/approved-subscription', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        setforapprovaldialog(false)
                        getAllForApprovedSubscription()
                        getAllPendingSubscription()
                        Notify.successRequest('payment link')
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setis_processapproved(false)
                })
        }
    }

    useEffect(() => {
        getAllPendingSubscription()
        getAllForApprovedSubscription()

    }, [getAllPendingSubscription, getAllForApprovedSubscription])

    return (
        <Fragment>
            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item sm={3} xs={12}>
                        <Paper
                            component={Box}
                            p={2}
                        >
                            <Typography
                                noWrap
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                PENDING SUBSCRIPTION
                            </Typography>
                            <List>
                                {
                                    pendingready ?
                                        pending.length > 0 ?
                                            pending.map((data, index) => {
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        selected={data.subscription_id === pendingsubsid}
                                                        id={data.subscription_id}
                                                        onClick={handleSelectedPending}
                                                        className={`gtc-capitalize`}
                                                    >
                                                        <ListItemText
                                                            primary={data.patient_name}
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                            : <Box p={3}> {Notify.noRecord()} </Box>
                                        : <Box p={3}> {Notify.loading()} </Box>
                                }
                            </List>
                        </Paper>
                        {/* subscription for approved */}
                        <Box mt={2}>
                            <Paper
                                component={Box}
                                p={2}
                            >
                                <Typography
                                    noWrap
                                    variant="subtitle2"
                                    color="textPrimary"
                                >
                                    SUBSCRIPTION FOR APPROVAL
                                </Typography>
                                <List>
                                    {
                                        forapprovedready ?
                                            forapproved.length > 0 ?
                                                forapproved.map((data, index) => {
                                                    return (
                                                        <ListItem
                                                            key={index}
                                                            button
                                                            selected={data.subscription_id === approvedsubsid}
                                                            subscription_length={data.subscription_length_month}
                                                            id={data.subscription_id}
                                                            onClick={handleSelectedApproval}
                                                            className={`gtc-capitalize`}
                                                        >
                                                            <ListItemText
                                                                primary={data.patient_name}
                                                            />
                                                        </ListItem>
                                                    )
                                                })
                                                : <Box p={3}> {Notify.noRecord()} </Box>
                                            : <Box p={3}> {Notify.loading()} </Box>
                                    }

                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Box
                            p={2}
                        >
                            <Box
                                mb={2}
                            >
                                <Typography
                                    noWrap
                                    variant="subtitle2"
                                    color="textPrimary"
                                >
                                    SUBSCRIPTION LIST
                                </Typography>
                            </Box>

                            <Accordion
                                square
                                expanded={expanded === 'panel1'}
                                onChange={handleChange('panel1')}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                    >
                                        ACTIVE SUBSCRIPTION
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ActiveSubscription />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                square
                                expanded={expanded === 'panel2'}
                                onChange={handleChange('panel2')}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="caption" color="textPrimary">
                                        EXPIRED SUBSCRIPTION
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                        sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* process pending dialog */}
            <Dialog
                TransitionComponent={Zoom}
                open={pendingdialog}
                onClose={() => setpendingdialog(false)}
                keepMounted
                disableBackdropClick={true}
                transitionDuration={1000}
            >
                <DialogTitle
                    disableTypography
                >
                    <Typography
                        variant="subtitle2"
                        className={`gtc-uppercase`}
                    >
                        Subscription Payment Link
                    </Typography>
                </DialogTitle>

                <form onSubmit={handleSendPaymentLink}>
                    <DialogContent dividers>
                        <Box mb={1}>
                            <TextField fullWidth margin="dense" name="payment_link" variant="outlined" label="Payment Link" />
                        </Box>

                        <Box my={1}>
                            <TextField fullWidth margin="dense" name="password" variant="outlined" label="password" type="password" />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setpendingdialog(false);
                                setpendingsubsid(null)
                            }}
                            color="default"
                            variant="contained"
                            startIcon={<CancelOutlined />}
                        >
                            Close
                        </Button>

                        <Button
                            startIcon={is_process ? <CircularProgress color="inherit" size={15} /> : <AddCircleIcon />}
                            disabled={is_process} color="primary" variant="contained" type="submit">
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* process forapproval */}
            {/* process pending dialog */}
            <Dialog
                TransitionComponent={Zoom}
                open={forapprovaldialog}
                onClose={() => setforapprovaldialog(false)}
                keepMounted
                disableBackdropClick={true}
                transitionDuration={1000}
            >
                <DialogTitle
                    disableTypography
                >
                    <Typography
                        variant="subtitle2"
                        className={`gtc-uppercase`}
                    >
                        Subscription Approval
                    </Typography>
                </DialogTitle>
                <form onSubmit={handleApproveSubscription}>
                    <DialogContent dividers>
                        <Box my={1}>
                            <TextField fullWidth margin="dense" name="password" variant="outlined" label="password" type="password" />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setforapprovaldialog(false);
                                setapprovedsubsid(null)
                            }}
                            color="default"
                            variant="contained"
                            startIcon={<CancelOutlined />}
                        >
                            Close
                        </Button>

                        <Button
                            startIcon={is_processapproved ? <CircularProgress color="inherit" size={15} /> : <AddCircleIcon />}
                            disabled={is_processapproved} color="primary" variant="contained" type="submit">
                            Approved
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    )
}

export default Subscribes;
