import { useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import { Line } from "react-chartjs-2";

const Male = ({ list }) => {
  const chartRef = React.useRef();
  const theme = useTheme();

  const label = [
    "Birth", // birth
    "2",
    "4",
    "6",
    "8",
    "10",

    "1 year", // 1year
    "2",
    "4",
    "6",
    "8",
    "10",

    "2 year", // 2 years
    "2",
    "4",
    "6",
    "8",
    "10",

    "3 year", // 3 years
    "2",
    "4",
    "6",
    "8",
    "10",
    "4 year",

    "2", // 4 years
    "4",
    "6",
    "8",
    "10",
    "5 year",
  ];

  const generateChildsGrowth = (list) => {
    let growth = [];

    if (list.length > 0) {
      growth.push(
        list[0]._birth_zero === null ? 0 : list[0]._birth_zero,
        list[0]._birth_two === null ? 0 : list[0]._birth_two,
        list[0]._birth_four === null ? 0 : list[0]._birth_four,
        list[0]._birth_six === null ? 0 : list[0]._birth_six,
        list[0]._birth_eight === null ? 0 : list[0]._birth_eight,

        list[0]._first_ten === null ? 0 : list[0]._first_ten,
        list[0]._first_zero === null ? 0 : list[0]._first_zero,
        list[0]._first_two === null ? 0 : list[0]._first_two,
        list[0]._first_four === null ? 0 : list[0]._first_four,
        list[0]._first_six === null ? 0 : list[0]._first_six,
        list[0]._first_eight === null ? 0 : list[0]._first_eight,

        list[0]._second_ten === null ? 0 : list[0]._second_ten,
        list[0]._second_zero === null ? 0 : list[0]._second_zero,
        list[0]._second_two === null ? 0 : list[0]._second_two,
        list[0]._second_four === null ? 0 : list[0]._second_four,
        list[0]._second_six === null ? 0 : list[0]._second_six,
        list[0]._second_eight === null ? 0 : list[0]._second_eight,

        list[0]._third_ten === null ? 0 : list[0]._third_ten,
        list[0]._third_zero === null ? 0 : list[0]._third_zero,
        list[0]._third_two === null ? 0 : list[0]._third_two,
        list[0]._third_four === null ? 0 : list[0]._third_four,
        list[0]._third_six === null ? 0 : list[0]._third_six,
        list[0]._third_eight === null ? 0 : list[0]._third_eight,

        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,
        list[0]._fourth_zero === null ? 0 : list[0]._fourth_zero,
        list[0]._fourth_two === null ? 0 : list[0]._fourth_two,
        list[0]._fourth_four === null ? 0 : list[0]._fourth_four,
        list[0]._fourth_six === null ? 0 : list[0]._fourth_six,
        list[0]._fourth_eight === null ? 0 : list[0]._fourth_eight,
        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,

        list[0]._fifth_zero === null ? 0 : list[0]._fifth_zero
      );
    } else {
      growth.push(0);
    }

    return growth;
  };

  const data = {
    labels: label,
    datasets: [
      {
        label: "Weight",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: `${theme.palette.primary.main}`,
        borderColor: `${theme.palette.primary.main}`,
        pointHoverRadius: 1,
        borderWidth: 2,
        data: generateChildsGrowth(list),
      },
      {
        label: "-3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000",
        borderColor: "#000",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: [
          2,
          4,
          5,
          5.7,
          6.1,
          6.5,
          7, // 1 year

          7.2,
          7.5,
          7.9,
          8,
          8.2,
          8.4, // 2year

          8.8,
          9.1,
          9.2,
          9.5,
          9.8,
          10, // 3year

          10.2,
          10.3,
          10.5,
          10.8,
          11,
          11.2, // 4year

          11.4,
          11.5,
          11.7,
          12,
          12.2,
          12.5, // 5year
        ],
      },
      {
        label: "-2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "red",
        borderColor: "red",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: [
          2.5,
          4.3,
          5.5,
          6.3,
          7,
          7.2,
          7.9, // 1year

          8,
          8.2,
          8.4,
          9,
          9.2,
          9.8, //2 year

          10,
          10.1,
          10.5,
          10.9,
          11,
          11.2, // 3 year

          11.5,
          11.9,
          12,
          12.1,
          12.5,
          12.9, // 4 year

          13,
          13.1,
          13.2,
          13.6,
          13.9,
          14, // 5years
        ],
      },

      {
        label: "0",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "green",
        borderColor: "green",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: [
          3.5,
          5.5,
          7,
          8,
          8.5,
          9.1,
          9.7, // 1year

          10,
          10.5,
          11,
          11.3,
          11.9,
          12, // 2year

          12.5,
          13,
          13.2,
          13.7,
          14,
          14.3, // 3year

          14.7,
          15,
          15.3,
          15.7,
          16,
          16.2, // 4year

          16.2,
          16.8,
          17,
          17.3,
          17.8,
          18,
          18.2, // 5year
        ],
      },

      {
        label: "2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "red",
        borderColor: "red",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: [
          4.5,
          7,
          8.7,
          10,
          10.8,
          11.2,
          12, // 1year

          12.5,
          13,
          13.8,
          14,
          14.8,
          15, // 2year

          16,
          16.3,
          17,
          17.3,
          18,
          18.2, // 3 years

          18.8,
          19.2,
          19.8,
          20.2,
          20.9,
          21, // 4 years

          21.7,
          22,
          22.7,
          23,
          23.6,
          24.1, // 5 years
        ],
      },

      {
        label: "3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000",
        borderColor: "#000",
        pointHoverRadius: 1,
        borderWidth: 2,
        data: [
          5,
          8,
          10,
          11,
          12,
          12.7,
          13.3, // 1year

          14,
          14.6,
          15.2,
          16,
          16.5,
          17, // 2year

          18,
          18.2,
          19,
          19.5,
          20,
          20.8, // 3 years

          21.2,
          22,
          22.5,
          23,
          23.5,
          24, // 4 years

          24.8,
          25.3,
          26,
          26.5,
          27,
          28, // 5 years
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      position: "bottom",
      cursor: "pointer",
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Weight(kg)",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Age(Completed months and years)",
          },
        },
      ],
    },
  };

  return (
    <Box>
      <Line ref={chartRef} data={data} options={options} />
    </Box>
  );
};

export default Male;
