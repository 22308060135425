import {
  Grid,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Fragment } from "preact";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const FecalAnalysisOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card>
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={formheader && formheader.address}
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          {/* order details */}
          <Box>
            <Typography variant="h6" align="center">
              <b> FECAL ANALYSIS </b>
            </Typography>
          </Box>

          <Box>
            <>
              <Box mb={2}>
                {/* clinical microscopy */}
                {orderDetails.data.length > 0 ? (
                  orderDetails.data.map((data, key) => (
                    <Fragment key={key}>
                      <Box>
                        {Boolean(parseInt(data.fecal_analysis)) && (
                          <Box mt={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6}>
                                <>
                                  <Box>
                                    <Typography variant="subtitle2">
                                      <b> Cellular Elements </b>
                                    </Typography>
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> COLOR: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_color}
                                        </Box>
                                      </Box>

                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> CONSISTENCY: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_consistency}
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> PUS: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_pus}
                                        </Box>
                                        /HPF
                                      </Box>
                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> RBC: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_rbc}
                                        </Box>
                                        /HPF
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </>
                              </Grid>
                              <Grid item xs={6} sm={6}>
                                <Grid container>
                                  <Grid item xs={3} sm={3} />
                                  <Grid item xs={9} sm={9}>
                                    <Box>
                                      <Box>
                                        <Typography variant="subtitle2">
                                          <b> Others </b>
                                        </Typography>
                                      </Box>

                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> FAT GLOBULES: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_fat_globules}
                                        </Box>
                                      </Box>

                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> OCCULT BLOOD:: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_occultblood}
                                        </Box>
                                      </Box>

                                      <Box mt={1} display="flex">
                                        <Typography variant="caption">
                                          <b> BACTERIA: </b>
                                        </Typography>
                                        <Box
                                          mx={2}
                                          align="center"
                                          minWidth={100}
                                          borderBottom={1}
                                        >
                                          {data.cellular_elements_bacteria}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Box mt={1}>
                              <Typography variant="caption">
                                <b> RESULT: </b>
                              </Typography>

                              <Box mx={2} minWidth={100}>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {data.cellular_elements_result}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Fragment>
                  ))
                ) : (
                  <Box>
                    <Typography color="secondary">No record found.</Typography>
                  </Box>
                )}
              </Box>
            </>
          </Box>

          <Box>
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default FecalAnalysisOrder;
