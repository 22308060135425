import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  DialogActions,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
} from "@material-ui/core";
import Axios from "axios";
import React, { useCallback, useEffect, useState, Fragment } from "react";
import {
  CheckSquare,
  ChevronRight,
  ChevronsDown,
  Edit,
  Trash,
  XCircle,
} from "react-feather";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const DetailsLocalUnpaid = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: false });
  const [editRate, setEditRate] = useState({ dialog: false, id: null });
  const [remarks, setRemarks] = useState("");
  const [isProcess, setIsProcess] = useState(false);
  const [newRate, setNewRate] = useState(0);
  const [removeProcess, setRemoveProcess] = useState(false);
  const [remove, setRemove] = useState({ dialog: false, id: null });
  const [unpaidRemarks, setUnpaidRemarks] = useState({
    open: false,
    data: "",
  });

  const getUnpaidBillsByPatient = useCallback(async () => {
    Axios.get("doctor/patient/appointment/unpaidbill-list", {
      params: {
        token,
        user_id,
        trace_number,
        patient_id,
      },
    }).then((response) => {
      const data = response.data;
      if (mounted.current) {
        setList({ data, ready: true });
      }
    });
  }, [mounted, patient_id, token, user_id, trace_number]);

  const handleNewRate = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("epb_id", editRate.id);

    var error = [];

    if (formdata.get("password").trim() === "") {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (parseFloat(formdata.get("new_rate")) < 1) {
      error = "error";
      Notify.fieldInvalid("new rate");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      Axios.post(
        "doctor/patient/appointment/update-consultation-rate",
        formdata
      )
        .then((response) => {
          if (response.data.message === "pass-invalid") {
            Notify.fieldInvalid("password");
          }

          if (response.data.message === "success") {
            Notify.successRequest("rate updated");
            getUnpaidBillsByPatient();
            setEditRate({ dialog: false, id: null });
            setRemarks("");
            setNewRate(0);
          }
        })
        .catch((error) => console.log("request has error: ", error.message))
        .finally(() => setIsProcess(false));
    }
  };

  const handleRemovePaid = () => {
    var formdata = new FormData();
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("epb_id", remove.id);

    setRemoveProcess(true);
    Axios.post("doctor/patient/appointment/remove-unpaidlist", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          getUnpaidBillsByPatient();
          setRemove({ dialog: false, id: null });
        }
      })
      .catch((error) => console.log("error request:", error.message))
      .finally(() => setRemoveProcess(false));
  };

  useEffect(() => {
    getUnpaidBillsByPatient();
  }, [getUnpaidBillsByPatient]);

  return (
    <Card>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Bill </TableCell>
                <TableCell> Amount</TableCell>
                <TableCell align="center"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.ready ? (
                list.data.length > 0 ? (
                  list.data.map((data, key) => (
                    <Fragment key={key}>
                      <TableRow>
                        <TableCell> {data.bill_name} </TableCell>
                        <TableCell align="right">{data.bill_amount}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            disabled={
                              unpaidRemarks.open &&
                              unpaidRemarks.data.id === data.id
                            }
                            color="primary"
                            onClick={() =>
                              setUnpaidRemarks({
                                open: true,
                                data: data,
                              })
                            }
                          >
                            {unpaidRemarks.open &&
                            unpaidRemarks.data.id === data.id ? (
                              <ChevronsDown />
                            ) : (
                              <ChevronRight />
                            )}
                          </IconButton>

                          <IconButton
                            color="primary"
                            onClick={() =>
                              setEditRate({ dialog: true, id: data.epb_id })
                            }
                          >
                            <Edit />
                          </IconButton>

                          <IconButton
                            color="secondary"
                            onClick={() =>
                              setRemove({ dialog: true, id: data.epb_id })
                            }
                          >
                            <Trash />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {unpaidRemarks.open && unpaidRemarks.data.id === data.id && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Collapse
                              in={unpaidRemarks.open}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box p={1}>
                                <Typography variant="caption">
                                  REMARKS :
                                </Typography>

                                <Typography color="textSecondary">
                                  {unpaidRemarks.data.remarks}
                                </Typography>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      {/* dialog edit rate */}
      <Dialog
        open={editRate.dialog}
        onClose={() => setEditRate({ dialog: false, id: null })}
        disableBackdropClick
      >
        <DialogTitle> Change Rate </DialogTitle>

        <form onSubmit={handleNewRate}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                label="New Rate"
                value={newRate}
                onChange={(e) => setNewRate(e.target.value)}
                name="new_rate"
                fullWidth
                variant="outlined"
                type="number"
                required
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 99999,
                  },
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                name="new_rate_remarks"
                fullWidth
                variant="outlined"
                required
                multiline
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="Password"
                name="password"
                fullWidth
                variant="outlined"
                type="password"
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setEditRate(false)}
              startIcon={<XCircle />}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={
                isProcess ? (
                  <CircularProgress color="inherit" size={23} />
                ) : (
                  <CheckSquare />
                )
              }
              disabled={isProcess}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialog delete confirm */}
      {/* dialog edit rate */}
      <Dialog
        open={remove.dialog}
        onClose={() => setRemove({ dialog: false, id: null })}
        disableBackdropClick
      >
        <DialogContent>
          <Typography>Are you sure to remove this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setRemove({ dialog: false, id: null })}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={removeProcess}
            startIcon={
              removeProcess ? (
                <CircularProgress color="inherit" size={23} />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => handleRemovePaid()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DetailsLocalUnpaid;
