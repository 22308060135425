import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, FormHelperText, Grid, Paper, TextField, Typography, Slide, IconButton } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import NewSpecialization from './NewSpecialization';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Autocomplete from '@material-ui/lab/Autocomplete';

const NewDoctor = ({ getDoctorsList }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const [open, setOpen] = useState(false)
    const [image, setImage] = useState([])
    const [sign, setSign] = useState([])

    const [special, setSpecial] = useState([])

    const getFormData = (object) => {
        const formData = new FormData();
        formData.set('image', image.length > 0 ? image[0] : '')
        formData.set('signature', sign.length > 0 ? sign[0] : '')

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const getSpecializationList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        Axios.post('gtcadmin/doctors/get-all-specialization', formdata)
            .then((response) => {
                const data = response.data;
                setSpecial(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    const handleImageChange = (files) => {
        setImage(files)
    }

    const handleSignChange = (files) => {
        setSign(files)
    }

    const handleClose = () => {
        setOpen(!open);
    }

    useEffect(() => {
        getSpecializationList();
    }, [getSpecializationList])

    return (
        <>
            <Slide in={!open} direction="up">
                <Paper
                    hidden={open}
                    component={Box}
                    p={2}
                >
                    {/* sample formik */}
                    <Formik
                        initialValues={{
                            username: username,
                            user_id: user_id,
                            token: token,
                            name: '',
                            address: '',
                            gender: '',
                            birthday: '',
                            contact: '',
                            specialization: '',
                            license: '',
                            dea: '',
                            appointment: '',
                            doctors_username: '',
                            doctors_password: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().required(),
                            name: Yup.string().required(),
                            address: Yup.string().required(),
                            gender: Yup.string().required(),
                            birthday: Yup.date().required(),
                            contact: Yup.string().required(),
                            specialization: Yup.string().required(),
                            license: Yup.string().required(),
                            appointment: Yup.string().required(),
                            doctors_username: Yup.string().required(),
                            doctors_password: Yup.string().required()
                        })}
                        onSubmit={async (values, {
                            setErrors,
                            setStatus,
                            setSubmitting,
                            resetForm
                        }) => {
                            try {
                                const request = await Axios.post('gtcadmin/doctors/newdoctor-save', getFormData(values))
                                if (request.data === 'pass-invalid') {
                                    setErrors({ submit: 'Password is invalid.' });
                                    Notify.fieldInvalid('password')
                                }
                                if (request.data === 'username-exist') {
                                    setErrors({ submit: 'Doctors username taken.' });
                                    Notify.customToast('Username Taken', 'Doctors username not available.')
                                }
                                if (request.data === 'success') {
                                    Notify.successRequest('new doctor')
                                    resetForm();
                                    setImage([])
                                    setSign([])
                                    setSubmitting(true);
                                    getDoctorsList()
                                }
                            } catch (error) {
                                const message = error.message || 'Something went wrong';
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Box display="flex">
                                    <Box mt={2}>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle2"
                                        >
                                            NEW DOCTOR
                                        </Typography>
                                    </Box>
                                    <Box flexGrow={1} />
                                    <Box>
                                        <Typography color="textPrimary" variant="subtitle2" >
                                            NEW SPECIALIZATION
                                            <IconButton
                                                onClick={() => { setOpen(true); }}
                                            >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        </Typography>
                                    </Box>
                                </Box>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={7}>

                                        <Box mb={2}>
                                            <Typography
                                                color="textPrimary"
                                                variant="caption"
                                            >
                                                DOCTORS INFORMATION
                                            </Typography>
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                fullWidth
                                                required
                                                name="name"
                                                label="Doctor Name"
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                fullWidth
                                                required
                                                error={Boolean(touched.address && errors.address)}
                                                helperText={touched.address && errors.address}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.address}
                                                name="address"
                                                label="Doctor Address"
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                select
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                required
                                                fullWidth
                                                error={Boolean(touched.gender && errors.gender)}
                                                helperText={touched.gender && errors.gender}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.gender}
                                                name="gender"
                                                label="Gender"
                                                variant="outlined"
                                            >
                                                <option value={''}> Select </option>
                                                <option value="Male"> Male</option>
                                                <option value="Female"> Female</option>
                                            </TextField>
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Birthday"
                                                error={Boolean(touched.birthday && errors.birthday)}
                                                helperText={touched.birthday && errors.birthday}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.birthday}
                                                name="birthday"
                                                variant="outlined"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                error={Boolean(touched.contact && errors.contact)}
                                                helperText={touched.contact && errors.contact}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.contact}
                                                name="contact"
                                                label="Contact Number"
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <Autocomplete
                                                id="sample-id"
                                                onChange={(e, data) => {
                                                    setFieldValue('specialization', data === null ? '' : data)
                                                }}
                                                options={special.map((option) => option.specialization)}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        required
                                                        fullWidth
                                                        label="Specialization"
                                                        name="specialization"
                                                        variant="outlined"
                                                        error={Boolean(touched.specialization && errors.specialization)}
                                                        helperText={touched.specialization && errors.specialization}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.specialization}
                                                    />
                                                )}
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                error={Boolean(touched.license && errors.license)}
                                                helperText={touched.license && errors.license}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.license}
                                                name="license"
                                                label="License Number"
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.dea && errors.dea)}
                                                helperText={touched.dea && errors.dea}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.dea}
                                                name="dea"
                                                label="DEA Number"
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField
                                                select
                                                required
                                                fullWidth
                                                error={Boolean(touched.appointment && errors.appointment)}
                                                helperText={touched.appointment && errors.appointment}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.appointment}
                                                name="appointment"
                                                label="Online Appointment"
                                                variant="outlined"
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <option value={''}> Select </option>
                                                <option value={'1'}> Yes </option>
                                                <option value={'0'}> No </option>
                                            </TextField>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <Box mb={2}>
                                            <Typography
                                                color="textPrimary"
                                                variant="caption"
                                            >
                                                DOCTORS IMAGE AND SIGNATURE
                                    </Typography>
                                        </Box>

                                        <Box mb={2}>
                                            <DropzoneArea
                                                filesLimit={1}
                                                onChange={handleImageChange}
                                                acceptedFiles={['image/jpeg', 'image/png']}
                                                dropzoneText="Image (Optional)"
                                                maxFileSize={300000}
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <DropzoneArea
                                                filesLimit={1}
                                                onChange={handleSignChange}
                                                acceptedFiles={['image/jpeg', 'image/png']}
                                                dropzoneText="Signature (Optional)"
                                                maxFileSize={300000}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={Boolean(touched.doctors_username && errors.doctors_username)}
                                        helperText={touched.doctors_username && errors.doctors_username}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.doctors_username}
                                        name="doctors_username"
                                        label="Doctor Username"
                                        variant="outlined"
                                    />
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={Boolean(touched.doctors_password && errors.doctors_password)}
                                        helperText={touched.doctors_password && errors.doctors_password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.doctors_password}
                                        name="doctors_password"
                                        label="Doctor Password"
                                        variant="outlined"
                                    />
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        label="Enter your password"
                                        variant="outlined"
                                        type="password"
                                    />
                                </Box>



                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box mb={2} display="flex">
                                    <Box flexGrow={1} />

                                    <Button
                                        variant="contained"
                                        color="default"
                                        type="reset"
                                        startIcon={<ClearIcon />}
                                    >
                                        Clear
                                    </Button>
                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                            disabled={isSubmitting}
                                        >
                                            Save
                                </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Slide>
            <Slide in={open} direction="left">
                <Box>
                    <NewSpecialization close={() => handleClose()} />
                </Box>
            </Slide>
        </>
    )
}

export default NewDoctor; 