import {
  Paper,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Container from "src/layout/Container";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { Formik } from "formik";
import * as Yup from "yup";
import PharmacyWarehouseAccountList from "./AccountList";
import Axios from "axios";
import Notify from "src/notification/Notify";

const username = localStorage.getItem("username");
const token = localStorage.getItem("token");
const user_id = localStorage.getItem("user_id");
const management_id = localStorage.getItem("management_id");

const PharmacyWarehouseAccounts = () => {
  const [accountList, setAccountList] = useState({ data: [], ready: false });

  const getAccountList = React.useCallback(async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/account/getaccount-list",
      {
        params: {
          management_id: management_id,
          token: token,
          user_id: user_id,
        },
      }
    );

    setAccountList({ data: response.data, ready: true });
  }, []);

  useEffect(() => {
    getAccountList();
  }, [getAccountList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Account",
        items: [{ name: "Dashboard", path: "/clinic/app/warehouse" }],
      }}
      title={"Account List"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} lg={9}>
          <Paper>
            <Box p={2}>
              <PharmacyWarehouseAccountList
                accountList={accountList}
                getAccountList={() => getAccountList()}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4} lg={3}>
          <Paper component={Box} p={2}>
            <Box mb={2}>
              <Typography color="textSecondary" variant="h6">
                New Account
              </Typography>
            </Box>

            <Box>
              <NewAccountForm getAccountList={getAccountList} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PharmacyWarehouseAccounts;

const NewAccountForm = ({ getAccountList }) => {
  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Formik
      initialValues={{
        username: username,
        user_id: user_id,
        token: token,
        management_id: management_id,
        pharmacy_name: "",
        pharmacy_address: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        pharmacy_name: Yup.string().required(
          "Pharmacy name is a required field"
        ),
        pharmacy_address: Yup.string().required(
          "Pharmacy address is a required field"
        ),
        password: Yup.string().required(),
      })}
      onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
        try {
          let response = await Axios.post(
            "/pharmacy/warehouse/account/saveaccount",
            getFormData(values)
          );

          let result = response.data;
          if (result.message === "pass-invalid") {
            setErrors({ password: "Password is invalid." });
          }

          if (result.message === "success") {
            resetForm();
            getAccountList();
            Notify.successRequest("account added");
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              error={Boolean(touched.pharmacy_name && errors.pharmacy_name)}
              helperText={touched.pharmacy_name && errors.pharmacy_name}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.pharmacy_name}
              fullWidth
              required
              name="pharmacy_name"
              label="Pharmacy Name"
              variant="outlined"
            />
          </Box>

          <Box mb={2}>
            <TextField
              error={Boolean(
                touched.pharmacy_address && errors.pharmacy_address
              )}
              helperText={touched.pharmacy_address && errors.pharmacy_address}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.pharmacy_address}
              fullWidth
              required
              name="pharmacy_address"
              label="Pharmacy Address"
              variant="outlined"
            />
          </Box>

          <Box mb={2}>
            <TextField
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              fullWidth
              required
              name="password"
              label="Password"
              type="password"
              variant="outlined"
            />
          </Box>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box display="flex">
            <Box flexGrow={1} />

            <Button
              size="large"
              variant="contained"
              color="default"
              type="reset"
              startIcon={<ClearIcon />}
              onClick={() => resetForm()}
            >
              Clear
            </Button>
            <Box ml={2}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <CheckIcon />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
