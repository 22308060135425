import React, { useState, useEffect, Fragment } from 'react';   
import PrescriptionVirtual from './prescription/PrescriptionVirtual';
import PrescriptionLocal from './prescription/PrescriptionLocal';
import UnsavePrescription from './prescription/UnsavePrescription';
import PrescriptionList from './prescription/PrescriptionList'; 
import Box from '@material-ui/core/Box'; 
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab' 
import TabPanel from '../../utils/TabPanel';
import { Grid, makeStyles, Paper, Badge } from '@material-ui/core';
import PrescriptionDoctor from './prescription/PrescriptionDoctor';
import Axios from 'axios';

const useStyle = makeStyles({
    indicatorLeft: {
      left: "0px"
    }
}); 

const PrescriptionSidebar = ({ patient_id, prescType }) =>{

    const classes = useStyle()
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [category, setCategory] = useState('list')

    const [unsaveCount, setUnsaveCount] = useState(0) 

    const handleChange = (event, newcategory) =>{
        event.persist()
        setCategory(newcategory)
    } 
    
    const getUnsavePrescription = async () =>{
        try {
            var formdata = new FormData();  
            formdata.set('token', token); 
            formdata.set('user_id', user_id);    
            formdata.set('patient_id', patient_id);    
            formdata.set('connection', prescType === 'virtual-prescription' ? 'online' : 'local')
            
            const response = await Axios.post('prescription/unsave/get-countby-presctype', formdata )
            const data = response.data  
            setUnsaveCount(data.length)
        } catch (error) {
            console.log('unable to fetch count by presc', error)
        } 
    }

    useEffect(() =>{

        getUnsavePrescription()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient_id, prescType]) 

    return(
        <Fragment>  
            <Grid container spacing={2}>
                <Box clone order={{ xs: 2, sm: 2 , md: 1 }}>
                    <Grid item xs={12} sm={12} md={9} lg={10}>
                        <Box 
                            p={2}
                            component={Paper}
                            elevation={2}
                        >
                            <TabPanel value={ category } index={'list'}>
                                <PrescriptionList prescType ={ prescType } patient_id = {patient_id} />
                            </TabPanel>

                            <TabPanel value={ category } index={'doctor'}>
                                <PrescriptionDoctor unsaveCount = { unsaveCount } getUnsavePrescription = { () => getUnsavePrescription() } prescType ={ prescType } patient_id = {patient_id} displayUnsave={() => setCategory('unsave') } />
                            </TabPanel>

                            <TabPanel value={ category } index={'clinic'}>
                                <PrescriptionLocal unsaveCount = { unsaveCount } getUnsavePrescription = { () => getUnsavePrescription() } prescType ={ prescType } patient_id = {patient_id} displayUnsave={() => setCategory('unsave') } />
                            </TabPanel>

                            <TabPanel value={ category } index={'virtual'}>
                                <PrescriptionVirtual unsaveCount = { unsaveCount } getUnsavePrescription = { getUnsavePrescription } prescType ={ prescType } patient_id = { patient_id } displayUnsave={() => setCategory('unsave') } />
                            </TabPanel>

                            <TabPanel value={ category } index={'unsave'}>
                                <UnsavePrescription getUnsavePrescription = { getUnsavePrescription } prescType ={ prescType } patient_id = { patient_id } />
                            </TabPanel>
                        </Box> 
                    </Grid>
                </Box>
                <Box clone order={{ xs: 1, sm: 1 , md: 2 }}>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                        <Paper elevation={2}>
                            <Tabs  
                                value={ category } 
                                onChange={ handleChange }
                                indicatorColor="primary"
                                textColor="primary" 
                                scrollButtons="on"  
                                variant="scrollable"
                                orientation={window.innerWidth > 959 ? "vertical":"horizontal"} 
                                classes={{
                                    indicator: classes.indicatorLeft
                                }}
                            >
                                <Tab value="list" wrapped label="Presc. List" /> 

                                { prescType === 'local-prescription' && ( 
                                    <Tab value="doctor" wrapped label="Doctor Presc." /> 
                                )} 

                                { prescType === 'local-prescription' && ( 
                                    <Tab value="clinic" wrapped label="Clinic Presc." /> 
                                )}  

                                { prescType === 'virtual-prescription' && (
                                    <Tab value="virtual" wrapped label="Virtual Presc" />
                                )}
                                
                                <Tab value="unsave" wrapped label={
                                    <Badge color="secondary" badgeContent={ unsaveCount }>
                                        Unsave Presc
                                    </Badge>
                                } /> 
                            </Tabs> 
                        </Paper>
                    </Grid>
                </Box>
            </Grid>   
        </Fragment>
    )

}

export default PrescriptionSidebar;
 