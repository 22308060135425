import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import Notify from "src/notification/Notify";

const ManagementDoctor = ({ details }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [mgntDoctor, setMgntDoctor] = useState([]);
  const [selectedMgntDoctor, setSelectedMgntDoctor] = useState({});
  const [selectedMgntDoctorRxHeader, setSelectedMgntDoctorRxHeader] = useState(
    {}
  );
  const [headerRxType, setHeaderRxType] = useState("add_rxheader");
  const imageLocation = process.env.REACT_APP_API_IMAGE;

  const [selectedMgntDoctorSec, setSelectedMgntDoctorSec] = useState({});
  const [secType, setSecType] = useState("add_secretary");

  const getDoctorsList = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      const request = await Axios.post(
        "gtcadmin/doctors/getdoctors-list",
        formdata
      );
      setDoctors(request.data);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token]);

  const getManagementDoctorsList = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("management_id", details.management_id);
      const request = await Axios.post(
        "gtcadmin/doctors/getmanagementdoctors-list",
        formdata
      );
      setMgntDoctor(request.data);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, details]);

  const handleSelectedDoctor = React.useCallback(async () => {
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("doctorsuser_id", selectedDoctor);
    formdata.append("management_id", details.management_id);

    let response = await Axios.post("gtcadmin/management/add-doctor", formdata);
    let result = response.data;

    if (result.message === "success") {
      Notify.successRequest("doctor added to management");
    }
  }, [token, details, selectedDoctor]);

  const handleGetHeaderRxInfo = async (data) => {
    if (data.doctors_id === selectedMgntDoctor.doctors_id) {
      console.log("id duplicated");
      return;
    }

    setSelectedMgntDoctorRxHeader({});
    setSelectedMgntDoctorSec({});

    let response = await Axios.get(
      "gtcadmin/doctors/getmanagementdoctors-rxheader",
      {
        params: {
          token: token,
          doctors_id: data.doctors_id,
        },
      }
    );

    if (response.data.length > 0) {
      setSelectedMgntDoctorRxHeader(response.data[0]);
      setHeaderRxType("edit_rxheader");
    }

    // get secretary

    let secresponse = await Axios.get(
      "gtcadmin/doctors/getmanagementdoctors-secretary",
      {
        params: {
          token: token,
          doctors_id: data.doctors_id,
        },
      }
    );

    if (secresponse.data.length > 0) {
      setSelectedMgntDoctorSec(secresponse.data[0]);
      setSecType("edit_secretary");
    }
  };

  const handleSaveRxHeader = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("doctors_id", selectedMgntDoctor.doctors_id);
    formdata.set("header", selectedMgntDoctorRxHeader.header);
    formdata.set("sub_header", selectedMgntDoctorRxHeader.sub_header);
    formdata.set("location", selectedMgntDoctorRxHeader.location);
    formdata.set("contact_no", selectedMgntDoctorRxHeader.contact_no);
    formdata.set("days_open", selectedMgntDoctorRxHeader.days_open);
    formdata.set("type", headerRxType);

    let response = await Axios.post(
      "gtcadmin/doctors/management/adddoctors-rxheader",
      formdata
    );

    if (response.data.message === "success") {
      Notify.successRequest("rx header added.");
    }
  };

  const handleSecretary = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("doctors_id", selectedMgntDoctor.doctors_id);
    formdata.set("management_id", selectedMgntDoctor.management_id);

    formdata.set("sec_userid", selectedMgntDoctorSec.user_id);
    formdata.set("sec_name", selectedMgntDoctorSec.name);
    formdata.set("sec_gender", selectedMgntDoctorSec.gender);
    formdata.set("sec_address", selectedMgntDoctorSec.address);
    formdata.set("sec_birthday", selectedMgntDoctorSec.birthday);
    formdata.set("sec_username", selectedMgntDoctorSec.username);
    formdata.set("sec_password", selectedMgntDoctorSec.password);
    formdata.set("type", secType);

    let response = await Axios.post(
      "gtcadmin/doctors/getmanagement/doctors-addsecretary",
      formdata
    );

    if (response.data.message === "success") {
      Notify.successRequest("secretary added.");
    }
  };

  useEffect(() => {
    getDoctorsList();
    getManagementDoctorsList();
  }, [getDoctorsList, getManagementDoctorsList]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          {Object.keys(selectedMgntDoctor).length > 0 ? (
            <Box>
              <Paper component={Box} p={2} mb={2}>
                <Box>
                  <Typography variant="h6">Doctors List</Typography>
                </Box>
                <Box>
                  <Box my={2}>
                    <Typography variant="caption" color="textSecondary">
                      Name:
                    </Typography>
                    {selectedMgntDoctor.name}
                  </Box>
                  <Box mb={2}>
                    <Typography variant="caption" color="textSecondary">
                      address:
                    </Typography>
                    {selectedMgntDoctor.address}
                  </Box>
                  <Box mb={2}>
                    <Typography variant="caption" color="textSecondary">
                      birthday:
                    </Typography>
                    {selectedMgntDoctor.birthday}
                  </Box>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      specialization:
                    </Typography>
                    {selectedMgntDoctor.specialization}
                  </Box>
                </Box>
              </Paper>

              <Paper component={Box} p={2}>
                <Box>
                  <Box mb={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography variant="h6">Doctors Rx Header</Typography>
                      <Typography variant="subtitle2">
                        This is serve as header in rx print out
                      </Typography>
                      {headerRxType === "add_rxheader" && (
                        <Typography variant="subtitle2" color="secondary">
                          Rx header not set. Fill up all fields.
                        </Typography>
                      )}
                      {headerRxType === "edit_rxheader" && (
                        <Typography variant="subtitle2" color="primary">
                          Rx header fields are ready for update.
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <form onSubmit={handleSaveRxHeader}>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Header"
                        variant="outlined"
                        onChange={(e) =>
                          setSelectedMgntDoctorRxHeader({
                            ...selectedMgntDoctorRxHeader,
                            header: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorRxHeader).length > 0
                            ? selectedMgntDoctorRxHeader.header
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Sub Header"
                        variant="outlined"
                        onChange={(e) =>
                          setSelectedMgntDoctorRxHeader({
                            ...selectedMgntDoctorRxHeader,
                            sub_header: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorRxHeader).length > 0
                            ? selectedMgntDoctorRxHeader.sub_header
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Location"
                        variant="outlined"
                        multiline
                        onChange={(e) =>
                          setSelectedMgntDoctorRxHeader({
                            ...selectedMgntDoctorRxHeader,
                            location: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorRxHeader).length > 0
                            ? selectedMgntDoctorRxHeader.location
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Contact No:"
                        name="contact_no"
                        variant="outlined"
                        multiline
                        onChange={(e) =>
                          setSelectedMgntDoctorRxHeader({
                            ...selectedMgntDoctorRxHeader,
                            contact_no: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorRxHeader).length > 0
                            ? selectedMgntDoctorRxHeader.contact_no
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Days open"
                        name="days_open"
                        variant="outlined"
                        multiline
                        onChange={(e) =>
                          setSelectedMgntDoctorRxHeader({
                            ...selectedMgntDoctorRxHeader,
                            days_open: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorRxHeader).length > 0
                            ? selectedMgntDoctorRxHeader.days_open
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="First Logo"
                        name="logos"
                        variant="outlined"
                        type="file"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>

                    {Object.keys(selectedMgntDoctorRxHeader).length > 0 && (
                      <Box mb={2}>
                        <img
                          alt=""
                          style={{
                            maxWidth: 150,
                          }}
                          src={`${imageLocation}/doctors/rxlogo/${selectedMgntDoctorRxHeader.logo}`}
                        />
                      </Box>
                    )}

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Second Logo"
                        name="sub_logos"
                        variant="outlined"
                        type="file"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>

                    {Object.keys(selectedMgntDoctorRxHeader).length > 0 && (
                      <Box mb={2}>
                        <img
                          alt=""
                          style={{
                            maxWidth: 150,
                          }}
                          src={`${imageLocation}/doctors/rxlogo/${selectedMgntDoctorRxHeader.sub_logo}`}
                        />
                      </Box>
                    )}

                    <Box>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Paper>

              {/* add secretary */}
              <Paper component={Box} mt={2}>
                <Box p={2}>
                  <form onSubmit={handleSecretary}>
                    <Box mb={2}>
                      <Typography variant="h6">Doctors Secretary</Typography>
                      <Typography
                        variant="subtitle1"
                        color={
                          secType === "add_secretary" ? "secondary" : "primary"
                        }
                      >
                        {secType === "add_secretary"
                          ? "Secretary not set. fill the fields."
                          : "Fields are ready for update."}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Doctor"
                        variant="outlined"
                        value={
                          Object.keys(selectedMgntDoctor).length > 0
                            ? selectedMgntDoctor.doctors_id
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Name"
                        variant="outlined"
                        onChange={(e) =>
                          setSelectedMgntDoctorSec({
                            ...selectedMgntDoctorSec,
                            name: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorSec).length > 0
                            ? selectedMgntDoctorSec.name
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Gender"
                        variant="outlined"
                        onChange={(e) =>
                          setSelectedMgntDoctorSec({
                            ...selectedMgntDoctorSec,
                            gender: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorSec).length > 0
                            ? selectedMgntDoctorSec.gender
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Address"
                        variant="outlined"
                        onChange={(e) =>
                          setSelectedMgntDoctorSec({
                            ...selectedMgntDoctorSec,
                            address: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorSec).length > 0
                            ? selectedMgntDoctorSec.address
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Birthday"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setSelectedMgntDoctorSec({
                            ...selectedMgntDoctorSec,
                            birthday: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorSec).length > 0
                            ? selectedMgntDoctorSec.birthday
                            : ""
                        }
                        type="date"
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Secretary Username"
                        variant="outlined"
                        onChange={(e) =>
                          setSelectedMgntDoctorSec({
                            ...selectedMgntDoctorSec,
                            username: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorSec).length > 0
                            ? selectedMgntDoctorSec.username
                            : ""
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Secretary Password"
                        variant="outlined"
                        type="password"
                        onChange={(e) =>
                          setSelectedMgntDoctorSec({
                            ...selectedMgntDoctorSec,
                            password: e.target.value,
                          })
                        }
                        value={
                          Object.keys(selectedMgntDoctorSec).length > 0
                            ? selectedMgntDoctorSec.password
                            : ""
                        }
                      />
                    </Box>

                    <Box>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Paper>
            </Box>
          ) : (
            <Box>
              <NoSelectedDoctor />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper>
            <Box p={2}>
              <Box>
                <Typography variant="h6" color="textSecondary">
                  Doctors List
                </Typography>
              </Box>

              <List>
                <ListItem button onClick={() => setDialog(true)}>
                  <ListItemText
                    primary={"Add Doctor"}
                    secondary={"New doctor in management"}
                  />
                </ListItem>

                {mgntDoctor.length > 0 &&
                  mgntDoctor.map((data, index) => {
                    return (
                      <ListItem button key={index}>
                        <ListItemText
                          primary={data.name}
                          secondary={data.address}
                          onClick={() => {
                            handleGetHeaderRxInfo(data);
                            setSelectedMgntDoctor(data);
                          }}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/*  */}
      {dialog && (
        <AddDoctorDialog
          open={dialog}
          close={() => setDialog(false)}
          doctors={doctors}
          setSelectedDoctor={setSelectedDoctor}
          action={handleSelectedDoctor}
        />
      )}
    </Box>
  );
};

export default ManagementDoctor;

const AddDoctorDialog = ({
  open,
  close,
  doctors,
  setSelectedDoctor,
  action,
}) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle> Select Doctor </DialogTitle>
      <DialogContent dividers>
        <Box>
          <TextField
            fullWidth
            label="Doctor"
            variant="outlined"
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setSelectedDoctor(e.target.value)}
          >
            <option value=""> Select </option>
            {doctors.length > 0 &&
              doctors.map((data, index) => {
                return (
                  <option key={index} value={data.user_id}>
                    {data.name}
                  </option>
                );
              })}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="default" onClick={close}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={action}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NoSelectedDoctor = () => (
  <Box align="center">
    <Typography variant="h6">DOCTOR</Typography>

    <Typography variant="subtitle2">
      SELECT DOCTOR TO DISPLAY INFORMATION
    </Typography>
  </Box>
);
