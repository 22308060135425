import React, { Fragment, useState } from "react";
import Notify from "../../notification/Notify";
import Axios from "axios";

import {
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Button,
  Zoom,
  Dialog,
  DialogTitle,
  CardHeader,
  CardContent,
  Card,
  IconButton,
} from "@material-ui/core";
import PaymentForm from "./PaymentForm";
import { CheckSquare } from "react-feather";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

function PurchaseList({ purchaseData, fetchPuchaseList }) {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);

  const handleDeltePurchase = (id, prod_id, batch, expiry) => {
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("management_id", management_id);
    formdata.append("id", id);
    formdata.append("prod_id", prod_id);
    formdata.append("expiry", expiry);
    formdata.append("batch", batch);

    Axios.post("pharmacy/delete-purchase-id", formdata)
      .then(() => {
        Notify.successRequest("delete purchase");
        fetchPuchaseList();
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader title="Unpaid purchase list" />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> PRODUCT </TableCell>
                  <TableCell> QTY </TableCell>
                  <TableCell> PRICE </TableCell>
                  <TableCell> AMOUNT </TableCell>
                  <TableCell> ACTION </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseData.ready ? (
                  purchaseData.data.length > 0 ? (
                    purchaseData.data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell> {data.product} </TableCell>
                          <TableCell align="right">
                            {data.purchase_quantity}
                          </TableCell>
                          <TableCell align="right">{data.price}</TableCell>
                          <TableCell align="right">{data.total}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                handleDeltePurchase(
                                  data.id,
                                  data.product_id,
                                  data.batch_no,
                                  data.expiry_date
                                )
                              }
                            >
                              <DeleteIcon style={{ fontSize: 25 }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="subtitle2" color="secondary">
                          No purchase added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography variant="subtitle2" color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => setPaymentModal(true)}
                      startIcon={<CheckSquare />}
                      disabled={parseFloat(purchaseData.data.length) === 0}
                    >
                      Payment
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={purchaseData.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </CardContent>
      </Card>

      <Dialog
        open={paymentModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Zoom}
        transitionDuration={1000}
        disableBackdropClick={true}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Payment Form</DialogTitle>

        <PaymentForm
          purchaseData={purchaseData}
          fetchPuchaseList={() => fetchPuchaseList()}
          closeModal={() => setPaymentModal(false)}
        />
      </Dialog>
    </Fragment>
  );
}

export default PurchaseList;
