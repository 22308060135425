import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper, Typography, TextField, CardMedia, Button, FormHelperText } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import L from 'leaflet';
import { Map, TileLayer, Marker } from "react-leaflet";
import * as Yup from 'yup';
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import FullscreenControl from 'react-leaflet-fullscreen';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicDetails = ({ clinicId, getClinics }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const [details, setDetails] = useState(null)
    const [editComponent, setEditComponent] = useState(false);
    const [profileLabel, setProfileLabel] = useState('Upload Profile');
    const [coords, setCoords] = useState({
        lat: 7.1907, lng: 125.4553
    })

    var refmarker = useRef(null);

    const handleDragMarker = (e) => {
        setCoords(refmarker.leafletElement.getLatLng())
    }

    const getFormData = (object) => {
        const formData = new FormData();
        formData.set('clinic_id', clinicId);
        formData.set('lat', coords.lat);
        formData.set('lng', coords.lng);

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const handleUploadImage = async (event) => {
        var ext = event.target.files[0].type.split('/')[1];
        var maxfile = event.target.files[0].size / 1024 / 1024;
        var extArray = ['png', 'jpeg', 'jpg']
        var resetUpload = event.target;
        if (extArray.indexOf(ext) === -1) {
            Notify.customToast('Image Not Allowed', 'Upload PNG and JPEG only.')
            resetUpload.value = '';
        } else if (maxfile.toFixed(2) > 2) {
            Notify.customToast('Image Not Allowed', 'Image size too big, Upload 2mb or less.')
            resetUpload.value = '';
        }
        else {
            setProfileLabel('Uploading...')
            var formdata = new FormData();
            formdata.append('token', token);
            formdata.append('image', event.target.files[0])
            formdata.append('clinic_id', clinicId)
            Axios.post('gtcadmin/clinic/update-profileImage', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'success') {
                        getCliniDetails()
                        Notify.successRequest('update profile')
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => {
                    setProfileLabel('Upload Profile')
                })
        }
    }

    const getCliniDetails = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('clinic_id', clinicId)
            const request = await Axios.post('gtcadmin/doctors/getclinic-details', formdata)
            setDetails(request.data)
            setCoords(request.data.latitude === null ? { lat: 7.1907, lng: 125.4553 } : { lat: request.data.latitude, lng: request.data.longitude })
        } catch (error) { Notify.requestError(error) }

    }, [token, clinicId])

    useEffect(() => {
        getCliniDetails()
    }, [getCliniDetails])

    return (
        <>
            <Box>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                    >
                        <Box
                            p={2}
                            component={Paper}
                            className={`gtc-textfield-noborder `}
                        >
                            {details === null && (<Typography color="secondary"> Clinic detail not found. </Typography>)}
                            {details && (
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7}
                                        md={9}
                                    >
                                        <Box
                                            mb={2}
                                            display="flex"
                                        >
                                            <Box
                                                flexGrow={1}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                >
                                                    {editComponent ? 'EDIT DETAILS' : 'CLINIC DETAILS'}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    startIcon={editComponent ? <CloseIcon /> : <EditIcon />}
                                                    onClick={() => setEditComponent(!editComponent)}
                                                >
                                                    {editComponent ? 'Back' : 'Edit'}
                                                </Button>
                                            </Box>
                                        </Box>
                                        {editComponent ?
                                            <Formik
                                                initialValues={{
                                                    user_id: user_id,
                                                    token: token,
                                                    username: username,
                                                    clinic: details.clinic,
                                                    address: details.address,
                                                    days_open: details.days_open,
                                                    time_open: details.time_open,
                                                    contact_no: details.contact_no,
                                                    remarks: details.remarks === null ? '' : details.remarks,
                                                    password: '',
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    clinic: Yup.string().required(),
                                                    address: Yup.string().required(),
                                                    days_open: Yup.string().required(),
                                                    time_open: Yup.string().required(),
                                                    contact_no: Yup.string().required(),
                                                    remarks: Yup.string().required(),
                                                    password: Yup.string().required()
                                                })}
                                                onSubmit={async (values, {
                                                    setErrors,
                                                    setSubmitting,
                                                    resetForm
                                                }) => {
                                                    try {
                                                        const request = await Axios.post('gtcadmin/clinic/edit-details', getFormData(values))
                                                        if (request.data === 'pass-invalid') {
                                                            setErrors({ submit: 'Password is invalid.' });
                                                            Notify.fieldInvalid('password')
                                                        }
                                                        if (request.data === 'success') {
                                                            Notify.successRequest('new specialization')
                                                            resetForm();
                                                            setSubmitting(true);
                                                            getCliniDetails();
                                                            getClinics();
                                                            setEditComponent(false);
                                                        }
                                                    } catch (error) {
                                                        const message = error.message || 'Something went wrong';
                                                        setErrors({ submit: message });
                                                        setSubmitting(false);
                                                    }
                                                }}
                                            >
                                                {({
                                                    errors,
                                                    handleBlur,
                                                    handleChange,
                                                    handleSubmit,
                                                    isSubmitting,
                                                    touched,
                                                    values
                                                }) => (
                                                    <form
                                                        noValidate
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <Box mb={2}>
                                                            <TextField
                                                                error={Boolean(touched.clinic && errors.clinic)}
                                                                helperText={touched.clinic && errors.clinic}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                defaultValue={values.clinic}
                                                                fullWidth
                                                                required
                                                                name="clinic"
                                                                label="Clinic"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <TextField
                                                                error={Boolean(touched.address && errors.address)}
                                                                helperText={touched.address && errors.address}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                defaultValue={values.address}
                                                                fullWidth
                                                                required
                                                                multiline
                                                                name="address"
                                                                label="Address"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <TextField
                                                                error={Boolean(touched.days_open && errors.days_open)}
                                                                helperText={touched.days_open && errors.days_open}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                defaultValue={values.days_open}
                                                                fullWidth
                                                                required
                                                                multiline
                                                                name="days_open"
                                                                label="Days Open"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <TextField
                                                                error={Boolean(touched.time_open && errors.time_open)}
                                                                helperText={touched.time_open && errors.time_open}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                defaultValue={values.time_open}
                                                                fullWidth
                                                                required
                                                                multiline
                                                                name="time_open"
                                                                label="Time Open"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <TextField
                                                                error={Boolean(touched.contact_no && errors.contact_no)}
                                                                helperText={touched.contact_no && errors.contact_no}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                defaultValue={values.contact_no}
                                                                fullWidth
                                                                required
                                                                multiline
                                                                name="contact_no"
                                                                label="Contact"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <TextField
                                                                error={Boolean(touched.remarks && errors.remarks)}
                                                                helperText={touched.remarks && errors.remarks}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                defaultValue={values.remarks}
                                                                fullWidth
                                                                required
                                                                multiline
                                                                name="remarks"
                                                                label="Remarks"
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box mb={2}>
                                                            <Box
                                                                mb={1}
                                                            >
                                                                <Typography variant="caption" color="textPrimary">
                                                                    CLINIC MAP LOCATION
                                                                </Typography>
                                                            </Box>
                                                            <Map
                                                                center={[7.1907, 125.4553]}
                                                                zoom={5}
                                                                style={{ height: '350px' }}
                                                                locate={{
                                                                    watch: true,
                                                                    enableHighAccuracy: true
                                                                }}
                                                            >
                                                                <TileLayer
                                                                    url={process.env.REACT_APP_MAP_URL}
                                                                    attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                                                    maxZoom={19}
                                                                    minZoom={6}
                                                                    crossOrigin={true}
                                                                />
                                                                <Marker
                                                                    position={coords}
                                                                    onDrag={handleDragMarker}
                                                                    draggable={true}
                                                                    onDragend={handleDragMarker}
                                                                    ref={(ref) => refmarker = ref}
                                                                />
                                                                <FullscreenControl position="topright" />
                                                            </Map>
                                                        </Box>

                                                        <Box mb={2}>
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                error={Boolean(touched.password && errors.password)}
                                                                helperText={touched.password && errors.password}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.password}
                                                                name="password"
                                                                label="Enter your password"
                                                                variant="outlined"
                                                                type="password"
                                                            />
                                                        </Box>

                                                        {errors.submit && (
                                                            <Box mt={3} >
                                                                <FormHelperText error>
                                                                    {errors.submit}
                                                                </FormHelperText>
                                                            </Box>
                                                        )}

                                                        <Box mb={2} display="flex">
                                                            <Box flexGrow={1} />

                                                            <Button
                                                                variant="contained"
                                                                color="default"
                                                                type="reset"
                                                                startIcon={<ClearIcon />}
                                                            >
                                                                Clear
                                                            </Button>
                                                            <Box ml={2}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    startIcon={<CheckIcon />}
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Update
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </form>
                                                )}
                                            </Formik>
                                            :
                                            <>
                                                <Box mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        value={details.clinic === null ? '' : details.clinic}
                                                        InputProps={{ readOnly: true }}
                                                        label="Clinic"
                                                        variant="outlined"
                                                    />
                                                </Box>

                                                <Box mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        value={details.address === null ? '' : details.address}
                                                        InputProps={{ readOnly: true }}
                                                        label="Address"
                                                        variant="outlined"
                                                    />
                                                </Box>

                                                <Box mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        value={details.days_open === null ? '' : details.days_open}
                                                        InputProps={{ readOnly: true }}
                                                        label="Days Open"
                                                        variant="outlined"
                                                    />
                                                </Box>

                                                <Box mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        value={details.time_open === null ? '' : details.time_open}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ readOnly: true }}
                                                        label="Time Open"
                                                        variant="outlined"
                                                    />
                                                </Box>

                                                <Box mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        value={details.contact_no === null ? '' : details.contact_no}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ readOnly: true }}
                                                        label="Contact"
                                                        variant="outlined"
                                                    />
                                                </Box>

                                                <Box mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        value={details.remarks === null ? '' : details.remarks}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ readOnly: true }}
                                                        label="Remarks"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Box
                                                        mb={1}
                                                    >
                                                        <Typography variant="caption" color="textPrimary">
                                                            CLINIC MAP LOCATION
                                                        </Typography>
                                                    </Box>

                                                    {details.latitude === null && details.longitude === null && (<Typography color="secondary"> Map not available. </Typography>)}
                                                    {details.latitude && details.longitude && (
                                                        <Map
                                                            center={[7.1907, 125.4553]}
                                                            zoom={5}
                                                            style={{ height: '250px' }}
                                                            locate={{ watch: true, enableHighAccuracy: true }}
                                                            zoomControl={false}
                                                            scrollWheelZoom={false}
                                                            dragging={false}
                                                            attributionControl={false}
                                                        >
                                                            <TileLayer
                                                                url={process.env.REACT_APP_MAP_URL}
                                                                crossOrigin={true}
                                                                maxZoom={19}
                                                                minZoom={6}
                                                            />
                                                            <Marker
                                                                position={[details.latitude, details.longitude]}
                                                            />
                                                        </Map>
                                                    )}
                                                </Box>
                                            </>
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        md={3}
                                    >
                                        <Box
                                            mb={3}
                                        >
                                            <Box
                                                mb={1}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                >
                                                    CLINIC PROFILE
                                                </Typography>
                                            </Box>

                                            {details.clinic_image === null && (<Typography color="secondary"> No profile. </Typography>)}
                                            {details.clinic_image && (
                                                <CardMedia
                                                    style={{ borderRadius: 5 }}
                                                    component="img"
                                                    title={details.name}
                                                    src={`${imageLocation}clinic/${details.clinic_image}`}
                                                />
                                            )}
                                            <Box
                                                mt={3}
                                                align="center"
                                            >
                                                <input
                                                    accept="image/*"
                                                    id="profile-image"
                                                    type="file"
                                                    onChange={handleUploadImage}
                                                    hidden
                                                />
                                                <label
                                                    htmlFor="profile-image"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component={Box}
                                                    >
                                                        {profileLabel}
                                                    </Button>
                                                </label>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ClinicDetails;