import {
  Box,
  Card,
  CircularProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { grey } from "@material-ui/core/colors";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ImageIcon from "@material-ui/icons/Image";
import AirlineSeatFlatAngledIcon from "@material-ui/icons/AirlineSeatFlatAngled";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { useHistory } from "react-router";

const NotificationAppBar = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [isUnviewedData, setIsUnviewedData] = useState([]);
  const [isUnviewedDataRdy, setIsUnviewedDataRdy] = useState(false);

  const [isViewedData, setIsViewedData] = useState([]);
  const [isViewedDataRdy, setIsViewedDataRdy] = useState(false);

  const history = useHistory();

  const theme = useTheme();

  const fetchUnviewData = useCallback(async () => {
    try {
      var params = {
        token: token,
        user_id: user_id,
      };
      const request = await Axios.get("patient/notification/get-all-unviewed", {
        params,
      });
      setIsUnviewedData(request.data);
      setIsUnviewedDataRdy(true);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);

  const fetchViewData = useCallback(async () => {
    try {
      var params = {
        token: token,
        user_id: user_id,
      };
      const request = await Axios.get("patient/notification/get-all-viewed", {
        params,
      });
      setIsViewedData(request.data);
      setIsViewedDataRdy(true);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);

  const handleUpdate = (id) => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("id", id);

    Axios.post("patient/update-to-viewed-by-id", formdata)
      .then((response) => {
        // fetchUnviewData();
        // fetchViewData();
        console.log("msg:", response.data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    fetchUnviewData();
    fetchViewData();
  }, [fetchUnviewData, fetchViewData]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "notification",
          items: [{ name: "Dashboard", path: "/clinic/app/patient" }],
        }}
        title="Notification"
      >
        {isUnviewedDataRdy && isViewedDataRdy ? (
          <Fragment>
            {isUnviewedData.map((unview, unviewIndex) => {
              return (
                <Fragment key={unviewIndex}>
                  <Card
                    component={Box}
                    elevation={1}
                    borderLeft={4}
                    borderColor={theme.palette.primary.main}
                    padding={2}
                    style={{ cursor: "pointer" }}
                    display="flex"
                    onClick={() => {
                      unview.clinic_id !== null &&
                      unview.category === "appointment"
                        ? history.push(
                            `/clinic/app/patient/appointment/clinic/${unview.clinic_id}`
                          )
                        : unview.category === "laboratory"
                        ? history.push("/clinic/app/patient/laboratory")
                        : unview.category === "imaging"
                        ? history.push("/clinic/app/patient/imaging")
                        : history.push("/clinic/app/patient/cart");
                      handleUpdate(unview.id);
                    }}
                  >
                    <Box mr={2}>
                      {unview.clinic_id !== null &&
                      unview.category === "appointment" ? (
                        <LocalHospitalIcon color="primary" />
                      ) : unview.category === "laboratory" ? (
                        <AirlineSeatFlatAngledIcon color="primary" />
                      ) : unview.category === "imaging" ? (
                        <ImageIcon color="primary" />
                      ) : (
                        <ShoppingCartIcon color="primary" />
                      )}
                    </Box>
                    <Typography color="primary">
                      {Capitalize(unview.message)}
                    </Typography>
                    <Box flexGrow={1} />
                    <FiberManualRecordIcon color="primary" />
                  </Card>
                  <Box display="flex" mb={2}>
                    <Box flexGrow={1} />
                    <Typography color="primary" variant="subtitle2">
                      {Notify.dateTimeConvert(unview.created_at)}
                    </Typography>
                  </Box>
                </Fragment>
              );
            })}
            {isViewedData.map((view, viewIndex) => {
              return (
                <Fragment key={viewIndex}>
                  <Card
                    component={Box}
                    elevation={1}
                    borderLeft={4}
                    borderColor={grey[600]}
                    padding={2}
                    style={{ cursor: "pointer" }}
                    display="flex"
                    onClick={() =>
                      view.clinic_id !== null && view.category === "appointment"
                        ? history.push(
                            `/clinic/app/patient/appointment/clinic/${view.clinic_id}`
                          )
                        : view.category === "laboratory"
                        ? history.push("/clinic/app/patient/laboratory")
                        : view.category === "imaging"
                        ? history.push("/clinic/app/patient/imaging")
                        : history.push("/clinic/app/patient/cart")
                    }
                  >
                    <Box mr={2}>
                      {view.clinic_id !== null &&
                      view.category === "appointment" ? (
                        <LocalHospitalIcon />
                      ) : view.category === "laboratory" ? (
                        <AirlineSeatFlatAngledIcon />
                      ) : view.category === "imaging" ? (
                        <ImageIcon />
                      ) : (
                        <ShoppingCartIcon />
                      )}
                    </Box>
                    <Typography>{Capitalize(view.message)}</Typography>
                  </Card>
                  <Box display="flex" mb={2}>
                    <Box flexGrow={1} />
                    <Typography variant="subtitle2">
                      {Notify.dateTimeConvert(view.created_at)}
                    </Typography>
                  </Box>
                </Fragment>
              );
            })}
            {parseFloat(isViewedData).length < 1 &&
              parseFloat(isUnviewedData).length < 1 && (
                <Box
                  border={1}
                  display="flex"
                  style={{ height: "calc(100vh - 300px)" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box align="center">
                    <AllInboxIcon fontSize="large" color="secondary" />
                    <Typography color="secondary">
                      {" "}
                      No notification.{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
          </Fragment>
        ) : (
          <Box
            display="flex"
            style={{ height: "calc(100vh - 300px)" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box align="center">
              <CircularProgress color="primary" size={35} />
              <Typography color="primary"> Fetching... </Typography>
            </Box>
          </Box>
        )}
      </Container>
    </Fragment>
  );
};

export default NotificationAppBar;
