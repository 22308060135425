import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  TablePagination,
  useTheme,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import {
  CheckCircle,
  Printer as PrinterIcon,
  Trash,
  XCircle,
} from "react-feather";
import { Edit } from "@material-ui/icons";
import Axios from "axios";
import EditOrder from "./EditOrder";

const AdmittingOrderList = ({
  getAllAdmittingOrders,
  admittingOrderList,
  connection,
  patient_id,
  // getPatientsList, getPermission, permission
}) => {
  const token = localStorage.getItem("token");
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [editDialog, setEditDialog] = useState({ open: false, data: null });
  const [removeDialog, setRemoveDialog] = useState({
    open: false,
    remove_id: null,
  });

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handlePrintableAdmittingOrder = (e) => {
    var pao_id = e.currentTarget.getAttribute("pao_id");
    var patientid = e.currentTarget.getAttribute("patientid");
    var doctorsid = e.currentTarget.getAttribute("doctorsid");
    window.open(
      `${window.location.origin}/clinic/app/encoder/admitting-order/${pao_id}/${patientid}/${doctorsid}`,
      "",
      "width=500,height=500"
    );
  };

  const handleRemoveOrder = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", removeDialog.remove_id);
    formdata.set("token", token);
    let response = await Axios.post(
      "/doctor/patient/admitting/order-delete",
      formdata
    );

    const data = response.data;

    if (data.message === "success") {
      getAllAdmittingOrders();
      setRemoveDialog({ open: false, remove_id: null });
      Notify.successRequest("order remove");
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  const applyPagination = (order, page, limit) => {
    return order.slice(page * limit, page * limit + limit);
  };

  const paginatedOrder = applyPagination(
    admittingOrderList.data,
    page,
    rowsPerPage
  );

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          Admitting Order List
        </Typography>
      </Box>

      {admittingOrderList.ready
        ? admittingOrderList.data.length > 0
          ? paginatedOrder.map((data, index) => (
              <Paper elevation={2} key={index} component={Box} mb={2} p={2}>
                <Box display="flex">
                  <Box flexGrow={1} mt={1}>
                    <Typography variant="h6">
                      {Notify.dateTimeConvert(data.created_at)}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title={"Edit Order"}>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setEditDialog({ open: true, data: data })
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Delete Order"}>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          setRemoveDialog({
                            open: true,
                            remove_id: data.pao_id,
                          })
                        }
                      >
                        <Trash />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Print Order"}>
                      <IconButton
                        pao_id={data.pao_id}
                        doctorsid={data.doctor_id}
                        patientid={data.patient_id}
                        order_from={
                          data.prescription_type === "virtual"
                            ? "online"
                            : "local"
                        }
                        onClick={handlePrintableAdmittingOrder}
                      >
                        <PrinterIcon color={theme.palette.primary.light} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Paper>
            ))
          : Notify.noRecord()
        : Notify.loading()}

      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={admittingOrderList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {removeDialog.open && (
        <Dialog
          open={removeDialog.open}
          onClose={() => setRemoveDialog({ remove_id: null, open: false })}
        >
          <DialogContent>
            <Typography> Are you sure to delete this order?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setRemoveDialog({ remove_id: null, open: false })}
              startIcon={<XCircle />}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CheckCircle />}
              onClick={handleRemoveOrder}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {editDialog.open && (
        <Dialog
          open={editDialog.open}
          onClose={() => setEditDialog({ remove_id: null, open: false })}
        >
          <DialogTitle> Edit Order </DialogTitle>
          <EditOrder
            details={editDialog.data}
            close={() => setEditDialog({ remove_id: null, open: false })}
            getAllAdmittingOrders={() => getAllAdmittingOrders()}
          />
        </Dialog>
      )}
    </>
  );
};

export default AdmittingOrderList;
