import React from "react";
export default class LaboratoryOrder extends React.Component {
  static dept = () => {
    var orders = [
      {
        label: "Hemathology",
        value: "hemathology",
      },
      {
        label: "Serology",
        value: "serology",
      },
      {
        label: "Clinical Microscopy",
        value: "clinical-microscopy",
      },
      // {
      //   label: "Fecal Analysis",
      //   value: "fecal-analysis",
      // },
      {
        label: "Blood Chemistry",
        value: "clinical-chemistry",
      },
      {
        label: "Stool Test",
        value: "stool-test",
      },
      {
        label: "Papsmear Test",
        value: "papsmear-test",
      },

      {
        label: "Urinalysis",
        value: "urinalysis",
      },

      {
        label: "ECG",
        value: "ecg",
      },

      // {
      //   label: "Physical Examination",
      //   value: "medical-exam",
      // },

      // {
      //   label: "Oral Glucose Tolerance",
      //   value: "oral-glucose",
      // },

      {
        label: "Thyroid Profile",
        value: "thyroid-profile",
      },

      {
        label: "Immunology",
        value: "immunology",
      },

      {
        label: "Miscellaneous",
        value: "miscellaneous",
      },

      {
        label: "Hepatitis Profile",
        value: "hepatitis-profile",
      },

      {
        label: "covid-19",
        value: "covid-19",
      },

      {
        label: "Tumor Maker",
        value: "Tumor Maker",
      },

      {
        label: "Drug Test",
        value: "Drug Test",
      },
    ];

    return orders;
  };

  static list = () => {
    var orders = [
      // hemathology list start
      {
        department: "hemathology",
        label: "Hemoglobin",
        value: "hemoglobin",
      },
      {
        department: "hemathology",
        label: "Hematocrit",
        value: "hematocrit",
      },
      {
        department: "hemathology",
        label: "RBC",
        value: "rbc",
      },
      {
        department: "hemathology",
        label: "WBC",
        value: "wbc",
      },
      {
        department: "hemathology",
        label: "Platelet Count",
        value: "platelet count",
      },
      {
        department: "hemathology",
        label: "Differential Count",
        value: "differential count",
      },
      {
        department: "hemathology",
        label: "Neutrophil",
        value: "neutrophil",
      },
      {
        department: "hemathology",
        label: "Lymphocyte",
        value: "lymphocyte",
      },
      {
        department: "hemathology",
        label: "Monocyte",
        value: "monocyte",
      },
      {
        department: "hemathology",
        label: "Eosinophil",
        value: "eosinophil",
      },
      {
        department: "hemathology",
        label: "Basophil",
        value: "basophil",
      },
      {
        department: "hemathology",
        label: "Bands",
        value: "bands",
      },
      {
        department: "hemathology",
        label: "ABO Blood Type / RH Type",
        value: "abo blood type / rh type",
      },
      {
        department: "hemathology",
        label: "Bleeding Time",
        value: "bleeding time",
      },
      {
        department: "hemathology",
        label: "Clotting Time",
        value: "clotting time",
      },
      {
        department: "hemathology",
        label: "Mean Corpuscular Volume",
        value: "mcv",
      },
      {
        department: "hemathology",
        label: "Mean Corpuscular Hemoglobin",
        value: "mch",
      },
      {
        department: "hemathology",
        label: "Mean Corpuscular Hemoglobin Concentration",
        value: "mchc",
      },
      {
        department: "hemathology",
        label: "Red Blood Cell Distribution Width",
        value: "rdw",
      },
      {
        department: "hemathology",
        label: "Mean Platelet Volume",
        value: "mpv",
      },
      {
        department: "hemathology",
        label: "Platelet Distribution Width",
        value: "pdw",
      },
      {
        department: "hemathology",
        label: "Plateletcrit",
        value: "pct",
      },
      {
        department: "hemathology",
        label: "Blood Typing W/ RH",
        value: "Blood Typing W/ RH",
      },

      {
        department: "hemathology",
        label: "CT/BT",
        value: "CT/BT",
      },

      {
        department: "hemathology",
        label: "ESR",
        value: "ESR",
      },

      {
        department: "hemathology",
        label: "Ferritin",
        value: "Ferritin",
      },

      {
        department: "hemathology",
        label: "APTT",
        value: "APTT",
      },
      {
        department: "hemathology",
        label: "Peripheral Smear",
        value: "Peripheral Smear",
      },

      {
        department: "hemathology",
        label: "Protime",
        value: "Protime",
      },

      {
        department: "hemathology",
        label: "CBC",
        value: "cbc",
      },

      {
        department: "hemathology",
        label: "CBC Platelet",
        value: "cbc platelet",
      },
      // hemathology
      // hemathology list end

      // serology list start
      // {
      //   department: "serology",
      //   label: "Hepatitis B surface Antigen (HBsAg)",
      //   value: "Hepatitis B surface Antigen (HBsAg)",
      // },
      // {
      //   department: "serology",
      //   label: "HAV (Hepatitis A Virus) IgG/IgM",
      //   value: "HAV (Hepatitis A Virus) IgG/IgM",
      // },

      // {
      //   department: "serology",
      //   label: "VDRL/RPR",
      //   value: "VDRL/RPR",
      // },

      {
        department: "serology",
        label: "ANTI-HBC IGM",
        value: "ANTI-HBC IGM",
      },

      {
        department: "serology",
        label: "HCV (Hepatitis C Virus)",
        value: "HCV (Hepatitis C Virus)",
      },

      {
        department: "serology",
        label: "BETA HCG (QUALI)",
        value: "BETA HCG (QUALI)",
      },

      {
        department: "serology",
        label: "TYPHIDOT",
        value: "TYPHIDOT",
      },

      {
        department: "serology",
        label: "H. PYLORI",
        value: "H. PYLORI",
      },

      {
        department: "serology",
        label: "VDRL/SYPHILIS TEST",
        value: "VDRL/SYPHILIS TEST",
      },

      {
        department: "serology",
        label: "HACT",
        value: "HACT",
      },

      {
        department: "serology",
        label: "ANA",
        value: "ANA",
      },

      {
        department: "serology",
        label: "DENGUE TEST",
        value: "DENGUE TEST",
      },
      // serology list end

      // microscopy list
      // {
      //   department: "clinical-microscopy",
      //   label: "Chemical Test",
      //   value: "chemical test",
      // },
      // {
      //   department: "clinical-microscopy",
      //   label: "Microscopic Test",
      //   value: "microscopic test",
      // },
      // {
      //   department: "clinical-microscopy",
      //   label: "Pregnancy Test (HCG)",
      //   value: "pregnancy test (HCG)",
      // },

      // {
      //   department: "clinical-microscopy",
      //   label: "Pregnancy Test (HCG)",
      //   value: "pregnancy test (HCG)",
      // },

      {
        department: "clinical-microscopy",
        label: "Micral Test",
        value: "Micral Test",
      },

      {
        department: "clinical-microscopy",
        label: "Semenalysis",
        value: "Semenalysis",
      },

      {
        department: "clinical-microscopy",
        label: "Occult Blood",
        value: "Occult Blood",
      },

      // microscopy list end

      // fecal list
      // {
      //   department: "fecal-analysis",
      //   label: "Fecal Analysis",
      //   value: "fecal analysis",
      // },
      // fecal list end

      // chemistry list
      {
        department: "clinical-chemistry",
        label: "Glucose",
        value: "glucose",
      },
      {
        department: "clinical-chemistry",
        label: "Creatinine",
        value: "creatinine",
      },
      {
        department: "clinical-chemistry",
        label: "Uric Acid",
        value: "uric acid",
      },
      {
        department: "clinical-chemistry",
        label: "Cholesterol",
        value: "cholesterol",
      },
      {
        department: "clinical-chemistry",
        label: "Triglyceride",
        value: "triglyceride",
      },
      {
        department: "clinical-chemistry",
        label: "HDL Cholesterol",
        value: "hdl cholesterol",
      },
      {
        department: "clinical-chemistry",
        label: "LDL Cholesterol",
        value: "ldl cholesterol",
      },
      {
        department: "clinical-chemistry",
        label: "SGPT",
        value: "SGPT",
      },
      {
        department: "clinical-chemistry",
        label: "SGOT",
        value: "SGOT",
      },
      {
        department: "clinical-chemistry",
        label: "BUN",
        value: "bun",
      },

      {
        department: "clinical-chemistry",
        label: "Soduim",
        value: "soduim",
      },

      {
        department: "clinical-chemistry",
        label: "Potassium",
        value: "potassium",
      },
      {
        department: "clinical-chemistry",
        label: "HBA1C",
        value: "hba1c",
      },

      {
        department: "clinical-chemistry",
        label: "Alkaline Phosphatase",
        value: "alkaline_phosphatase",
      },

      {
        department: "clinical-chemistry",
        label: "Albumin",
        value: "albumin",
      },

      {
        department: "clinical-chemistry",
        label: "Calcium",
        value: "calcium",
      },
      {
        department: "clinical-chemistry",
        label: "Magnesium",
        value: "magnesium",
      },
      {
        department: "clinical-chemistry",
        label: "Chloride",
        value: "chloride",
      },
      {
        department: "clinical-chemistry",
        label: "FBS",
        value: "fbs",
      },
      {
        department: "clinical-chemistry",
        label: "Serum Uric Acid",
        value: "Serum Uric Acid",
      },

      {
        department: "clinical-chemistry",
        label: "Lipid Profile",
        value: "Lipid Profile",
      },

      {
        department: "clinical-chemistry",
        label: "LDH",
        value: "LDH",
      },

      {
        department: "clinical-chemistry",
        label: "TPAG Ratio",
        value: "TPAG Ratio",
      },

      {
        department: "clinical-chemistry",
        label: "Bilirubin (Total/Direct)",
        value: "Bilirubin (Total/Direct)",
      },

      {
        department: "clinical-chemistry",
        label: "Total Protein",
        value: "Total Protein",
      },

      {
        department: "clinical-chemistry",
        label: "Potassium (k+)",
        value: "Potassium (k+)",
      },

      {
        department: "clinical-chemistry",
        label: "NA+/K+",
        value: "NA+/K+",
      },

      {
        department: "clinical-chemistry",
        label: "Cholinesterase",
        value: "Cholinesterase",
      },

      {
        department: "clinical-chemistry",
        label: "GGT",
        value: "GGT",
      },

      {
        department: "clinical-chemistry",
        label: "Phosphorous",
        value: "Phosphorous",
      },

      {
        department: "clinical-chemistry",
        label: "RBS",
        value: "RBS",
      },

      {
        department: "clinical-chemistry",
        label: "VLDL",
        value: "VLDL",
      },

      {
        department: "clinical-chemistry",
        label: "RBC Cholinesterases",
        value: "RBC Cholinesterases",
      },

      {
        department: "clinical-chemistry",
        label: "CRP",
        value: "CRP",
      },

      {
        department: "clinical-chemistry",
        label: "PRO CALCITONIN",
        value: "PRO CALCITONIN",
      },

      {
        department: "clinical-chemistry",
        label: "OGTC 1 TAKE (50 GRM)",
        value: "OGTC 1 TAKE (50 GRM)",
      },

      {
        department: "clinical-chemistry",
        label: "OGTC 1 TAKE (75 GRM)",
        value: "OGTC 1 TAKE (75 GRM)",
      },

      {
        department: "clinical-chemistry",
        label: "OGTC 2 TAKES (100 GRM)",
        value: "OGTC 2 TAKES (100 GRM)",
      },

      {
        department: "clinical-chemistry",
        label: "OGTC 2 TAKES (75 GRM)",
        value: "OGTC 2 TAKES (75 GRM)",
      },

      {
        department: "clinical-chemistry",
        label: "OGTC 3 TAKES (100 GRM)",
        value: "OGTC 3 TAKES (100 GRM)",
      },

      {
        department: "clinical-chemistry",
        label: "OGTC 4 TAKES (100 GRM)",
        value: "OGTC 4 TAKES (100 GRM)",
      },

      // chemistry list end

      // stool test started
      {
        department: "stool-test",
        label: "Fecalysis",
        value: "fecalysis",
      },
      // stool test end

      // urinalysis test start
      {
        department: "urinalysis",
        label: "Urinalysis",
        value: "urinalysis",
      },
      // urinalysis test end

      // ecg test start
      {
        department: "ecg",
        label: "Electrocardiogram (ECG)",
        value: "ecg",
      },
      // ecg test end

      // //medical-exam
      // {
      //   department: "medical-exam",
      //   label: "Physical Examination",
      //   value: "medical-exam",
      // },
      // //medical-exam end

      {
        department: "papsmear-test",
        label: "Papsmear (Female 35yo & up)",
        value: "Papsmear (Female 35yo & up)",
      },

      // {
      //   department: "oral-glucose",
      //   label: "Baseline",
      //   value: "Baseline",
      // },
      // {
      //   department: "oral-glucose",
      //   label: "First Hour",
      //   value: "First Hour",
      // },
      // {
      //   department: "oral-glucose",
      //   label: "Second Hour",
      //   value: "Second Hour",
      // },

      {
        department: "thyroid-profile",
        label: "T3",
        value: "T3",
      },
      {
        department: "thyroid-profile",
        label: "T4",
        value: "T4",
      },
      {
        department: "thyroid-profile",
        label: "TSH",
        value: "TSH",
      },
      {
        department: "thyroid-profile",
        label: "FT4",
        value: "FT4",
      },
      {
        department: "thyroid-profile",
        label: "FT3",
        value: "FT3",
      },

      {
        department: "thyroid-profile",
        label: "T3T4",
        value: "T3T4",
      },

      {
        department: "thyroid-profile",
        label: "FHT",
        value: "FHT",
      },

      {
        department: "thyroid-profile",
        label: "T3, T4, TSH",
        value: "T3-T4-TSH",
      },

      {
        department: "immunology",
        label: "Alpha Fetoprotein",
        value: "Alpha Fetoprotein",
      },

      // {
      //   department: "miscellaneous",
      //   label: "Miscellaneous",
      //   value: "Miscellaneous",
      // },

      {
        department: "miscellaneous",
        label: "Pregnancy Test (Urine)",
        value: "Pregnancy Test (Urine)",
      },

      {
        department: "miscellaneous",
        label: "Pregnancy Test (Serum)",
        value: "Pregnancy Test (Serum)",
      },

      {
        department: "miscellaneous",
        label: "Papsmear",
        value: "Papsmear",
      },

      {
        department: "miscellaneous",
        label: "Papsmear with Gramstain",
        value: "Papsmear with Gramstain",
      },

      {
        department: "hepatitis-profile",
        label: "HBsAg(Quali)",
        value: "HBsAg(Quali)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBs(Quali)",
        value: "Anti-HBs(Quali)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HCV(Quali)",
        value: "Anti-HCV(Quali)",
      },
      {
        department: "hepatitis-profile",
        label: "HBsAG(Quanti)",
        value: "HBsAG(Quanti)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBs(Quanti)",
        value: "Anti-HBs(Quanti)",
      },
      {
        department: "hepatitis-profile",
        label: "HBeAg",
        value: "HBeAg",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBe",
        value: "Anti-HBe",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBc(IgM)",
        value: "Anti-HBc(IgM)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HAV(IgM)",
        value: "Anti-HAV(IgM)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HAVIGM/IGG",
        value: "Anti-HAVIGM/IGG",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBc(IgG Total)",
        value: "Anti-HBc(IgG Total)",
      },

      {
        department: "hepatitis-profile",
        label: "Anti-HBs(Quali)",
        value: "Anti-HBs(Quali)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HCV(Quali)",
        value: "Anti-HCV(Quali)",
      },
      {
        department: "hepatitis-profile",
        label: "HBsAG(Quanti)",
        value: "HBsAG(Quanti)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBs(Quanti)",
        value: "Anti-HBs(Quanti)",
      },
      {
        department: "hepatitis-profile",
        label: "HBeAg",
        value: "HBeAg",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBe",
        value: "Anti-HBe",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBc(IgM)",
        value: "Anti-HBc(IgM)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HAV(IgM)",
        value: "Anti-HAV(IgM)",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HAVIGM/IGG",
        value: "Anti-HAVIGM/IGG",
      },
      {
        department: "hepatitis-profile",
        label: "Anti-HBc(IgG Total)",
        value: "Anti-HBc(IgG Total)",
      },

      // covid 19 test
      {
        department: "covid-19",
        label: "Covid Rapid Test - Antibody",
        value: "Covid Rapid Test - Antibody",
      },

      {
        department: "covid-19",
        label: "Covid Rapid Test - Antigen",
        value: "Covid Rapid Test - Antigen",
      },

      //   tumor maker
      {
        department: "Tumor Maker",
        label: "ASO",
        value: "ASO",
      },

      {
        department: "Tumor Maker",
        label: "Biopsy",
        value: "Biopsy",
      },

      {
        department: "Tumor Maker",
        label: "C3",
        value: "C3",
      },

      {
        department: "Tumor Maker",
        label: "CA 125 (OVARY)",
        value: "CA 125 (OVARY)",
      },

      {
        department: "Tumor Maker",
        label: "CEA",
        value: "CEA",
      },

      {
        department: "Tumor Maker",
        label: "PSA (PROSTATE)",
        value: "PSA (PROSTATE)",
      },

      {
        department: "Tumor Maker",
        label: "AFP",
        value: "AFP",
      },

      // Drug Test
      {
        department: "Drug Test",
        label: "Drug Test (2 Panels)",
        value: "Drug Test (2 Panels)",
      },

      {
        department: "Drug Test",
        label: "Drug Test (3 Panels)",
        value: "Drug Test (3 Panels)",
      },

      {
        department: "Drug Test",
        label: "Drug Test (5 Panels)",
        value: "Drug Test (5 Panels)",
      },
    ];

    return orders;
  };
}
