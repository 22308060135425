 
import React, { Fragment, useEffect, useRef } from 'react'   

var Peer = require('simple-peer')  


const VirtualCall  = () =>{
   

    const outgoing  = useRef(null)
    const incoming = useRef(null)

    const p = new Peer({
        initiator: window.location.hash === '#start',
        trickle: false,
        config: { 
            'iceServers': [
                {
                  'url': 'stun:stun.l.google.com:19302'
                },
                {
                  'url': 'turn:192.158.29.39:3478?transport=udp',
                  'credential': 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
                  'username': '28224511:1379330808'
                },
                {
                  'url': 'turn:192.158.29.39:3478?transport=tcp',
                  'credential': 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
                  'username': '28224511:1379330808'
                }
            ]
        },
    })

    useEffect(() => {
        
    
        p.on('error', err => console.log('error', err))
     
        p.on('signal', data => { 
            outgoing.current.value = JSON.stringify(data) 
        })
    
        // document.querySelector('form').addEventListener('submit', ev => {
        //     ev.preventDefault()
        //     p.signal(JSON.parse(document.querySelector('#incoming').value))
        // })
    
        p.on('connect', () => {
            console.log('CONNECT')
            p.send('whatever' + Math.random())
        })
    
        p.on('data', data => {
            console.log('data: ' + data)
        })
    },[p])
    
    const sampleProcess = () =>{    
        p.signal(JSON.parse(incoming.current.value))
    }
 
    return (
        <Fragment>    
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /> 
            host
            <textarea id="incoming" ref={ outgoing }></textarea>
 
            client
                <textarea id="incoming" ref={ incoming }></textarea>
                <button type="submit" onClick={ sampleProcess }>submit</button> 
        </Fragment>
    )

}

export default VirtualCall;
