import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  Button,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Notify from "../../../notification/Notify";
import Select from "react-select";
import { Typography, Box, IconButton } from "@material-ui/core";
import { Keyboard } from "@material-ui/icons";

export default class PrescriptionLocal extends Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      _user_id: localStorage.getItem("user_id"),
      _token: localStorage.getItem("token"),
      _management_id: localStorage.getItem("management_id"),
      _type: localStorage.getItem("type"),
      _username: localStorage.getItem("username"),
      _patient_id: this.props.patient_id,

      _pharmacy: [],
      _pharmacy_ready: false,

      _product: [],
      _product_ready: false,

      _selected_prod_qty: 0,
      _selected_prod_type: "",
      _selected_prod_name: "",
      _selected_prod_amount: 0,

      _unsave_product_count: 0,
      _is_process: false,
      isSearchable: false,
      _category: "prescription-local",
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.getProducts();
    this.getPharmacy();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate() {
    if (this.state._patient_id !== this.props.patient_id) {
      this.setState({
        _patient_id: this.props.patient_id,
      });
      this.componentDidMount();
    }
  }

  getPharmacy() {
    var formdata = new FormData();
    formdata.set("token", this.state._token);
    formdata.set("user_id", this.state._user_id);
    formdata.set("management_id", this.state._management_id);

    Axios.post("prescription/local/getLocalPharmacy", formdata)
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            this.setState({
              _pharmacy: data,
              _pharmacy_ready: true,
            });
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  getProducts() {
    var formdata = new FormData();
    formdata.set("token", this.state._token);
    formdata.set("user_id", this.state._user_id);
    formdata.set("management_id", this.state._management_id);

    Axios.post("prescription/local/product-list", formdata)
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            this.setState({
              _product: data,
              _product_ready: true,
            });
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  handleSelectedProd(e) {
    // e.value
    var formdata = new FormData();
    formdata.set("token", this.state._token);
    formdata.set("user_id", this.state._user_id);
    formdata.set("product_id", e.value);

    Axios.post("prescription/local/product-details", formdata)
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            if (data.length > 0) {
              this.setState({
                _selected_prod_qty: data[0].available_qty,
                _selected_prod_type: data[0].unit,
                _selected_prod_name: data[0].product_name,
                _selected_prod_amount: data[0].product_amount,
              });
            }
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  addToPrecription = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("token", this.state._token);
    formdata.set("user_id", this.state._user_id);
    formdata.set("management_id", this.state._management_id);
    formdata.set("patient_id", this.props.patient_id);
    formdata.set("product_id", e.value);
    formdata.set("prescription_type", "clinic");

    if (
      formdata.get("prescription").length === 0 ||
      formdata.get("prescription").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("prescription");
    }

    if (
      formdata.get("order_qty").length === 0 ||
      formdata.get("order_qty").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("order qty");
    } else {
      if (
        parseInt(formdata.get("order_qty")) >
        parseInt(formdata.get("available_qty"))
      ) {
        error = "error";
        Notify.fieldInvalid("order qty");
      }
    }

    if (
      formdata.get("dosage").length === 0 ||
      formdata.get("dosage").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("dosage");
    }

    // if(formdata.get('take_every').length === 0 || formdata.get('take_every').trim()===''){
    //     error = 'error';
    //     Notify.fieldRequired('every take');
    // }

    // if(formdata.get('take_times').length === 0 || formdata.get('take_times').trim()===''){
    //     error = 'error';
    //     Notify.fieldRequired('take times');
    // }

    if (error.length > 0) {
      Notify.consoleLog("add product");
    } else {
      this.setState({
        _is_process: true,
      });
      Axios.post("prescription/local/product-add", formdata)
        .then(
          function (response) {
            const data = response.data;
            if (this._mounted) {
              this.setState({
                _is_process: false,
              });
              if (data === "success") {
                e.target.reset();
                this.props.getUnsavePrescription();
                Notify.successRequest("add product");
              } else {
                Notify.warnRequest("add product");
              }
            }
          }.bind(this)
        )
        .catch((error) => {
          this.setState({
            _is_process: false,
          });
          Notify.requestError(error);
        });
    }
  };

  render() {
    return (
      <Fragment>
        <Box mb={1}>
          <Typography variant="h6" color="textSecondary">
            Create Clinic Prescription
          </Typography>
        </Box>
        {this.state._pharmacy_ready ? (
          this.state._pharmacy.length > 0 ? (
            <Form onSubmit={this.addToPrecription}>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      Pharmacy
                    </span>
                    <FormControl
                      type="text"
                      name="pharmacy_id"
                      defaultValue={this.state._pharmacy[0].value}
                      hidden
                    />
                    <FormControl
                      isValid={true}
                      defaultValue={this.state._pharmacy[0].label}
                      readOnly
                    />
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <Box mb={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="caption" color="textSecondary">
                          PRESCRIPTION
                        </Typography>
                      </Box>

                      <Box>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              isSearchable: !this.state.isSearchable,
                            });
                          }}
                          size="small"
                          color={
                            this.state.isSearchable ? "primary" : "default"
                          }
                        >
                          <Keyboard />
                        </IconButton>
                      </Box>
                    </Box>

                    <Select
                      name="prescription"
                      options={this.state._product}
                      onChange={this.handleSelectedProd.bind(this)}
                      isSearchable={this.state.isSearchable}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                          color: "black",
                        }),
                        control: (base) => ({
                          ...base,
                          minHeight: 55,
                        }),
                      }}
                    />
                  </Box>
                </Col>

                <Col sm={4}>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      {" "}
                      order qty{" "}
                    </span>
                    <FormControl type="number" min="1" name="order_qty" />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      {" "}
                      available qty{" "}
                    </span>
                    <FormControl
                      name="available_qty"
                      value={this.state._selected_prod_qty}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      {" "}
                      Type{" "}
                    </span>
                    <FormControl
                      name="type"
                      value={this.state._selected_prod_type}
                      readOnly
                    />
                    <FormControl
                      name="product_name"
                      value={this.state._selected_prod_name}
                      readOnly
                      hidden
                    />
                    <FormControl
                      name="product_amount"
                      value={this.state._selected_prod_amount}
                      readOnly
                      hidden
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      {" "}
                      dosage{" "}
                    </span>
                    <FormControl name="dosage" />
                  </FormGroup>
                </Col>
                <Col sm={4} hidden>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      {" "}
                      Take Every{" "}
                    </span>
                    <FormControl name="take_every" />
                  </FormGroup>
                </Col>
                <Col sm={4} hidden>
                  <FormGroup>
                    <span className="gtc-small text-uppercase text-muted">
                      {" "}
                      Times{" "}
                    </span>
                    <FormControl name="take_times" />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <span className="gtc-small text-uppercase text-muted">
                  {" "}
                  Sig.{" "}
                </span>
                <FormControl name="remarks" />
              </FormGroup>

              {/* <FormGroup>
                                    <span className="gtc-small text-uppercase text-muted"> brand </span>
                                    <FormControl name="brand" as="select">
                                        <option value=""> --Select-- </option>
                                        <option value="FK">FK</option>
                                        <option value="BBRAUN">BRAUN</option>
                                    </FormControl>
                                </FormGroup> */}

              <p className="m-0 mb-2 text-muted">
                {" "}
                Are you sure to continue adding this item?{" "}
              </p>
              <Button
                type="submit"
                variant="success"
                disabled={this.state._is_process ? true : false}
              >
                {" "}
                <FontAwesomeIcon
                  icon={this.state._is_process ? faSpinner : faCheckCircle}
                  spin={this.state._is_process ? true : false}
                />{" "}
                Add{" "}
              </Button>
              <Button type="reset" variant="secondary ml-2">
                {" "}
                <FontAwesomeIcon icon={faTimesCircle} /> No{" "}
              </Button>
              <Button
                type="button"
                variant="primary ml-2"
                onClick={() => this.props.displayUnsave()}
              >
                {" "}
                <Badge variant="danger"> {this.props.unsaveCount} </Badge>{" "}
                Unsave Prescription{" "}
              </Button>
            </Form>
          ) : (
            <div className="mt-3">
              {Notify.noRecord()}
              <p className="text-danger text-center"> No pharmacy available </p>
            </div>
          )
        ) : (
          Notify.loading()
        )}
      </Fragment>
    );
  }
}
