import React, { Fragment, useState, useEffect } from 'react'
import { TextField, Box, Typography, Grid, Select, MenuItem, FormControl, InputLabel, Button, IconButton, CircularProgress  } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff'; 
import Axios from 'axios';
import Notify from '../../notification/Notify';

const PersonalHistoryFamilyForm = ({ cancel, getHistories }) =>{
    const token = localStorage.getItem('token')
    const username = localStorage.getItem('username')
    const user_id = localStorage.getItem('user_id')

    const [isupdated, setIsupdated] = useState(1)
    const [father_isalive, setFatherIsAlive] = useState('Alive')
    const [mother_isalive, setMotherIsAlive] = useState('Alive')
    
    const [isProcess, setIsProcess] = useState(false) 
    
    const [childrens, setChildren] = useState([ 0 ])
      

    const handleHistory = (e) => { 
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('username', username)  
            formdata.set('childrens', childrens.length)

        var error = []; 
        
        // fathers forms
        if(formdata.get('father_fullname').length === 0 || formdata.get('father_fullname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('father_fullname'); 
        }

        if(formdata.get('father_address').length === 0 || formdata.get('father_address').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('father_address'); 
        }

        if(formdata.get('father_birthday').length === 0 || formdata.get('father_birthday').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('father_birthday'); 
        }
        
        if(formdata.get('father_occupation').length === 0 || formdata.get('father_occupation').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('father_occupation'); 
        }

        if(formdata.get('father_healthstatus').length === 0 || formdata.get('father_healthstatus').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('father_healthstatus'); 
        }
        
        if(formdata.get('father_isalive').length === 0 || formdata.get('father_isalive').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('father_isalive'); 
        }
        
        // mothers forms
        if(formdata.get('mother_fullname').length === 0 || formdata.get('mother_fullname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mother_fullname'); 
        }

        if(formdata.get('mother_address').length === 0 || formdata.get('mother_address').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mother_address'); 
        }

        if(formdata.get('mother_birthday').length === 0 || formdata.get('mother_birthday').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mother_birthday'); 
        }
        
        if(formdata.get('mother_occupation').length === 0 || formdata.get('mother_occupation').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mother_occupation'); 
        }

        if(formdata.get('mother_healthstatus').length === 0 || formdata.get('mother_healthstatus').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mother_healthstatus'); 
        }
        
        if(formdata.get('mother_isalive').length === 0 || formdata.get('mother_isalive').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mother_isalive'); 
        }

        for (let i = 0; i < childrens.length; i++) {   
            var childnumber  = parseInt(i) + 1;
            if(formdata.get('children_fullname['+i+']').length === 0 || formdata.get('children_fullname['+i+']').trim()===''){ 
                error = 'error';
                Notify.fieldRequired('children '+ childnumber +' fullname'); 
            }

            if(formdata.get('children_address['+i+']').length === 0 || formdata.get('children_address['+i+']').trim()===''){ 
                error = 'error';
                Notify.fieldRequired('children '+ childnumber +' address'); 
            }

            if(formdata.get('children_birthday['+i+']').length === 0 || formdata.get('children_birthday['+i+']').trim()===''){ 
                error = 'error';
                Notify.fieldRequired('children '+ childnumber +' birthday'); 
            }

            if(formdata.get('children_occupation['+i+']').length === 0 || formdata.get('children_occupation['+i+']').trim()===''){ 
                error = 'error';
                Notify.fieldRequired('children '+ childnumber +' occupation'); 
            }

            if(formdata.get('children_isalive['+i+']').length === 0 || formdata.get('children_isalive['+i+']').trim()===''){ 
                error = 'error';
                Notify.fieldRequired('children '+ childnumber +'is alive'); 
            }

            if(formdata.get('children_healthstatus['+i+']').length === 0 || formdata.get('children_healthstatus['+i+']').trim()===''){ 
                error = 'error';
                Notify.fieldRequired('children '+ childnumber +' health status'); 
            }
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            console.warn("Form has an error. ")
        }else{
            setIsProcess(true)
            Axios.post('patient/information/history/family-savehistory', formdata )
            .then((response) => { 
                const data = response.data;   
                if(data === 'success'){
                    Notify.successRequest('family history')
                    e.target.reset();
                    cancel()
                    getHistories()
                }  
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                } 
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() =>{
                setIsProcess(false)
            });
        }
        
    }

    const addChild = () =>{  
        setChildren(childrens.concat(childrens.length > 0 ? (childrens.length - 1)  + 1 : 1))
        setIsupdated(isupdated + 1) 
    }
    
    const removeChild = (index) => {    
        var newchildrens = childrens.indexOf(index)
        childrens.splice(newchildrens, 1)  
        setIsupdated(isupdated + 1)
    } 
    
    useEffect(() =>{ 
        
    },[isupdated])

    return(
        <Fragment>    
            <form onSubmit={ handleHistory }> 
                {/* fathers information */}
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}>
                        <Box my={1}>
                            <Typography color="primary" variant="subtitle2"> Father Information </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={8} md={9}>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Fullname" 
                                            name="father_fullname"
                                        /> 
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Address" 
                                            name="father_address"
                                            multiline
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box> 

                        <Box my={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box>  
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Birthday" 
                                            name="father_birthday"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />  
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Occupation" 
                                            name="father_occupation"
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box my={1}> 
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={6}>
                                    <Box> 
                                        <FormControl 
                                            variant="outlined" 
                                            margin="dense" 
                                            fullWidth
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">Is Alive</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={ father_isalive }
                                                onChange={(e) => setFatherIsAlive(e.target.value)}
                                                label="Is Alive"
                                                name="father_isalive"
                                            > 
                                                <MenuItem value={'Alive'}>Alive</MenuItem>
                                                <MenuItem value={'Deceased'}>Deceased</MenuItem> 
                                            </Select>
                                        </FormControl>
                                        
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined"
                                            label="Health Status"
                                            name="father_healthstatus"
                                            multiline
                                        />
                                    </Box> 
                                </Grid> 
                            </Grid>
                        </Box> 

                        <Box my={1} hidden={ father_isalive === 'Alive' ? true : false }>
                            <Grid container spacing={3}>  
                                <Grid item xs={12} sm={6}>
                                    <Box>  
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Is Deceased Date" 
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="father_isdeceaseddate"
                                        /> 
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Is Deceased Reason" 
                                            name="father_isdeceasedreason"
                                            multiline
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                {/* mothers information */}
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}>
                        <Box my={1}>
                            <Typography color="primary" variant="subtitle2"> Mother Information </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Fullname" 
                                            name="mother_fullname"
                                        /> 
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Address" 
                                            name="mother_address"
                                            multiline
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box> 

                        <Box my={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box> 
                                        <TextField
                                            fullWidth 
                                            variant="outlined"
                                            margin="dense" 
                                            label="Birthday"  
                                            name="mother_birthday"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />  
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Occupation" 
                                            name="mother_occupation"
                                        /> 
                                    </Box>
                                </Grid> 
                            </Grid>
                        </Box>

                        <Box my={1}> 
                            <Grid container spacing={3}> 
                                <Grid item xs={6} sm={6}>
                                    <Box> 
                                        <FormControl 
                                            variant="outlined" 
                                            margin="dense" 
                                            fullWidth
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">Is Alive</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={ mother_isalive }
                                                onChange={(e) => setMotherIsAlive(e.target.value)}
                                                label="Is Alive"
                                                name="mother_isalive"
                                            > 
                                                <MenuItem value={'Alive'}>Alive</MenuItem>
                                                <MenuItem value={'Deceased'}>Deceased</MenuItem> 
                                            </Select>
                                        </FormControl> 
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField 
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        label="Health Status"
                                        name="mother_healthstatus"
                                        multiline
                                    /> 
                                </Grid>
                            </Grid> 
                        </Box> 

                        <Box my={1} hidden={ mother_isalive === 'Alive' ? true : false }>
                            <Grid container spacing={3}>  
                                <Grid item xs={12} sm={6}>
                                    <Box> 
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Is Deceased Date" 
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="mother_isdeceaseddate"
                                        /> 
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            margin="dense"
                                            fullWidth
                                            variant="outlined" 
                                            label="Is Deceased Reason" 
                                            name="mother_isdeceasedreason"
                                            multiline
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid> 

                {/* Childers */} 
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}> 
                        <Box display="flex">
                            <Box  mt={1} flexGrow={1}>
                                <Typography color="primary" variant="subtitle2"> Children Information </Typography>
                            </Box>
                            <Box>
                                <IconButton 
                                    color="primary"
                                    onClick = { addChild }
                                >
                                    <AddCircleIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>  
                    </Grid>  
                    <Grid item xs={12} sm={8} md={9}>
                        {
                            childrens.map((data, index)=>(
                                <Fragment key={index}> 
                                    <Box>
                                        <Box display="flex">
                                            <Box flexGrow={1} mt={1}>
                                                <Typography variant="subtitle2"> Children { index + 1 } </Typography>
                                            </Box>
                                            <Box hidden={ data < 1 ? true  : false }>
                                                <IconButton 
                                                    color="primary" 
                                                    onClick = { index < 1 ? (e)=> e.preventDefault() : () => removeChild(index) }
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        margin="dense"
                                                        fullWidth
                                                        variant="outlined" 
                                                        label="Fullname" 
                                                        name={'children_fullname['+index+']'}
                                                    /> 
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        margin="dense"
                                                        fullWidth
                                                        variant="outlined" 
                                                        label="Address"  
                                                        name={'children_address['+index+']'}
                                                        multiline
                                                    /> 
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box my={1}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        margin="dense"
                                                        fullWidth
                                                        variant="outlined" 
                                                        label="Birthday"  
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        name={'children_birthday['+index+']'}
                                                    /> 
                                                </Box>
                                            </Grid> 
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        margin="dense"
                                                        fullWidth
                                                        variant="outlined" 
                                                        label="Occupation"  
                                                        name={'children_occupation['+index+']'}
                                                    /> 
                                                </Box>
                                            </Grid>  
                                        </Grid>
                                    </Box>

                                    <Box my={1}>
                                        <Grid container spacing={3}> 
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <FormControl 
                                                        variant="outlined" 
                                                        margin="dense" 
                                                        fullWidth
                                                    > 
                                                        <select  
                                                            className="form-control" 
                                                            label="Is Alive" 
                                                            name={'children_isalive['+index+']'} 
                                                        > 
                                                            <option value={'Alive'}>Alive</option>
                                                            <option value={'Deceased'}>Deceased</option> 
                                                        </select>
                                                    </FormControl> 
                                                </Box>
                                            </Grid> 
                                            <Grid item xs={12} sm={6}>
                                                <TextField 
                                                    margin="dense"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Health Status" 
                                                    name={'children_healthstatus['+index+']'}
                                                    multiline
                                                /> 
                                            </Grid>
                                        </Grid> 
                                    </Box> 

                                    <Box my={1}>
                                        <Grid container spacing={3}>  
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        margin="dense"
                                                        fullWidth
                                                        variant="outlined" 
                                                        label="Is Deceased Date"  
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        name={'children_deceased_date['+index+']'} 
                                                    />  
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        margin="dense"
                                                        fullWidth
                                                        variant="outlined" 
                                                        label="Is Deceased Reason" 
                                                        name={'children_deceased_reason['+index+']'}
                                                        multiline
                                                    /> 
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>  
                                </Fragment>
                            ))
                        }
                        
                        <Box mb={2}>
                            <TextField
                                margin="dense"
                                fullWidth
                                variant="outlined" 
                                label="Password"
                                type="password" 
                                name={'password'}
                            /> 
                        </Box>
                    </Grid> 
                </Grid>   
                
                <Box display="flex"> 
                    <Box flexGrow={1} />  
                    <Button    
                        startIcon={<HighlightOffIcon />} 
                        color="inherit" 
                        variant="contained"  
                        onClick={ cancel }
                    >
                        No
                    </Button>  
                    <Box ml={2}> 
                        <Button  
                            startIcon={ isProcess ? <CircularProgress color="inherit" size={15} /> : <CheckCircleOutline /> }
                            disabled={ isProcess } 
                            color="primary" 
                            variant="contained" 
                            type="submit"
                        >
                            Save
                        </Button> 
                    </Box>
                </Box>
            </form>
        </Fragment>
    )
}

export default PersonalHistoryFamilyForm;