import {
  Box,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { CheckSquare, XCircle } from "react-feather";

const ClinicalMicroscopyOrderDetails = ({
  order,
  getLabCliniclMicroscopyOrder,
  resetDisplay,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");

  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [processDialog, setProcessDialog] = useState({
    open: false,
    data: null,
  });

  const saveButtonRef = React.useRef();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);

  const getLabCMicroscopyOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("trace_number", order.trace_number);
    Axios.post("laboratory/order/ordernew-clinicalmicroscopy/details", formdata)
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });
        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getLabCMicroscopyOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("trace_number", order.trace_number);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setResultSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-clinicalmicroscopy/save-process-result",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added.");
            setSavebtnDisabled(true);
            setTimeout(() => {
              history.push(
                `/clinic/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handleProcessOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("order_id", processDialog.data.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setProcessSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-clinicalmicroscopy/save-setprocessing",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabCliniclMicroscopyOrder();
            getLabCMicroscopyOrderDetails();
            setProcessDialog({ open: false, data: null });
            Notify.successRequest("order processed");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setProcessSubmitting(false));
    }
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <CardHeader
            component={Box}
            align="center"
            title={
              <Box>
                <Typography variant="h5">
                  <b> LABORATORIO MEDICO DE CALIDAD </b>
                </Typography>
                <Typography>With</Typography>
                <Typography variant="h6">
                  <b> DR. MELY R. PENAFLORIDA-LASTIMOSO MD FPSV </b>
                </Typography>
              </Box>
            }
            subheader={`General Medicine & Treats SEXUALLY TRANSMITTED DISEASES Including HIV & AIDS and other venereal diseases
                        2/F Room 4&5 VENCER building National Highway 
                         General Santos City`}
          />
          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      NAME:
                    </Typography>
                    {`${order.firstname} ${order.lastname}`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        SEX:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      ADDRESS:
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:
                    </Typography>
                    {Notify.dateTimeConvert(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            {/* order details */}
            <Box>
              <Typography variant="h6" align="center">
                <b> CLINICAL MICROSCOPY </b>
              </Typography>
            </Box>

            <Box>
              {orderDetails.ready
                ? orderDetails.data.length > 0
                  ? orderDetails.data.map((data, key) => (
                      <Fragment key={key}>
                        {/* hidden files */}
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="order_id[]"
                            label="order_id"
                            hidden
                            value={data.order_id}
                          />
                          <TextField
                            margin="dense"
                            fullWidth
                            name="patient_id"
                            label="patient_id"
                            hidden
                            value={data.patient_id}
                          />

                          <TextField
                            margin="dense"
                            fullWidth
                            name="doctors_id"
                            label="doctor_id"
                            hidden
                            value={
                              data.doctor_id === null ? "" : data.doctor_id
                            }
                          />
                        </Box>

                        {key === 0 && (
                          <Box mb={1}>
                            <Typography variant="caption">
                              <b> SPECIMEN: </b>
                            </Typography>
                            <span className="gtc-uppercase">
                              {data.spicemen}
                            </span>
                          </Box>
                        )}

                        <Box
                          mb={2}
                          hidden={!Boolean(parseInt(data.chemical_test))}
                        >
                          {/* clinical microscopy */}
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography variant="subtitle1">
                                <b> CHEMICAL TEST </b>
                              </Typography>
                            </Box>

                            <Box>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box ml={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <Box display="flex">
                                    <Box
                                      mt={2}
                                      mr={1}
                                      flexGrow={1}
                                      align="right"
                                    >
                                      <Typography variant="caption">
                                        <b> COLOR: </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        label="Result"
                                        name="color[]"
                                        margin="dense"
                                        variant="outlined"
                                        disabled={
                                          Boolean(parseInt(order.is_pending)) ||
                                          !Boolean(
                                            parseInt(order.is_processed)
                                          ) ||
                                          resultSubmitting
                                        }
                                      />
                                    </Box>
                                  </Box>
                                  <Box display="flex">
                                    <Box
                                      mt={2}
                                      mr={1}
                                      flexGrow={1}
                                      align="right"
                                    >
                                      <Typography variant="caption">
                                        <b> TRANPARENCY: </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        label="Result"
                                        name="transparency[]"
                                        margin="dense"
                                        variant="outlined"
                                        disabled={
                                          Boolean(parseInt(order.is_pending)) ||
                                          !Boolean(
                                            parseInt(order.is_processed)
                                          ) ||
                                          resultSubmitting
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <Box display="flex">
                                    <Box
                                      mt={2}
                                      mr={1}
                                      flexGrow={1}
                                      align="right"
                                    >
                                      <Typography variant="caption">
                                        <b> PH: </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        label="Result"
                                        name="ph[]"
                                        margin="dense"
                                        variant="outlined"
                                        disabled={
                                          Boolean(parseInt(order.is_pending)) ||
                                          !Boolean(
                                            parseInt(order.is_processed)
                                          ) ||
                                          resultSubmitting
                                        }
                                      />
                                    </Box>
                                  </Box>
                                  <Box display="flex">
                                    <Box
                                      mt={2}
                                      mr={1}
                                      flexGrow={1}
                                      align="right"
                                    >
                                      <Typography variant="caption">
                                        <b> SPECIFIC GRAVITY: </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        label="Result"
                                        name="specific_gravity[]"
                                        margin="dense"
                                        variant="outlined"
                                        disabled={
                                          Boolean(parseInt(order.is_pending)) ||
                                          !Boolean(
                                            parseInt(order.is_processed)
                                          ) ||
                                          resultSubmitting
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <Box display="flex">
                                    <Box
                                      mt={2}
                                      mr={1}
                                      flexGrow={1}
                                      align="right"
                                    >
                                      <Typography variant="caption">
                                        <b> GLUCOSE: </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        label="Result"
                                        name="glucose[]"
                                        margin="dense"
                                        variant="outlined"
                                        disabled={
                                          Boolean(parseInt(order.is_pending)) ||
                                          !Boolean(
                                            parseInt(order.is_processed)
                                          ) ||
                                          resultSubmitting
                                        }
                                      />
                                    </Box>
                                  </Box>
                                  <Box display="flex">
                                    <Box
                                      mt={2}
                                      mr={1}
                                      flexGrow={1}
                                      align="right"
                                    >
                                      <Typography variant="caption">
                                        <b> ALBUMIN: </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        label="Result"
                                        name="albumin[]"
                                        margin="dense"
                                        variant="outlined"
                                        disabled={
                                          Boolean(parseInt(order.is_pending)) ||
                                          !Boolean(
                                            parseInt(order.is_processed)
                                          ) ||
                                          resultSubmitting
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Box>

                        <Box
                          mb={2}
                          hidden={!Boolean(parseInt(data.microscopic_test))}
                        >
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography variant="subtitle1">
                                <b> MICROSCOPIC TEST </b>
                              </Typography>
                            </Box>

                            <Box>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box ml={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                  <>
                                    <Box mt={2}>
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                      >
                                        CELLS
                                      </Typography>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                        mt={2}
                                      >
                                        <Typography variant="caption">
                                          <b> SQUAMOUS CELLS: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="squamous[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> PUS CELLS: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="pus"
                                          margin="dense"
                                          variant="outlined"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                /HPF
                                              </InputAdornment>
                                            ),
                                          }}
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> RED BLOOD CELLS: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="redblood[]"
                                          margin="dense"
                                          variant="outlined"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                /HPF
                                              </InputAdornment>
                                            ),
                                          }}
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </>

                                  <>
                                    <Box mt={2}>
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                      >
                                        CASTS
                                      </Typography>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                        mt={2}
                                      >
                                        <Typography
                                          variant="caption"
                                          className={`gtc-uppercase`}
                                        >
                                          <b> Hyaline Cast: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="hyaline[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography
                                          variant="caption"
                                          className={`gtc-uppercase`}
                                        >
                                          <b> WBC Cast: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="wbc_cast"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography
                                          variant="caption"
                                          className={`gtc-uppercase`}
                                        >
                                          <b> RBC Cast: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="rbc_cast[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography
                                          variant="caption"
                                          className={`gtc-uppercase`}
                                        >
                                          <b> Fine Granualar Cast: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="fine_granualar[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography
                                          variant="caption"
                                          className={`gtc-uppercase`}
                                        >
                                          <b> Coarse Granualar Cast: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="coarse_granualar[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                  <>
                                    <Box mt={2}>
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                      >
                                        CRYSTALS
                                      </Typography>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                        mt={2}
                                      >
                                        <Typography variant="caption">
                                          <b> CALCIUM OXALATE: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="crystal_oxalate[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> TRIPLE PHOSPHATE: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="triple_phosphate[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> LEUCINE/TYROCINE: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="leucine_tyrocine[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> AMMONIUME BIURATE: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="ammoniume[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> AMORPHOUS URATES: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="amorphous_urates[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> AMORPHOUS PHOSPHATES: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="amorphous_phosphate[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> URIC ACID: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="uric_acid[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                  <>
                                    <Box mt={2}>
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                      >
                                        OTHERS
                                      </Typography>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> MUCUS THREAD: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="mucus_thread[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> BACTERIA: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="bacteria[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> YEAST: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="yeast[]"
                                          margin="dense"
                                          variant="outlined"
                                          disabled={
                                            Boolean(
                                              parseInt(order.is_pending)
                                            ) ||
                                            !Boolean(
                                              parseInt(order.is_processed)
                                            ) ||
                                            resultSubmitting
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Box>

                        <Box
                          mb={2}
                          hidden={!Boolean(parseInt(data.pregnancy_test_hcg))}
                        >
                          {/* clinical pregnancy */}
                          <>
                            <Box display="flex">
                              <Box flexGrow={1}>
                                <Typography variant="subtitle1">
                                  <b> PREGNANCY TEST (HCG) </b>
                                </Typography>
                              </Box>

                              <Box>
                                <Button
                                  disabled={Boolean(
                                    parseInt(data.is_processed)
                                  )}
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setProcessDialog({
                                      data: data,
                                      open: true,
                                    });
                                  }}
                                >
                                  Process
                                </Button>
                              </Box>
                            </Box>
                            {Boolean(parseInt(data.is_processed)) && (
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="pregnancy_test[]"
                                  margin="dense"
                                  variant="outlined"
                                  multiline
                                  rows={3}
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            )}
                          </>
                        </Box>

                        <Box
                          mb={2}
                          hidden={!Boolean(parseInt(data.seminalysis_test))}
                        >
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography variant="subtitle1">
                                <b> SEMINALYSIS TEST </b>
                              </Typography>
                            </Box>

                            <Box>
                              <Box>
                                <Button
                                  disabled={Boolean(
                                    parseInt(data.is_processed)
                                  )}
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setProcessDialog({
                                      data: data,
                                      open: true,
                                    });
                                  }}
                                >
                                  Process
                                </Button>
                              </Box>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box mt={1}>
                              <Box>
                                <TextField
                                  label="Result"
                                  fullWidth
                                  name="seminalysis_test_result[]"
                                  multiline
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>

                        <Box
                          mb={2}
                          hidden={!Boolean(parseInt(data.grams_stain))}
                        >
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography variant="subtitle1">
                                <b> GRAMS STAIN TEST </b>
                              </Typography>
                            </Box>

                            <Box>
                              <Box>
                                <Button
                                  disabled={Boolean(
                                    parseInt(data.is_processed)
                                  )}
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setProcessDialog({
                                      data: data,
                                      open: true,
                                    });
                                  }}
                                >
                                  Process
                                </Button>
                              </Box>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box mt={1}>
                              <Box>
                                <TextField
                                  label="Result"
                                  fullWidth
                                  name="grams_stain_result[]"
                                  multiline
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>

                        <Box mb={2} hidden={!Boolean(parseInt(data.afb_stain))}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography variant="subtitle1">
                                <b> AFB STAIN TEST </b>
                              </Typography>
                            </Box>

                            <Box>
                              <Box>
                                <Button
                                  disabled={Boolean(
                                    parseInt(data.is_processed)
                                  )}
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setProcessDialog({
                                      data: data,
                                      open: true,
                                    });
                                  }}
                                >
                                  Process
                                </Button>
                              </Box>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box mt={1}>
                              <Box>
                                <TextField
                                  label="Result"
                                  fullWidth
                                  name="afb_stain_result[]"
                                  multiline
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Fragment>
                    ))
                  : "Order not found."
                : "please wait..."}
            </Box>
          </CardContent>
          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  hidden
                  ref={saveButtonRef}
                  disabled={!confirmDialog}
                >
                  Save Result Button
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setConfirmDialog(true)}
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.open}
        onClose={() => setProcessDialog({ open: false, data: null })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as processing</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ open: false, data: null })}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogContent>
          <Typography>Are you sure to continue and save result?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setConfirmDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={resultSubmitting}
            startIcon={
              resultSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => saveButtonRef.current.click()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClinicalMicroscopyOrderDetails;
