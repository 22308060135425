import React, { Fragment } from "react";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  DialogContent,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Notify from "../../notification/Notify";
import Axios from "axios";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router-dom";

function PaymentForm({ purchaseData, fetchPuchaseList, closeModal }) {
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const pharmacy_id = localStorage.getItem("pharmacy_id");
  const receipt_id = Math.floor(Math.random() * 999999) + 1;
  const history = useHistory();

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("receipt_id", receipt_id);
    formData.append("totalCost", purchaseData.data[0].sumAllTotalCost);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box px={2}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> PRODUCT </TableCell>
                      <TableCell> QTY </TableCell>
                      <TableCell> PRICE </TableCell>
                      <TableCell> AMOUNT </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchaseData.ready ? (
                      purchaseData.data.length > 0 ? (
                        purchaseData.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell> {data.product} </TableCell>
                            <TableCell align="right">
                              {data.purchase_quantity}
                            </TableCell>
                            <TableCell align="right">{data.price}</TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(data.total)}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography variant="subtitle2" color="secondary">
                              No purchase added
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="subtitle2" color="primary">
                            loading...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={3} align="right">
                        <strong> TOTAL COST: </strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>
                          {Notify.convertToNumber(
                            purchaseData.data[0].sumAllTotalCost
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box>
              <Formik
                initialValues={{
                  username: username,
                  user_id: user_id,
                  management_id: management_id,
                  pharmacy_id: pharmacy_id,
                  client_name: "",
                  client_add: "",
                  amount_paid: "",
                  client_tin: "",
                  remarks_payment: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  client_name: Yup.string().required(),
                  amount_paid: Yup.number().required(),
                  password: Yup.string().required(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  if (
                    parseInt(values.amount_paid) <
                    parseInt(purchaseData.data[0].sumAllTotalCost)
                  ) {
                    setErrors({ amount_paid: "Payment is invalid" });
                  } else {
                    try {
                      const request = await Axios.post(
                        "pharmacy/confirm-payment",
                        getFormData(values)
                      );
                      if (request.data === "pass-invalid") {
                        setErrors({ password: "Password is invalid." });
                        Notify.fieldInvalid("password");
                      }
                      if (request.data === "success") {
                        Notify.successRequest("confirm purchase");
                        fetchPuchaseList();
                        resetForm();
                        closeModal();
                        history.push(`/clinic/app/receipt/${receipt_id}`);
                      }
                    } catch (error) {
                      const message = error.message || "Something went wrong";
                      setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              touched.client_name && errors.client_name
                            )}
                            helperText={
                              touched.client_name && errors.client_name
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.client_name}
                            name="client_name"
                            label="Client Name"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              touched.amount_paid && errors.amount_paid
                            )}
                            helperText={
                              touched.amount_paid && errors.amount_paid
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount_paid}
                            name="amount_paid"
                            label="Amount Paid"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              touched.client_add && errors.client_add
                            )}
                            helperText={touched.client_add && errors.client_add}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.client_add}
                            name="client_add"
                            label="Client Address"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              touched.remarks_payment && errors.remarks_payment
                            )}
                            helperText={
                              touched.remarks_payment && errors.remarks_payment
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.remarks_payment}
                            name="remarks_payment"
                            label="Remarks"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              touched.client_tin && errors.client_tin
                            )}
                            helperText={touched.client_tin && errors.client_tin}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.client_tin}
                            name="client_tin"
                            label="Client TIN"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Box mb={2}>
                          <TextField
                            fullWidth
                            required
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            label="Enter your password"
                            variant="outlined"
                            type="password"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        size="large"
                        variant="contained"
                        color="default"
                        onClick={() => closeModal()}
                        startIcon={<ClearIcon />}
                      >
                        Close
                      </Button>

                      <Box ml={2}>
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<CheckIcon />}
                          disabled={isSubmitting}
                        >
                          Confirm
                        </Button>{" "}
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment>
  );
}

export default PaymentForm;
