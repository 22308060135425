import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./fonts/Montserrat-Regular.ttf";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "cropperjs/dist/cropper.css";
import DateFnsUtils from "@date-io/date-fns";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<App />
	</MuiPickersUtilsProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
