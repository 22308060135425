import Notify from "./notification/Notify";

export default class MediaHandler{
	getPermissions(){
        return new Promise((res, rej)=>{
            navigator.mediaDevices.getUserMedia({video:true, audio: true})
                .then((stream)=>{
                    res(stream);
                }).catch(err =>{
                    // throw new Error('Unable to fetch stream', err);
                    Notify.customToast('Stream Error', 'Please enable your microphone and camera.')
                    console.log(err) 
                    setTimeout(() => {
                        // window.location.href = '/streamerror'
                    }, 3000);
                })
        });
    }
}
