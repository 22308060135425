import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Container from "src/layout/Container";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import Notify from "src/notification/Notify";
import List from "./List";
import IsMountedRef from "src/utils/IsMountedRef";

const PharmacyWarehouseProducts = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const mounted = IsMountedRef();
  const [product, setProduct] = useState({ data: [], ready: false });

  const [dialog, setDialog] = useState(false);

  const getProductList = useCallback(async () => {
    let response = await Axios.get("pharmacy/warehouse/get-warehouseproducts", {
      params: {
        token: token,
        user_id: user_id,
        management_id,
      },
    });
    if (mounted.current) {
      setProduct({ data: response.data, ready: true });
    }
  }, [token, user_id, management_id, mounted]);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Products",
        items: [{ name: "Dashboard", path: "/clinic/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Product List</Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setDialog(true)}
            >
              New Product
            </Button>
          </Box>
        </Box>
      }
    >
      <List product={product} />

      {dialog && (
        <NewProductDialog
          open={dialog}
          close={() => setDialog(false)}
          getProductList={getProductList}
        />
      )}
    </Container>
  );
};

export default PharmacyWarehouseProducts;

const NewProductDialog = ({ open, close, getProductList }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const management_id = localStorage.getItem("management_id");

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Dialog open={open} onClose={() => close()} maxWidth="xs" fullWidth>
      <DialogTitle>New Product Dialog</DialogTitle>
      <Formik
        initialValues={{
          username: username,
          user_id: user_id,
          token: token,
          management_id: management_id,
          password: "",
          product_name: "",
          product_generic: "",
          unit: "",
          msrp: "",
          srp: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(),
          product_name: Yup.string()
            .trim()
            .required("product name is a required field"),
          product_generic: Yup.string()
            .trim()
            .required("product generic is a required field"),
          unit: Yup.string().trim().required(),
          msrp: Yup.number().min(1).max(999999).required(),
          srp: Yup.number().min(1).max(999999).required(),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
          try {
            const request = await Axios.post(
              "/pharmacy/warehouse/products/new-product",
              getFormData(values)
            );
            if (request.data.message === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data.message === "success") {
              Notify.successRequest("new doctor");
              getProductList();
              resetForm();
              close();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box mb={2}>
                <TextField
                  error={Boolean(touched.product_name && errors.product_name)}
                  helperText={touched.product_name && errors.product_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.product_name}
                  fullWidth
                  name="product_name"
                  label="Product Name"
                  variant="outlined"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Product Generic"
                  name="product_generic"
                  error={Boolean(
                    touched.product_generic && errors.product_generic
                  )}
                  helperText={touched.product_generic && errors.product_generic}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.product_generic}
                  fullWidth
                  variant="outlined"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Product Unit"
                  name="unit"
                  error={Boolean(touched.unit && errors.unit)}
                  helperText={touched.unit && errors.unit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.unit}
                  fullWidth
                  variant="outlined"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Product MSRP"
                  name="msrp"
                  error={Boolean(touched.msrp && errors.msrp)}
                  helperText={touched.msrp && errors.msrp}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.msrp}
                  fullWidth
                  variant="outlined"
                  type="number"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Product SRP"
                  name="srp"
                  error={Boolean(touched.srp && errors.srp)}
                  helperText={touched.srp && errors.srp}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.srp}
                  fullWidth
                  variant="outlined"
                  type="number"
                />
              </Box>
              <Box>
                <TextField
                  label="Password"
                  name="password"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  fullWidth
                  variant="outlined"
                  type="password"
                />
              </Box>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="default" variant="contained" onClick={close}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
