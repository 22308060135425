import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TextField,
  IconButton,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";

const ImagingReport = () => {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const themes = useTheme();
  const [records, setRecords] = useState({
    data: [],
    ready: false,
  });

  const getPatientRead = React.useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.set("user_id", user_id);
      formdata.set("token", token);

      const response = await axios.post(
        "imaging/patients/getpatients-recorded",
        formdata
      );
      setRecords({
        data: response.data,
        ready: true,
      });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [user_id, token]);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilteredReport = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);

    if (
      formdata.get("date_from").trim() === "" ||
      formdata.get("date_to").trim() === ""
    ) {
      Notify.customToast("Date Invalid", "Date is a required field.");
      return false;
    }

    let response = await axios.post(
      "imaging/patients/getpatients-recorded",
      formdata
    );
    setRecords({ data: response.data, ready: true });
  };

  useEffect(() => {
    getPatientRead();
  }, [getPatientRead]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Report",
        items: [{ name: "Dashboard", path: "/clinic/app" }],
      }}
      title={"Recorded Patients"}
    >
      <Paper>
        <Box p={2}>
          <Box display="flex" my={2}>
            <Box flexGrow={1}>
              <Typography variant="h6" color="textSecondary">
                Patient Reviewed
              </Typography>
            </Box>

            <form onSubmit={handleFilteredReport}>
              <Box display="flex">
                <Box>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="From"
                    name="date_from"
                  />
                </Box>

                <Box mx={2}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="To"
                    name="date_to"
                  />
                </Box>
                <Box>
                  <IconButton
                    type="submit"
                    style={{
                      backgroundColor: themes.palette.primary.main,
                      color: "#fff",
                    }}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Box>
            </form>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Patient </TableCell>
                  <TableCell> Order </TableCell>
                  <TableCell> Date </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.ready ? (
                  records.data.length > 0 ? (
                    (rowsPerPage > 0
                      ? records.data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : records.data
                    ).map((data, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          {`${data.lastname}, ${data.firstname}`}
                        </TableCell>
                        <TableCell>{data.imaging_order}</TableCell>
                        <TableCell>
                          {Notify.dateTimeConvert(data.created_at)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="error">No record found.</Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="textPrimary">
                        please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="list"
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={records.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default ImagingReport;
