import {
  Grid,
  Box,
  Typography,
  Badge,
  CardHeader,
  CardContent,
  Card,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import Container from "../layout/Container";
import Notify from "../notification/Notify";
import Axios from "axios";
import PurchaseList from "./dashboard/PurchaseList";
import OverTheCounter from "./dashboard/OverTheCounter";
import Alert from "@material-ui/lab/Alert";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import { blue, red } from "@material-ui/core/colors";
import { useHistory } from "react-router";
import CheckInternet from "./../utils/CheckInternet";
import LaptopChromebookIcon from "@material-ui/icons/LaptopChromebook";
import CheckingConnection from "src/CheckInternet";

const PharmacyDashboard = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const pharmacy_id = localStorage.getItem("pharmacy_id");
  const username = localStorage.getItem("username");
  const [brandList, setBrandList] = useState([]);
  const history = useHistory();
  const [online, setOnline] = useState("checking");
  const [localOrderCount, setLocalOrderCount] = useState(0);
  const [virtualOrderCount, setVirtualOrderCount] = useState(0);

  var interval = null;

  const [purchaseData, setPurchaseData] = useState({
    data: [],
    ready: false,
  });

  const checkinternet = () => {
    CheckInternet.online()
      .then(() => setOnline("connected"))
      .catch(() => setOnline("disconnected"));
  };

  const getVirtualOrderCount = () => {
    var params = {
      token: token,
      user_id: user_id,
      management_id: management_id,
      pharmacy_id: pharmacy_id,
      connection: "online",
    };
    Axios.get("pharmacy/get-prescription-list", { params })
      .then((response) => {
        const data = response.data;
        setVirtualOrderCount(data.length);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getLocalOrderCount = async () => {
    var params = {
      user_id: user_id,
      management_id: management_id,
      connection: "local",
      token: token,
    };

    const response = await Axios.get("pharmacy/get/all-unclaimed-pres", {
      params,
    });
    const data = response.data;
    setLocalOrderCount(data.length);
  };

  const getBrandName = () => {
    var params = {
      user_id: user_id,
      management_id: management_id,
      pharmacy_id: pharmacy_id,
      token: token,
    };
    Axios.get("pharmacy/get-brand-list", { params })
      .then((response) => {
        const data = response.data;
        setBrandList(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const fetchPuchaseList = () => {
    var params = {
      user_id: user_id,
      management_id: management_id,
      token: token,
    };
    Axios.get("pharmacy/get-purchase-list", { params })
      .then((response) => {
        const data = response.data;
        setPurchaseData({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const initializeInterval = () => {
    interval = setInterval(() => {
      getLocalOrderCount();
      getVirtualOrderCount();
    }, 60000);
  };

  useEffect(() => {
    checkinternet();
    getBrandName();
    fetchPuchaseList();
    initializeInterval();
    getLocalOrderCount();
    getVirtualOrderCount();

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      breadcrumbs={{
        enable: false,
        current: "qwe",
        items: [],
      }}
      title={
        <>
          <Typography variant={"h4"}>Dashboard</Typography>
          <Typography variant="subtitle1">
            Welcome back, {username} 👋👋👋
          </Typography>
        </>
      }
    >
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  {online === "checking" && <CheckingConnection />}
                  {online === "disconnected" && (
                    <Fragment>
                      <Box border={1} borderRadius={4} borderColor={red[100]}>
                        <Alert
                          icon={<WifiOffIcon style={{ fontSize: "2.2em" }} />}
                          severity="error"
                        >
                          <Typography variant="subtitle2">Offline.</Typography>
                          <Typography variant="body2">
                            The system cannot receive orders from virtual
                            doctors.
                          </Typography>
                        </Alert>
                      </Box>
                    </Fragment>
                  )}

                  {online === "connected" && (
                    <Box
                      display="flex"
                      border={1}
                      borderRadius={4}
                      borderColor={blue[100]}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/clinic/app/virtual")}
                    >
                      <Box flexGrow={1}>
                        <Alert
                          icon={<WifiIcon style={{ fontSize: "2.2em" }} />}
                          severity="info"
                        >
                          <Typography variant="subtitle1"> Online </Typography>
                          <Typography variant="subtitle2">
                            The system can receive orders from virtual doctors.
                          </Typography>
                        </Alert>
                      </Box>
                      <Badge
                        badgeContent={virtualOrderCount}
                        color="secondary"
                      />
                    </Box>
                  )}

                  <Box
                    my={2}
                    display="flex"
                    border={1}
                    borderRadius={4}
                    borderColor={red[100]}
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/clinic/app/local")}
                  >
                    <Box flexGrow={1}>
                      <Alert
                        icon={
                          <LaptopChromebookIcon style={{ fontSize: "2.2em" }} />
                        }
                        severity="error"
                      >
                        <Typography variant="subtitle1">Local Order</Typography>
                        <Typography variant="subtitle2">
                          The system can receive orders from doctors.
                        </Typography>
                      </Alert>
                    </Box>
                    <Badge badgeContent={localOrderCount} color="secondary" />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Over the counter" />
                    <CardContent>
                      <OverTheCounter
                        brandListProps={brandList}
                        fetchPuchaseList={() => fetchPuchaseList()}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={8} xs={12}>
              <PurchaseList
                purchaseData={purchaseData}
                fetchPuchaseList={() => fetchPuchaseList()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PharmacyDashboard;
