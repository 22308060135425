
import React from 'react';
import { Box } from "@material-ui/core";     
 
const QRCode = require('qrcode.react');
const GenerateQr = () => {   
    const user_id = 'U-1568616200435554';
 

    return(

        <>
            <Box m={10}>  
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <QRCode size={250} value={`${user_id}`} level="H" />  {/* user_id of patient */}
            </Box> 
        </>
    )
}

export default GenerateQr;