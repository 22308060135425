
import React, { useEffect, useCallback, useState, Fragment } from 'react'
import { Box, Tooltip, Typography } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Notify from '../../notification/Notify';
import { useHistory } from 'react-router-dom';
import ArrowIcon from '@material-ui/icons/TrendingFlat';

const ClinicDetailsInquiryMessages = ({ messages, screen }) => {

    const [scrollHeight, setScrollHeight] = useState();
    const history = useHistory()
    
    const scrollBottom = useCallback(() => {
        if (scrollHeight) {
            scrollHeight.scrollTop = scrollHeight.scrollHeight;
        }
    }, [scrollHeight])

    useEffect(() => {
        setTimeout(() => {
            scrollBottom()
        }, 400);
    }, [scrollBottom, messages]);

    const validGTCUrl = (msg) => {
        if (msg.includes('patient/appointment/doctor/'))
            return true;
    }

    return (
        <Box
            overflow="auto"
            style={{
                height: screen === 'bigscreen' ?
                    'calc(100vh - 140px)'
                    : 400
            }}
        >
            <PerfectScrollbar
                containerRef={ref => {
                    setScrollHeight(ref);
                }}
            >
                {
                    messages.ready ?
                        messages.data.length > 0 ?
                            messages.data.map((data, index) => (
                                <Fragment key={index}>
                                    {
                                        data.send_by === 'Patient' ?
                                            <Box
                                                id="sender"
                                                my={2}
                                                display={'flex'}
                                            >
                                                <Box flexGrow={1} />
                                                <Box
                                                    width={'80%'}
                                                    border={1}
                                                    borderColor={blue[300]}
                                                    bgcolor={blue[200]}
                                                    p={1}
                                                    borderRadius={4}
                                                    style={{ wordBreak: 'break-word' }}
                                                >
                                                    <Typography variant="subtitle2">
                                                        {data.message}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            :
                                            <Box
                                                id="receiver"
                                                my={2}
                                                width={'80%'}
                                                border={1}
                                                borderColor={grey[300]}
                                                bgcolor={grey[200]}
                                                p={1}
                                                borderRadius={4}
                                                style={{ wordBreak: 'break-word' }}
                                            >
                                                <Typography variant="subtitle2">
                                                    {
                                                        validGTCUrl(data.message) ?
                                                            <Tooltip title="Click to open">
                                                                <Typography className={`gtc-uppercase pointer`} variant="caption" color="primary" onClick={() => history.replace(`/${data.message}`)}>
                                                                    <ArrowIcon /> {data.message.replace('patient/', '')}
                                                                </Typography>
                                                            </Tooltip>
                                                            : data.message
                                                    }
                                                </Typography>
                                            </Box>
                                    }
                                </Fragment>
                            ))
                            :
                            <Typography color="secondary">
                                No record found.
                            </Typography>
                        :
                        <Box display="flex" height={screen === 'bigscreen' ? 'calc(100vh - 140px)' : 400} justifyContent="center" alignItems="center">
                            <Box>
                                {Notify.loading()}
                            </Box>
                        </Box>
                }
            </PerfectScrollbar>
        </Box>
    )
}

export default ClinicDetailsInquiryMessages;