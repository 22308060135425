import { Box, Typography, makeStyles, Card, Grid, Paper, IconButton, Collapse, Divider } from '@material-ui/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ClinicList from './ClinicList';
import DoctorsList from './DoctorsList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchAppointmentDocList from './SearchAppointmentDocList';
import { grey } from '@material-ui/core/colors';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import Container from 'src/layout/Container';
import { isMobile } from 'react-device-detect';
import { ExpandLess } from '@material-ui/icons';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const SearchAppointment = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(null);
    const [expandedTypeList, setExpandedTypeList] = useState(null);
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [docSpecial, setDocSpecial] = useState([]);

    const getAllDocSpecialization = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
        }
        Axios.get('patient/appointment/get-all-doc-special', { params })
            .then((response) => {
                const data = response.data;
                setDocSpecial(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id])

    useEffect(() => {
        getAllDocSpecialization();
    }, [getAllDocSpecialization])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'make appointment',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Appointment', path: '/clinic/app/patient/appointment' },
                    ]
                }}
                title="Make Appointment"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Box 
                            component={Paper}
                            variant="outlined"
                        >
                            {
                                isMobile ?
                                    <Box m={2} >
                                        <Card
                                            elevation={0}
                                            component={Box}
                                            className={classes.searchbox}
                                        >
                                            <SearchAppointmentDocList />
                                        </Card>
                                    </Box>
                                    :
                                    <Box m={2} display="flex">
                                        <Box flexGrow={1} mb={1} />
                                        <Card
                                            elevation={0}
                                            component={Box}
                                            className={classes.searchbox}
                                        >
                                            <SearchAppointmentDocList />
                                        </Card>
                                    </Box>
                            }
                            <Box m={2}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography variant={'subtitle2'} color="primary">
                                            <strong> GTC DOCTOR LIST </strong>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            variant="outlined"
                                            color={'primary'}
                                            onClick={() => setExpandedTypeList(expandedTypeList === 'doc-list' ? null : 'doc-list')}
                                        >
                                            {expandedTypeList === 'doc-list' ? <ExpandLess /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Collapse in={expandedTypeList === 'doc-list' ? true : false}>
                                    {
                                        docSpecial.length > 0 ?
                                            docSpecial.map((data, index) => {
                                                return (
                                                    <Box ml={2} key={index}>
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Box flexGrow={1}>
                                                                <Typography className={classes.heading}>
                                                                    {data.specialization}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => setExpanded(expanded === data.specialization ? null : data.specialization)}
                                                                >
                                                                    {expanded === data.specialization && expandedTypeList === 'doc-list' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <Collapse in={expanded === data.specialization && expandedTypeList === 'doc-list'}>
                                                            {expanded === data.specialization ? <DoctorsList specialization={data.specialization} /> : null}
                                                        </Collapse>
                                                    </Box>
                                                )
                                            })
                                            : null
                                    }
                                </Collapse>
                            </Box>
                            <Divider light />
                            <Box m={2}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography variant={'subtitle2'} color="primary">
                                            <strong> GTC CLINIC LIST </strong>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            variant="outlined"
                                            color={'primary'}
                                            onClick={() => setExpandedTypeList(expandedTypeList === 'clinic-list' ? null : 'clinic-list')}
                                        >
                                            {expandedTypeList === 'clinic-list' ? <ExpandLess /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Collapse in={expandedTypeList === 'clinic-list' ? true : false}>
                                    <ClinicList />
                                </Collapse>
                            </Box>
                            <Divider light />
                            <Box m={2}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography variant={'subtitle2'} color="primary">
                                            <strong> GTC HOSPITAL LIST </strong>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            variant="outlined"
                                            color={'primary'}
                                            onClick={() => setExpandedTypeList(expandedTypeList === 'hospital-list' ? null : 'hospital-list')}
                                        >
                                            {expandedTypeList === 'hospital-list' ? <ExpandLess /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Collapse in={expandedTypeList === 'hospital-list' ? true : false}>
                                    {'T O   B E   A D D E D   S O O N . . .'}
                                </Collapse>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Fragment >
    )
}

export default SearchAppointment;

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "uppercase"
    },
    searchbox: {
        borderColor: grey[500],
        padding: 4,
        elevation: 0,
        border: 1,
        display: 'flex',
    }
}));