import React, { Fragment, useState, useEffect } from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import {
  TextField,
  Button,
  Grid,
  DialogContent,
  FormHelperText,
  DialogActions,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactSelect from "react-select";
import SelectStyle from "src/utils/SelectStyle";
import { CheckSquare, XCircle } from "react-feather";

function PatientDetailsAppointmentCreate({ patient_id, close, reload }) {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const management_id = localStorage.getItem("management_id");
  const selectStyle = SelectStyle();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [services, setServices] = useState([]);
  const mounted = IsMountedRef();

  const getDoctorsServices = React.useCallback(async () => {
    try {
      let response = await Axios.get(
        "/encoder/patient/appointment/doctor-serviceslist",
        {
          params: {
            token,
            management_id,
            user_id,
          },
        }
      );

      if (mounted.current) {
        setServices(response.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [mounted, token, management_id, user_id]);

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("app_date", selectedDate.toLocaleString());
    formData.append("patient_id", patient_id);

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {
    getDoctorsServices();
  }, [getDoctorsServices]);

  return (
    <Fragment>
      <Formik
        initialValues={{
          username: username,
          user_id: user_id,
          token: token,
          password: "",
          reason: "",
          service_id: "",
          service: "",
          fee: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(),
          reason: Yup.string().required(),
          service: Yup.string().required(),
          fee: Yup.number().required(),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await Axios.post(
              "encoder/patient/create-appointment",
              getFormData(values)
            );
            if (request.data === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data === "has-appointment") {
              setErrors({ submit: "Patient has an active appointment." });
              Notify.customToast(
                "Appointment Active",
                "Patient has an active appointment."
              );
            }

            if (request.data === "success") {
              Notify.successRequest("new appointment");
              resetForm();
              close();
              reload();
              setSubmitting(true);
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box mb={1} mt={1}>
                <DatePicker
                  fullWidth
                  disablePast
                  margin="normal"
                  id="date-picker"
                  label="Date Picker"
                  value={selectedDate}
                  inputVariant="outlined"
                  onChange={(date) => setSelectedDate(date)}
                  name="app_date"
                />
              </Box>

              <Box mb={1}>
                <TimePicker
                  fullWidth
                  margin="normal"
                  id="time-picker"
                  label="Time Picker"
                  value={selectedDate}
                  inputVariant="outlined"
                  onChange={(date) => setSelectedDate(date)}
                  name="app_time"
                />
              </Box>

              <Box mb={1}>
                <TextField
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reason}
                  fullWidth
                  label="Reason of Appointment"
                  margin="normal"
                  variant="outlined"
                  multiline
                  name="reason"
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Box>
                    <ReactSelect
                      options={services}
                      styles={selectStyle}
                      name="app_service"
                      onChange={(e) => {
                        // handleSelectChange(e, setFieldValue, values.findings)
                        setFieldValue("service", e.services);
                        setFieldValue("service_id", e.service_id);
                        setFieldValue("fee", e.amount);
                        console.log(e);
                      }}
                    />

                    {errors.service && (
                      <FormHelperText error>{errors.service}</FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Box>
                    <TextField
                      error={Boolean(touched.fee && errors.fee)}
                      helperText={touched.fee && errors.fee}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fee}
                      fullWidth
                      label="Fee"
                      margin="normal"
                      variant="outlined"
                      name="fee"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.01,
                        },
                      }}
                      autoComplete="off"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box mb={1}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  type="password"
                  fullWidth
                  label="Password"
                  margin="normal"
                  variant="outlined"
                  name="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<XCircle />}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CheckSquare />}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default PatientDetailsAppointmentCreate;
