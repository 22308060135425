import React, { Fragment, Suspense, useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import DoctorsInformationComment from './DoctorsInformationComment';
import { Box, CardContent, CardMedia, Grid, Card, Typography, TableContainer, TableRow, TableCell, Table, TableHead, TableBody, Tabs, Tab, makeStyles, CircularProgress, TextField, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { red } from '@material-ui/core/colors';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CommentIcon from '@material-ui/icons/Comment';
import TabPanel from '../../utils/TabPanel';
import { useParams } from 'react-router-dom';
import Container from 'src/layout/Container';

const RequestAppointment = React.lazy(() => import('./RequestAppointment'));
const imageLocation = process.env.REACT_APP_API_IMAGE;

const DoctorsInformation = () => {
    const classes = useStyle();
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const management_id = localStorage.getItem('management_id');
    const { doctor_id } = useParams()
    const [doctorInfo, setDoctorInfo] = useState([])
    const [doctorInfoReady, setDoctorInfoReady] = useState(false)
    const [doctorService, setDoctorService] = useState([])
    const [doctorServiceReady, setDoctorServiceReady] = useState(false)
    const [credit, setCredit] = useState(0)
    const [category, setCategory] = useState('request')
    const [unpaidConsultation, setUnpaidCosultation] = useState(0)
    const [unpaidConsuCount, setUnpaidConsuCount] = useState(0)

    const getDoctorsInformation = useCallback(() => {
        var params = {
            token: token,
            management_id: management_id,
            user_id: user_id,
            doctors_id: doctor_id,
        }
        Axios.get('appointment/doctors/doctors-information', { params })
            .then(function (response) {
                const data = response.data;
                setDoctorInfo(data)
                setDoctorInfoReady(true)

            }).catch(error => {
                Notify.requestError(error);
            });
    }, [user_id, token, management_id, doctor_id])

    const getServices = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            doctors_id: doctor_id,
        }
        Axios.get('appointment/doctors/doctors-services', { params })
            .then(function (response) {
                const data = response.data;
                setDoctorService(data)
                setDoctorServiceReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [user_id, token, doctor_id])

    const getCreditBalance = useCallback(() => {
        var params = {
            token: token,
            management_id: management_id,
            user_id: user_id,
            doctors_id: doctor_id,
        }
        Axios.get('patient/credits/credits-balance', { params })
            .then(function (response) {
                const data = response.data;
                console.log(data);
                if (data.length > 0) {
                    setCredit(data[0].credit_total - data[0].credit_expense + data[0].unpaid_not_enough_creditsss);
                    setUnpaidCosultation(data[0].unpaid_not_enough_credit);
                    setUnpaidConsuCount(data[0].countUnpaid)
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [user_id, token, management_id, doctor_id])

    const handleChange = (e, newCategory) => {
        e.persist()
        setCategory(newCategory)
    }

    useEffect(() => {
        getDoctorsInformation()
        getServices()
        getCreditBalance()
    }, [getDoctorsInformation, getServices, getCreditBalance])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: "doctor's information",
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Appointment', path: '/clinic/app/patient/appointment' },
                        { name: 'Make Appointment', path: '/clinic/app/patient/appointment/make-appointment' },
                    ]
                }}
                title="Doctor's Information"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Box component={Paper}>
                            {
                                doctorInfoReady ?
                                    doctorInfo.length > 0 ?
                                        <Box 
                                            p={2}
                                            className="gtc-textfield-noborder"
                                        >
                                            <Grid container spacing={3}>
                                                <Grid item xs={4} sm={4} md={3}>
                                                    <Card
                                                        elevation={0}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            image={doctorInfo[0].image === null ? "/doctorsIcon.png" : imageLocation + 'doctors/' + doctorInfo[0].image}
                                                            title="test"
                                                            alt=""
                                                            style={{ borderRadius: '50%' }}
                                                        />
                                                        <CardContent>
                                                            <Box mt={2}>
                                                                <Typography align="center" variant={'subtitle2'} color="primary" style={style.uppercase}>
                                                                    {doctorInfo[0].name}
                                                                </Typography>
                                                                <Typography align="center" style={style.capitalize} variant="body2">
                                                                    {doctorInfo[0].specialization}
                                                                </Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                                <Grid item xs={8} sm={8} md={9}>
                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Age"
                                                            variant="outlined"
                                                            value={doctorInfo[0].birthday === null ? "None" : Notify.calculateAge(doctorInfo[0].birthday)}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Birthday"
                                                            variant="outlined"
                                                            value={doctorInfo[0].birthday === null ? "None" : Notify.birthday(doctorInfo[0].birthday)}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Gender"
                                                            variant="outlined"
                                                            value={doctorInfo[0].gender === null ? 'None' : doctorInfo[0].gender}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Contact No."
                                                            variant="outlined"
                                                            value={doctorInfo[0].contact_no === null ? 'None' : doctorInfo[0].contact_no}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Address"
                                                            variant="outlined"
                                                            value={doctorInfo[0].address === null ? 'None' : doctorInfo[0].address}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        : Notify.noRecord()
                                    : Notify.loading()
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Box mb={2} width={'100%'}>
                            <Alert
                                icon={
                                    <Box mt={1}>
                                        <WalletIcon fontSize="large" />
                                    </Box>
                                }
                                variant="filled"
                                severity="success"
                            >
                                <Typography variant="caption" style={style.uppercase}>
                                    Credit Balance
                                </Typography>
                                <Typography variant="h5" style={style.uppercase}>
                                    {Notify.convertToNumber(credit)}
                                </Typography>
                            </Alert>
                        </Box>
                        {
                            unpaidConsuCount > 0 ?
                                <Box mb={2} border={1} borderColor={red[100]} width={'100%'}>
                                    <Alert
                                        icon={
                                            <Box mt={1}>
                                                <WalletIcon fontSize="large" />
                                            </Box>
                                        }
                                        variant="standard"
                                        severity="error"
                                    >
                                        <Typography variant="caption" style={style.uppercase}>
                                            Unpaid Consultation
                                        </Typography>

                                        <Typography variant="h5" style={style.uppercase}>
                                            {Notify.convertToNumber(unpaidConsultation)} {`(${unpaidConsuCount})`}
                                        </Typography>
                                    </Alert>
                                </Box>
                                : null
                        }
                        <Paper component={Box} p={2}>
                            <TableContainer >
                                <Typography variant="subtitle2" style={style.uppercase} color="primary"> Services Available </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> Services  </TableCell>
                                            <TableCell align="right"> Credit  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            doctorServiceReady ?
                                                doctorService.length > 0 ?
                                                    doctorService.map((data, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell className="text-left"> {data.services.toUpperCase()}  </TableCell>
                                                                <TableCell className="text-right"> {data.amount} </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    : <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography color="secondary"> No record </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                : <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography color="primary"> Please wait... </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Paper>
                            <Tabs
                                component={Box} 
                                value={category}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                classes={{ indicator: classes.noIndicator }}
                            >
                                <Tab value="request" wrapped icon={<DateRangeIcon />} label="Request Appointment" />
                                <Tab value="comment" wrapped icon={<CommentIcon />} label="Comments" />
                            </Tabs>
                        </Paper>

                        <Box my={2}>
                            <TabPanel value={category} index={'request'}>
                                <Suspense fallback={<> <CircularProgress size={20} /> Please wait... </>}>
                                    <RequestAppointment
                                        services={doctorService.length > 0 ? doctorService : []}
                                        credits={credit}
                                        countUnpaid={unpaidConsuCount}
                                        doctors_id={doctor_id}
                                        getCredit={getCreditBalance}
                                    />
                                </Suspense>
                            </TabPanel>

                            <TabPanel value={category} index={'comment'}>
                                <DoctorsInformationComment doctors_id={doctor_id} />
                            </TabPanel>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </Fragment>
    )
}

export default DoctorsInformation;

const style = {
    uppercase: {
        textTransform: 'uppercase',
    },
    capitalize: {
        textTransform: 'capitalize'
    }
}

const useStyle = makeStyles((theme) => ({
    noIndicator: {
        backgroundColor: "transparent"
    }
}));