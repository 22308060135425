import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useState } from "react";
import { CheckSquare, XCircle } from "react-feather";
import Notify from "src/notification/Notify";

const BillingDetailsChangeRate = ({
  open,
  close,
  trace_number,
  patient_id,
  details,
  getBillingDetails,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const [newRate, setNewRate] = useState(details.bill_amount);
  const [remarks, setRemarks] = useState("");
  const [isProcess, setIsProcess] = useState(false);

  const handleNewRate = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("epb_id", details.epb_id);
    formdata.append("patient_id", patient_id);
    formdata.append("trace_number", trace_number);

    var error = [];

    if (formdata.get("password").trim() === "") {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (parseFloat(formdata.get("new_rate")) < 1) {
      error = "error";
      Notify.fieldInvalid("new rate");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      Axios.post(
        "encoder/patient/appointment/update-consultation-rate",
        formdata
      )
        .then((response) => {
          if (response.data.message === "pass-invalid") {
            Notify.fieldInvalid("password");
          }

          if (response.data.message === "success") {
            close();
            getBillingDetails();
            Notify.successRequest("rate updated");
          }
        })
        .catch((error) => console.log("request has error: ", error.message))
        .finally(() => setIsProcess(false));
    }
  };

  return (
    <Dialog open={open} onClose={close} disableBackdropClick>
      <DialogTitle> Change Rate </DialogTitle>

      <form onSubmit={handleNewRate}>
        <DialogContent dividers>
          <Box mb={2}>
            <TextField
              label="New Rate"
              value={newRate}
              onChange={(e) => setNewRate(e.target.value)}
              name="new_rate"
              fullWidth
              variant="outlined"
              type="number"
              required
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 99999,
                },
              }}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              name="new_rate_remarks"
              fullWidth
              variant="outlined"
              required
              multiline
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Password"
              name="password"
              fullWidth
              variant="outlined"
              type="password"
              required
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="default"
            onClick={close}
            startIcon={<XCircle />}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            startIcon={
              isProcess ? (
                <CircularProgress color="inherit" size={23} />
              ) : (
                <CheckSquare />
              )
            }
            disabled={isProcess}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BillingDetailsChangeRate;
