 
import { Box, Typography, makeStyles, Paper, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState }  from 'react';  
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' 
import listPlugin from '@fullcalendar/list';
import timePlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios';
import Notify from 'src/notification/Notify';
import { useHistory } from 'react-router';
import { RefreshCw } from 'react-feather';
import IsMountedRef from 'src/utils/IsMountedRef';

const useStyles = makeStyles((theme) => ({  
    appointmentbg_success:{
        marginBottom: 5,
        marginTop: 5,
        padding: 10,
        backgroundColor: theme.palette.success.light,
        borderColor: theme.palette.success.light,
        color: '#fff !important', 
        cursor: 'pointer', 
    },
    appointmentbg_new:{
        marginBottom: 5,
        marginTop: 5,
        padding: 10,
        backgroundColor: theme.palette.warning.light,
        borderColor: theme.palette.warning.light,
        color: '#fff !important', 
        cursor: 'pointer', 
    },
    appointmentbg_approved:{
        marginBottom: 5,
        marginTop: 5,
        padding: 10,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        color: '#fff !important', 
        cursor: 'pointer', 
    }
})); 


const AppointmentCalendarDaily = () =>{ 
    const ismounted = IsMountedRef()
    const history = useHistory();
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const classes = useStyles()
    const calendarRef = React.useRef(null)   
    const [appointment, setAppointment] = useState([])
    const [appointmentReady, setAppointmentReady] = useState(false) 
    const [refresh, setRefresh] = useState(1)  
 
    const getAllLocalAppointment = () => { 
        var params = {
            token, user_id
        }    
        
        axios.get('doctors/fullcalendar/appointment/local-dailylist', { params } )
        .then( (response) =>{ 
            if(ismounted.current){
                const data = response.data;       
                generateEventFromQuery(data)
            } 
        }).catch(error=>{ 
            Notify.requestError(error);
        }) 
    } 

    const getAllVirtualAppointment = () => {  

        var params = {
            token, user_id
        }    
        
        axios.get('doctors/fullcalendar/appointment/virtual-dailylist', { params } )
        .then( (response) =>{ 
            if(ismounted.current){
                const data = response.data;     
                generateEventFromQuery(data) 
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        }).finally(() => setTimeout(() => { setAppointmentReady(true) }, 2000) )
    }

    const generateEventFromQuery = (data) =>{
        setAppointment((appointment) => appointment.concat(data))  
    }

    const handleMountedEvent = (e) =>{
        
        const event = e.event._def.extendedProps;   

        if(Boolean(event.reference_no)){ //if reference numnber is not empty appointment is taken from virtual  
            if(event.appointment_status === 'successful'){
                e.el.className = `${e.el.className}  ${classes.appointmentbg_success}` 
            } 

            if(event.appointment_status === 'new'){
                e.el.className = `${e.el.className} ${classes.appointmentbg_new}` 
            }
            
            if(event.appointment_status === 'approved'){
                e.el.className = `${e.el.className} ${classes.appointmentbg_approved}` 
            }  
        }else{ //if reference numnber is not empty appointment is taken from local 
 
            if(Boolean(parseInt(event.is_complete)) && Boolean(parseInt(event.is_paid_bysecretary))){
                e.el.className = `${e.el.className}  ${classes.appointmentbg_success}` 
            } 

            if(Boolean(parseInt(event.is_paid_bysecretary)) && !Boolean(parseInt(event.is_complete))){
                e.el.className = `${e.el.className}  ${classes.appointmentbg_approved}` 
            } 

            if(!Boolean(parseInt(event.is_complete)) && !Boolean(parseInt(event.is_paid_bysecretary)) ){
                e.el.className = `${e.el.className}  ${classes.appointmentbg_new}` 
            } 
 
        }   
        
    }

    const handleDayClick = (e) =>{  
        const event = e.event._def.extendedProps;  
        history.push(`/clinic/app/doctor/appointment/calendar/details/${Boolean(event.reference_no)  ? 'virtual' : 'local'}/${event.appointment_id}`)
    }

    const handleRefresh = () =>{
        setRefresh(refresh +1)
    }

    useEffect(() =>{ 

        getAllLocalAppointment()
        getAllVirtualAppointment()  
        
        return(() =>{ 
            setAppointment([])
            setAppointmentReady(false)   
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh, ismounted])

    const height = window.innerWidth  > 600 ? `calc(50vh - 210px)` : `calc(30vh - 100px)`;
 
    return(
        <Box my={3}>  
  
            <Paper elevation={2} component={Box} p={2} id={`dailyCalendar`}>
                
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" color="textSecondary">
                            Todays Appointment
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Tooltip title="fetch appointment" arrow>
                            <Box>
                                <IconButton 
                                    color="primary"
                                    size="small"
                                    onClick={ handleRefresh }
                                    disabled={ !appointmentReady }
                                >
                                    <RefreshCw size={20} />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box> 

                {
                    appointmentReady ? 
                        appointment.length > 0 ?
                            <FullCalendar  
                                ref = { calendarRef } 
                                height={ height }
                                initialView = { `dayGrid` } 
                                plugins = {[ listPlugin, dayGridPlugin, interactionPlugin, timePlugin ]}
                                allDayContent = ""     
                                events = { appointment }    
                                eventDidMount = { handleMountedEvent }
                                eventClick = { handleDayClick }
                                eventDisplay = "auto" 
                                headerToolbar = {{ left: '', right:'' }} // hide button and title
                            />
                        :   Notify.noRecord()
                    : Notify.loading()
                } 
            </Paper>
        </Box>
    )
}

export default AppointmentCalendarDaily;