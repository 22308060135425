import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  DialogActions,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { CheckSquare, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactSelect from "react-select";
import SelectStyle from "src/utils/SelectStyle";

const BillingDetailsNewBill = ({
  open,
  close,
  trace_number,
  patient_id,
  getBillingDetails,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const management_id = localStorage.getItem("management_id");
  const selectStyle = SelectStyle();

  const [services, setServices] = useState([]);
  const mounted = IsMountedRef();

  const getDoctorsServices = React.useCallback(async () => {
    try {
      let response = await Axios.get(
        "/encoder/patient/appointment/doctor-serviceslist",
        {
          params: {
            token,
            management_id,
            user_id,
          },
        }
      );

      if (mounted.current) {
        setServices(response.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [mounted, token, management_id, user_id]);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {
    getDoctorsServices();
  }, [getDoctorsServices]);

  return (
    <Dialog open={open} onClose={close} scroll="body">
      <DialogTitle> New Bill </DialogTitle>

      <Formik
        initialValues={{
          username: username,
          user_id: user_id,
          token: token,
          trace_number: trace_number,
          patient_id: patient_id,
          type: "",
          service_id: "",
          service: "",
          service_rate: 0,
          remarks: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().trim().required(),
          service: Yup.string().trim().required(),
          service_rate: Yup.number().required(),
          remarks: Yup.string().required(),
          password: Yup.string().trim().required(),
        })}
        onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
          Axios.post(
            "encoder/patient/appointment/new-appointmentbilling",
            getFormData(values)
          )
            .then((response) => {
              const data = response.data;

              if (data.message === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
              }

              if (data.message === "success") {
                Notify.successRequest("biiling added");
                getBillingDetails();
                resetForm();
                close();
              }
            })
            .catch((error) => console.log("request error:", error.message))
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box>
                <TextField
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("service", "");
                    setFieldValue("service_rate", 0);
                    if (e.target.value) {
                      setFieldValue("service_id", "encoder-manual");
                    }
                  }}
                  value={values.type}
                  fullWidth
                  name="type"
                  label="Service Type"
                  variant="outlined"
                  margin="normal"
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option> Select </option>
                  <option value="doctor"> Doctor Services </option>
                  <option value="manual"> Manual </option>
                </TextField>
              </Box>

              {values.type === "doctor" ? (
                <>
                  {/* doctors services list */}

                  <Box my={1.5}>
                    <ReactSelect
                      options={services}
                      styles={selectStyle}
                      name="service"
                      onChange={(e) => {
                        // handleSelectChange(e, setFieldValue, values.findings)
                        setFieldValue("service", e.services);
                        setFieldValue("service_id", e.service_id);
                        setFieldValue("service_rate", e.amount);
                      }}
                    />

                    {errors.service && (
                      <FormHelperText error>{errors.service}</FormHelperText>
                    )}
                  </Box>

                  <Box>
                    <TextField
                      error={Boolean(
                        touched.service_rate && errors.service_rate
                      )}
                      helperText={touched.service_rate && errors.service_rate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.service_rate}
                      fullWidth
                      name="service_rate"
                      label="Rate"
                      variant="outlined"
                      margin="normal"
                      type="number"
                      InputProps={{
                        readOnly: true,
                        inputProps: {
                          min: 0,
                          max: 999999,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  {/* manual billing  */}
                  <Box>
                    <TextField
                      error={Boolean(touched.service && errors.service)}
                      helperText={touched.service && errors.service}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.service}
                      fullWidth
                      name="service"
                      label="Service"
                      variant="outlined"
                      margin="normal"
                    />
                  </Box>

                  <Box>
                    <TextField
                      error={Boolean(
                        touched.service_rate && errors.service_rate
                      )}
                      helperText={touched.service_rate && errors.service_rate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.service_rate}
                      fullWidth
                      name="service_rate"
                      label="Rate"
                      variant="outlined"
                      margin="normal"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 999999,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>
                </>
              )}

              <Box>
                <TextField
                  error={Boolean(touched.remarks && errors.remarks)}
                  helperText={touched.remarks && errors.remarks}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.remarks}
                  fullWidth
                  name="remarks"
                  type="remarks"
                  label="Remarks"
                  variant="outlined"
                  margin="normal"
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  fullWidth
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  margin="normal"
                />
              </Box>

              {errors.submit && (
                <Box>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<XCircle />}
              >
                No
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default BillingDetailsNewBill;
