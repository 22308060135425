import React from 'react';
export default class Utils extends React.Component{
    static zeroChecker = (value) => {
        if(value === null){
            return 0
        }

        if(value === undefined){
            return 0
        }

        if(value.length === 0){
            return 0
        }

        return value
    }
}