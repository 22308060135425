import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import SorologyRef from "../references/Ref_Sorology";
import FormvalidationSorology from "./validation/FormvalidationSorology";
import { CheckSquare, XCircle } from "react-feather";

const SorologyOrderDetails = ({ order, getLabSorologyOrder, resetDisplay }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");

  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState({
    open: false,
    data: null,
  });

  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const saveButtonRef = React.useRef();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);

  const getLabSoroOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("trace_number", order.trace_number);
    Axios.post("laboratory/order/ordernew-sorology/details", formdata)
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getLabSoroOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("trace_number", order.trace_number);

    var error = [];

    if (orderDetails.data) {
      error = FormvalidationSorology(orderDetails.data, formdata);
    }

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setResultSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-sorology/save-process-result",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added.");
            setTimeout(() => {
              history.push(
                `/clinic/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("order_id", order.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post("laboratory/order/ordernew-sorology/save-setpending", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabSorologyOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("order_id", processDialog.data.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setProcessSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-sorology/save-setprocessing",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabSoroOrderDetails();
            setProcessDialog({ open: false, data: null });
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setProcessSubmitting(false));
    }
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <CardHeader
            component={Box}
            align="center"
            title={
              <Box>
                <Typography variant="h5">
                  <b> LABORATORIO MEDICO DE CALIDAD </b>
                </Typography>
                <Typography>With</Typography>
                <Typography variant="h6">
                  <b> DR. MELY R. PENAFLORIDA-LASTIMOSO MD FPSV </b>
                </Typography>
              </Box>
            }
            subheader={`General Medicine & Treats SEXUALLY TRANSMITTED DISEASES Including HIV & AIDS and other venereal diseases
                        2/F Room 4&5 VENCER building National Highway 
                         General Santos City`}
          />
          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      NAME:
                    </Typography>
                    {`${order.firstname} ${order.lastname}`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        SEX:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      ADDRESS:
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:
                    </Typography>
                    {Notify.dateTimeConvert(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            {/* order details */}
            <Box>
              <Typography variant="h6" align="center">
                <b> SEROLOGY </b>
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b> EXAMINATION REQUESTED </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> NORMAL </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> TEST RESULT </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> ACTION </b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orderDetails.ready ? (
                    orderDetails.data.length > 0 ? (
                      orderDetails.data.map((data, key) => (
                        <Fragment key={key}>
                          <TableRow hidden={data.hav === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                HAV (Hepatitis A Virus) lgG/lgM
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {SorologyRef.normal()}
                            </TableCell>
                            <TableCell align="center">
                              {/* hidden inputs */}
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  name="order_id[]"
                                  label="order_id"
                                  hidden
                                  value={data.order_id}
                                />
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  name="patient_id"
                                  label="patient_id"
                                  hidden
                                  value={data.patient_id}
                                />

                                <TextField
                                  margin="dense"
                                  fullWidth
                                  name="doctors_id"
                                  label="doctor_id"
                                  hidden
                                  value={
                                    data.doctor_id === null
                                      ? ""
                                      : data.doctor_id
                                  }
                                />
                              </Box>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="hav[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.hav === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                HCV (Hepatitis C Virus)
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {SorologyRef.normal()}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="hcv[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.vdrl_rpr === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                VDRL/RPR
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {SorologyRef.normal()}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="vdrl_rpr[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.hbsag === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                Hepatitis B surface Antigen (HBsAg)
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {SorologyRef.normal()}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="hbsag[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow
                            hidden={!Boolean(parseInt(data.syphilis_test))}
                          >
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                Syphilis Test
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {/* {SorologyRef.normal()} */}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="syphilis_test_result[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={!Boolean(parseInt(data.hiv_test))}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                HIV Test
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {/* {SorologyRef.normal()} */}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="hiv_test_result[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={!Boolean(parseInt(data.cd4_test))}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                CD4 Count
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {/* {SorologyRef.normal()} */}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="cd4_test_result[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={!Boolean(parseInt(data.rpr_test))}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                RPR
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {/* {SorologyRef.normal()} */}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  multiline
                                  name="rpr_test_result[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography color="secondary" variant="subtitle2">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <Typography color="primary" variant="subtitle2">
                          please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  hidden
                  ref={saveButtonRef}
                  disabled={!confirmDialog}
                >
                  Save Result Button
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setConfirmDialog(true)}
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="outlined"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.open}
        onClose={() => setProcessDialog({ open: false, data: null })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as processing</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ open: false, data: null })}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogContent>
          <Typography>Are you sure to continue and save result?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setConfirmDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={resultSubmitting}
            startIcon={
              resultSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => saveButtonRef.current.click()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SorologyOrderDetails;
