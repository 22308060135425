import React, { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import Notify from "src/notification/Notify";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormHelperText,
} from "@material-ui/core";
import IsMountedRef from "src/utils/IsMountedRef";
import Select from "react-select";

const PharmacyWarehouseNewInvoiceFrom = ({ getTempProductList }) => {
  const username = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const mounted = IsMountedRef();
  const [product, setProduct] = useState();
  const [batches, setBatches] = useState([]);

  const getProductList = async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/new-invoice/get-productlist",
      {
        params: {
          token: token,
          user_id: user_id,
          management_id,
        },
      }
    );
    if (mounted.current) {
      setProduct(response.data);
      getTempProductList();
    }
  };

  const getProductBatches = async (product_id) => {
    console.log(product_id);
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/product-details",
      {
        params: {
          token: token,
          management_id: management_id,
          product_id: product_id,
        },
      }
    );

    setBatches(response.data);
  };

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {
    getProductList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        username: username,
        user_id: user_id,
        token: token,
        management_id: management_id,
        selectedName: "",
        selectedGeneric: "",
        selectedUnit: "",
        selectedMsrp: "",
        selectedSrp: "",
        selectedBatchType: "",
        product_name: "",
        product_batch: "",
        product_manufactured: "",
        product_expiration: "",
        product_quantity: "",
      }}
      validationSchema={Yup.object().shape({
        product_name: Yup.string().required("Product name is a required field"),
        product_batch: Yup.string().required(
          "Product batch is a required field"
        ),
        product_manufactured: Yup.string().required(
          "Product manufactured date is a required field"
        ),
        product_expiration: Yup.string().required(
          "Product expiration date is a required field"
        ),
        product_quantity: Yup.string().required(
          "Product quantity is a required field"
        ),
      })}
      onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
        try {
          const request = await Axios.post(
            "pharmacy/warehouse/inventory/newinvoice-savetotemp",
            getFormData(values)
          );
          if (request.data.message === "batch-exist") {
            Notify.customToast("batch number", "Batch number not available");
          }
          if (request.data.message === "success") {
            Notify.successRequest("invoice added");
            getTempProductList();
            resetForm();
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <Typography color="textSecondary" variant="h6">
              Select Product
            </Typography>
          </Box>

          <Box mb={2}>
            <Select
              options={product}
              defaultValue={values.product_name}
              onChange={(value) => {
                setFieldValue("selectedName", value.product_name);
                setFieldValue("selectedGeneric", value.product_generic);
                setFieldValue("selectedUnit", value.unit);
                setFieldValue("selectedMsrp", value.msrp);
                setFieldValue("selectedSrp", value.srp);
                setFieldValue("product_name", value.product_id);
              }}
              placeholder="Select Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "black",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                }),
              }}
            />
          </Box>

          <Box mb={2}>
            <TextField
              value={values.selectedName}
              fullWidth
              required
              variant="outlined"
              InputProps={{ readOnly: true }}
              label="Product"
            />
          </Box>

          <Box mb={2}>
            <TextField
              value={values.selectedUnit}
              fullWidth
              required
              variant="outlined"
              InputProps={{ readOnly: true }}
              label="Unit"
            />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <TextField
                  value={values.selectedMsrp}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  label="MSRP"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <TextField
                  value={values.selectedSrp}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  label="SRP"
                />
              </Box>
            </Grid>
          </Grid>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Batch Number"
              variant="outlined"
              select
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
              defaultValue={values.selectedBatchType}
              disabled={!Boolean(values.product_name)}
              onChange={(e) => {
                setFieldValue("selectedBatchType", e.target.value);
                if (e.target.value === "existing_batch") {
                  getProductBatches(values.product_name);
                }
              }}
            >
              <option value=""> Select </option>
              <option value="new_batch"> New Batch </option>
              <option value="existing_batch"> Existing Batch </option>
            </TextField>
          </Box>

          {values.selectedBatchType === "new_batch" && (
            <Box>
              <Box>
                <TextField
                  label="Batch Number"
                  fullWidth
                  variant="outlined"
                  name="product_batch"
                  error={Boolean(touched.product_batch && errors.product_batch)}
                  helperText={touched.product_batch && errors.product_batch}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>

              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Manufactured Date"
                        fullWidth
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={values.product_manufactured}
                        name="product_manufactured"
                        error={Boolean(
                          touched.product_manufactured &&
                            errors.product_manufactured
                        )}
                        helperText={
                          touched.product_manufactured &&
                          errors.product_manufactured
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Expiration Date"
                        fullWidth
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={values.product_expiration}
                        name="product_expiration"
                        error={Boolean(
                          touched.product_expiration &&
                            errors.product_expiration
                        )}
                        helperText={
                          touched.product_expiration &&
                          errors.product_expiration
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Quantity"
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={values.product_quantity}
                  name="product_quantity"
                  error={Boolean(
                    touched.product_quantity && errors.product_quantity
                  )}
                  helperText={
                    touched.product_quantity && errors.product_quantity
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          )}

          {values.selectedBatchType === "existing_batch" && (
            <Box>
              <Box mb={2}>
                <TextField
                  label="Select batch"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setFieldValue("product_batch", e.target.value)
                  }
                >
                  <option value=""> Select </option>
                  {batches.map((data, index) => (
                    <option key={index} value={data.batch_number}>
                      {data.batch_number}
                    </option>
                  ))}
                </TextField>
              </Box>

              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Manufactured Date"
                        fullWidth
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={values.product_manufactured}
                        name="product_manufactured"
                        error={Boolean(
                          touched.product_manufactured &&
                            errors.product_manufactured
                        )}
                        helperText={
                          touched.product_manufactured &&
                          errors.product_manufactured
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Expiration Date"
                        fullWidth
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={values.product_expiration}
                        name="product_expiration"
                        error={Boolean(
                          touched.product_expiration &&
                            errors.product_expiration
                        )}
                        helperText={
                          touched.product_expiration &&
                          errors.product_expiration
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Quantity"
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={values.product_quantity}
                  name="product_quantity"
                  error={Boolean(
                    touched.product_quantity && errors.product_quantity
                  )}
                  helperText={
                    touched.product_quantity && errors.product_quantity
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          )}

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box mb={2} display="flex">
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="default"
              type="reset"
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
            <Box ml={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
                disabled={isSubmitting}
              >
                ADD
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PharmacyWarehouseNewInvoiceFrom;
