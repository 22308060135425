import {
  Typography,
  Grid,
  Box,
  Paper,
  Badge,
  List,
  TextField,
  InputAdornment,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  TablePagination,
} from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router";
import Container from "src/layout/Container";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import SearchIcon from "@material-ui/icons/Search";
import { blue, red } from "@material-ui/core/colors";
import Alert from "@material-ui/lab/Alert";
import CheckInternet from "src/utils/CheckInternet";
import axios from "axios";
import Notify from "src/notification/Notify";
import OrderDetails from "./OrderDetails";
import CheckingConnection from "src/CheckInternet";
import GTCInitialDisplay from "src/GTCInitialDisplay";

const ImagingPatients = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");

  const history = useHistory();
  const [online, setOnline] = useState("checking");
  const [patientList, setPatientList] = useState({ data: [], ready: false });

  const [allLocalRad, setAllLocalRad] = React.useState({
    data: [],
    ready: false,
  });

  const [allTeleRad, setAllTeleRad] = React.useState({
    data: [],
    ready: false,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("new-patient");
  const [newOrderVirtualCount, setNewOrderVirtualCount] = useState(0);

  const checkinternet = () => {
    CheckInternet.online()
      .then(() => setOnline("connected"))
      .catch(() => setOnline("disconnected"));
  };

  const getAllPatientForImaging = () => {
    var params = { user_id: user_id, management_id: management_id, token };
    axios
      .get("imaging/get/patient/forimaging", { params })
      .then((response) => {
        const data = response.data;
        setPatientList({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getImagingVirtualOrder = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get(
        "imaging/virtual/get/patient/forimaging",
        { params }
      );
      setNewOrderVirtualCount(response.data.length);
    } catch (error) {
      console.log("Unable to get badge for virtual.", error);
    }
  };

  const getAllLocalRadiologist = () => {
    var params = { user_id: user_id, management_id: management_id, token };
    axios
      .get("imaging/get-all-localrad-list", { params })
      .then((response) => {
        const data = response.data;
        setAllLocalRad({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getAllTeleRadiologist = () => {
    var params = { user_id: user_id, management_id: management_id, token };
    axios
      .get("imaging/get-all-telerad-list", { params })
      .then((response) => {
        const data = response.data;
        setAllTeleRad({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    checkinternet();
    getImagingVirtualOrder();
    getAllPatientForImaging();
    getAllLocalRadiologist();
    getAllTeleRadiologist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = patientList.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patients",
          items: [{ path: "/clinic/app", name: "Dashboard" }],
        }}
        title={"Patient"}
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid item sm={4} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    {online === "checking" && <CheckingConnection />}
                    {online === "disconnected" && (
                      <Fragment>
                        <Box border={1} borderRadius={4} borderColor={red[100]}>
                          <Alert
                            icon={<WifiOffIcon style={{ fontSize: "2.2em" }} />}
                            severity="error"
                          >
                            <Typography variant="subtitle2">
                              {" "}
                              Offline.{" "}
                            </Typography>
                            <Typography variant="body2">
                              {" "}
                              The system cannot receive orders from virtual
                              doctors.{" "}
                            </Typography>
                          </Alert>
                        </Box>
                      </Fragment>
                    )}
                    {online === "connected" && (
                      <Box
                        display="flex"
                        border={1}
                        borderRadius={4}
                        borderColor={blue[100]}
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/clinic/app/virtual")}
                      >
                        <Box flexGrow={1}>
                          <Alert
                            icon={<WifiIcon style={{ fontSize: "2.2em" }} />}
                            severity="info"
                          >
                            <Typography variant="subtitle1">
                              {" "}
                              Online{" "}
                            </Typography>
                            <Typography variant="subtitle2">
                              {" "}
                              The system can receive orders from virtual
                              doctors.{" "}
                            </Typography>
                          </Alert>
                        </Box>
                        <Badge
                          badgeContent={newOrderVirtualCount}
                          color="secondary"
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Paper component={Box}>
                      <Box mt={1}>
                        <Box p={2} borderRadius={4}>
                          <Box display="flex" justifyContent="center">
                            <Box flexGrow={1}>
                              <Typography
                                noWrap
                                variant="h6"
                                color="textSecondary"
                              >
                                New Patient
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box m={2}>
                          {patientList.ready > 0 ? (
                            patientList.data.length > 0 ? (
                              <List component="div">
                                <Box mb={2}>
                                  <TextField
                                    label="Search patient"
                                    variant="outlined"
                                    fullWidth
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment>
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                                <>
                                  {searchable.map((data, index) => {
                                    return (
                                      <ListItem
                                        key={index}
                                        button
                                        className="mb-2"
                                        onClick={() => {
                                          setSelectedPatient(data.patients_id);
                                        }}
                                        selected={
                                          selectedPatient === data.patients_id
                                        }
                                      >
                                        <ListItemIcon>
                                          <Avatar>
                                            {data.name !== null
                                              ? data.name.charAt().toUpperCase()
                                              : "N"}
                                          </Avatar>
                                        </ListItemIcon>
                                        <ListItemText
                                          className={`gtc-capitalize`}
                                          primary={`${data.name}`}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </>
                              </List>
                            ) : (
                              <Box> {Notify.noRecord()} </Box>
                            )
                          ) : (
                            <Box> {Notify.loading()} </Box>
                          )}

                          <TablePagination
                            component="div"
                            count={patientList.data.length}
                            rowsPerPageOptions={[10, 50, 100]}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage="List"
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={8} xs={12}>
                {selectedPatient === "new-patient" && <GTCInitialDisplay />}
                {selectedPatient !== "new-patient" && (
                  <OrderDetails
                    patient_id={selectedPatient}
                    allLocalRad={allLocalRad}
                    allTeleRad={allTeleRad}
                    onClose={() => {
                      setSelectedPatient("new-patient");
                    }}
                    getAllPatientForImaging={() => getAllPatientForImaging()}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default ImagingPatients;
