import React, { useState, useEffect, useCallback, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Box, Grid, Collapse, Typography, IconButton, Avatar, CircularProgress, TextField, Zoom, Badge, TablePagination } from '@material-ui/core';
import { Lightbox } from "react-modal-image";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { DropzoneArea } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LaboratoryOrderDetails from './LaboratoryOrderDetails';
import { useHistory } from 'react-router-dom';
import Container from 'src/layout/Container';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PrintIcon from '@material-ui/icons/Print';
import BarChartIcon from '@material-ui/icons/BarChart';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import LaboratoryOrderSibDetails from './LaboratoryOrderSibDetails';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const LaboratoryNew = () => {
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [token] = useState(localStorage.getItem('token'))
    const [username] = useState(localStorage.getItem('username'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [laboratory, setLaboratory] = useState([])
    const [laboratoryReady, setLaboratoryReady] = useState(false)

    const [selectedCollapseId, setselectedCollapseId] = useState(null)
    const [selectedCollapseIdSib, setselectedCollapseIdSib] = useState(null)
    const [selectedIdAndCat, setSelectedIdAndCat] = useState({
        orderID: null,
        orderCategory: null,
    })

    const [uploadProcess, setuploadProcess] = useState(false)
    const [sharedImages, setSharedImages] = useState([])
    const [sharedImagesReady, setsharedImagesReady] = useState(false)
    const [sharedDate, setSharedDate] = useState([])
    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState([])
    const [category, setCategory] = useState('');
    const [openLightbox, setopenLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [sharedRowsPerPage, setSharedRowsPerPage] = useState(3)
    const [sharedPage, setSharedPage] = useState(0)
    const history = useHistory();

    // const [sHowGraphDialog, setSHowGraphDialog] = useState(false);

    // const [orderPrint, setOrderPrint] = useState({
    //     open: false,
    //     doctors_id: null,
    //     patient_id: null,
    //     order: null,
    //     order_date: null
    // })

    const [orderDetails, setOrderDetails] = useState({
        openOrderDetails: false,
        doctors_id: null,
        patient_id: null,
        order_id: null,
        order_date: null,
        formheader: null,
        order_category: null,
    })

    const bodyparts = [
        { id: 1, value: 'Arm' },
        { id: 2, value: 'Body' },
        { id: 3, value: 'Foot' },
        { id: 4, value: 'Head' },
        { id: 5, value: 'Legs' }
    ]

    const getLaboratoryList = useCallback(() => {
        var params = {
            token: token,
            management_id: management_id,
            user_id: user_id,
        }
        // Axios.post('laboratory/patient/patient-laboratory', formdata ) //
        Axios.get('laboratory/patient/patient-laboratory-test', { params })
            .then((response) => {
                const data = response.data;
                setLaboratory(data)
                setLaboratoryReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, management_id, user_id]);

    const getSharedImagesAll = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            type: 'laboratory',
        }
        Axios.get('patient/shared/images/shared-list', { params })
            .then((response) => {
                const data = response.data;
                setSharedImages(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    const getSharedDateInGroupBy = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            type: 'laboratory',
        }
        Axios.get('patient/shared/images/shared-groupdate', { params })
            .then((response) => {
                const data = response.data;
                setSharedDate(data)
                setsharedImagesReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    const handleUpload = (e) => {
        e.persist()
        e.preventDefault()
        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.append('share_image', files[0]);
        formdata.set('type', 'laboratory');
        // for (let i = 0; i < files.length; i++) {
        //     formdata.append('share_image[]', files[i]);    
        // }
        var error = [];

        if (formdata.get('category').length === 0 || formdata.get('category').trim() === '') {
            error = 'error';
            Notify.fieldRequired('category');
        }
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }
        if (error.length > 0) {
            console.warn("form has error.")
        }
        else {
            setuploadProcess(true)
            Axios.post('patient/shared/images/share-new', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('upload')
                        getSharedImagesAll()
                        getSharedDateInGroupBy()
                        setOpen(false)
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setuploadProcess(false)
                });
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (files) => {
        //Saving files to state for further use and closing Modal.  
        setFiles(files)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleOpenlightbox = (e) => {
        setSelectedImage(e.currentTarget.getAttribute('image'))
        setopenLightbox(true)
    }

    const handleCloselightbox = (e) => {
        setSelectedImage(null)
        setopenLightbox(false)
    }

    useEffect(() => {
        getLaboratoryList()
        getSharedImagesAll()
        getSharedDateInGroupBy()
    }, [getLaboratoryList, getSharedImagesAll, getSharedDateInGroupBy])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage)
    };

    const handleSharedChangeRowsPerPage = (event) => {
        setSharedRowsPerPage(parseInt(event.target.value, 10))
        setSharedPage(0)
    };

    const handleSharedChangePage = (event, newPage) => {
        event.persist()
        setSharedPage(newPage)
    };

    const handleUpdateUnread = (order_id) => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order_id)
        formdata.set('connection', 'online')

        Axios.post('patient/notification/update/unread', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    getLaboratoryList();
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const paginateLaboratory = laboratory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const paginateSharedDate = sharedDate.slice(sharedPage * sharedRowsPerPage, sharedPage * sharedRowsPerPage + sharedRowsPerPage);

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'laboratory',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="My Laboratory"
            >
                <Grid container spacing={2}>
                    <Grid item sm={8} xs={12}>

                        <Box mb={1} className="clearfix">
                            <Box className="float-left" mt={1}>
                                <Typography variant="h6" color="textSecondary"> Order List </Typography>
                            </Box>
                        </Box>
                        {
                            laboratoryReady ?
                                laboratory.length > 0 ?
                                    paginateLaboratory.map((data, index) =>
                                        <Box
                                            key={index}
                                            p={2}
                                            border={1}
                                            mb={2}
                                            borderRadius={4}
                                            bgcolor="background.paper"
                                        >
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Box flexGrow={1}>
                                                    <Typography>
                                                        {Notify.dateTimeConvert(data.created_at)}
                                                    </Typography>
                                                </Box>

                                                <Box>
                                                    <IconButton
                                                        variant="outlined"
                                                        color={'primary'}
                                                        onClick={() => {
                                                            setOrderDetails({
                                                                openOrderDetails: true,
                                                                doctors_id: data.doctors_id,
                                                                patient_id: data.patient_id,
                                                                formheader: data,
                                                                order_id: data.order_id,
                                                                order_date: data.created_at,
                                                            });
                                                        }}
                                                    >
                                                        <PrintIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        variant="outlined"
                                                        color={'primary'}
                                                        onClick={() => {
                                                            parseFloat(data.unreadCount) > 0 && (handleUpdateUnread(data.order_id));
                                                            setselectedCollapseId(selectedCollapseId === data.id ? null : data.id);
                                                            setSelectedIdAndCat({ ...selectedIdAndCat, orderID: data.order_id });
                                                            setselectedCollapseIdSib(null);
                                                        }}
                                                    >
                                                        {selectedCollapseId === data.id ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Collapse in={selectedCollapseId === data.id ? true : false}>
                                                <Box mt={2} >
                                                    {parseFloat(data.count_chem) > 0 && (
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Box flexGrow={1}>
                                                                <Typography>
                                                                    <strong> &bull;Clinical Chemistry </strong>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => history.push('/clinic/app/patient/laboratory/clinical-chemistry')}
                                                                >
                                                                    <BarChartIcon />
                                                                </IconButton>

                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => {
                                                                        setselectedCollapseIdSib(selectedCollapseIdSib === 'clinical-chem' ? null : 'clinical-chem');
                                                                        setSelectedIdAndCat({ ...selectedIdAndCat, orderCategory: 'clinical-chem' });
                                                                    }}
                                                                >
                                                                    {selectedCollapseId === data.id && selectedCollapseIdSib === 'clinical-chem' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <Collapse in={selectedCollapseId === data.id && selectedCollapseIdSib === 'clinical-chem' ? true : false}>
                                                        {selectedCollapseId === data.id && selectedCollapseIdSib === 'clinical-chem' && (
                                                            <LaboratoryOrderSibDetails selectedIdAndCat={selectedIdAndCat} />
                                                        )}
                                                    </Collapse>
                                                    {parseFloat(data.count_micro) > 0 && (
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Box flexGrow={1}>
                                                                <Typography>
                                                                    <strong>&bull;Clinical Microscopy</strong>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => history.push('/clinic/app/patient/laboratory/clinical-microscopy')}
                                                                >
                                                                    <BarChartIcon />
                                                                </IconButton>

                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => {
                                                                        setselectedCollapseIdSib(selectedCollapseIdSib === 'clinical-micro' ? null : 'clinical-micro');
                                                                        setSelectedIdAndCat({ ...selectedIdAndCat, orderCategory: 'clinical-micro' });
                                                                    }}
                                                                >
                                                                    {selectedCollapseId === data.id && selectedCollapseIdSib === 'clinical-micro' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <Collapse in={selectedCollapseId === data.id && selectedCollapseIdSib === 'clinical-micro' ? true : false}>
                                                        {selectedCollapseId === data.id && selectedCollapseIdSib === 'clinical-micro' && (
                                                            <LaboratoryOrderSibDetails selectedIdAndCat={selectedIdAndCat} />
                                                        )}
                                                    </Collapse>
                                                    {parseFloat(data.count_feca) > 0 && (
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Box flexGrow={1}>
                                                                <Typography>
                                                                    <strong>&bull;Fecal Analysis</strong>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => history.push('/clinic/app/patient/laboratory/fecal-analysis')}
                                                                >
                                                                    <BarChartIcon />
                                                                </IconButton>

                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => {
                                                                        setselectedCollapseIdSib(selectedCollapseIdSib === 'fecal-analysis' ? null : 'fecal-analysis');
                                                                        setSelectedIdAndCat({ ...selectedIdAndCat, orderCategory: 'fecal-analysis' });
                                                                    }}
                                                                >
                                                                    {selectedCollapseId === data.id && selectedCollapseIdSib === 'fecal-analysis' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <Collapse in={selectedCollapseId === data.id && selectedCollapseIdSib === 'fecal-analysis' ? true : false}>
                                                        {selectedCollapseId === data.id && selectedCollapseIdSib === 'fecal-analysis' && (
                                                            <LaboratoryOrderSibDetails selectedIdAndCat={selectedIdAndCat} />
                                                        )}
                                                    </Collapse>
                                                    {parseFloat(data.count_hema) > 0 && (
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Box flexGrow={1}>
                                                                <Typography>
                                                                    <strong>&bull;Hemathology</strong>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => history.push('/clinic/app/patient/laboratory/hemathology')}
                                                                >
                                                                    <BarChartIcon />
                                                                </IconButton>

                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => {
                                                                        setselectedCollapseIdSib(selectedCollapseIdSib === 'hemathology' ? null : 'hemathology');
                                                                        setSelectedIdAndCat({ ...selectedIdAndCat, orderCategory: 'hemathology' });
                                                                    }}
                                                                >
                                                                    {selectedCollapseId === data.id && selectedCollapseIdSib === 'hemathology' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <Collapse in={selectedCollapseId === data.id && selectedCollapseIdSib === 'hemathology' ? true : false}>
                                                        {selectedCollapseId === data.id && selectedCollapseIdSib === 'hemathology' && (
                                                            <LaboratoryOrderSibDetails selectedIdAndCat={selectedIdAndCat} />
                                                        )}
                                                    </Collapse>
                                                    {parseFloat(data.count_sero) > 0 && (
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Box flexGrow={1}>
                                                                <Typography>
                                                                    <strong>&bull;Serology</strong>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color={'primary'}
                                                                    onClick={() => {
                                                                        setselectedCollapseIdSib(selectedCollapseIdSib === 'serology' ? null : 'serology');
                                                                        setSelectedIdAndCat({ ...selectedIdAndCat, orderCategory: 'serology' });
                                                                    }}
                                                                >
                                                                    {selectedCollapseId === data.id && selectedCollapseIdSib === 'serology' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <Collapse in={selectedCollapseId === data.id && selectedCollapseIdSib === 'serology' ? true : false}>
                                                        {selectedCollapseId === data.id && selectedCollapseIdSib === 'serology' && (
                                                            <LaboratoryOrderSibDetails selectedIdAndCat={selectedIdAndCat} />
                                                        )}
                                                    </Collapse>
                                                </Box>
                                            </Collapse>
                                        </Box>
                                    )
                                    : Notify.noRecord()
                                : Notify.loading()
                        }

                        <TablePagination
                            labelRowsPerPage="List"
                            rowsPerPageOptions={[5, 20, 50, 100, { label: 'All', value: -1 }]}
                            component="div"
                            count={laboratory.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <Box mb={1} className="clearfix">
                            <Box className="float-left" mt={1}>
                                <Typography variant="h6" color="textSecondary"> Shared Images </Typography>
                            </Box>
                            <Box className="float-right">
                                <IconButton color="primary" onClick={handleOpen}>
                                    <AddAPhotoIcon />
                                </IconButton>

                            </Box>
                        </Box>
                        {
                            sharedImagesReady ?
                                sharedDate.length > 0 ?
                                    paginateSharedDate.map((d, dindex) => {
                                        return (
                                            <Box key={dindex} mb={2}>
                                                <Typography> {Notify.createdAt(d.shared_date)} </Typography>
                                                {/* check if match the date */}
                                                <Box
                                                    display="flex"
                                                    flexWrap="wrap"
                                                >
                                                    {
                                                        sharedImages.length > 0 ?
                                                            sharedImages.map((data, index) => {
                                                                return (
                                                                    <Box
                                                                        p={1}
                                                                        key={index}
                                                                        className="pointer"
                                                                        hidden={Notify.createdAt(d.shared_date) === Notify.createdAt(data.created_at) ? false : true}
                                                                    >
                                                                        <Badge
                                                                            overlap="rectangle"
                                                                            anchorOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            color="error"
                                                                            badgeContent={data.category}
                                                                        >
                                                                            <Avatar
                                                                                onClick={handleOpenlightbox}
                                                                                style={{
                                                                                    width: '100px',
                                                                                    height: '100px'
                                                                                }}
                                                                                variant="rounded"
                                                                                image={data.image}
                                                                                alt={data.category}
                                                                                title={data.category}
                                                                                src={imageLocation + 'imaging/sharedimages/' + data.image}
                                                                            />
                                                                        </Badge>
                                                                    </Box>
                                                                )
                                                            })
                                                            : Notify.noRecord()
                                                    }
                                                </Box>
                                            </Box>
                                        )
                                    })
                                    : Notify.noRecord()
                                : Notify.loading()
                        }

                        <TablePagination
                            labelRowsPerPage="List"
                            rowsPerPageOptions={[3, 20, 50, 100, { label: 'All', value: -1 }]}
                            component="div"
                            count={sharedDate.length}
                            rowsPerPage={sharedRowsPerPage}
                            page={sharedPage}
                            onChangePage={handleSharedChangePage}
                            onChangeRowsPerPage={handleSharedChangeRowsPerPage}
                        />
                    </Grid>

                </Grid>

                {/* lightbox images */}
                {
                    openLightbox && (
                        <Lightbox
                            large={imageLocation + 'imaging/sharedimages/' + selectedImage}
                            alt={selectedImage}
                            onClose={handleCloselightbox}
                            showRotate={true}
                        />
                    )
                }
                {/* upload dialogs */}
                <Dialog
                    TransitionComponent={Zoom}
                    open={open}
                    onClose={handleClose}
                    disableBackdropClick
                    disableEscapeKeyDown
                    transitionDuration={900}
                >
                    <form onSubmit={handleUpload} encType="multipart/form-data">
                        <DialogTitle id="alert-dialog-title"> Share Image </DialogTitle>
                        <DialogContent dividers>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                margin="dense"
                            >
                                <InputLabel id="demo-simple-select-label"> Category </InputLabel>
                                <Select
                                    label="Category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    name="category"
                                >
                                    {
                                        bodyparts.map((data) => (
                                            <MenuItem value={data.value} key={data.id}> { data.value} </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <Box my={2}>
                                <DropzoneArea
                                    filesLimit={1}
                                    onChange={handleChange}
                                    acceptedFiles={['image/jpeg', 'image/png']}
                                />
                            </Box>

                            <TextField
                                fullWidth
                                label="Password"
                                variant="outlined"
                                margin="dense"
                                name="password"
                                type="password"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setOpen(false)}
                                variant="contained"
                                color="default"
                                startIcon={
                                    <CancelIcon />
                                }
                            >
                                Close
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    uploadProcess ? <CircularProgress size={15} color="inherit" /> : <CloudUploadIcon />
                                }
                                variant="contained"
                                color="primary"
                                autoFocus
                                disabled={uploadProcess}
                            >
                                Upload
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    TransitionComponent={Zoom}
                    transitionDuration={600}
                    open={orderDetails.openOrderDetails}
                    fullScreen
                >
                    <LaboratoryOrderDetails
                        orderDetails={orderDetails}
                        close={() => setOrderDetails({
                            openOrderDetails: false,
                            doctors_id: null,
                            patient_id: null,
                            formheader: null,
                            order_id: null,
                            order_date: null
                        })}
                    />
                </Dialog>
            </Container>
        </Fragment >
    )
}

export default LaboratoryNew;