import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  ListItemIcon,
} from "@material-ui/core";
import Report from "./Report";
import Notify from "src/notification/Notify";
import axios from "axios";
import LocalAppDetails from "./LocalAppDetails";
import Container from "src/layout/Container";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ArrowLeft, FileText } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: 7,
  },
}));

const LocalAppointment = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [displayType, setDisplayType] = useState("patient-list");

  const [incomplete, setIncomplete] = useState({
    data: [],
    ready: false,
  });

  const [selectedApp, setSelectedApp] = useState(null);

  const getIncompleteList = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/appointment/local/incomplete-list", formdata)
      .then((response) => {
        const data = response.data;
        setIncomplete({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getIncompleteList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Clinic Appointment",
        items: [{ name: "Dashboard", path: "/clinic/app/encoder" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}> Clinic Appointment </Box>
          <Box>
            <Button
              variant="contained"
              color={displayType === "report-list" ? "secondary" : "primary"}
              startIcon={
                displayType === "patient-list" ? <FileText /> : <ArrowLeft />
              }
              onClick={() =>
                setDisplayType(
                  displayType === "report-list" ? "patient-list" : "report-list"
                )
              }
            >
              {displayType === "report-list" ? "Back" : "Report"}
            </Button>
          </Box>
        </Box>
      }
    >
      {displayType === "report-list" ? (
        <Report />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title="Appointment" />
              <CardContent>
                <Box maxHeight="calc(90vh - 120px)" overflow={"auto"}>
                  <PerfectScrollbar>
                    <List component="div">
                      {incomplete.ready
                        ? incomplete.data.length > 0
                          ? incomplete.data.map((data, index) => (
                              <ListItem
                                key={index}
                                onClick={() => setSelectedApp(data)}
                                selected={
                                  selectedApp && selectedApp.id === data.id
                                }
                                button
                              >
                                <ListItemIcon>
                                  {data.image === null ? (
                                    <Avatar className={classes.primary}>
                                      {data.lname.charAt().toUpperCase()}
                                    </Avatar>
                                  ) : (
                                    <Avatar
                                      className={classes.primary}
                                      src={`${imageLocation}patients/${data.image}`}
                                      alt=""
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  className={`gtc-capitalize`}
                                  primary={`${data.lname}, ${data.fname}`}
                                  secondary={Notify.dateTimeConvert(
                                    data.appointment_date
                                  )}
                                />
                              </ListItem>
                            ))
                          : Notify.noRecord()
                        : Notify.loading()}
                    </List>
                  </PerfectScrollbar>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {selectedApp && (
            <Grid item xs={12} sm={8}>
              <LocalAppDetails
                app={selectedApp}
                getIncompleteList={getIncompleteList}
                resetDisplay={() => setSelectedApp(null)}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default LocalAppointment;
