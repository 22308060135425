import React, { useContext, useRef, useState } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios'; 
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination'; 
import Typography from '@material-ui/core/Typography'; 
import Avatar from '@material-ui/core/Avatar'; 
import { InputAdornment, TextField, Badge, Paper, TableContainer, Table, TableRow, TableCell, TableBody, CircularProgress, makeStyles, TableHead } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { PatientsUnRead } from 'src/ContextAPI';
import {  useHistory } from 'react-router-dom'; 
import Container from 'src/layout/Container';
import { Trello as NorecordIcon } from 'react-feather';
import IsMountedRef from 'src/utils/IsMountedRef';


const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({ 
    primary: { 
      backgroundColor: theme.palette.primary.main,
      color: '#fff', 
        width: theme.spacing(6.8),
        height: theme.spacing(6.8), 
    }
}));

const Patients = () =>{
    const classes = useStyles()
    const user_id =  localStorage.getItem('user_id')
    const token =  localStorage.getItem('token')  
    const history = useHistory()

    const [patients, setPatients] = useState({ data: [] , ready: false })

    const [search , setSearch] = useState('') 

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0) 

    const tableRef = useRef(null)

    const unread = useContext(PatientsUnRead);
    
    var interval = null;

    const ismounted = IsMountedRef()

    const getPatients = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);

        Axios.post('doctor/patient/patients-list', formdata)
        .then((response) => {
            if(ismounted.current){
                const data = response.data;
                setPatients({ data: data, ready: true }) 
            }
        }).catch(error => {
            Notify.requestError(error);
        });
    }    

    const checkPatientUnreadNotif = ( patient_id, index ) =>{
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual; 

        let count = 0;

        for (let i = 0; i < xx.length; i++) {
            if( patient_id === xx[i].patient_id){
                count += 1;
            }  
        }  

        for (let i = 0; i < yy.length; i++) {
            if( patient_id === yy[i].patient_id){
                count += 1;
            } 
        }       

        return count; 
    } 

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage)
    };    

    const initializeTableSort = () =>{
        interval = setInterval(() => {
            
            sortTable() 

        }, 3000);
    }

    const sortTable = () => {

        var table, rows, switching, i, x, y, shouldSwitch;
            table = tableRef.current; 
            switching = true;  

        while (switching) { 

            switching = false;
            rows = table.rows;   

            for (i = 1; i < (rows.length - 1); i++) { 

                shouldSwitch = false; 
                x = rows[i].getElementsByTagName("TD")[0];
                y = rows[i + 1].getElementsByTagName("TD")[0];    

                if (Number(x.innerHTML) < Number(y.innerHTML)) { 
                    shouldSwitch = true;
                    break;
                }
            }

            if (shouldSwitch) { 
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;  
            } 
        }
    }

    React.useEffect(() =>{

        getPatients()    

        if(tableRef.current){
            initializeTableSort()
        } 

        return(() =>{
            clearInterval(interval)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])  


    var searchable = patients.data.filter( (data) => {
        return data.lastname.toLowerCase().indexOf(search.toLowerCase()) !== -1 || data.firstname.toLowerCase().indexOf(search.toLowerCase()) !== -1;;
    });  
    
 
    return (
        <>
            <Container 
                breadcrumbs={{
                    enable: true,
                    current: 'Patient',
                    items:[
                        { name: 'Dashboard', path: '/clinic/app/doctor' },  
                    ]
                }} 
                title="Patients List"
            >
                <Paper  
                    component={Box} 
                    elevation={2}
                    p={2}
                > 
                    <Box >
                        <Box
                            display="flex"
                            justifyContent="center"
                            mb={2}
                        >
                            <Box 
                                flexGrow={1} 
                                mt={1}
                            />  
                            <Box>
                                <TextField
                                    label="Search name"
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    fullWidth
                                    variant="outlined" 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <SearchIcon color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>    
                        </Box>  
                        
                        <TableContainer>
                            <Table ref={ tableRef }>
                                <TableHead>
                                    {/* controller of sortby desc */}
                                    <TableRow hidden>
                                        <TableCell style={{ width: 0 }} /> 
                                    </TableRow>
                                </TableHead>

                                <TableBody> 
                                    {
                                        patients.ready ?
                                            patients.data.length > 0 ?
                                                (rowsPerPage > 0 ? searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchable )
                                                .map((data, index) =>(
                                                    <TableRow 
                                                        key={index} 
                                                        hover 
                                                        className={`pointer`}
                                                        onClick={ () =>{
                                                            history.push(`/clinic/app/doctor/patient/details/${data.patient_id}`)
                                                        }}
                                                    >
                                                        {/* sort by desc purposes */}
                                                        <TableCell hidden> 
                                                            { checkPatientUnreadNotif(data.patient_id) }
                                                        </TableCell>
                                                        <TableCell style={{ width: 50 }} >   
                                                            <Badge 
                                                                invisible={ ! Boolean(checkPatientUnreadNotif(data.patient_id)) }
                                                                color="error"
                                                                badgeContent={ checkPatientUnreadNotif(data.patient_id) }
                                                                anchorOrigin={{
                                                                    horizontal: 'left',
                                                                    vertical: 'top'
                                                                }}
                                                            >    
                                                                { 
                                                                    data.image === null ? 
                                                                        <Avatar 
                                                                            className={ classes.primary }
                                                                        > 
                                                                            { data.lastname.charAt().toUpperCase() } 
                                                                        </Avatar>
                                                                        : 
                                                                        <Avatar 
                                                                            className={ classes.primary }
                                                                            src={`${imageLocation}patients/${data.image}`} 
                                                                            alt="" 
                                                                        />
                                                                } 
                                                            </Badge>
                                                        </TableCell>

                                                        <TableCell>  
                                                            <Typography variant={'h6'} className={`gtc-capitalize`}>
                                                                {` ${data.lastname}, ${data.firstname}`}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )) 
                                            :   <TableRow>
                                                    <TableCell colSpan={2} align="center"> 
                                                        <Typography color="secondary"> <NorecordIcon size={30} /> <br /> No patient found. </Typography>
                                                    </TableCell> 
                                                </TableRow>
                                        :   <TableRow>
                                                <TableCell colSpan={2} align="center">
                                                    <CircularProgress size={20} color="primary" />
                                                    <Typography color="primary">  please wait... </Typography>
                                                </TableCell> 
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                component="div"
                                count={searchable.length}
                                rowsPerPageOptions={[10, 50, 100]}
                                page={page}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage} 
                            /> 
                        </TableContainer> 
                    </Box> 
                </Paper>
            </Container> 
        </>
    )
}

export default Patients;  
     