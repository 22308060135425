import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  makeStyles,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import HemathologyOrder from "./HemathologyOrder";
import SerologyOrder from "./SerologyOrder";
import MicroscopyOrder from "./MicroscopyOrder";
import FecalOrder from "./FecalOrder";
import ChemistryOrder from "./ChemistryOrder";
import SearchIcon from "@material-ui/icons/Search";
import { blue } from "@material-ui/core/colors";
import Container from "src/layout/Container";
import UrinalysisOrder from "./UrinalysisOrder";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const NewLaboratory = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");

  const [search, setSearch] = useState("");

  const [patientsList, setPatientsList] = useState({
    data: [],
    ready: false,
  });

  const [selectedPatient, setSelectedPatient] = useState({
    patient_id: null,
    category: "",
  });

  var interval = null;

  const getPatientsWithOrder = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("management_id", management_id);

    Axios.post("laboratory/order/getpatientswith-neworder", formdata)
      .then((response) => {
        const data = response.data;
        setPatientsList({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, management_id]);

  const checkcount = () => {
    interval = setInterval(() => {
      getPatientsWithOrder();
    }, 15000);
  };

  useEffect(() => {
    checkcount();
    getPatientsWithOrder();

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = patientsList.data.filter((data) => {
    return (
      data.patient_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
      -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "patients",
          items: [{ name: "Dashboard", path: "/clinic/app" }],
        }}
        title={"Patients"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Paper component={Box} variant="outlined" mb={2}>
              <Box ml={2} mt={2}>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  PATIENTS LIST
                </Typography>
              </Box>

              {patientsList.ready > 0 ? (
                patientsList.data.length > 0 ? (
                  <List component="div">
                    <Box mx={2} mb={2}>
                      <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <>
                      {searchable.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <ListItem
                              button
                              hidden={!Boolean(parseInt(data.order_count))}
                              onClick={() =>
                                setSelectedPatient({
                                  ...selectedPatient,
                                  patient_id: data.pid,
                                })
                              }
                              selected={selectedPatient.patient_id === data.pid}
                            >
                              <ListItemIcon>
                                <AssignmentIcon
                                  color={
                                    selectedPatient.patient_id === data.pid
                                      ? "primary"
                                      : "inherit"
                                  }
                                />
                              </ListItemIcon>

                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={data.patient_name}
                                primaryTypographyProps={{
                                  color:
                                    selectedPatient.patient_id === data.pid
                                      ? "primary"
                                      : "inherit",
                                }}
                              />
                            </ListItem>

                            <Collapse
                              in={selectedPatient.patient_id === data.pid}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={
                                    !Boolean(
                                      parseInt(data.count_hema) +
                                        parseInt(data.count_hema_cbc)
                                    )
                                  }
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "hemathology",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "hemathology"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "hemathology"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Hemathology"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_reso))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "serology",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category === "serology"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category === "serology"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Serology"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_micro))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "microscopy",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "microscopy"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "microscopy"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Clinical Microscopy"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_fecal))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "fecal",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category === "fecal"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category === "fecal"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Fecal Analysis"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_chem))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "chemistry",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category === "chemistry"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category === "chemistry"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Clinical Chemistry"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={
                                    !Boolean(parseInt(data.count_urinalysis))
                                  }
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "urinalysis",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "urinalysis"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "urinalysis"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Urinalysis"}
                                  />
                                </ListItem>
                              </List>
                            </Collapse>
                          </Fragment>
                        );
                      })}
                    </>
                  </List>
                ) : (
                  <Box>{Notify.noRecord()}</Box>
                )
              ) : (
                <Box>{Notify.loading()}</Box>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Box>
              {selectedPatient.category === "" && (
                <>
                  <Typography color="secondary" variant="h3" align="center">
                    NO ORDER SELECTED
                  </Typography>

                  <Typography variant="h6" align="center">
                    Select order to display information
                  </Typography>
                </>
              )}
              {selectedPatient.patient_id !== null &&
                selectedPatient.category === "hemathology" && (
                  <HemathologyOrder patient_id={selectedPatient.patient_id} />
                )}
              {selectedPatient.patient_id !== null &&
                selectedPatient.category === "serology" && (
                  <SerologyOrder patient_id={selectedPatient.patient_id} />
                )}
              {selectedPatient.patient_id !== null &&
                selectedPatient.category === "microscopy" && (
                  <MicroscopyOrder patient_id={selectedPatient.patient_id} />
                )}
              {selectedPatient.patient_id !== null &&
                selectedPatient.category === "fecal" && (
                  <FecalOrder patient_id={selectedPatient.patient_id} />
                )}
              {selectedPatient.patient_id !== null &&
                selectedPatient.category === "chemistry" && (
                  <ChemistryOrder patient_id={selectedPatient.patient_id} />
                )}

              {selectedPatient.patient_id !== null &&
                selectedPatient.category === "urinalysis" && (
                  <UrinalysisOrder patient_id={selectedPatient.patient_id} />
                )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default NewLaboratory;
