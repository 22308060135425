import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import DoctorsDetailsBasic from './DoctorsDetailsBasic';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import DoctorsServices from './DoctorsServices';
import DoctorsServiceNew from './DoctorsServiceNew';

const DoctorsDetails = ({ doctorUserId, getDoctorsList }) => {

    const token = localStorage.getItem('token')
    const [details, setDetails] = useState(null)
    const [services, setServices] = useState([])

    const [open, setOpen] = useState(false)

    const getDocDetails = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('doctorUserId', doctorUserId)
            const request = await Axios.post('gtcadmin/doctors/doctors-information', formdata)
            setDetails(request.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, doctorUserId])

    const getOnlineService = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('doctorUserId', doctorUserId)
            const request = await Axios.post('gtcadmin/doctors/doctors-servicesonlline', formdata)
            setServices(request.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, doctorUserId])

    useEffect(() => {

        getDocDetails()
        getOnlineService()

    }, [getDocDetails, getOnlineService])

    return (
        <Box>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                >
                    <Box
                        p={2}
                        component={Paper}
                        className={`gtc-textfield-noborder `}
                    >
                        {details === null && (<Typography color="secondary"> Doctors information not found. </Typography>)}
                        {details && (<DoctorsDetailsBasic details={details} getDocDetails={getDocDetails} doctorUserId={doctorUserId} />)}
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                >
                    <Box
                        p={2}
                        component={Paper}
                    >
                        <Box
                            mb={2}
                            display={'flex'}
                        >
                            <Box
                                flexGrow={1}
                                mt={2}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                >
                                    DOCTORS SERVICES
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={
                                        () => setOpen(true)
                                    }
                                >
                                    <PlusIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        {services.length === 0 && (<Typography color="secondary"> No services. </Typography>)}
                        {services.length > 0 && (<DoctorsServices services={services} />)}
                        {open && (<DoctorsServiceNew doctorUserId={doctorUserId} open={open} close={() => setOpen(false)} getOnlineService={getOnlineService} />)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DoctorsDetails;