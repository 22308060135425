import React, { Fragment, useState, useEffect } from 'react'; 
import Axios from 'axios';   
import Notify from '../../../notification/Notify';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box, Typography, Card, CardContent, CardMedia, Button, Grid, Badge, TablePagination, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar'

import Dialog from '@material-ui/core/Dialog'; 
import IconButton from '@material-ui/core/IconButton';
import AddToQueueIcon from '@material-ui/icons/AddToQueue'; 
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'; 
import RotateRightIcon from '@material-ui/icons/RotateRight'; 
import ZoomableImage from 'src/utils/ZoomableImage';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; 

const imageLocation = process.env.REACT_APP_API_IMAGE; 

const LaboratorySharedImages = ({ patient_id, connection }) => { 

    const [user_id] = useState(localStorage.getItem('user_id'))
    const [token] = useState(localStorage.getItem('token'))   

    const [shareImages, setShareImages] = useState([])
    const [shareImagesReady, setShareImagesReady] = useState(false)
    

    const [shareDates, setShareDates] = useState([])
    const [shareDatesReady, setShareDatesReady] = useState(false)

    const [selectedImage, setSelectedImage] = useState({ image: null, date: null })
    const [selectedImageSecond, setSelectedImageSecond] = useState({ image: null, date: null })

    const [fstRotateImg, setFstRotateImg] = useState(0)  
    const [secondRotateImg, setsecondRotateImg] = useState(0)  

    const [openLightbox, setOpenLightbox] = useState(false)
    const [directViewImage, setDirectViewImage] = useState(null) 

    const [dialog, setDialog] = useState(false)
 
    const [rowsPerPage, setRowsPerPage] = React.useState(10);  
    const [page, setPage] = React.useState(0); 

    const [expanded, setExpanded] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };    

    const getShareDateInGroupBy = () =>{
        var formdata = new FormData();
        formdata.append('user_id', user_id);
        formdata.append('token', token);
        formdata.append('patient_id', patient_id)
        formdata.append('type', 'laboratory')
        formdata.append('connection', connection)

        Axios.post('doctor/imaging/imaging-details/shared-dateingroupby', formdata )
        .then( (response) => { 
            const data = response.data;     
            setShareDates(data)
            setShareDatesReady(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }
    
    const handleSelectedImage = (e) => { 
        if(selectedImage.image === null){
            setSelectedImage({ image: e.currentTarget.getAttribute('image'), date: e.currentTarget.getAttribute('date')}) 
            Notify.customToast('First Image', 'Ready to compare')
        }else if(selectedImageSecond.image === null){
            setSelectedImageSecond({ image: e.currentTarget.getAttribute('image'), date: e.currentTarget.getAttribute('date')})
            setDialog(true)
            Notify.customToast('Second Image', 'Ready to compare') 
        }else{ 
            console.log("max compare enabled.")
        }  
    }

    const handleDirectViewImage = (e) => {
        setDirectViewImage(directViewImage === e.currentTarget.id ? null : e.currentTarget.id)
        setOpenLightbox(!openLightbox)
    }

    const handleCloseDialog =() =>{
        setSelectedImage({ image: null, date: null })
        setSelectedImageSecond({ image: null, date: null }) 
        setFstRotateImg(0)
        setsecondRotateImg(0) 
        setDialog(false)
    }

    const rotateFirstImage = () =>{ 
        let newRotation = fstRotateImg + 90;
        if(newRotation >= 360){
            newRotation =- 360;
        }
        setFstRotateImg(newRotation) 
    }

    const rotateSecondImage = () =>{ 
        let newRotation = secondRotateImg + 90;
        if(newRotation >= 360){
            newRotation =- 360;
        }
        setsecondRotateImg(newRotation) 
    }

    const handleSelectedDate = (panel) => async (event, isExpanded) => {
 
        if(isExpanded){ 
            try {
                var formdata = new FormData(); 
                formdata.append('token', token); 
                formdata.set('selectedDate', panel)
                formdata.set('patient_id', patient_id)
                formdata.append('type', 'laboratory') 
                formdata.append('connection', connection)

                const response = await Axios.post('doctor/imaging/imaging-details/shared-dateingroupby-details', formdata )

                const data = response.data;     
                setTimeout(() => {
                    setShareImages(data)
                    setShareImagesReady(true)
                }, 3000);

            } catch (error) {
                Notify.requestError(error);
            } 
        }

        setExpanded(isExpanded ? panel : false);
        setShareImagesReady(false) 
    };

    useEffect(() =>{
         
        getShareDateInGroupBy()

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient_id])

    return (
        <Fragment>
            <Box mb={2}>   
                {
                    shareDatesReady ? 
                        shareDates.length > 0 ?
                            shareDates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((d, d_index) =>{
                                return( 
                                    <Accordion key={d_index} expanded={expanded === Notify.createdAt(d.shared_date)} onChange={ handleSelectedDate(Notify.createdAt(d.shared_date)) }>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon color="primary" />} 
                                        >
                                            <Typography variant="subtitle2" className="gtc-uppercase"> 
                                                Shared Images on { Notify.createdAt(d.shared_date) }
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails> 
                                            {
                                                shareImagesReady ? 
                                                    shareImages.length > 0 ?
                                                        shareImages.map((data, index) =>{
                                                            return( 
                                                                <Box 
                                                                    p={1} 
                                                                    key={index}   
                                                                > 
                                                                    <Badge
                                                                        overlap="rectangle"
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        color="error"
                                                                        badgeContent={data.category}
                                                                    >
                                                                        <Card elevation={0}>
                                                                            <CardContent>
                                                                                <Box align="center">
                                                                                    <CardMedia 
                                                                                        style={{
                                                                                            width: '110px',
                                                                                            height: '110px', 
                                                                                        }}
                                                                                        component="img"
                                                                                        src={imageLocation+'imaging/sharedimages/'+data.image} 
                                                                                    />
                                                                                    <Box>
                                                                                        <Button  
                                                                                            color="secondary"
                                                                                            size="small"
                                                                                            onClick={ handleDirectViewImage }
                                                                                            id={ data.image }  

                                                                                        > 
                                                                                            view
                                                                                        </Button> 
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Button  
                                                                                            color="primary"
                                                                                            size="small"
                                                                                            onClick={ handleSelectedImage }
                                                                                            image={ data.image }  
                                                                                            date={ data.created_at }  
                                                                                        >
                                                                                            compare
                                                                                        </Button> 
                                                                                    </Box> 
                                                                                </Box> 
                                                                            </CardContent>
                                                                        </Card> 
                                                                    </Badge> 
                                                                </Box>
                                                            )
                                                        })
                                                    : Notify.noRecord() 
                                                : Notify.loading() 
                                            }  
                                        </AccordionDetails>
                                    </Accordion>   
                                )
                            })
                        : Notify.noRecord()
                    : Notify.loading()
                }
                <TablePagination
                    labelRowsPerPage = "List"
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={shareDates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box> 
             
            <ZoomableImage
                open = { openLightbox }
                close = { () => {
                    setDirectViewImage(null);
                    setOpenLightbox(false);   
                }}
                title = {`Shared Image`}
                image = {`${imageLocation}imaging/sharedimages/${directViewImage}`}
            />
            
            <Dialog 
                scroll="body" 
                fullScreen={true} 
                disableBackdropClick={true}  
                open={dialog} 
                onClose={handleCloseDialog} 
            >
                <PerfectScrollbar>
                    <Box 
                        className="gtc-viewimage"  
                    > 
                        <Grid container spacing={2}>
                            <Grid item xs={6}> 
                                <TransformWrapper> 
                                    {({ zoomIn, zoomOut }) => (
                                        <Fragment>   
                                            <div className="clearfix border-bottom pl-1">  
                                                <div className="float-left m-2 ">  
                                                    <Typography color="primary" variant="h6"> FirstImage - { selectedImage.date &&Notify.dateTimeConvert(selectedImage.date)} </Typography>
                                                </div>   
                                                <div className="float-right">    
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span"
                                                        onClick={ rotateFirstImage }
                                                    >
                                                        <RotateRightIcon />
                                                    </IconButton> 
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span"
                                                        onClick={zoomIn}
                                                    >
                                                        <AddToQueueIcon />
                                                    </IconButton>
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span"
                                                        onClick={zoomOut}
                                                    >
                                                        <RemoveFromQueueIcon />
                                                    </IconButton> 
                                                </div>
                                            </div>
                                            <TransformComponent>   
                                                <div>
                                                    {
                                                        shareImages.length > 0 ?
                                                            <img  
                                                                src={imageLocation+'imaging/sharedimages/'+selectedImage.image} 
                                                                alt="" 
                                                                style={{ 
                                                                    transform: `rotate(${fstRotateImg}deg)`,  
                                                                }}
                                                            />
                                                        : null
                                                    }   
                                                </div>
                                            </TransformComponent>  
                                        </Fragment> 
                                    )}
                                </TransformWrapper>  
                            </Grid>
                            <Grid item xs={6}> 
                                <TransformWrapper> 
                                    {({ zoomIn, zoomOut }) => (
                                        <Fragment>   
                                            <div className="clearfix border-bottom pl-1">
                                                <div className="float-left m-2 ">  
                                                    <Typography color="primary" variant="h6"> SecondImage - { selectedImageSecond.date &&Notify.dateTimeConvert(selectedImageSecond.date)}  </Typography>
                                                </div>  
                                                <div className="float-right">    
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span" 
                                                        onClick={ rotateSecondImage }
                                                    >
                                                        <RotateRightIcon />
                                                    </IconButton> 
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span"
                                                        onClick={zoomIn}
                                                    >
                                                        <AddToQueueIcon />
                                                    </IconButton>
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span"
                                                        onClick={zoomOut}
                                                    >
                                                        <RemoveFromQueueIcon />
                                                    </IconButton>
                                                    <IconButton 
                                                        color="primary" 
                                                        component="span"
                                                        onClick={ handleCloseDialog }
                                                    >
                                                        <CancelPresentationIcon />
                                                    </IconButton>  
                                                </div>
                                            </div>
                                            <TransformComponent>   
                                                {
                                                    shareImages.length > 0 ?
                                                        <img 
                                                            src={imageLocation+'imaging/sharedimages/'+selectedImageSecond.image} 
                                                            alt=""  
                                                            style={{ 
                                                                transform: `rotate(${secondRotateImg}deg)`,  
                                                            }}
                                                        />
                                                    : null
                                                }   
                                            </TransformComponent>  
                                        </Fragment> 
                                    )}
                                </TransformWrapper>  
                            </Grid>
                        </Grid>
                    </Box>
                    </PerfectScrollbar>
            </Dialog>
        </Fragment>
    )
}

export default LaboratorySharedImages;
