import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormHelperText, Typography } from '@material-ui/core';
import * as Yup from 'yup'; 
import { Formik } from 'formik';
import Notify from 'src/notification/Notify';
import TrashIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/HighlightOff'; 
import Axios from 'axios';

const PermissionApproved = ({ approved , close, reload }) =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const convertToFormdata = (object) => {
        const formData = new FormData();
            formData.append('token', token) 
            formData.append('username', username)  
            formData.append('user_id', user_id)  
            formData.append('permission_id', approved.permission)   
            formData.append('doctors_id', approved.doctors_id)   

            Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return(
        <Dialog
            disableBackdropClick
            open={ approved.open }
            onClose={ close }
        >
            <DialogTitle
                disableTypography
            >
                <Typography
                    variant="subtitle1"
                >
                    Approve Permission 
                </Typography>
            </DialogTitle>
            <Formik 
                initialValues={{  
                    password: '',
                }}
                validationSchema={Yup.object().shape({   
                    password: Yup.string().required(),
                })}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting, 
                    resetForm
                }) => {   
                    try { 
                        const request = await Axios.post('patient/permission/permission-approve', convertToFormdata(values)) 
                        if(request.data === 'pass-invalid'){ 
                            setErrors({ submit: 'Password is invalid.' });
                            Notify.fieldInvalid('password')
                        }
                        if(request.data === 'success'){  
                            resetForm()
                            setSubmitting(true);
                            reload()
                            close()
                            Notify.successRequest('permission approve');
                        }
                    } catch (error) {
                        Notify.requestError(error)
                        const message = error.message || 'Something went wrong'; 
                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
                >
                {({ 
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        noValidate 
                        onSubmit={handleSubmit}  
                    >     
                        <DialogContent
                            dividers
                        >
                            <Box>
                                <Typography 
                                    color="textSecondary"
                                >
                                    Are you sure to approve <br /> the access of this doctor.
                                </Typography>
                            </Box>
                            <Box> 
                                <TextField 
                                    fullWidth
                                    required
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}  
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.password} 
                                    name="password"
                                    label="Password"
                                    variant="outlined" 
                                    type="password" 
                                />
                            </Box>  

                            {errors.submit && (
                                <Box>
                                    <FormHelperText error>
                                    {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}
                        </DialogContent> 
                        <DialogActions>  
                            <Button
                                variant="contained"
                                color="default" 
                                startIcon={ <ClearIcon /> } 
                                onClick = { close }
                            >
                                close
                            </Button> 
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={ <TrashIcon /> } 
                                disabled={isSubmitting}  
                            >
                                Approve
                            </Button> 
                        </DialogActions>
                    </form>
                )}
            </Formik>  
        </Dialog>
    )
}

export default PermissionApproved;