import { Box, Paper, Grid, Tabs, Tab, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TabPanel from "src/utils/TabPanel";
import ManagementImaging from "./imaging/ManagementImaging";
import ManagementLaboratory from "./laborotary";
import ManagementDoctor from "./ManagementDoctor";
import PharmacyManagement from "./pharmacy/PharmacyManagement";
import RadiologistManagement from "./radiologist/RadiologistManagement";
import WarehouseManagement from "./warehouse/WarehouseManagement";

const ManagementInformation = ({ details }) => {
  const [category, setCategory] = useState("doctor");

  const handleChange = (event, catx) => {
    setCategory(catx);
  };

  useEffect(() => {}, [details]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Box p={2} align="center">
          <Typography variant="h4">{details.name}</Typography>
          <Typography variant="subtitle1">{details.address}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Paper component={Box} mb={2}>
          <Box>
            <Tabs
              value={category}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab value={"doctor"} label="Doctor" />
              <Tab value={"laboratory"} label="Laboratory" />
              <Tab value={"imaging"} label="Imaging" />
              <Tab value={"radiologist"} label="Radiologist" />
              <Tab value={"pharmacy"} label="Pharmacy" />
              <Tab value={"warehouse"} label="Pharmacy Warehouse" />
            </Tabs>
          </Box>
        </Paper>

        <Box>
          <TabPanel value={category} index="doctor">
            <ManagementDoctor details={details} />
          </TabPanel>

          <TabPanel value={category} index="laboratory">
            <ManagementLaboratory details={details} />
          </TabPanel>

          <TabPanel value={category} index="imaging">
            <ManagementImaging details={details} />
          </TabPanel>

          <TabPanel value={category} index="radiologist">
            <RadiologistManagement details={details} />
          </TabPanel>

          <TabPanel value={category} index="pharmacy">
            <PharmacyManagement details={details} />
          </TabPanel>

          <TabPanel value={category} index="warehouse">
            <WarehouseManagement details={details} />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ManagementInformation;
