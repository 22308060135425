import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react'
import { Row, Col, FormGroup, FormControl, Button, Form } from 'react-bootstrap'
import Axios from 'axios'
import Notify from '../../notification/Notify'
import ReactDatePicker from 'react-datepicker'
import RegistrationSuccess from './RegistrationSuccess'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import L from 'leaflet'; 

import { Map, TileLayer, Marker } from "react-leaflet";   

import FullscreenControl from 'react-leaflet-fullscreen';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


const RegistrationBasicInfo = (props) => { 
    const [isregistered] = useState(localStorage.getItem('isregistered'))
    const [selectedPackage] = useState(props.match.params.package)
    const [birthday, setBirthDay] = useState(null)
    const [isprocess, setisprocess] = useState(false)
    const [issuccess, setissuccess] = useState(false); 
    const [issuccessemail, setissuccessemail] = useState(null);

    const [marker, setmarker] = useState({lat: null,  lng: null});  
    var refmarker = useRef(null);

    const packageChecker = useCallback(() =>{ 
        if(selectedPackage !== 'premium' && selectedPackage !== 'standard' ){
            return window.location.href="/clinic/app/pagenotfound" 
        }
    },[selectedPackage]);

    const handleRegistration = (e) => {
        e.preventDefault();
        e.persist(); 
        
        var formdata = new FormData(e.target);   

        var error = [];
        if(formdata.get('selected_package').length === 0 || formdata.get('selected_package').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('Package required');
        } 
        if(formdata.get('firstname').length === 0 || formdata.get('firstname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('firstname');
        } 
        if(formdata.get('lastname').length === 0 || formdata.get('lastname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('lastname');
        } 
        if(formdata.get('middlename').length === 0 || formdata.get('middlename').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('middlename');
        } 
        if(formdata.get('gender').length === 0 || formdata.get('gender').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('gender');
        }
        if(formdata.get('birthday').length === 0 || formdata.get('birthday').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('birthday');
        }
        
        if(formdata.get('housenumber').length === 0 || formdata.get('housenumber').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('house number');
        }

        if(formdata.get('street').length === 0 || formdata.get('street').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('street');
        }
        if(formdata.get('barangay').length === 0 || formdata.get('barangay').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('barangay');
        }
        if(formdata.get('city').length === 0 || formdata.get('city').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('city');
        }
        if(formdata.get('email').length === 0 || formdata.get('email').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('email');
        }else{
            if(!Notify.isvalidEmail(formdata.get('email'))){
                error = 'error';
                Notify.fieldInvalid('email');
            }
        }

        if(formdata.get('mobile').length === 0 || formdata.get('mobile').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('mobile');
        }

        if(formdata.get('latitude').length === 0 || formdata.get('latitude').trim()===''){ 
            error = 'error';
            Notify.customToast('Map Latitude', 'Your location is required.');
        }

        if(formdata.get('longitude').length === 0 || formdata.get('longitude').trim()===''){ 
            error = 'error';
            Notify.customToast('Map Longitude', 'Your location is required.');
        } 

        if(error.length > 0){
            console.warn("form has an error")
        }else{
            var successemail = formdata.get('email');
            setisprocess(true)
            Axios.post('welcome/registration/basic-information/process-registration', formdata )
            .then((response) => { 
                const data = response.data;
                if(data === 'email-exist'){
                    Notify.fieldInvalid('Email Already Taken')
                }
                if(data === 'success'){
                    localStorage.setItem('isregistered', true)
                    setissuccessemail(successemail)
                    setissuccess(true)
                    Notify.successRequest('process registration')
                }
            }).catch(error=>{
                Notify.requestError(error);
            }).finally(()=>{
                setisprocess(false)
            })
        }

    } 
    
    const handleDragMarker = (e) =>{   
        setmarker(refmarker.leafletElement.getLatLng()) 
    }

    const getCurrentLocation = useCallback(() =>{
        navigator.geolocation.getCurrentPosition(function(location){  
            var current_location = [{
                lat: location.coords.latitude,
                lng: location.coords.longitude
            }]; 
            setmarker(current_location[0]) 
        })
    },[]);

    useEffect(() => { 

        packageChecker()    
        getCurrentLocation() 

    }, [packageChecker, getCurrentLocation]);

    return (
        <Fragment> 
            {
                issuccess ?  
                    <Fragment>
                        <RegistrationSuccess email ={issuccessemail} />
                    </Fragment> 
                : 
                    <Col sm={9} md={8} className="mt-5 mx-auto">
                        <br />
                        <br />
                        <Form className="my-auto" onSubmit={ handleRegistration }>
                            <p className="m-0 my-2 text-muted h5"> Personal Information </p>
                            <Row> 

                                <Col sm={12} className="mb-2">
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Package </span>  
                                        <FormControl name="selected_package" defaultValue={ selectedPackage } readOnly isValid autoComplete="off"/>
                                    </FormGroup>
                                </Col>
 
                                <Col sm={4}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Lastname </span>
                                        <FormControl name="lastname" autoComplete="off"/>
                                    </FormGroup>
                                </Col>

                                <Col sm={4}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Firstname </span>
                                        <FormControl name="firstname" autoComplete="off"/>
                                    </FormGroup>
                                </Col>

                                <Col sm={4}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel"> Middle Name </span>
                                        <FormControl name="middlename" autoComplete="off"/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col sm={6}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Gender </span>
                                        <FormControl name="gender" as="select"> 
                                            <option value=""> -- select -- </option>
                                            <option value="Female"> Female </option>
                                            <option value="Male"> Male </option>
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}> 
                                    <FormGroup className="gtc-custom-picker">
                                        <span className="gtc-formlabel isrequired"> Birthday </span>
                                        <ReactDatePicker 
                                            autoComplete="off"
                                            className="form-control"
                                            name="birthday"
                                            selected={birthday}
                                            onChange={ (date) => setBirthDay(date) }
                                            maxDate={ new Date() }
                                            placeholderText="00/00/0000"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown 
                                            dropdownMode="select"
                                        />
                                    </FormGroup>
                                </Col> 
                            </Row>

                            <Row className="mt-3">
                                <Col sm={6}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Email </span>
                                        <FormControl name="email" autoComplete="off"/>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Mobile </span>
                                        <FormControl name="mobile" autoComplete="off"/>
                                    </FormGroup>
                                </Col> 
                            </Row>


                            <Row className="mt-3">
                                <Col sm={12}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> house Number ( LOT # / Block # / Subdivision ) </span>
                                        <FormControl name="housenumber" autoComplete="off"/>
                                    </FormGroup>
                                </Col> 
                            </Row>  

                            <Row className="mt-3">
                                <Col sm={4}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Street </span>
                                        <FormControl name="street" autoComplete="off"/>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}> 
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Barangay </span>
                                        <FormControl name="barangay" autoComplete="off"/>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}> 
                                    <FormGroup>
                                        <span className="text-uppercase text-muted gtc-small isrequired"> City </span>
                                        <FormControl name="city" autoComplete="off"/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row> 
                                <Col sm={12}> 
                                    {/* hidden inputs */}
                                    <FormControl name="latitude" hidden defaultValue={marker.lat} readOnly autoComplete="off"/>
                                    <FormControl name="longitude" hidden  defaultValue={marker.lng} readOnly autoComplete="off"/>
                                    <FormGroup>
                                        <span className="gtc-formlabel isrequired"> Map </span> 
                                        <Map 
                                            center={[12.8797, 121.7740]} 
                                            zoom={5} 
                                            style={{height: '250px'}}  
                                            locate={{
                                                watch: true,
                                                enableHighAccuracy: true
                                            }}
                                        >  
                                            <TileLayer
                                                url={process.env.REACT_APP_MAP_URL} 
                                                attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                                maxZoom={19}
                                                minZoom={6}
                                                crossOrigin={true}
                                            />
                                            <Marker 
                                                position={marker} 
                                                onDrag={ handleDragMarker }
                                                draggable={true}
                                                onDragend={ handleDragMarker }
                                                ref={ (ref) => refmarker = ref }
                                            /> 
                                            <FullscreenControl position="topright" />
                                        </Map>
                                    </FormGroup>
                                </Col>
                            </Row>  

                            <FormGroup>
                                <p className="text-muted my-2"> By clicking Continue, you agree to our Terms, Data Policy and localStorage Policy. You may receive <b className="text-dark"> email for verification </b> from us and can opt out any time. </p>
                            </FormGroup>
                            { isregistered !== null ? 
                                <p className="text-danger small m-0 mb-2"> Your registration is already successful. You are not allowed to register again. </p>
                            : null }
                            <FormGroup> 
                                <Button variant={ isregistered !== null ? "danger rounded-0 col-sm-3":"success rounded-0 col-sm-3"} type="submit" disabled={isregistered === null ? isprocess ? true : false : true}>
                                    { isprocess ? 
                                            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                                        : null    
                                    } Continue 
                                </Button> 
                                <span className="ml-3 text-muted">
                                    Already have an account? <span className="text-primary pointer" onClick={ () => window.location.href="/"}> Sign In </span>
                                </span>
                            </FormGroup>
                        </Form>
                    </Col>  
            }
            
            <br />
            <br /> 

        </Fragment>
    )
}

export default RegistrationBasicInfo;
