import React, { useCallback, useEffect, useState } from 'react'

import { Avatar, Box, Divider, Drawer as MUIDrawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core"; 
import CloseIcon from '@material-ui/icons/CancelOutlined';  
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import MessageIcon from '@material-ui/icons/MailOutline';
import Chats from './Chats';

const Drawer = ({ open, close }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [messages, setMessages] = useState([])
    const [messagesRd, setMessagesRd] = useState(false)

    const [selected, setSelected] = useState({
        senderId: null,
        senderName: null,
        opendDetails: false
    })

    const getMessages = useCallback( async () => {
        try { 
            const params = {
                token, user_id
            }
            const request = await Axios.get('telerad/message/get-message', { params }) 
                setMessages(request.data) 
                setMessagesRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    },[token, user_id])

    useEffect(() =>{

        getMessages()

    }, [getMessages])

    return(
        <MUIDrawer
            anchor={`right`} 
            open={ open } 
            onClose={ close }
        >
            <Box
                width={400}
            >
                <Box 
                    display="flex" 
                    m={2}
                >
                    <Box 
                        flexGrow={1}
                        mt={1}
                    >
                        <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                        >
                            { selected.senderName === null ? "Messages" : selected.senderName }
                        </Typography>
                    </Box>
                    <IconButton
                        color={'secondary'}
                        size="small"
                        onClick={ () => close() }
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />

                {
                    selected.opendDetails && selected.senderId ? 
                        <Chats selected = { selected } />
                    :
                        <List
                            component="div"
                        >
                            {
                                messagesRd ? 
                                    messages.length > 0 ?
                                        messages.map((data, index) =>( 
                                            <ListItem 
                                                divider
                                                button
                                                key={ index }
                                                onClick={ () => setSelected({
                                                    senderId: data.sender_user_id,
                                                    senderName: data.imaging_name,
                                                    opendDetails: true
                                                })}
                                                selected = { selected.senderId === data.sender_user_id} 
                                            >
                                                <ListItemIcon> 
                                                    <Avatar>
                                                        <MessageIcon />
                                                    </Avatar>
                                                </ListItemIcon>
                                                <ListItemText  
                                                    className={`gtc-capitalize`}  
                                                    primary={
                                                        <Typography
                                                            color={parseInt(data.unread) > 0 ? "textPrimary" : "textSecondary"}
                                                        >
                                                            { data.imaging_name }
                                                        </Typography>
                                                    } 
                                                    secondary={ parseInt(data.unread) > 0 &&( `${data.unread} - unread message`) } 
                                                />
                                            </ListItem>  
                                        ))
                                    : Notify.noRecord()
                                : Notify.loading()
                            }
                        </List> 
                } 
            </Box>
        </MUIDrawer>
    )
}

export default Drawer;