import React, { useState } from 'react';
import Container from 'src/layout/Container';
import { Map, TileLayer } from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen';
import { Sidebar, Tab } from 'react-leaflet-sidebarv2';
import './GTCMappingSidebar.css';

const mapUrl = process.env.REACT_APP_MAP_URL;

const GTCMapping = () => {
    const [collapse, setCollapse] = useState(false)
    const [selectedId, setSelectedId] = useState('home')

    return (
        <>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'GTC Map',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="GTC Map"
            >
                <div>
                    <Sidebar
                        id="sidebar"
                        collapsed={collapse}
                        selected={selectedId}
                        onOpen={(id) => { setCollapse(true); setSelectedId(id) }}
                        onClose={() => setCollapse(false)}
                    >
                        <Tab id="home" header="Home" icon="fa fa-home">
                            <p>No place like home!</p>
                        </Tab>
                        <Tab id="settings" header="Settings" icon="fa fa-cog" anchor="bottom">
                            <p>Settings dialogue.</p>
                        </Tab>
                    </Sidebar>
                    <Map className="sidebar-map" center={[6.1164, 125.1716]} zoom={5} style={{ height: 'calc(100vh - 198px)' }}>
                        {/* <Map
                    className="sidebar-map"
                    center={[6.1164, 125.1716]}
                    zoom={5}
                    style={{ height: 'calc(100vh - 198px)' }}
                    locate={{
                        watch: true,
                        enableHighAccuracy: true
                    }}
                > */}

                        {/* <TileLayer
                        url={mapUrl}
                        attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                        maxZoom={19}
                        minZoom={6}
                        crossOrigin={true}
                    /> */}
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url={mapUrl}
                        />
                        <FullscreenControl position="topright" />
                    </Map>
                </div>
            </Container>
        </>
    )
}

export default GTCMapping;