import React from "react";
import { DialogContent, Box, TextField, Grid } from "@material-ui/core";
import Notify from "src/notification/Notify";
import Label from "src/utils/Label";

function PatientDetailsAppointmentDetails({ app }) {
  return (
    <>
      <DialogContent>
        <Box className={`gtc-textfield-noborder`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={Boolean(app.is_reschedule) ? 6 : 12}>
              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Date"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={Notify.dateTimeConvert(app.app_date)}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Reason"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.app_reason}
                  multiline
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Service"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.services}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Fee"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.amount}
                />
              </Box>
            </Grid>
            {Boolean(app.is_reschedule) && (
              <Grid item xs={12} sm={12} md={6}>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    label={"Reschedule Date"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    defaultValue={Notify.dateTimeConvert(
                      app.is_reschedule_date
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    fullWidth
                    label={"Reschedule Reason"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    defaultValue={app.is_reschedule_reason}
                    multiline
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          <Box my={2} display="flex">
            <Box flexGrow={1}>
              <Label color={Boolean(app.is_complete) ? "success" : "error"}>
                STATUS : {Boolean(app.is_complete) ? "Complete" : "Incomplete"}
              </Label>
            </Box>

            <Box>
              <Label color={Boolean(app.is_reschedule) ? "success" : "primary"}>
                RESCHEDULE : {Boolean(app.is_reschedule) ? "Yes" : "No"}
              </Label>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
}

export default PatientDetailsAppointmentDetails;
