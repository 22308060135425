import React, { Fragment, useState } from "react";
import Axios from "axios";
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Send, XCircle } from "react-feather";

const MessagePatient = (props) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");

  const [isprocess, setIsProcess] = useState(false);

  const handleNotificationMsg = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("patient_id", props.patient_id);
    formdata.set("appid", props.appid);
    formdata.set("connection", "online");

    var error = [];

    if (
      formdata.get("message").length === 0 ||
      formdata.get("message").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("message");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog("notification");
    } else {
      setIsProcess(true);
      Axios.post("encoder/billing/send-notifmsg", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-inv") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            props.closeDialog();
            Notify.successRequest("notification sent");
          }
          if (data === "db-error") {
            Notify.warnRequest("notification sent");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcess(false));
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleNotificationMsg}>
        <DialogContent dividers>
          <Box>
            <TextField
              fullWidth
              name="message"
              label="Messsage"
              margin="normal"
              variant="outlined"
              multiline
            />
          </Box>
          <Box>
            <TextField
              fullWidth
              name="password"
              label="Password"
              margin="normal"
              variant="outlined"
              type="password"
              autoComplete="off"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="ml-2"
            startIcon={<XCircle />}
            variant="contained"
            color="default"
            onClick={props.closeDialog}
          >
            No
          </Button>

          <Button
            type="submit"
            disabled={isprocess}
            startIcon={
              isprocess ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                <Send />
              )
            }
            variant="contained"
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
};

export default MessagePatient;
