import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import HemathologyRef from "src/laboratory/newlaboratory/references/Ref_Hemathology";
import Label from "src/utils/Label";
import { BarChart2 } from "react-feather";
import { useHistory } from "react-router";

const Hemathology = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const history = useHistory();
  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("table", "laboratory_hematology");
    formdata.set("connection", "local");

    axios
      .post("doctor/patient/laboratory/order/paid-detailsbytable", formdata)
      .then((res) => {
        const data = res.data;
        setDetails(data);
      })
      .catch((er) => Notify.requestError(er));
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trace_number, patient_id]);

  return (
    <>
      {details.length > 0 && (
        <Box my={2} p={2}>
          <Box display="flex" mb={2}>
            <Box flexGrow={1}>
              <Typography variant="h6">
                <strong> Hemathology Order </strong>
              </Typography>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="text"
                startIcon={<BarChart2 />}
                onClick={() =>
                  history.push(
                    `/clinic/app/doctor/patient/laboratory/hemathology/${patient_id}`
                  )
                }
                className={`d-print-none`}
              >
                Graph
              </Button>
            </Box>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell> Order </TableCell>
                  <TableCell align="center"> Result </TableCell>
                  <TableCell align="center"> Reference Values </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {details.hemoglobin && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        hemoglobin
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.hemoglobin)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.hemoglobin()}
                    </TableCell>
                  </TableRow>
                )}

                {details.hematocrit && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        hematocrit
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.hematocrit)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.hematocrit()}
                    </TableCell>
                  </TableRow>
                )}

                {details.rbc && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        RBC
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.rbc)}</Typography>
                    </TableCell>
                    <TableCell align="center">{HemathologyRef.rbc()}</TableCell>
                  </TableRow>
                )}

                {details.wbc && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        wbc
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.wbc)}</Typography>
                    </TableCell>
                    <TableCell align="center">{HemathologyRef.wbc()}</TableCell>
                  </TableRow>
                )}

                {details.platelet_count && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        Platelet count
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {checkResult(details.platelet_count)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.plateletCount()}
                    </TableCell>
                  </TableRow>
                )}

                {details.differential_count && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        differential count
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {checkResult(details.differential_count)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" />
                  </TableRow>
                )}

                {details.neutrophil && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        neutrophil
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.neutrophil)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.neutrophil()}
                    </TableCell>
                  </TableRow>
                )}

                {details.lymphocyte && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        lymphocyte
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.lymphocyte)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.lymphocyte()}
                    </TableCell>
                  </TableRow>
                )}

                {details.monocyte && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        monocyte
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.monocyte)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.monocyte()}
                    </TableCell>
                  </TableRow>
                )}
                {details.eosinophil && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        eosinophil
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.eosinophil)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.eosinophil()}
                    </TableCell>
                  </TableRow>
                )}
                {details.basophil && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        basophil
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.basophil)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.basophil()}
                    </TableCell>
                  </TableRow>
                )}
                {details.bands && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        bands
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{checkResult(details.bands)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.bands()}
                    </TableCell>
                  </TableRow>
                )}
                {details.abo_blood_type_and_rh_type && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        ABO blood type / rh type
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {checkResult(details.abo_blood_type_and_rh_type)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" />
                  </TableRow>
                )}
                {details.bleeding_time && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        bleeding time
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {checkResult(details.bleeding_time)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.bleedingTime()}
                    </TableCell>
                  </TableRow>
                )}
                {details.clotting_time && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" className={`gtc-uppercase`}>
                        clotting time
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {checkResult(details.clotting_time)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {HemathologyRef.clottingTime()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default Hemathology;
