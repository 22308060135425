import React, { Fragment, useState, useEffect } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Grid, Box, Typography, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, Paper } from '@material-ui/core';
import GTCContactInfo from '../../GTCContactInfo';
import { Alert } from 'react-bootstrap';
import GTCPackageCountdown from '../../notification/GTCPackageCountdown';
import Container from 'src/layout/Container'; 
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

function Subscription() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [subscription, setSubscription] = useState([])
    const [subscriptionReady, setSubscriptionReady] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const getSubscription = () => {
        var params = {
            token: token,
            user_id: user_id,
        }
        Axios.get('patient/subscription/get-subscription', { params })
            .then((response) => {
                const data = response.data;
                setSubscription(data)
                setSubscriptionReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getSubscription()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'subscription',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="My Subscription"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Box>
                            {
                                subscriptionReady ?
                                    subscription.length > 0 && (<GTCPackageCountdown expiredOn={subscription[0].subscription_end} />)
                                    : Notify.loading()
                            }
                        </Box>
                        <Box component={Paper} variant="outlined" mt={2}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"> Subscription </TableCell>
                                            <TableCell align="center"> Length </TableCell>
                                            <TableCell align="center"> Amount </TableCell>
                                            <TableCell align="center"> Start </TableCell>
                                            <TableCell align="center"> Ended  </TableCell>
                                            <TableCell align="center"> Status  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? subscription.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : subscription
                                        ).map((data) => (
                                            <TableRow key={data.id}>
                                                <TableCell align="left"> <Typography style={style.capitalize}> {data.subscription} </Typography> </TableCell>
                                                <TableCell align="right">{data.subscription_length_month}</TableCell>
                                                <TableCell align="right">{data.subscription_amount}</TableCell>
                                                <TableCell align="right">{Notify.dateTimeConvert(data.subscription_started)}</TableCell>
                                                <TableCell align="right">{Notify.dateTimeConvert(data.subscription_end)}</TableCell>
                                                <TableCell align="center">
                                                    {parseInt(data.status) > 0 ? <span className="text-success"> Active </span> : <span className="text-danger"> Expired </span>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component={"div"}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={subscription.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                        <Box mb={2}>
                            {
                                subscriptionReady ?
                                    subscription.length > 0 ?
                                        <Alert variant="success text-center border border-success">
                                            <Box display="flex" width={'100%'} justifyContent="center" alignItems="center">
                                                <VerifiedUserIcon />
                                                <Typography noWrap variant="h6" style={style.capitalize}> {subscription[0].subscription} </Typography>
                                            </Box>
                                        </Alert>
                                        : Notify.noRecord()
                                    : Notify.loading()
                            }
                        </Box>
                        <GTCContactInfo />
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default Subscription;

const style = {
    capitalize: {
        textTransform: 'capitalize'
    },
    upgrade: {
        backgroundColor: '#4caf50',
        color: '#fff',
    }
}