import React, { Fragment, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Axios from "axios";
import Notify from "../../notification/Notify";
import Transaction from "./Transaction";
import Loadout from "./Loadout";
import RequestCredit from "./RequestCredit";
import GTCContactInfo from "../../GTCContactInfo";
import Others from "./Others";
import { Grid, Box, ButtonGroup, Button } from "@material-ui/core";
import Container from "src/layout/Container";

function Credits() {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [_credit_balance, set_credit_balance] = useState(0);
  const [_unpaidConsultation, set_unpaidConsultation] = useState(0);
  const [_unpaidConsuCount, set_unpaidConsuCount] = useState(0);
  const [_credit_category, set_credit_category] = useState("transaction");

  const getCreditBalance = () => {
    var params = {
      token: token,
      user_id: user_id,
      management_id: management_id,
    };
    Axios.get("patient/credits/credits-balance", { params })
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          set_credit_balance(
            data[0].credit_total -
              data[0].credit_expense +
              data[0].unpaid_not_enough_creditsss
          );
          set_unpaidConsultation(data[0].unpaid_not_enough_credit);
          set_unpaidConsuCount(data[0].countUnpaid);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleCreditType = (e) => {
    set_credit_category(e.currentTarget.id);
  };

  useEffect(() => {
    getCreditBalance();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "credits",
          items: [{ name: "Dashboard", path: "/clinic/app/patient" }],
        }}
        title="My Credits"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9}>
            <Box mb={2}>
              <ButtonGroup variant="contained" color="primary" size="large">
                <Button id="transaction" onClick={handleCreditType}>
                  Transaction
                </Button>
                <Button id="loadout" onClick={handleCreditType}>
                  LoadIn
                </Button>
                <Button id="request" onClick={handleCreditType}>
                  Request
                </Button>
                <Button id="others" onClick={handleCreditType}>
                  Others
                </Button>
              </ButtonGroup>
            </Box>

            <Box>
              {_credit_category === "transaction" && <Transaction />}
              {_credit_category === "loadout" && <Loadout />}
              {_credit_category === "request" && <RequestCredit />}
              {_credit_category === "others" && <Others />}
            </Box>
          </Grid>

          <Grid item sm={12} md={3}>
            <Alert variant="success text-center border border-success">
              <strong> Credit Balance </strong>
              <p className="h4 font-weight-bold">
                {" "}
                {Notify.convertToNumber(_credit_balance)}{" "}
              </p>
            </Alert>

            {_unpaidConsuCount > 0 ? (
              <Alert variant="danger text-center border border-danger">
                <strong> {_unpaidConsuCount} Unpaid Consultation </strong>
                <p className="h4 font-weight-bold">
                  {" "}
                  {Notify.convertToNumber(_unpaidConsultation)}{" "}
                </p>
              </Alert>
            ) : null}
            <GTCContactInfo />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default Credits;
