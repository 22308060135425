import React from "react";
import { Box, TextField, Button, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { CheckSquare, XCircle } from "react-feather";

const token = localStorage.getItem("token");
const user_id = localStorage.getItem("user_id");
const username = localStorage.getItem("username");
const management_id = localStorage.getItem("management_id");

const EditService = ({ open, details, close, getServiceList }) => {
  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Formik
      initialValues={{
        user_id: user_id,
        token: token,
        username: username,
        management_id: management_id,
        service_id: details.service_id,
        services: details.services,
        rate: details.amount,
        password: "",
      }}
      validationSchema={Yup.object().shape({
        services: Yup.string().required(),
        rate: Yup.number().min(1).max(9999).required(),
        password: Yup.string().required(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          const request = await Axios.post(
            "doctors/services/update-service",
            getFormData(values)
          );

          if (request.data.message === "success") {
            Notify.successRequest("update service");
            getServiceList();
            close();
            resetForm();
          }

          if (request.data.message === "pass-invalid") {
            setErrors({ password: "Password is invalid." });
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box mb={3}>
            <TextField
              label="Service"
              fullWidth
              variant="outlined"
              name="services"
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 200,
                },
              }}
              error={Boolean(touched.services && errors.services)}
              helperText={touched.services && errors.services}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.services}
            />
          </Box>

          <Box mb={3}>
            <TextField
              label="Rate"
              fullWidth
              variant="outlined"
              name="rate"
              type="number"
              error={Boolean(touched.rate && errors.rate)}
              helperText={touched.rate && errors.rate}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.rate}
            />
          </Box>

          <Box mb={3}>
            <TextField
              label="Password"
              fullWidth
              variant="outlined"
              name="password"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              type="password"
            />
          </Box>

          <Box>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              startIcon={
                isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
              disabled={isSubmitting}
            >
              Save
            </Button>

            <Button
              size="large"
              variant="contained"
              color="default"
              startIcon={<XCircle />}
              onClick={() => close()}
            >
              No
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EditService;
