 

import React, { useEffect, useState } from 'react'; 
import { Avatar, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, makeStyles, Badge, Hidden } from "@material-ui/core"; 
import { deepOrange } from '@material-ui/core/colors'; 
import Notify from 'src/notification/Notify';
import Axios from 'axios'; 
import Container from 'src/layout/Container'; 
import ReviewPatient from '../ReviewPatient';
import ReviewedPatientToday from '../ReviewedPatientToday';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({ 
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],  
    },
    large:{ 
        width: theme.spacing(6.8),
        height: theme.spacing(6.8), 
        marginRight: 20,
        color: '#fff',
        fontSize: 24,
    }
}));
  
const TeleradPatients = () =>{  
    const token = localStorage.getItem('token')
    // const username = localStorage.getItem('username')
    const user_id = localStorage.getItem('user_id')
    const { imagingorder_id } = useParams()
    const [selectedPatient, setSelectedPatient] = useState(imagingorder_id === 'all' ? null : imagingorder_id ) 

    const [patients, setPatients] = useState([])
    const [patientsRd, setPatientsRd] = useState(false) 
    
    const [lastId, setLastId] = useState(0)

    let interval = null;

    const updateSelectedPatient = (data) =>{
        setSelectedPatient(data)
    }

    const getPatientsReview = async() =>{
        try { 
            const params = {
                token, user_id
            }
            const request = await Axios.get('telerad/patients/getpatient-toview', { params })
            setPatients(request.data)
            setPatientsRd(true) 
            setLastId(request.data.length > 0 ? request.data[0].id  : 0)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const getNewPAtietnForReading = async () =>{
        try { 
            const params = {
                token, 
                user_id,
                lastid: lastId
            }
            const request = await Axios.get('telerad/patients/latest-newpatient', { params })
            if(request.data.length > 0){ 
                getPatientsReview() 
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const initializeInterval = () =>{
        interval = setInterval( async() => {
            getNewPAtietnForReading()
        }, 15000);
    }

    useEffect(() =>{ 

        initializeInterval()
        getPatientsReview()

        return(() => {
            clearInterval(interval)
        })
        // eslint-disable-next-line
    }, [lastId])

    return(
        <Container
            breadcrumbs={{
                enable: true,
                current: 'patients',
                items:[
                    { name: 'dashboard', path: '/clinic/app/telerad'}
                ]
            }} 
            title={'Patients For Review '}
        >
            <Grid
                container
                spacing={2}
            >   
                <Grid 
                    item
                    xs={ 12}
                    sm={ 12}
                    md={ selectedPatient === null ? 12 : 5 }  
                >
                    <Paper
                        elevation={ 2 }
                        component={Box}
                        p={2}
                        mb={2}
                    >
                        <Box mb={2} display="flex">
                            <Box flexGrow={1}> 
                                <Typography
                                    variant="h6"
                                    color="textSecondary"
                                > 
                                    New Patients
                                </Typography>  
                            </Box> 

                            <Box mr={2}>
                                <Badge color="error" badgeContent={ patients.length } /> 
                            </Box>
                        </Box> 

                        <Box>  
                            <List 
                                component="div"
                            > 
                                {
                                    patientsRd ?
                                        <RenderList 
                                            selectedPatient = { selectedPatient } 
                                            updateSelectedPatient = { updateSelectedPatient }
                                            patients = { patients } 
                                        />
                                    : <Box p={2} > { Notify.loading() } </Box>
                                }
                            </List>
                        </Box>
                    </Paper>

                    <Hidden smDown>
                        <Box>
                            <ReviewedPatientToday /> 
                        </Box>
                    </Hidden>
                </Grid> 
                
                {/* patient order information and patient details */}
                { selectedPatient && ( 
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={7} 
                    >
                        <ReviewPatient resetDisplay = { () =>{ setSelectedPatient(null) }} getPatientsReview = { getPatientsReview } imagingOrderId = { selectedPatient } /> 
                    </Grid> 
                )}
            </Grid>
        </Container>
    )
}

export default TeleradPatients;

const RenderList = ({ selectedPatient, updateSelectedPatient, patients }) => { 

    const classes = useStyles(); 

    return(
        <>
            { 
                patients.length > 0 ?
                    patients.map((data, index) => (
                        <ListItem
                            key = { index }
                            button 
                            onClick = { () => updateSelectedPatient(data.imaging_center_id) }
                            selected = { selectedPatient === data.imaging_center_id }
                        >
                            <ListItemIcon>
                                <Avatar className={selectedPatient === data.imaging_center_id ? `${classes.orange} ${classes.large}` : classes.large} >
                                    {data.patients_name.charAt().toUpperCase()}
                                </Avatar>
                            </ListItemIcon>
                            
                            <ListItemText 
                                primaryTypographyProps={{
                                    className: 'gtc-capitalize',
                                    variant: 'h6'
                                }}
                                primary={ data.patients_name } 
                                secondary={ Notify.dateTimeConvert(data.created_at)} 
                            />
                        </ListItem>
                    ))
                : <Box p={2} > { Notify.noRecord() } </Box> 
            } 
        </>
    )
}