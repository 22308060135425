import React from "react";
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const AddManagementDialog = ({ open, close, handleNewManagement }) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>New Management</DialogTitle>

      <form onSubmit={handleNewManagement}>
        <DialogContent dividers>
          <Box mb={2}>
            <TextField
              required
              label="name"
              name="name"
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              label="address"
              name="address"
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="tin"
              name="tin"
              fullWidth
              variant="outlined"
              multiple
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="business style"
              fullWidth
              name="business_style"
              variant="outlined"
              multiple
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={close}>
            No
          </Button>

          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddManagementDialog;
