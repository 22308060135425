import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Label from "src/utils/Label";
import Notify from "src/notification/Notify";
import PatientDetailsAppointmentResched from "src/encoder/PatientDetailsAppointmentResched";
import PatientDetailsHeader from "src/encoder/PatientDetailsHeader";
import { CheckSquare, Clock, Trash2, XCircle } from "react-feather";
import Axios from "axios";

const LocalAppDetails = ({ app, getIncompleteList, resetDisplay }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [reschedDialog, setReschedDialog] = useState({
    data: null,
    open: false,
  });

  const [removeDialog, setRemoveDialog] = useState({
    data: null,
    open: false,
  });

  const [isProcess, setIsProcess] = useState(false);

  const removeAppointment = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("appointment_id", removeDialog.data.appointment_id);
    setIsProcess(true);
    Axios.post("encoder/appointment/remove-appointment", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          getIncompleteList();
          Notify.successRequest("appointment delete");
          resetDisplay();
        }
      })
      .catch((error) => console.log("error request: ", error.message))
      .finally(() => setIsProcess(false));
  };

  return (
    <>
      <Box mb={2}>
        <PatientDetailsHeader patient_id={app.patients_id} />
      </Box>

      <Card>
        <CardHeader
          title={
            <Box display="flex">
              <Box flexGrow={1}> Details </Box>
              <Box>
                <Tooltip title="Reschedule Appointment" arrow>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setReschedDialog({
                        data: app,
                        open: true,
                      })
                    }
                  >
                    <Clock />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box>
                <Tooltip title="" arrow>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setRemoveDialog({
                        data: app,
                        open: true,
                      })
                    }
                  >
                    <Trash2 />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          }
        />

        <CardContent>
          <Box className={`gtc-textfield-noborder`}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    fullWidth
                    label={"Appointment Date"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    value={Notify.dateTimeConvert(app.app_date)}
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    label={"Appointment Reasons"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    value={app.app_reason === null ? "None" : app.app_reason}
                    multiline
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    label={"Appointment Service"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    value={app.services}
                  />
                </Box>

                <Box>
                  <TextField
                    fullWidth
                    label={"Appointment Fee"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    value={app.amount}
                  />
                </Box>
              </Grid>

              {Boolean(app.is_reschedule) && (
                <>
                  <Box mx={1} my={1.5}>
                    <Label
                      color={Boolean(app.is_reschedule) ? "success" : "primary"}
                    >
                      RESCHEDULE : {Boolean(app.is_reschedule) ? "Yes" : "No"}
                    </Label>
                  </Box>

                  <Grid item xs={12} sm={12}>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label={"Reschedule Date"}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        value={Notify.dateTimeConvert(app.is_reschedule_date)}
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        label={"Reschedule Reason"}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        value={app.is_reschedule_reason}
                        multiline
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </CardContent>
      </Card>

      {/* appointment reschedule */}
      <Dialog
        open={reschedDialog.open}
        onClose={() => setReschedDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>Appointment Reschedule</DialogTitle>
        {reschedDialog.data && (
          <PatientDetailsAppointmentResched
            app={reschedDialog.data}
            close={() => setReschedDialog({ data: null, open: false })}
            reload={getIncompleteList}
          />
        )}
      </Dialog>

      {/* appointment reschedule */}
      <Dialog
        open={removeDialog.open}
        onClose={() => setRemoveDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"xs"}
        fullWidth
      >
        <DialogContent>
          <Typography>
            Removing appointment will remove also the billing, Are you sure to
            continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="contained"
            startIcon={<XCircle />}
            onClick={() => setRemoveDialog({ data: null, open: false })}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isProcess}
            startIcon={
              isProcess ? (
                <CircularProgress color="inherit" size={23} />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => removeAppointment()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocalAppDetails;
