import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Laboratory from "../laboratory/newlaboratory";
import LabTest from "src/laboratory/labtest";
import PrintOrder from "src/laboratory/newlaboratory/PrintOrder";
import { Badge } from "@material-ui/core";
import Sidebar from "src/layout/Sidebar";
import GTCValidateLS from "src/notification/GTCValidateLS";
import axios from "axios";
import { Home, Users, FileText as ListIcon, FileText } from "react-feather";
import Report from "src/laboratory/newlaboratory/Report";
import GTCNewsFullImage from "src/gtcnews/GTCNewsFullImage";

const RouteAdministrator = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [sidebarHeader, setSidebarHeader] = useState([]);
  var interval = null;

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get(
        "radiologist/sidebar/header-infomartion",
        { params }
      );
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    GTCValidateLS.verifyLocalStorage();

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidbarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app/laboratory",
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge variant="standard">
          <Users color="white" />
        </Badge>
      ),
      path: "/clinic/app/laboratory/patients",
    },
    {
      name: "Test",
      show: true,
      icon: <FileText color="white" />,
      path: "/clinic/app/laboratory/test",
    },
    {
      name: "Reports",
      show: true,
      icon: (
        <Badge variant="standard">
          <ListIcon color="white" />
        </Badge>
      ),
      path: "/clinic/app/laboratory/reports",
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <Sidebar
          notification={{
            enable: false,
            local: [],
            virtual: [],
            owner: "laboratory",
          }}
          header={sidebarHeader}
          routes={sidbarRoute}
          module={"laboratory"}
        />

        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app/laboratory" />}
          />
          <Route
            exact
            path="/clinic"
            component={() => <Redirect to="/clinic/app/laboratory" />}
          />
          <Route
            exact
            path="/clinic/app"
            component={() => <Redirect to="/clinic/app/laboratory" />}
          />
          <Route
            exact
            path="/clinic/app/laboratory"
            component={GTCNewsFullImage}
          />
          <Route
            exact
            path="/clinic/app/laboratory/patients"
            component={Laboratory}
          />
          <Route
            exact
            path="/clinic/app/laboratory/reports"
            component={Report}
          />
          <Route exact path="/clinic/app/laboratory/test" component={LabTest} />
          <Route
            exact
            path="/clinic/app/laboratory/record/print/order/:trace_number"
            component={PrintOrder}
          />
          <Route exact path="/clinic/app/logout" component={Logout} />

          <Route render={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteAdministrator;
