import { Avatar, Card, CardHeader, Grid, IconButton, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import React, { Fragment } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';
import Container from 'src/layout/Container';

const GraphDropdown = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'graph',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="Graph"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Pa
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/pain');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Pain"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Te
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/temperature');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Temperature"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        BP
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/blood-pressure');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Blood Pressure"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Pu
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/pulse');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Pulse"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Re
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/respiratory');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Respiratory"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        We
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/weight');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Weight"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Gl
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/glucose');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Glucose"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Cho
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/cholesterol');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Cholesterol"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        UA
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/uric-acid');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Uric Acid"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Ca
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/calcium');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Calcium"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Ch
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/chloride');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Chloride"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Cr
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/creatinine');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Creatinine"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Hdl
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/hdl');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="HDL"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Ldl
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/ldl');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="LDL"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Li
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/lithium');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Lithium"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Ma
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/magnesium');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Magnesium"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Po
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/potassium');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Potassium"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        Pr
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/protein');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Protein"
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.root}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="graph" className={classes.avatar}>
                                        So
                                </Avatar>
                                }
                                action={
                                    <IconButton aria-label="graphsettings"
                                        onClick={() => {
                                            history.push('/clinic/app/patient/graph/sodium');
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                title="Sodium"
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default GraphDropdown;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    avatar: {
        backgroundColor: blue[600],
    }
}));