import React, { Component, Fragment } from 'react';  
import Axios from 'axios';
import Notify from '../../notification/Notify'; 
import { Box, DialogContent, InputAdornment, TextField } from '@material-ui/core';
import { Download } from 'react-feather';

const fileLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL; 

export default class AppointmentDetails extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _appid: this.props.appid,
            _details: [],
            _details_ready: false,
		}
	} 

    getAppDetails(){ 
        const params = {
            token: this.state._token,
            user_id: this.state._user_id,
            appointment_id: this.props.appid,
            connection: this.props.connection, 
        }

        Axios.get('appointment/patient/appointment-detail', { params } )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _details: data,
                    _details_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }
    
    componentDidUpdate(){
        if(this.state._appid !== this.props.appid){
            this.setState({
                _appid: this.props.appid
            })
            this.componentDidMount();
        }
    }

    componentDidMount(){
        this._mounted = true;
        this.getAppDetails();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

	render(){ 
		return(
			<Fragment>
                <DialogContent dividers>
                    {
                        this.state._details_ready ? 
                            this.state._details.length > 0 ?
                                <Box> 
                                    <Box mb={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Appointment Request Date"
                                            value={ Notify.dateTimeConvert(this.state._details[0].appointment_date) }
                                            InputProps={{ readOnly: true }}
                                            multiline
                                        /> 
                                    </Box> 

                                    <Box mb={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Appointment Reason "
                                            value={ this.state._details[0].appointment_reason === null ? 'None' : this.state._details[0].appointment_reason }
                                            InputProps={{ readOnly: true }}
                                            multiline
                                        /> 
                                    </Box> 

                                    <Box mb={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Service"
                                            value={ this.state._details[0].appointment_reason === null ? 'None' : this.state._details[0].appointment_reason }
                                            InputProps={{ readOnly: true }}
                                            multiline
                                        /> 
                                    </Box> 

                                    <Box mb={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Shared File/Attachment "
                                            value={ this.state._details[0].attachment === null ? 'None' : this.state._details[0].appointment_reason } 
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    Boolean(this.state._details[0].attachment) && (
                                                        <InputAdornment position="start">
                                                            <a rel="noopener noreferrer" className="pointer" target="_blank" href={fileLocation+'appointment/'+this.state._details[0].attachment} download> <Download /> </a>
                                                        </InputAdornment>
                                                    )
                                                ),
                                            }}
                                        /> 
                                    </Box>
   
                                    <Box mb={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Requested On"
                                            value={ this.state._details[0].created_at === null ? 'None' : Notify.dateTimeConvert(this.state._details[0].created_at) }
                                            InputProps={{ readOnly: true }}
                                            multiline
                                        /> 
                                    </Box> 
 
                                </Box>
                            : Notify.noRecord()
                        : Notify.loading()
                    }
                </DialogContent> 
			</Fragment>
		)
	}
}
