import { Box, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import TabPanel from "src/utils/TabPanel";
import FirstPhoto from "./FirstPhoto";
import ChildGrowths from "./growth";
import HeadCellCircumference from "./HeadCellCircumference";
import Immunization from "./immunization";
import ChildWeight from "./weight";

const Pediatrics = ({ patient_id, connection }) => {
  const [category, setCategory] = React.useState("growth");
  const [info, setInfo] = useState({ data: [], ready: false });
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const mounted = IsMountedRef();

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  const getPatientInformation = React.useCallback(async () => {
    const params = {
      token,
      user_id,
      patient_id,
      connection,
    };

    let response = await Axios.get("doctor/patient/patient-information", {
      params,
    });

    const data = response.data;

    if (mounted.current) {
      setInfo({ data: data, ready: true });
    }
  }, [token, user_id, patient_id, connection, mounted]);

  useEffect(() => {
    getPatientInformation();
  }, [getPatientInformation]);

  return (
    <Box>
      <Paper>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab wrapped value={"growth"} label="Growth" />

          <Tab wrapped value={"weight"} label="Weight" />

          <Tab wrapped value={"immunization"} label="Immunization" />

          <Tab wrapped value={"hcc"} label="Head Cell Circumference" />

          <Tab wrapped value={"first-photo"} label="First Photo" />
        </Tabs>
      </Paper>

      <Box>
        <TabPanel value={category} index={"growth"}>
          {info.ready ? (
            <ChildGrowths
              info={info.data}
              patient_id={patient_id}
              connection={connection}
            />
          ) : (
            <Typography color="primary" style={{ marginTop: 10 }}>
              {" "}
              loading, please wait...
            </Typography>
          )}
        </TabPanel>

        <TabPanel value={category} index={"weight"}>
          {info.ready ? (
            <ChildWeight
              info={info.data}
              patient_id={patient_id}
              connection={connection}
            />
          ) : (
            <Box m={3}>{Notify.loading()}</Box>
          )}
        </TabPanel>

        <TabPanel value={category} index={"immunization"}>
          <Immunization patient_id={patient_id} connection={connection} />
        </TabPanel>

        <TabPanel value={category} index={"hcc"}>
          <HeadCellCircumference
            patient_id={patient_id}
            connection={connection}
          />
        </TabPanel>

        <TabPanel value={category} index={"first-photo"}>
          {info.ready ? (
            <FirstPhoto
              info={info.data}
              patient_id={patient_id}
              connection={connection}
            />
          ) : (
            <Box m={3}>{Notify.loading()}</Box>
          )}
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Pediatrics;
