import React from "react";
import {
  Box,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import Axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

function List() {
  const token = localStorage.getItem("token");

  const [list, setList] = React.useState([]);
  const [isloadable, setIsLoadable] = React.useState(true);

  const limit = 7;

  const ismounted = IsMountedRef();

  const getNewsList = async () => {
    var params = {
      token: token,
      is_featured: 0,
      limit: limit,
    };

    let response = await Axios.get("gtc/news/featured-list", { params });
    if (ismounted.current) {
      if (response.data.length === 0) {
        setIsLoadable(false);
      }
      setList(response.data);
    }
  };

  const getMoreNewsList = async () => {
    var params = {
      token: token,
      is_featured: 0,
      limit: limit,
      lastid: list.length > 0 ? list[list.length - 1].id : 0,
    };

    let response = await Axios.get("gtc/news/featured-list-more", { params });
    if (ismounted.current) {
      if (response.data.length === 0) {
        setIsLoadable(false);
      }
      setList((list) => list.concat(response.data));
    }
  };

  React.useEffect(() => {
    getNewsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {Boolean(parseInt(list.length)) ? (
        <>
          {list.map((data, index) => (
            <Box
              key={index}
              my={2}
              p={1}
              className={`pointer`}
              component={Paper}
              elevation={2}
              onClick={() => window.open(data.news_url, "_blank")}
            >
              <Grid container spacing={2}>
                <Grid item xs={5} sm={4} lg={3}>
                  <CardMedia
                    component={"img"}
                    alt="gtc-news"
                    src={`${imageLocation}news/thumbnail/${data.news_thumbnail}`}
                    style={{ borderRadius: 2 }}
                  />
                </Grid>

                <Grid item xs={7} sm={8} lg={9}>
                  <Box>
                    <Typography color="primary" variant="subtitle2">
                      {data.tag}
                    </Typography>
                  </Box>

                  <Box component={"div"} textOverflow="ellipsis">
                    {/* { data.news_title.length > 100 ? `${data.news_title.substr(0, 100)}...` :  } */}
                    {data.news_title}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box>
            <Button
              fullWidth
              color="default"
              onClick={getMoreNewsList}
              hidden={!isloadable}
            >
              Load More News...
            </Button>
          </Box>
        </>
      ) : (
        <Typography variant="caption" color="secondary">
          No news found in a list.
        </Typography>
      )}
    </Box>
  );
}

export default List;
