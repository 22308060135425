import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  FormHelperText,
  TextField,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableFooter,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { CheckSquare, Trash, XCircle } from "react-feather";
import axios from "axios";
import Notify from "src/notification/Notify";
import SelectStyle from "src/utils/SelectStyle";
import Select from "react-select";
import LaboratoryOrder from "src/utils/LaboratoryOrder";
import IsMountedRef from "src/utils/IsMountedRef";

const LaboratoryPackage = ({ list }) => {
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [items, setItems] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();
  const selectStyle = SelectStyle();
  const [filteredList, setFilteredList] = useState([]);
  const [remove, setRemove] = useState({ open: false, data: null });
  const [packages, setPackages] = useState({ open: false });

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));

    return formData;
  };

  const handleSelectedDept = (data, setFieldValue) => {
    setFieldValue("department", data.value);
    const xx = list.data.filter((x) => x.department === data.value);
    setFilteredList(xx);
  };

  const getTempItemForPackage = React.useCallback(async () => {
    let response = await axios.get("laboratory/package/item/get-totemp", {
      params: {
        token,
        management_id,
      },
    });

    const data = response.data;
    if (mounted.current) {
      setItems({ data, ready: true });
    }
  }, [mounted, management_id, token]);

  const handleRemoveItem = async (removeDate) => {
    let response = await axios.get("laboratory/package/item/remove-totemp", {
      params: {
        token,
        management_id,
        pck_temp_id: removeDate.pck_temp_id,
      },
    });

    const data = response.data;
    if (data.message === "success") {
      setRemove({ data: null, open: false });
      Notify.successRequest("item remove");
      getTempItemForPackage();
    }
  };

  useEffect(() => {
    getTempItemForPackage();
  }, [getTempItemForPackage]);

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} sm={12} md={8}>
        <Card>
          <CardHeader title="Unsave Items For Package" />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Department </TableCell>
                  <TableCell> Category </TableCell>
                  <TableCell> Order </TableCell>
                  <TableCell align="center"> Action </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items.ready ? (
                  items.data.length > 0 ? (
                    items.data.map((data, key) => (
                      <TableRow key={key}>
                        <TableCell> {data.department} </TableCell>
                        <TableCell> {data.category} </TableCell>
                        <TableCell> {data.order_name} </TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="secondary"
                            onClick={() => setRemove({ data, open: true })}
                          >
                            <Trash />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      startIcon={<CheckSquare />}
                      onClick={() => setPackages({ open: true })}
                      disabled={items.data.length === 0}
                    >
                      Create Package
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Card>
          <CardHeader title="Select Item" />
          <CardContent>
            <Formik
              initialValues={{
                username: username,
                user_id: user_id,
                token: token,
                management_id: management_id,
                department: "",
                category: "laboratory",
                order_id: "",
                order_name: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                department: Yup.string().required(),
                order_id: Yup.string().required("Order is a requried field."),
                password: Yup.string().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                try {
                  const response = await axios.post(
                    "laboratory/package/item/add-totemp",
                    getFormData(values)
                  );

                  if (response.data.message === "order-duplicated") {
                    setErrors({
                      order_id:
                        "Duplicated item entry, Try again with new order.",
                    });
                  }
                  if (response.data.message === "pass-invalid") {
                    setErrors({ password: "Password is invalid." });
                  }
                  if (response.data.message === "success") {
                    Notify.successRequest("new item");
                    resetForm();
                    setSubmitting(true);
                    getTempItemForPackage();
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box my={2}>
                    <Select
                      options={LaboratoryOrder.dept()}
                      defaultValue={LaboratoryOrder.dept().filter(
                        (x) => x.value === values.dept
                      )}
                      onChange={(data) =>
                        handleSelectedDept(data, setFieldValue)
                      }
                      styles={selectStyle}
                      menuPortalTarget={document.body}
                    />
                    {touched.department && errors.department && (
                      <FormHelperText error>{errors.department}</FormHelperText>
                    )}
                  </Box>

                  <Box my={2}>
                    <Select
                      options={filteredList}
                      onChange={(data) => {
                        setFieldValue("order_id", data.value);
                        setFieldValue("order_name", data.label);
                        setFieldValue("dept", data.value);
                      }}
                      styles={selectStyle}
                      menuPortalTarget={document.body}
                    />
                    {touched.order_id && errors.order_id && (
                      <FormHelperText error> {errors.order_id} </FormHelperText>
                    )}
                  </Box>

                  <Box mb={2}>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      label="Enter your password"
                      variant="outlined"
                      type="password"
                    />
                  </Box>

                  {errors.submit && (
                    <Box my={2}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Box display="flex">
                    <Box flexGrow={1} />

                    <Button
                      size="large"
                      variant="contained"
                      color="default"
                      type="reset"
                      startIcon={<XCircle />}
                    >
                      No
                    </Button>

                    <Box ml={2}>
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<CheckSquare />}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>

      {/* dialog remove */}
      <Dialog
        open={remove.open}
        onClose={() => setRemove({ data: null, open: false })}
      >
        <DialogContent>
          <Typography>Are you sure to remove this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setRemove({ data: null, open: false })}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRemoveItem(remove.data)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog package */}
      <Dialog open={packages.open} onClose={() => setPackages({ open: false })}>
        <DialogTitle> Create Package </DialogTitle>
        <Formik
          initialValues={{
            username: username,
            user_id: user_id,
            token: token,
            management_id: management_id,
            package_id: `package-${Math.floor(
              Math.random() * 9999
            )}-${new Date().getTime()}`,
            package_name: "",
            package_amount: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            package_name: Yup.string().required("Package is a requried field."),
            package_amount: Yup.string().required(
              "Amount is a requried field."
            ),
            password: Yup.string().required(),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const response = await axios.post(
                "laboratory/package/create-newpackage",
                getFormData(values)
              );

              if (response.data.message === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
              }
              if (response.data.message === "success") {
                Notify.successRequest("new item");
                resetForm();
                setPackages({ open: false, data: null });
                setSubmitting(true);
                getTempItemForPackage();
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box mb={3}>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(touched.package_name && errors.package_name)}
                    helperText={touched.package_name && errors.package_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.package_name}
                    name="package_name"
                    label="Package Name"
                    variant="outlined"
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(
                      touched.package_amount && errors.package_amount
                    )}
                    helperText={touched.package_amount && errors.package_amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.package_amount}
                    name="package_amount"
                    label="Package Amount"
                    variant="outlined"
                  />
                </Box>

                <Box>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    label="Enter your password"
                    variant="outlined"
                    type="password"
                  />
                </Box>

                {errors.submit && (
                  <Box my={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  type="reset"
                  startIcon={<XCircle />}
                  onClick={() => setPackages({ open: false })}
                >
                  No
                </Button>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckSquare />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </Grid>
  );
};

export default LaboratoryPackage;
