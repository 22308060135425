import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import Notify from "src/notification/Notify";

const LaboratoryInformation = ({ labinfo }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [formHeaderInfo, setFormHeaderInfo] = useState([]);

  const getFormHeaderInfo = async () => {
    let response = await Axios.get(
      "gtcadmin/doctors/getmanagement/doctors-newform/getlaboratory-header",
      {
        params: {
          token: token,
          management_id: labinfo.management_id,
        },
      }
    );

    let result = response.data;
    setFormHeaderInfo(result);
  };

  const editLaboratyInfo = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", labinfo.user_id);

    let response = await Axios.post(
      "gtcadmin/doctors/getmanagement/doctors-editlaboratory",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      Notify.successRequest("token");
    }
  };

  const handleNewLabFormHeader = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", labinfo.user_id);
    formdata.set("management_id", labinfo.management_id);

    let response = await Axios.post(
      "gtcadmin/doctors/getmanagement/doctors-newform/laboratory-header",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      Notify.successRequest("token");
      getFormHeaderInfo();
    }
  };

  useEffect(() => {
    getFormHeaderInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Paper component={Box} mb={2}>
        <form onSubmit={editLaboratyInfo}>
          <Box p={2}>
            <Box mb={2}>
              <Typography variant="h6" color="textSecondary">
                Laboratory Information
              </Typography>
            </Box>

            <Box mb={2}>
              <TextField
                label="name"
                name="name"
                variant="outlined"
                fullWidth
                defaultValue={labinfo.name}
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="address"
                name="address"
                variant="outlined"
                fullWidth
                defaultValue={labinfo.address}
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="Gender"
                name="gender"
                variant="outlined"
                fullWidth
                defaultValue={labinfo.gender}
              />
            </Box>

            <Box>
              <TextField
                label="Birthday"
                name="birthday"
                variant="outlined"
                fullWidth
                type="date"
                defaultValue={moment(new Date(labinfo.birthday)).format(
                  "YYYY-MM-DD"
                )}
              />
            </Box>
          </Box>
          <Box px={2} display="flex">
            <Box mr={2} mb={2}>
              <Button large="large" variant="contained" color="default">
                No
              </Button>
            </Box>

            <Box>
              <Button
                large="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                Update
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>

      <Paper component={Box} mb={2}>
        <Box p={2} display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              Laboratory Form Header
            </Typography>
            {formHeaderInfo.length === 0 && (
              <Typography color="secondary" variant="subtitle1">
                Form header is not set. click add lab form.
              </Typography>
            )}
          </Box>
          <Box>
            <Button color="primary" onClick={() => setDialog(true)}>
              Add Lab Form
            </Button>
          </Box>
        </Box>

        {formHeaderInfo.length > 0 && (
          <Box p={2}>
            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="Name"
                name="name"
                value={formHeaderInfo.length > 0 ? formHeaderInfo[0].name : ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="address"
                name="address"
                value={
                  formHeaderInfo.length > 0 ? formHeaderInfo[0].address : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="contact number"
                name="contact_number"
                value={
                  formHeaderInfo.length > 0
                    ? formHeaderInfo[0].contact_number
                    : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="pathologist"
                name="pathologist"
                value={
                  formHeaderInfo.length > 0 ? formHeaderInfo[0].pathologist : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="pathologist license number"
                name="pathologist_lcn"
                value={
                  formHeaderInfo.length > 0
                    ? formHeaderInfo[0].pathologist_lcn
                    : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <Typography color="textSecondary">
                Pathologist Signature
              </Typography>
              {formHeaderInfo.length > 0 && (
                <img
                  style={{
                    maxHeight: 270,
                  }}
                  alt=""
                  src={`${process.env.REACT_APP_API_IMAGE}laboratory/pathologist/${formHeaderInfo[0].pathologist_signature}`}
                />
              )}
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="medtech"
                name="medtech"
                value={
                  formHeaderInfo.length > 0 ? formHeaderInfo[0].medtech : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="medtect license number"
                name="medtect_lci"
                value={
                  formHeaderInfo.length > 0 ? formHeaderInfo[0].medtect_lci : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <Typography color="textSecondary"> Laboratory Logo </Typography>
              {formHeaderInfo.length > 0 && (
                <img
                  alt=""
                  style={{
                    maxHeight: 270,
                  }}
                  src={`${process.env.REACT_APP_API_IMAGE}laboratory/logo/${formHeaderInfo[0].logo}`}
                />
              )}
            </Box>
          </Box>
        )}
      </Paper>
      {/* new form dialog */}
      {dialog && (
        <NewFormHeader
          open={dialog}
          close={() => setDialog(false)}
          handleNewLabFormHeader={handleNewLabFormHeader}
        />
      )}
    </Box>
  );
};

export default LaboratoryInformation;

const NewFormHeader = ({ open, close, handleNewLabFormHeader }) => {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle> New Form Header Dialog </DialogTitle>

      <form onSubmit={handleNewLabFormHeader}>
        <DialogContent dividers>
          <Box>
            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="Name"
                name="name"
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="address"
                name="address"
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="contact number"
                name="contact_number"
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="pathologist"
                name="pathologist"
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="pathologist license number"
                name="pathologist_lcn"
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="pathologist pathologist signature"
                name="pathologist_sign"
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="medtech"
                name="medtech"
              />
            </Box>
            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="medtect license number"
                name="medtect_lci"
              />
            </Box>

            <Box mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="logo"
                name="logo"
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={close}
            large="large"
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            large="large"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
