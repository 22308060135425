import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  TextField,
  DialogActions,
  CircularProgress,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Notify from "src/notification/Notify";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";

const HeadCellCircumference = ({ patient_id, connection }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();

  const getHeadCellList = React.useCallback(async () => {
    let response = await axios.get("doctors/patients/childs/headcell-list", {
      params: {
        token,
        connection,
        patient_id,
      },
    });

    const data = response.data;

    if (mounted.current) {
      setList({ data, ready: true });
    }
  }, [mounted, patient_id, connection, token]);

  useEffect(() => {
    getHeadCellList();
  }, [getHeadCellList]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Paper component={Box} mt={2} p={2}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6" color="textSecondary">
                Head Cell Circumference
              </Typography>
            </Box>
            <Box>
              <Button
                variant="text"
                color="primary"
                onClick={() => setDialog(true)}
              >
                Update
              </Button>
            </Box>
          </Box>

          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Date </TableCell>
                    <TableCell> Head Circumference </TableCell>
                    <TableCell> Chest Circumference </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {list.ready ? (
                    list.data.length > 0 ? (
                      list.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell align="right"> {data.head}</TableCell>
                          <TableCell align="right"> {data.chest} </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="primary">please wait...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Grid>

      {/* new session dialog */}
      <AddNewSession
        open={dialog}
        close={() => setDialog(false)}
        patient_id={patient_id}
        action={() => getHeadCellList()}
      />
    </Grid>
  );
};

export default HeadCellCircumference;

const AddNewSession = ({ patient_id, connection, open, close, action }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth>
      <DialogTitle> Head/Chest Circumference </DialogTitle>
      <Formik
        initialValues={{
          user_id: user_id,
          token: token,
          patient_id: patient_id,
          connection: connection,
          head: "",
          chest: "",
          date: moment(new Date()).format("YYYY-MM-DD"),
        }}
        validationSchema={Yup.object().shape({
          head: Yup.number().required(),
          chest: Yup.number().required(),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "doctors/patients/childs/headcell-circumference-new",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("new head");
              resetForm();
              close();
              action();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  label="Head Circumference (cm)"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.head && errors.head)}
                  helperText={touched.head && errors.head}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.head}
                  name="head"
                  required
                  InputProps={{
                    inputProps: {
                      max: 9999,
                      min: 1,
                      step: 0.01,
                    },
                  }}
                  type="number"
                />
              </Box>

              <Box my={2}>
                <TextField
                  label="Chest Circumference (cm)"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.chest && errors.chest)}
                  helperText={touched.chest && errors.chest}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.chest}
                  name="chest"
                  required
                  InputProps={{
                    inputProps: {
                      max: 9999,
                      min: 1,
                      step: 0.01,
                    },
                  }}
                  type="number"
                />
              </Box>

              <Box my={2}>
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="date"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<HighlightOff />}
              >
                No
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
