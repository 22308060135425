import { Box, Toolbar, IconButton, Typography, makeStyles, Button, Badge, Popover, Dialog, Zoom, DialogContent } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import clsx from 'clsx';
import { ThemeContext } from 'src/ContextAPI';
import { blue, green, purple, red, grey, indigo, teal } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import { Bell as BellIcon, Sidebar as MenuIcon, Menu as MenuIconClose } from 'react-feather';
import { AccountCircle, CropFree, ExitToApp, ColorLens, CancelOutlined } from '@material-ui/icons';
import MyQRCode from "src/patients/qrcode/MyQRCode";
import LogoutDialog from './LogoutDialog';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 10,
    },
    whiteColor: {
        color: '#fff'
    },
    primaryColor: {
        color: theme.palette.primary.main
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    theme_blue: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${blue[500]} 50%, rgb(245, 245, 245) 0px)`
    },
    theme_green: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${green[500]} 50%, rgb(245, 245, 245) 0px)`
    },
    theme_red: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${red[500]} 50%, rgb(245, 245, 245) 0px)`
    },
    theme_purple: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${purple[500]} 50%, rgb(245, 245, 245) 0px)`
    },
    theme_indigo: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${indigo[500]} 50%, rgb(245, 245, 245) 0px)`
    },
    theme_teal: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${teal[500]} 50%, rgb(245, 245, 245) 0px)`
    },
    theme_dark: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
        background: `linear-gradient(-60deg, ${grey[700]} 50%, rgb(245, 245, 245) 0px)`
    }
}));

const TopbarPatients = ({ notification, module, render }) => {
    const classes = useStyles()
    const themeContext = useContext(ThemeContext)
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAccount, setOpenAccount] = useState(false);
    const [anchorElAccount, setAnchorElAccount] = React.useState(null);
    const [showMyQr, setShowMyQr] = useState(false);
    const [islogout, setIsLogout] = useState(false);
    const history = useHistory();

    const handleOpenToggle = (e) => {
        setOpenAccount(true);
        setAnchorElAccount(e.currentTarget);
    };

    return (
        <>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.whiteColor]: !themeContext.sidebar.open,
                                [classes.primaryColor]: themeContext.sidebar.open,
                            })}
                            onClick={() => {
                                themeContext.sidebar.toggle(!themeContext.sidebar.open)
                            }}
                        >
                            {themeContext.sidebar.open ? <MenuIconClose strokeWidth={1.7} /> : <MenuIcon />}
                        </IconButton>

                        <Typography
                            noWrap
                            variant="h6"
                            className={clsx(classes.uppercase, {
                                [classes.whiteColor]: themeContext.sidebar.open,
                                [classes.primaryColor]: themeContext.sidebar.open,
                            })}
                        >
                            GTC {module}
                        </Typography>
                    </Toolbar>
                </Box>

                <Box>
                    <Toolbar>
                        <Box>
                            <IconButton
                                className={clsx(classes.menuButton, {
                                    [classes.primaryColor]: themeContext.sidebar.open,
                                    [classes.whiteColor]: !themeContext.sidebar.open,
                                })}
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                    setOpen(true)
                                }}
                            >
                                <ColorLens />
                            </IconButton>
                            <ThemePickerPopover
                                open={open}
                                anchorEl={anchorEl}
                                close={() => {
                                    setAnchorEl(null)
                                    setOpen(false)
                                }}
                                themeContext={themeContext}
                            />
                        </Box>

                        {notification.enable && (
                            <Box>
                                <IconButton
                                    className={clsx(classes.menuButton, {
                                        [classes.primaryColor]: themeContext.sidebar.open,
                                        [classes.whiteColor]: !themeContext.sidebar.open,
                                    })}
                                    onClick={(e) => {
                                        localStorage.setItem('page', 'Notification');
                                        history.push('/clinic/app/patient/notification');
                                    }}
                                >
                                    <Badge
                                        badgeContent={notification.enable ? parseInt(notification.virtual.length) : 0}
                                        color="error"
                                    >
                                        <BellIcon />
                                    </Badge>
                                </IconButton>
                            </Box>
                        )}

                        <Box>
                            <IconButton
                                className={clsx(classes.menuButton, {
                                    [classes.primaryColor]: themeContext.sidebar.open,
                                    [classes.whiteColor]: !themeContext.sidebar.open,
                                })}
                                onClick={handleOpenToggle}
                            >
                                <AccountCircle />
                            </IconButton>

                            <Popover
                                open={openAccount}
                                anchorEl={anchorElAccount}
                                onClose={() => {
                                    setAnchorElAccount(null)
                                    setOpenAccount(false)
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Box p={1} width={'auto'} >
                                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" mb={1}>
                                        <Button
                                            startIcon={<CropFree />}
                                            onClick={() =>
                                                setShowMyQr(true)
                                            }
                                            style={{ justifyContent: 'left' }}
                                            fullWidth
                                        >
                                            My QR
                                        </Button>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                        <Button
                                            startIcon={<ExitToApp />}
                                            onClick={() => setIsLogout(true)}
                                        >
                                            Logout
                                        </Button>
                                    </Box>
                                </Box>
                            </Popover>
                        </Box>
                    </Toolbar>
                    <LogoutDialog
                        open={islogout}
                        close={() => setIsLogout(false)}
                    />
                </Box>

                <Dialog
                    TransitionComponent={Zoom}
                    transitionDuration={800}
                    maxWidth="xs"
                    fullWidth
                    open={showMyQr}
                    onClose={() => setShowMyQr(false)}
                >
                    <Box display="flex">
                        <Box flexGrow={1} />
                        <Box>
                            <IconButton color="secondary" onClick={() => setShowMyQr(false)}>
                                <CancelOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <DialogContent>
                        <MyQRCode />
                    </DialogContent>
                </Dialog>
            </Box>
        </>
    )
}

export default TopbarPatients;

const ThemePickerPopover = ({ open, close, anchorEl, themeContext }) => {
    const classes = useStyles()
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box p={2} maxWidth={180}>
                <Box mb={2}>
                    <Typography color="primary"> Themes </Typography>
                </Box>

                <Box
                    display="flex"
                    mb={2}
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('blue')}
                    fullWidth
                >

                    <Box className={classes.theme_blue} />
                    <Box width={70} ml={2}> BLUE </Box>
                </Box>
                <Box
                    display="flex"
                    mb={2}
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('green')}
                    fullWidth
                >
                    <Box className={classes.theme_green} />
                    <Box width={70} ml={2}> GREEN </Box>
                </Box>
                <Box
                    display="flex"
                    mb={2}
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('purple')}
                    fullWidth
                >
                    <Box className={classes.theme_purple} />
                    <Box width={70} ml={2}> PURPLE </Box>
                </Box>
                <Box
                    display="flex"
                    mb={2}
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('red')}
                    fullWidth
                >
                    <Box className={classes.theme_red} />
                    <Box width={70} ml={2}> RED </Box>
                </Box>

                <Box
                    display="flex"
                    mb={2}
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('indigo')}
                    fullWidth
                >
                    <Box className={classes.theme_indigo} />
                    <Box width={70} ml={2}> INDIGO </Box>
                </Box>


                <Box
                    display="flex"
                    mb={2}
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('teal')}
                    fullWidth
                >
                    <Box className={classes.theme_teal} />
                    <Box width={70} ml={2}> TEAL </Box>
                </Box>

                <Box
                    display="flex"
                    component={Button}
                    onClick={() => themeContext.updategtcTheme('dark')}
                    fullWidth
                >
                    <Box className={classes.theme_dark} />
                    <Box width={70} ml={2} > DARK </Box>
                </Box>
            </Box>
        </Popover>
    )
}