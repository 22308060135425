import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  CardMedia,
  CardHeader,
  Box,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import ClinicalMicroscopy from "../orderform/ClinicalMicroscopy";
import Hemathology from "../orderform/Hemathology";
import SerologyOrder from "../orderform/SerologyOrder";
import ChemistryOrder from "../orderform/ChemistryOrder";
import UrinalysisOrder from "../orderform/UrinalysisOrder";
import FecalOrder from "../orderform/FecalOrder";
import Footer from "src/laboratory/newlaboratory/print/Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PrintableOrder = ({ labownerid, trace_number, patient_id, close }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [printHeader, setPrintHeader] = useState([]);
  const [patientInfo, setPatientInfo] = useState([]);

  const getLaboratoryPrintHeader = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.set("labownermngid", labownerid);
      formdata.set("token", token);
      formdata.set("user_id", user_id);
      formdata.set("patient_id", patient_id);

      const params = {
        labownermngid: labownerid,
        token: token,
        user_id: user_id,
        patient_id: patient_id,
      };

      const prHeader = await axios.get(
        "doctor/laboratory/order/printable/printheader",
        { params }
      );
      setPrintHeader(prHeader.data);

      const patient = await axios.get("doctor/patient/patient-information", {
        params,
      });
      setPatientInfo(patient.data);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [labownerid, token, user_id, patient_id]);

  useEffect(() => {
    getLaboratoryPrintHeader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLaboratoryPrintHeader]);

  return (
    <>
      <Dialog open={Boolean(trace_number)} onClose={close} fullScreen>
        <DialogContent>
          {
            //print header
            Object.keys(printHeader).length > 0 && (
              <Box display="flex" justifyContent="center">
                <Box mt={2}>
                  {printHeader && (
                    <CardMedia
                      style={{ width: 70 }}
                      component={"img"}
                      src={`${imageLocation}laboratory/logo/${printHeader.logo}`}
                    />
                  )}
                </Box>
                <Box>
                  <CardHeader
                    component={Box}
                    align="center"
                    title={printHeader && printHeader.name}
                    subheader={printHeader && printHeader.address}
                  />
                </Box>
              </Box>
            )
          }

          <Box>
            {patientInfo.length > 0 && <Header details={patientInfo[0]} />}
          </Box>

          <Box>
            <Hemathology trace_number={trace_number} patient_id={patient_id} />
            <ClinicalMicroscopy
              trace_number={trace_number}
              patient_id={patient_id}
            />
            <SerologyOrder
              trace_number={trace_number}
              patient_id={patient_id}
            />
            <ChemistryOrder
              trace_number={trace_number}
              patient_id={patient_id}
            />
            <FecalOrder trace_number={trace_number} patient_id={patient_id} />
            <UrinalysisOrder
              trace_number={trace_number}
              patient_id={patient_id}
            />
          </Box>

          <Box>{printHeader && <Footer formheader={printHeader} />}</Box>
        </DialogContent>

        <DialogActions className={`d-print-none`}>
          <Button variant="contained" color="inherit" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.print()}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrintableOrder;

const Header = ({ details }) => {
  return (
    <>
      {/* paitent information */}
      <Box display="flex">
        <Box flexGrow={1} mb={2}>
          <Box mb={2}>
            <Typography variant="caption" className="font-weight-bold">
              NAME:
            </Typography>{" "}
            <span className="text-capitalize">
              {" "}
              {`${details.firstname} ${details.lastname}`}{" "}
            </span>
          </Box>

          <Box display="flex" mb={2}>
            <Box>
              <Typography variant="caption" className="font-weight-bold">
                AGE:
              </Typography>{" "}
              {details.birthday === null
                ? "None"
                : Notify.calculateAge(details.birthday)}
            </Box>
            <Box ml={5}>
              <Typography variant="caption" className="font-weight-bold">
                SEX:
              </Typography>{" "}
              {details.gender === null ? "None" : details.gender}
            </Box>
          </Box>

          <Box>
            <Typography variant="caption" className="font-weight-bold">
              ADDRESS:
            </Typography>{" "}
            <span className="text-capitalize">
              {" "}
              {`${details.street} ${details.barangay} ${details.city} `}{" "}
            </span>
          </Box>
        </Box>

        <Box>
          <Box mb={2}>
            <Typography variant="caption" className="font-weight-bold">
              DATE:
            </Typography>{" "}
            {new Date().toLocaleString()}
          </Box>
        </Box>
      </Box>
    </>
  );
};
