import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React, { Fragment } from "react";
import HemathologyRef from "../references/Ref_Hemathology";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const HemaOrder = ({ formheader, hemaOrderDetails }) => {
  return (
    <>
      <Card>
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={formheader && formheader.address}
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={hemaOrderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b> HEMATOLOGY </b>
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">
                    {" "}
                    <b> RESULT </b>{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <b> REFERENCE VALUES </b>{" "}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hemaOrderDetails.data.length > 0 ? (
                  hemaOrderDetails.data.map((data, key) => (
                    <Fragment key={key}>
                      {data.hemoglobin && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              hemoglobin
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.hemoglobin}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.hemoglobin()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.hematocrit && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              hematocrit
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.hematocrit}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.hematocrit()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.rbc && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              RBC
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.rbc}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.rbc()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.wbc && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              wbc
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.wbc}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.wbc()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.platelet_count && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Platelet count
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.platelet_count}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.plateletCount()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.differential_count && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              differential count
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.differential_count}</Typography>
                          </TableCell>
                          <TableCell align="center" />{" "}
                          {/*  No refenrece values */}
                        </TableRow>
                      )}

                      {data.neutrophil && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              neutrophil
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.neutrophil}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.neutrophil()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.lymphocyte && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              lymphocyte
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.lymphocyte}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.lymphocyte()}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.monocyte && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              monocyte
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.monocyte}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.monocyte()}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.eosinophil && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              eosinophil
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.eosinophil}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.eosinophil()}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.basophil && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              basophil
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.basophil}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.basophil()}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.bands && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              bands
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.bands}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.bands()}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.abo_blood_type_and_rh_type && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              ABO blood type / rh type
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {data.abo_blood_type_and_rh_type}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" />{" "}
                          {/* no refernece values */}
                        </TableRow>
                      )}
                      {data.bleeding_time && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              bleeding time
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.bleeding_time}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.bleedingTime()}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.clotting_time && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              clotting time
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.clotting_time}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.clottingTime()}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.blood_typing_with_rh && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Blood Typing
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.blood_typing_with_rh}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {HemathologyRef.clottingTime()} */}
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default HemaOrder;
