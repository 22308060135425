import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box mt={10} m={3}>
      <Typography
        align="center"
        variant={window.innerWidth > 600 ? "h2" : "h4"}
      >
        PRIVACY POLICY{" "}
      </Typography>
      <br />

      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        INTRODUCTION{" "}
      </Typography>
      <Typography variant="body1">
        GLOBAL TELEMEDICINE (GTC) CORP. respects and values your privacy and
        commits to ensure that all information especially personal data
        collected from you, our clients and customers, are processed in
        compliance with Republic Act No. 10173 or the Data Privacy Act of 2012
        (DPA), its Implementing Rules and Regulations, and other relevant laws,
        regulations, and policies, including issuances of the National Privacy
        Commission. This Policy describes the Company’s data processing
        practices which includes the collection, use, storage, disclosure and
        security of the information provided as well as the exercise of your
        rights over your personal data.
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        SCOPE AND LIMITATION{" "}
      </Typography>
      <Typography variant="body1">
        All Company personnel as well as the Company’s third-party providers
        must comply with the terms set out in this Policy, observing at all
        times the general principles of transparency, legitimate purpose, and
        proportionality. This Policy covers all data processing activities,
        whether collected online or offline, including those collected when you
        use the Company’s software application, mobile applications, and other
        sites owned and operated by the Company (collectively the “Site”) as
        well as other channels such as third party social networks, points of
        sale, and customer/client engagement services.
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        INFORMATION GATHERED{" "}
      </Typography>
      <Typography variant="body1">
        GLOBAL TELEMEDICINE (GTC) CORP. may collect the following information
        about you directly and/or from third party providers, as well as those
        collected automatically through the use of the Site and other various
        channels or availing of our services and depending on how you interact
        with the Company: • Personal Contact Information The Personal
        information the Company collects includes full name, physical address,
        email address, telephone number, identifiers that allow physical or
        online contacting or the location of you and other demographic
        information. • Health or Medical Information The Company may also
        collect health or medical information such as medical or health history,
        health status and laboratory testing results, and diagnostic images
        (still or moving) as well as audio files, prescriptions, treatment and
        examination notes and such other information or documents prepared by
        the healthcare providers who provide services through the Site/or our
        other channels. • Other information By registering and creating an
        account, the Company will also collect user identification and password
        and any information that you voluntarily share about your experience of
        using our products and services. Other information which may be
        collected includes the billing or payment information that you provide
        us, such as debit/credit card or bank account information. • Web traffic
        and Cookies In the use of the Site and other channels owned by the
        Company, information about you and your interaction with and activity in
        the Site are automatically tracked, collected and stored by employing
        cookies and web server logs. These pieces of information include:
        Internet Protocol (IP) address Uniform Resource Locators (URL) domain
        name type of computer; and type of web browser and location. Cookies are
        small data files sent and saved to your device to improve use
        experiences on the Site by personalizing content on the Company’s pages
        such as presenting information of greatest interest to you when you
        visit the Site.
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        USE OF THE INFORMATION{" "}
      </Typography>
      <Typography variant="body1">
        The Company process all information collected for the following
        purposes: (a) contact you with company updates, product information,
        software updates/release notes or to provide technical support and
        assistance, to respond to your inquiries, to send offers and other
        promotional communications, and for other customer/client service
        purposes. (b) display advertisements in the Site or other channels (c)
        facilitate communications between you and a healthcare provider (d) the
        provision of services (e) help resolve disputes and troubleshoot
        problems (f) collect fees and charges
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        SHARING YOUR INFORMATION{" "}
      </Typography>
      <Typography variant="body1">
        The Company may disclose information including sensitive personal
        information to the following authorized third parties as well as agents
        acting on their behalf:
      </Typography>
      <br />
      <ul>
        <li>
          Healthcare Providers. As directed and consented to by you, the
          relevant information you provided shall be shared to your Healthcare
          Provider of your choice in order to render the service/s required.{" "}
        </li>
        <li> Third party email service providers </li>
        <li> Service providers for payment processing </li>
        <li> Mobile health data devises, and others authorized by you. </li>
      </ul>
      <Typography variant="body1">
        The Company may share contact data with the foregoing to the extent
        permitted under this Policy and for the purposes you specified. The
        Company shall also disclose the information collected to the appropriate
        government agency, department or bureau or such other authorized third
        party in compliance with the law, a judicial proceeding, court order, or
        other legal process.
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        STORAGE AND SECURITY{" "}
      </Typography>
      <Typography variant="body1">
        The privacy and security of your information especially your sensitive
        personal information is recognized under the law. The Company is
        committed to maintain your information strictly confidential and secure.
        In ensuring that all information under its custody are adequately
        protected against any accidental or unlawful destruction, alteration and
        disclosure and against any other unlawful processing, the Company
        implements reasonable technical, physical, administrative, and
        organizational safeguards in storing collected information. All
        information gathered shall be retained for as long as necessary to
        satisfy the purposes for which your information were gathered and for
        such duration as may be permitted by law.
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        RIGHTS OVER YOUR PERSONAL DATA{" "}
      </Typography>
      <Typography variant="body1">
        You have the right to reasonable access to your personal information and
        review the same. In line with this right but subject to such limitations
        provided by law, you may (1) dispute the inaccuracy or error in the
        personal data and demand the correction thereof; (2) update your
        personal information; (3) request the suspension, withdrawal, blocking,
        removal or destruction of all or some of your personal information (4)
        lodge a complaint
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        CONTACT US{" "}
      </Typography>
      <Typography variant="body1">
        For any inquiry or concerns, you may contact us by using the Contact Us
        form or send a letter or email to the mailing address listed below.
        Attention:
      </Typography>
      <ul>
        <li> [INDICATE FULLNAME OF YOUR DATA PROCESSING OFFICER] </li>
        <li> [Complete Address] </li>
        <li> [Email Address] </li>
        <li> [Contact No.] </li>
      </ul>
      <br />
      <Typography variant="body1">
        Kindly include your complete name, contact information and a detailed
        description of your request or privacy concern.{" "}
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {" "}
        USER’S CONSENT{" "}
      </Typography>
      <Typography variant="body1">
        I hereby acknowledge that I have read and completely understood the
        Policy and warrant that I know and understood my rights therein. I give
        my consent to the processing including sharing of my personal
        information by the Company, any of its authorized third parties as well
        as agents acting on their behalf in accordance with the Policy.{" "}
      </Typography>
      <br />
    </Box>
  );
};

export default PrivacyPolicy;
