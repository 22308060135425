import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import SorologyRef from "src/laboratory/newlaboratory/references/Ref_Sorology";
const SerologyOrder = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("table", "laboratory_sorology");
    formdata.set("connection", "local");

    axios
      .post("doctor/patient/laboratory/order/paid-detailsbytable", formdata)
      .then((res) => {
        const data = res.data;
        setDetails(data);
      })
      .catch((er) => Notify.requestError(er));
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trace_number, patient_id]);

  return (
    <>
      {details.length > 0 && (
        <Box my={2} p={2}>
          <Box>
            <Typography variant="h6">
              <strong> Serology Order </strong>
            </Typography>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> Examination Requested </TableCell>
                  <TableCell align="center"> Normal </TableCell>
                  <TableCell align="center"> Result </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {details.map((data, key) => (
                  <Fragment key={key}>
                    {data.hbsag !== null && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Hepatitis B surface Antigen (HBsAg)
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.hbsag}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.hav !== null && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            HAV (Hepatitis A Virus) lgG/lgM
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.hav}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.hcv !== null && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            HCV (Hepatitis C Virus)
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.hcv}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.vdrl_rpr !== null && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            VDRL/RPR
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.vdrl_rpr}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {Boolean(parseInt(data.syphilis_test)) && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SYPHILIS TEST
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.syphilis_test_result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {Boolean(parseInt(data.hiv_test)) && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            HIV TEST
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.hiv_test_result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {Boolean(parseInt(data.cd4_test)) && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CD4 TEST
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.cd4_test_result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {Boolean(parseInt(data.rpr_test)) && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            RPR TEST
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.rpr_test_result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default SerologyOrder;
