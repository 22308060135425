import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { TableContainer, Paper, TextField, Typography, Box, Table, TableHead, CircularProgress, TableCell, TableRow, TableBody, Button, Menu, MenuItem, IconButton, Dialog, Zoom, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


function CreditRequest() {
    const [reqcredit, setreqcredit] = useState([])

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [selectedreqid, setselectedreqid] = useState('')
    const [sendlinkdialog, setsendlinkdialog] = useState(false)
    const [removereqdialog, setremovereqdialog] = useState(false)
    const [issentlinkprocess, setissentlinkprocess] = useState(false)

    const handleSelectedAction = (event) => {
        setAnchorEl(event.currentTarget);
        setselectedreqid(event.currentTarget.id)
    };

    const handleCloseAction = () => {
        setAnchorEl(null);
    };

    const getRequestCredit = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);

        Axios.post('gtcadmin/credit/requestcredit-list', formdata)
            .then((response) => {
                const data = response.data;
                setreqcredit(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    const handleSendPaymentDialog = () => {
        setsendlinkdialog(true)
        handleCloseAction()
    }

    const handleRemoveRequestDialog = () => {
        setremovereqdialog(true)
        handleCloseAction()
    }

    const handlePaymentLinkSent = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        var error = [];

        if (formdata.get('request_id').length === 0 || formdata.get('request_id').trim() === '') {
            error = 'error';
            Notify.fieldRequired('request id');
        }
        if (formdata.get('payment_link').length === 0 || formdata.get('payment_link').trim() === '') {
            error = 'error';
            Notify.fieldRequired('payment link');
        }
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }

        if (error.length > 0) {
            console.warn("hey form has an error.")
        } else {
            setissentlinkprocess(true)
            Axios.post('gtcadmin/credit/requestcredit-save', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'db-error') {
                        Notify.warnRequest('link sent')
                    }
                    if (data === 'success') {
                        getRequestCredit()
                        setsendlinkdialog(false)
                        Notify.successRequest('link sent')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setissentlinkprocess(true)
                })
        }
    }

    const handleRemoveRequestSubmit = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);

        setissentlinkprocess(true)
        Axios.post('gtcadmin/credit/remove-request-credit', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'db-error') {
                    Notify.warnRequest('remove request')
                }
                if (data === 'success') {
                    getRequestCredit()
                    setremovereqdialog(false)
                    Notify.successRequest('remove request')
                }
            }).catch(error => {
                Notify.requestError(error);
            }).finally(() => {
                setissentlinkprocess(true)
            })
    }

    const handleCheckLink = (e) => {
        window.open(e.currentTarget.id, '_blank');
    }

    useEffect(() => {
        getRequestCredit()
    }, [getRequestCredit])


    return (
        <Fragment>
            {
                reqcredit.length > 0 ?
                    <Box mb={2}>
                        <TableContainer component={Paper}>
                            <Box p={2}>
                                <b> User Request Credit </b>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> UserId </TableCell>
                                        <TableCell> Patient </TableCell>
                                        <TableCell> Request Amount </TableCell>
                                        <TableCell> Payment Link </TableCell>
                                        <TableCell> Action </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        reqcredit.map((data, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell> {data.user_id} </TableCell>
                                                    <TableCell> {data.patient_name} </TableCell>
                                                    <TableCell> {data.request_token}</TableCell>
                                                    <TableCell>
                                                        <span className="pointer text-primary" id={data.payment_link} onClick={handleCheckLink}> Check Link  </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton id={data.id} onClick={handleSelectedAction} >
                                                            <MoreVertIcon fontSize="small" />
                                                        </IconButton>
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleCloseAction}
                                                        >
                                                            <MenuItem onClick={handleSendPaymentDialog}> Send PaymentLink </MenuItem>
                                                            <MenuItem onClick={handleRemoveRequestDialog}> Remove Request </MenuItem>
                                                            {/* <MenuItem onClick={ handle}> Delete </MenuItem>  */}
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    : <p className="text-center gtc-small header-label "> No credit request found.</p>
            }

            {/* sent link dialog */}
            <Dialog
                TransitionComponent={Zoom}
                open={sendlinkdialog}
                keepMounted
                disableBackdropClick={true}
                transitionDuration={1000}
            >
                <DialogTitle>
                    Sent Payment Link
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handlePaymentLinkSent}>
                        <TextField hidden name="request_id" value={selectedreqid} />
                        <Box>
                            <TextField autoComplete="off" name="payment_link" label="Payment Link" margin="dense" variant="outlined" />
                        </Box>
                        <Box mb={1}>
                            <TextField autoComplete="off" name="password" type="password" label="Password" margin="dense" variant="outlined" />
                        </Box>
                        <Typography color="textSecondary">
                            Are you sure to send this link?
                        </Typography>
                        <Box display="flex" mt={1}>
                            <Button
                                startIcon={issentlinkprocess ? <CircularProgress color="inherit" size={15} /> : <SendIcon />}
                                disabled={issentlinkprocess} color="primary" variant="contained" type="submit">
                                Send
                            </Button>
                            <Box ml={2}>
                                <Button
                                    startIcon={<HighlightOffIcon />}
                                    onClick={() => setsendlinkdialog(false)}
                                    color="default" variant="contained" >
                                    No
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>

            {/* remove request */}
            <Dialog
                TransitionComponent={Zoom}
                open={removereqdialog}
                keepMounted
                disableBackdropClick={true}
                transitionDuration={1000}
            >
                <DialogContent dividers>
                    <Typography>
                        Are you sure to remove this request?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <form onSubmit={handleRemoveRequestSubmit}>
                        <TextField hidden name="request_id" value={selectedreqid} />
                        <Button disabled={issentlinkprocess} variant="contained" color="primary" type="submit"> Yes </Button>
                        <Button variant="contained" color="default" onClick={() => setremovereqdialog(false)}> No </Button>
                    </form>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default CreditRequest;
