import {
  Grid,
  Box,
  List,
  ListItemText,
  ListItem,
  Typography,
  Card,
  CardContent,
  Tab,
  Tabs,
  Paper,
} from "@material-ui/core";
import { AccountBox, Store, Storefront } from "@material-ui/icons";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import TabPanel from "src/utils/TabPanel";
import ManagementSecretary from "./ManagementSecretary";
import PharmacyDetails from "./PharmacyDetails";
import StockRoom from "./stockroom";

const ManagementPharmacy = ({ details }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: true });
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  const [category, setCategory] = useState("pharmacy");

  const getGetDoctorsPharmacy = React.useCallback(async () => {
    let response = await Axios.get("doctors/management/getpharmacy-list", {
      params: {
        token,
        user_id,
        management_id: details.management_id,
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [token, user_id, details, mounted]);

  useEffect(() => {
    getGetDoctorsPharmacy();
  }, [getGetDoctorsPharmacy]);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1} />
        <Box>
          <Paper>
            <Tabs
              value={category}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
              variant="scrollable"
              orientation={`horizontal`}
            >
              <Tab wrapped icon={<Store />} value="pharmacy" label="Pharmacy" />
              <Tab
                wrapped
                icon={<AccountBox />}
                value="secretary"
                label="Secretary"
              />
              <Tab
                wrapped
                icon={<Storefront />}
                value="stockroom"
                label="Stockroom"
              />
            </Tabs>
          </Paper>
        </Box>
      </Box>

      <Box mt={2}>
        <TabPanel value={category} index={"pharmacy"}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={selectedPharmacy !== null ? 2 : 4}>
              <Card>
                <CardContent>
                  <Box display={"flex"}>
                    <Box flexGrow={1} mt={1}>
                      <Typography variant="h5">Pharmacy List</Typography>
                    </Box>
                  </Box>

                  {list.ready ? (
                    list.data.length > 0 ? (
                      <List component="div">
                        {list.data.map((data, index) => (
                          <ListItem
                            button
                            key={index}
                            selected={
                              selectedPharmacy &&
                              data.pharmacy_id === selectedPharmacy.pharmacy_id
                            }
                            onClick={() => setSelectedPharmacy(data)}
                          >
                            <ListItemText
                              primary={data.name}
                              secondary={data.address}
                              primaryTypographyProps={{
                                noWrap: true,
                                style: {
                                  textTransform: "uppercase",
                                },
                              }}
                              secondaryTypographyProps={{
                                noWrap: true,
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      Notify.noRecord()
                    )
                  ) : (
                    Notify.loading()
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={selectedPharmacy !== null ? 10 : 8}>
              {selectedPharmacy !== null && (
                <PharmacyDetails
                  details={selectedPharmacy}
                  close={() => setSelectedPharmacy(null)}
                />
              )}
              {selectedPharmacy === null && (
                <Box>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">SELECT PHARMACY</Typography>
                      <Typography color="textSecondary">
                        Select pharmacy to continue.
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={category} index="secretary">
          <ManagementSecretary selectedManagement={details} />
        </TabPanel>

        <TabPanel value={category} index="stockroom">
          <StockRoom />
        </TabPanel>
      </Box>
    </>
  );
};

export default ManagementPharmacy;
