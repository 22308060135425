import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, makeStyles, Tabs, Tab, Paper } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import CreateAdmittingOrder from "./createadmittingorder/CreateAdmittingOrder";
import Notify from "src/notification/Notify";
import axios from "axios";
import AdmittingOrderList from "./list/AdmittingOrderList";
// import LaboratoryOrder from "./LaboratoryOrder";
// import UnpaidOrder from "./orderlist/UnpaidOrderList";
// import PaidOrder from "./orderlist/PaidOrder";
// import { PatientsUnRead } from "src/ContextAPI";
// import LaboratorySharedImages from "../laboratory/LaboratorySharedImages";

const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

const AdmittingOrder = ({
  connection,
  patient_id,
  getPermission,
  getPatientsList,
  permission,
}) => {
  const classes = useStyle();
  const [category, setCategory] = useState("create-admitting-order");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [admittingOrderList, setAdmittingOrderList] = useState({
    data: [],
    ready: false,
  });

  const handleChange = (event, cat) => {
    event.persist();
    setCategory(cat);
  };

  const getAllAdmittingOrders = useCallback(async () => {
    var params = { user_id: user_id, token: token, patient_id: patient_id };
    try {
      let response = await axios.get("doctor/get/all-admitting-order-by-pid", {
        params,
      });
      const data = response.data;
      setAdmittingOrderList({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [user_id, token, patient_id]);

  useEffect(() => {
    getAllAdmittingOrders();
  }, [getAllAdmittingOrders]);

  return (
    <Grid container spacing={2}>
      <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
        <Grid item xs={12} sm={12} md={10}>
          <TabPanel value={category} index={"create-admitting-order"}>
            <CreateAdmittingOrder
              patient_id={patient_id}
              connection={connection}
              getAllAdmittingOrders={() => getAllAdmittingOrders()}
            />
          </TabPanel>

          <TabPanel value={category} index={"admitting-order-list"}>
            <AdmittingOrderList
              admittingOrderList={admittingOrderList}
              patient_id={patient_id}
              connection={connection}
              getAllAdmittingOrders={() => getAllAdmittingOrders()}
            />
          </TabPanel>
        </Grid>
      </Box>

      <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
        <Grid item xs={12} sm={12} md={2}>
          <Box component={Paper} elevation={2} mb={2}>
            <Tabs
              value={category}
              onChange={handleChange}
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              orientation={window.innerWidth > 959 ? "vertical" : "horizontal"}
              classes={{
                indicator: classes.indicatorLeft,
              }}
            >
              <Tab wrapped value="create-admitting-order" label="Create" />

              <Tab wrapped value="admitting-order-list" label="List" />
            </Tabs>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AdmittingOrder;
