import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, CircularProgress } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PatientDetails from 'src/encoder/virtual-appointment/PatientDetails';
import Notify from 'src/notification/Notify'; 
import axios from 'axios'


const OnlineAppRequestDetails = ({ deeeee, close, getOnlineAppRequest }) =>{
    
    const token = localStorage.getItem('token')
    const username = localStorage.getItem('username')
    const user_id = localStorage.getItem('user_id')
    
    const [approveDialog , setApproveDialog] = useState(false)
    const [isSubmitting , setIsSubmitting] = useState(false)
 
console.log(deeeee)
    const handleApproveAppointment  = (e) => {
        e.preventDefault()
        e.persist()
        
        var formdata = new FormData(e.target); 
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('username', username)
            formdata.append('appointment_id', deeeee.data.appointment_id)
            formdata.append('patient_userid', deeeee.data.user_id)
            formdata.append('patient_id', deeeee.data.patient_id)
            
        
        var error = [];         

        if(formdata.get('message').length === 0 || formdata.get('message').trim() === ''){
            Notify.fieldRequired('message')
            error = 'error'
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim() === ''){
            Notify.fieldRequired('password')
            error = 'error'
        }

        if(error.length > 0){
            console.warn("form has an error.")
        }else{
            setIsSubmitting(true)
            axios.post('encoder/appointment/online/approve-request', formdata )
            .then( (response) => { 
                const data = response.data;      
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'success'){
                    getOnlineAppRequest()
                    setApproveDialog(false)
                    close()
                    Notify.successRequest('approve appointment')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() => setIsSubmitting(false))
        }
    }


    return(
        <>
            {/* dialog view */}
            <Dialog
                open={ deeeee.open }
                onClose={ close }
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle>
                    Request Details
                </DialogTitle>

                <DialogContent dividers>
                    <Box mb={2}> 
                        <PatientDetails patient_id={ deeeee.data.user_id } />
                    </Box>
                    
                    <Box className={`gtc-textfield-noborder`}> 
                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                label="Appointment Date"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={Notify.dateTimeConvert(deeeee.data.appointment_date)}
                                variant="outlined" 
                            />
                        </Box>

                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                label="Appointment Reason"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={ deeeee.data.appointment_reason }
                                multiline
                                variant="outlined" 
                            />
                        </Box> 

                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                label="Appointment Service"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={ deeeee.data.doctors_service }
                                variant="outlined" 
                            />
                        </Box>

                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Appointment Fee"
                                InputProps={{
                                    readOnly: true
                                }}
                                value={ deeeee.data.doctors_service_amount }
                                variant="outlined" 
                            />
                        </Box>
                    </Box> 
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={ close }
                        startIcon={ <HighlightOffIcon /> }
                    >
                        close
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ () => setApproveDialog(true) }
                        startIcon={ <ThumbUpIcon /> } 
                    >
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open = { approveDialog }
                onClose = { () => setApproveDialog(false) } 
                disableEscapeKeyDown
                disableBackdropClick
            >
                <form onSubmit={ handleApproveAppointment }>
                    <DialogTitle>
                        Verify Account
                    </DialogTitle>
                    <DialogContent dividers>

                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                label="Approve message"
                                variant="outlined"
                                name="message"
                                multiline 
                            />
                        </Box>

                        <Box>
                            <TextField 
                                fullWidth
                                label="Enter your password"
                                variant="outlined"
                                name="password"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="default"
                            variant="contained"
                            onClick={ () => setApproveDialog(false) }
                        >
                            Close
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit" 
                            disabled={ isSubmitting }
                            startIcon = { isSubmitting && <CircularProgress size={20} color="inherit" /> }
                        >
                            Approve
                        </Button>
                    </DialogActions> 
                </form>
            </Dialog>
        </>
    )
}

export default OnlineAppRequestDetails;