import {
  Box,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import moment from "moment";

const RadiologistManagement = ({ details }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [radiologistList, setRadiologistList] = useState([]);

  const getRadiologistByManagement = async () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);

    let response = await Axios.post(
      "gtcadmin/management/radiologist-list",
      formdata
    );
    let result = response.data;

    setRadiologistList(result);
  };

  const handleNewRadiologist = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);

    let response = await Axios.post(
      "gtcadmin/management/radiologist-add",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      setDialog(false);
      Notify.successRequest("new radiologist");
      getRadiologistByManagement();
    }
  };

  const handleUpdateRadiologist = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);

    let response = await Axios.post(
      "gtcadmin/management/radiologist-update",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      setDialog(false);
      Notify.successRequest("update");
      getRadiologistByManagement();
    }
  };

  useEffect(() => {
    getRadiologistByManagement();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Paper component={Box} p={2}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              Radiologist Information
            </Typography>
          </Box>
          <Box>
            <Button color="primary" onClick={() => setDialog(true)}>
              New Radiologist
            </Button>
          </Box>
        </Box>
        <Box mb={2}>
          {radiologistList.length > 0 ? (
            <Typography variant="subtitle1" color="primary">
              Fields are ready for update.
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="secondary">
              Radioligist is not set.
            </Typography>
          )}
        </Box>

        <Box>
          <form onSubmit={handleUpdateRadiologist}>
            {radiologistList.length > 0 && (
              <>
                <Box mb={2}>
                  <TextField
                    label="Id"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      radiologistList.length > 0
                        ? radiologistList[0].user_id
                        : ""
                    }
                    name="radiologist_userid"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      radiologistList.length > 0 ? radiologistList[0].name : ""
                    }
                    name="name"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Gender"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      radiologistList.length > 0
                        ? radiologistList[0].gender
                        : ""
                    }
                    name="gender"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Birthday"
                    variant="outlined"
                    fullWidth
                    defaultValue={moment(
                      new Date(radiologistList[0].birthday)
                    ).format("YYYY-MM-DD")}
                    name="birthday"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      radiologistList.length > 0
                        ? radiologistList[0].address
                        : ""
                    }
                    name="address"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Radiologist Username"
                    variant="outlined"
                    fullWidth
                    name="username"
                  />
                </Box>

                <Box>
                  <TextField
                    label="Radiologist Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                  />
                </Box>

                <Box display="flex" mt={2}>
                  <Box>
                    <Button variant="contained" color="primary" type="submit">
                      Update
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </form>
        </Box>
      </Paper>

      {/* new radiologist dialog */}
      <NewRadiologistInManagement
        open={dialog}
        close={() => setDialog(false)}
        handleNewRadiologist={handleNewRadiologist}
      />
    </Box>
  );
};

export default RadiologistManagement;

const NewRadiologistInManagement = ({ open, close, handleNewRadiologist }) => {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle> New Imaging </DialogTitle>
      <form onSubmit={handleNewRadiologist}>
        <DialogContent dividers>
          <Box mb={2}>
            <TextField label="Name" variant="outlined" fullWidth name="name" />
          </Box>

          <Box mb={2}>
            <TextField
              label="Gender"
              variant="outlined"
              fullWidth
              name="gender"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Birthday"
              variant="outlined"
              fullWidth
              name="birthday"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="address"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              name="username"
            />
          </Box>

          <Box>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              name="password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={close}>
            No
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
