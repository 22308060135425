
import Notify from 'src/notification/Notify'

const Formvalidatory = (fieldData, formField) =>{ 
    var error  = [];  
    
    if(fieldData.hemoglobin){
        if(formField.get('hemoglobin').length === 0 || formField.get('hemoglobin').trim() === ''){
            Notify.fieldRequired('hemoglobin')
            error = 'error';
        }
    }
    if(fieldData.hematocrit){
        if(formField.get('hematocrit').length === 0 || formField.get('hematocrit').trim() === ''){
            Notify.fieldRequired('hematocrit')
            error = 'error';
        }
    }
    
    if(fieldData.rbc){
        if(formField.get('rbc').length === 0 || formField.get('rbc').trim() === ''){
            Notify.fieldRequired('rbc')
            error = 'error';
        }
    }
    if(fieldData.wbc){
        if(formField.get('wbc').length === 0 || formField.get('wbc').trim() === ''){
            Notify.fieldRequired('wbc')
            error = 'error';
        }
    }

    if(fieldData.platelet_count){
        if(formField.get('platelet_count').length === 0 || formField.get('platelet_count').trim() === ''){
            Notify.fieldRequired('platelet count')
            error = 'error';
        }
    }
    if(fieldData.differential_count){
        if(formField.get('differential_count').length === 0 || formField.get('differential_count').trim() === ''){
            Notify.fieldRequired('differential count')
            error = 'error';
        }
    }
    if(fieldData.neutrophil){
        if(formField.get('neutrophil').length === 0 || formField.get('neutrophil').trim() === ''){
            Notify.fieldRequired('neutrophil')
            error = 'error';
        }
    }
    if(fieldData.lymphocyte){
        if(formField.get('lymphocyte').length === 0 || formField.get('lymphocyte').trim() === ''){
            Notify.fieldRequired('lymphocyte')
            error = 'error';
        }
    }
    if(fieldData.monocyte){
        if(formField.get('monocyte').length === 0 || formField.get('monocyte').trim() === ''){
            Notify.fieldRequired('monocyte')
            error = 'error';
        }
    }
    if(fieldData.eosinophil){
        if(formField.get('eosinophil').length === 0 || formField.get('eosinophil').trim() === ''){
            Notify.fieldRequired('eosinophil')
            error = 'error';
        }
    }
    if(fieldData.basophil){
        if(formField.get('basophil').length === 0 || formField.get('basophil').trim() === ''){
            Notify.fieldRequired('basophil')
            error = 'error';
        }
    }
    if(fieldData.bands){
        if(formField.get('bands').length === 0 || formField.get('bands').trim() === ''){
            Notify.fieldRequired('bands')
            error = 'error';
        }
    }
    if(fieldData.abo_blood_type_and_rh_type){
        if(formField.get('abo_blood_type_and_rh_type').length === 0 || formField.get('abo_blood_type_and_rh_type').trim() === ''){
            Notify.fieldRequired('ABO blood type / rh type')
            error = 'error';
        }
    }
    if(fieldData.bleeding_time){
        if(formField.get('bleeding_time').length === 0 || formField.get('bleeding_time').trim() === ''){
            Notify.fieldRequired('bleeding time')
            error = 'error';
        }
    }
    if(fieldData.clotting_time){
        if(formField.get('clotting_time').length === 0 || formField.get('clotting_time').trim() === ''){
            Notify.fieldRequired('clotting time')
            error = 'error';
        }
    } 

    return error;
}

export default Formvalidatory;