import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import FormvalidationClinicChemistry from "./validation/FormvalidationClinicChemistry";
import { Fragment } from "react";
import ChemistryRef from "../references/Ref_Chemistry";
import { CheckSquare, XCircle } from "react-feather";

const ClinicalChemistryOrderDetails = ({
  order,
  getLabCliniclChemistryOrder,
  resetDisplay,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });
  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState({
    open: false,
    data: null,
  });
  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const saveButtonRef = React.useRef();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);

  const getLabCChemistryOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("trace_number", order.trace_number);
    Axios.post("laboratory/order/ordernew-clinicalchemistry/details", formdata)
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getLabCChemistryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("trace_number", order.trace_number);

    var error = [];

    if (orderDetails.data) {
      error = FormvalidationClinicChemistry(orderDetails.data, formdata);
    }

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setResultSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-clinicalchemistry/save-process-result",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added.");
            setTimeout(() => {
              history.push(
                `/clinic/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("trace_number", order.trace_number);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-clinicalchemistry/save-setpending",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabCliniclChemistryOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("order_id", processDialog.data.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setProcessSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-clinicalchemistry/save-setprocessing",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabCChemistryOrderDetails();
            setProcessDialog({ open: false, data: null });
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setProcessSubmitting(false));
    }
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <CardHeader
            component={Box}
            align="center"
            title={
              <Box>
                <Typography variant="h5">
                  <b> LABORATORIO MEDICO DE CALIDAD </b>
                </Typography>
                <Typography>With</Typography>
                <Typography variant="h6">
                  <b> DR. MELY R. PENAFLORIDA-LASTIMOSO MD FPSV </b>
                </Typography>
              </Box>
            }
            subheader={`General Medicine & Treats SEXUALLY TRANSMITTED DISEASES Including HIV & AIDS and other venereal diseases
                        2/F Room 4&5 VENCER building National Highway 
                         General Santos City`}
          />

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      NAME:
                    </Typography>
                    {`${order.firstname} ${order.lastname}`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        SEX:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      ADDRESS:
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:
                    </Typography>
                    {Notify.dateTimeConvert(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            {/* order details */}
            <Box>
              <Typography variant="h6" align="center">
                <b> CLINICAL CHEMISTRY REPORT </b>
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <b> TEST REQUEST </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> RESULT </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> REFERENCE VALUES </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> Action </b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orderDetails.ready ? (
                    orderDetails.data.length > 0 ? (
                      orderDetails.data.map((data, key) => (
                        <Fragment key={key}>
                          <TableRow>
                            <TableCell colSpan={4} hidden>
                              {/* hidden files */}
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  name="order_id[]"
                                  label="order_id"
                                  hidden
                                  value={data.order_id}
                                />
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  name="patient_id"
                                  label="patient_id"
                                  hidden
                                  value={data.patient_id}
                                />

                                <TextField
                                  margin="dense"
                                  fullWidth
                                  name="doctors_id"
                                  label="doctor_id"
                                  hidden
                                  value={
                                    data.doctor_id === null
                                      ? ""
                                      : data.doctor_id
                                  }
                                />
                              </Box>

                              {key === 0 && (
                                <Box mb={1} display="flex">
                                  <Box>
                                    <Typography variant="subtitle2">
                                      <b> SPECIMEN: </b>
                                    </Typography>
                                  </Box>
                                  <Box ml={2}>
                                    <Typography variant="subtitle2">
                                      {data.spicemen}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            </TableCell>
                          </TableRow>

                          {/* GLUCOSE */}
                          <TableRow hidden={data.glucose === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                GLUCOSE
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="glucose[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.glucose()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* CREATININE */}
                          <TableRow hidden={data.creatinine === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                CREATININE
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="creatinine[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.creatinine()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* URIC ACID */}
                          <TableRow hidden={data.uric_acid === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                URIC ACID
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="uric_acid[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.uric()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* CHOLESTEROL */}
                          <TableRow hidden={data.cholesterol === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                CHOLESTEROL
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="cholesterol[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.cholesterol()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* TRIGLYCERIDE */}
                          <TableRow hidden={data.triglyceride === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                TRIGLYCERIDE
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="triglyceride[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.triglyceride()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* HDL CHOLESTEROL */}
                          <TableRow hidden={data.hdl_cholesterol === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                HDL CHOLESTEROL
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="hdl_cholesterol[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.hdlcholesterol()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* LDL CHOLESTEROL */}
                          <TableRow hidden={data.ldl_cholesterol === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                LDL CHOLESTEROL
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="ldl_cholesterol[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.ldlcholesterol()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* SGOT */}
                          <TableRow hidden={data.sgot === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                SGOT
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="sgot[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.sgot()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* SGPT */}
                          <TableRow hidden={data.sgpt === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                SGPT
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="sgpt[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {ChemistryRef.sgpt()}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* fbs */}
                          <TableRow hidden={data.fbs === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                FBS/RBS
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="fbs[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {/* {ChemistryRef.sgpt()} */}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* bun */}
                          <TableRow hidden={data.bun === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                BUN
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="bun[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {/* {ChemistryRef.sgpt()} */}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* sua */}
                          <TableRow hidden={data.serum_uric_acid === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                SERUM URIC ACID
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="serum_uric_acid[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {/* {ChemistryRef.sgpt()} */}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* alp */}
                          <TableRow hidden={data.alkaline_phosphatase === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                ALKALINE PHOSPHATASE
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="alkaline_phosphatase[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {/* {ChemistryRef.sgpt()} */}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* potassium */}
                          <TableRow hidden={data.potassium === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                POTASSIUM
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="potassium[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {/* {ChemistryRef.sgpt()} */}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* lipid_profile */}
                          <TableRow hidden={data.lipid_profile === null}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                LIPID PROFILE
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  // required
                                  name="lipid_profile[]"
                                  label="Result"
                                  disabled={
                                    Boolean(parseInt(order.is_pending)) ||
                                    !Boolean(parseInt(order.is_processed)) ||
                                    resultSubmitting
                                  }
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {/* {ChemistryRef.sgpt()} */}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={Boolean(parseInt(data.is_processed))}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setProcessDialog({
                                    data: data,
                                    open: true,
                                  });
                                }}
                              >
                                Process
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography color="primary">
                          loading, please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  hidden
                  ref={saveButtonRef}
                  disabled={!confirmDialog}
                >
                  Save Result Button
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setConfirmDialog(true)}
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="outlined"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.open}
        onClose={() => setProcessDialog({ data: null, open: false })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as processing</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ data: null, open: false })}
              startIcon={<HighlightOffIcon />}
            >
              no
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogContent>
          <Typography>Are you sure to continue and save result?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setConfirmDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={resultSubmitting}
            startIcon={
              resultSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => saveButtonRef.current.click()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClinicalChemistryOrderDetails;
