import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import Axios from "axios";
import React, { useState } from "react";
import { ArrowRight, Trash } from "react-feather";
import { useHistory } from "react-router-dom";
import Notify from "src/notification/Notify";

const PharmacyWarehouseAccountList = ({ accountList, getAccountList }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [remove, setRemove] = useState({
    dialog: false,
    id: null,
  });

  const removeItem = async () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("management_id", management_id);
    formdata.set("password", password);
    formdata.set("pwdr_id", remove.id);

    let response = await Axios.post(
      "pharmacy/warehouse/account/removeaccount",
      formdata
    );
    let result = response.data;
    if (result.message === "pass-invalid") {
      Notify.fieldInvalid("password");
    }
    if (result.message === "success") {
      getAccountList();
      setRemove({ dialog: false, id: null });
    }
    setLoading(false);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell> Account </TableCell>
            <TableCell> Address </TableCell>
            <TableCell align="center"> Action </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {accountList.ready ? (
            accountList.data.length > 0 ? (
              accountList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell> {data.pharmacy_name} </TableCell>
                  <TableCell> {data.pharmacy_address} </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setRemove({ dialog: true, id: data.pwdr_id })
                      }
                    >
                      <Trash />
                    </IconButton>

                    <IconButton
                      color="primary"
                      onClick={() =>
                        history.push(
                          `/clinic/app/warehouse/accounts/${data.pwdr_id}`
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography color="secondary">No record found.</Typography>
                </TableCell>
              </TableRow>
            )
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography color="primary">loading, please wait...</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* dialog remove */}
      <DeleteAccount
        open={remove.dialog}
        close={() => setRemove({ dialog: false, id: null })}
        removeItem={removeItem}
        setPassword={setPassword}
        setLoading={setLoading}
        loading={loading}
      />
    </TableContainer>
  );
};

export default PharmacyWarehouseAccountList;

const DeleteAccount = ({
  open,
  close,
  removeItem,
  setPassword,
  loading,
  setLoading,
}) => {
  return (
    <Dialog open={open} onClose={close} disableBackdropClick>
      <DialogTitle>Remove dialog</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Enter your password"
          fullWidth
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="contained"
          size="large"
          onClick={close}
          startIcon={<HighlightOff />}
        >
          No
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              <CheckCircleOutline />
            )
          }
          onClick={() => {
            setLoading(true);
            removeItem();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
