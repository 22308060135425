import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import Axios from "axios";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
const UnpaidOrderList = ({
  patient_id,
  // getCountImaging
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [order, setOrder] = useState({ data: [], ready: false });
  let total = 0;

  const getUnpaidImaing = () => {
    var formdata = new FormData();
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("management_id", management_id);
    formdata.set("patient_id", patient_id);

    Axios.post("imaging/order/unpaid-list", formdata)
      .then((response) => {
        const data = response.data;
        setOrder({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const calculateTotal = (amount) => {
    total += parseFloat(amount);

    return amount;
  };

  useEffect(() => {
    getUnpaidImaing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      <Paper>
        <Box p={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Date </TableCell>
                  <TableCell> ID </TableCell>
                  <TableCell> Order </TableCell>
                  <TableCell> Rate </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {order.ready ? (
                  order.data.length > 0 ? (
                    order.data.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {" "}
                          {Notify.createdAt(data.created_at)}{" "}
                        </TableCell>
                        <TableCell> {data.order_id} </TableCell>
                        <TableCell> {data.bill_name} </TableCell>
                        <TableCell>
                          {" "}
                          {calculateTotal(data.bill_amount)}{" "}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography color="secondary">
                          {" "}
                          No unpaid order{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="primary"> please wait... </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} />
                  <TableCell>Total:</TableCell>
                  <TableCell>{Notify.convertToNumber(total)}</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
};

export default UnpaidOrderList;
