import React, { useState, useCallback, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Collapse,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import axios from "axios";
import Notify from "src/notification/Notify";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import {
  CheckSquare,
  Edit,
  CreditCard,
  XCircle,
  PlusCircle,
  ChevronDown,
  ChevronRight,
} from "react-feather";
import BillingDetailsChangeRate from "./BillingDetailsChangeRate";
import BillingDetailsNewBill from "./BillingDetailsNewBill";

const BillingDetails = ({ details, getUnpaidOrder }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const management_id = localStorage.getItem("management_id");
  const history = useHistory();
  const [unpaidRemarks, setUnpaidRemarks] = useState({
    open: false,
    data: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    data: [],
    ready: false,
  });

  const [cancelOrder, setCancelOrder] = useState({
    data: null,
    dialog: false,
  });

  const [isprocessCancel, setIsProcessCancel] = useState(false);
  const [isprocessPaid, setIsProcessPaid] = useState(false);

  const [paidDialog, setPaidDialog] = useState({
    data: null,
    open: false,
  });

  const [edit, setEdit] = useState({
    open: false,
    trace_number: null,
    data: null,
  });
  const [newBill, setNewbill] = useState(false);

  let totalorder = 0;

  const getBillingDetails = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", details.patient_id);
    formdata.set("order_id", details.order_id);

    axios
      .post("encoder/pateint/billing/details", formdata)
      .then((response) => {
        const data = response.data;
        setBillingDetails({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, details]);

  useEffect(() => {
    getBillingDetails();
  }, [getBillingDetails]);

  const calculateOrder = (amount) => {
    totalorder += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  const handleCancelOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("token", token);
    formdata.set("patient_id", details.patient_id);
    formdata.set("username", username);

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("form error.");
    } else {
      setIsProcessCancel(true);
      Axios.post("encoder/billing/cancel-bill", formdata)
        .then((response) => {
          const data = response.data;

          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            setCancelOrder({ data: null, dialog: false });
            getBillingDetails();
            Notify.successRequest("billing cancel");
          }
          if (data === "db-error") {
            Notify.warnRequest("billing cancel");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcessCancel(false));
    }
  };

  const handleSetAsPaid = (e) => {
    e.preventDefault();
    e.persist();

    var receipt_number = `receipt-${Math.floor(
      100000 + Math.random() * new Date().getTime()
    )}`;
    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", details.patient_id);
    formdata.set("username", username);
    formdata.set("receipt_number", receipt_number);
    formdata.set("management_id", management_id);

    if (
      formdata.get("amountto_pay").length === 0 ||
      formdata.get("amountto_pay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("amountto_pay");
    }

    if (
      formdata.get("payment").length === 0 ||
      formdata.get("payment").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("payment");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("form error.");
    } else {
      setIsProcessPaid(true);
      Axios.post("encoder/billing/setaspaid-bill", formdata)
        .then((response) => {
          const data = response.data;

          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            setPaidDialog({ data: null, open: false });
            getBillingDetails();
            getUnpaidOrder();
            Notify.successRequest("billing cancel");
            history.push(
              `/clinic/app/encoder/billing/receipt/${receipt_number}`
            );
          }
          if (data === "db-error") {
            Notify.warnRequest("billing cancel");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcessPaid(false));
    }
  };

  return (
    <Card>
      <CardHeader title={`${details.lname}, ${details.fname} Bills`} />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Order </TableCell>
                <TableCell> Remarks </TableCell>
                <TableCell> Order Rate </TableCell>
                <TableCell align={"center"}> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {billingDetails.ready ? (
                billingDetails.data.length > 0 ? (
                  billingDetails.data.map((data, key) => (
                    <Fragment key={key}>
                      <TableRow>
                        <TableCell> {data.bill_name} </TableCell>
                        <TableCell> {data.remarks} </TableCell>
                        <TableCell align="right">
                          {calculateOrder(data.bill_amount)}
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            disabled={
                              unpaidRemarks.open &&
                              unpaidRemarks.data.id === data.id
                            }
                            color="primary"
                            onClick={() =>
                              setUnpaidRemarks({
                                open: true,
                                data: data,
                              })
                            }
                          >
                            {unpaidRemarks.open &&
                            unpaidRemarks.data.id === data.id ? (
                              <ChevronDown />
                            ) : (
                              <ChevronRight />
                            )}
                          </IconButton>

                          {data.bill_from === "appointment" && (
                            <Tooltip title="Edit Amount" arrow>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setEdit({
                                    open: true,
                                    trace_number: data.trace_number,
                                    data: data,
                                  })
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip arrow title="Cancel Order">
                            <IconButton
                              color="secondary"
                              hidden={data.bill_from === "appointment"}
                              onClick={() =>
                                setCancelOrder({ data: data, dialog: true })
                              }
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      {unpaidRemarks.open && unpaidRemarks.data.id === data.id && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Collapse
                              in={unpaidRemarks.open}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box p={1}>
                                <Typography variant="caption">
                                  REMARKS :
                                </Typography>

                                <Typography color="textSecondary">
                                  {unpaidRemarks.data.remarks}
                                </Typography>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="secondary">
                        No unpaid order.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="primary">please wait...</Typography>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell></TableCell>

                <TableCell>
                  <strong> TOTAL </strong>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" align="right">
                    {Notify.convertToNumber(totalorder)}
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNewbill(true)}
              startIcon={<PlusCircle />}
            >
              NEW BILL
            </Button>
          </Box>

          <Button
            variant="contained"
            color="primary"
            startIcon={<CreditCard />}
            onClick={() => setPaidDialog({ data: totalorder, open: true })}
          >
            PAYMENT
          </Button>
        </Box>
      </CardContent>

      {/* dialogssssssssssssssssssssss */}
      {/* cancel order dialog */}
      <Dialog
        open={cancelOrder.dialog}
        onClose={() => setCancelOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {cancelOrder.data && cancelOrder.dialog && (
          <form onSubmit={handleCancelOrder}>
            <DialogTitle>Verify cancel</DialogTitle>
            <DialogContent dividers>
              <Box mb={2}>
                <TextField
                  label={"Order Id"}
                  variant="outlined"
                  name="cancel_id"
                  defaultValue={cancelOrder.data.epb_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box>
                <TextField
                  label={"Enter password"}
                  variant="outlined"
                  name="password"
                  type="password"
                  fullWidth
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setCancelOrder({ data: null, dialog: false })}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      {/* setas paid bills dialog */}

      <Dialog
        open={paidDialog.open}
        onClose={() => setPaidDialog({ data: null, open: false })}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <form onSubmit={handleSetAsPaid}>
          <DialogTitle>Bills set as paid</DialogTitle>

          <DialogContent dividers>
            <Box mb={2} className={`gtc-textfield-noborder`}>
              <TextField
                required
                fullWidth
                label="Amount to pay"
                variant="outlined"
                defaultValue={paidDialog.data}
                InputProps={{
                  readOnly: true,
                }}
                name="amountto_pay"
              />
            </Box>

            <Box mb={2}>
              <TextField
                required
                fullWidth
                label="Payment"
                variant="outlined"
                name="payment"
                type="number"
                InputProps={{
                  inputProps: {
                    min: paidDialog.data && parseFloat(paidDialog.data),
                    step: 0.01,
                  },
                }}
              />
            </Box>

            <Box>
              <TextField
                required
                fullWidth
                label="Enter password"
                variant="outlined"
                type="password"
                name="password"
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPaidDialog({ data: null, open: false })}
              startIcon={<XCircle />}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isprocessPaid}
              startIcon={
                isprocessPaid ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* update rate dialog */}
      {edit.open && (
        <BillingDetailsChangeRate
          open={edit.open}
          details={edit.data}
          close={() => setEdit({ open: false, trace_number: null, data: null })}
          trace_number={details.trace_number}
          patient_id={details.patient_id}
          getBillingDetails={() => getBillingDetails()}
        />
      )}

      {/* new bill dialog */}
      <BillingDetailsNewBill
        open={newBill}
        close={() => setNewbill(false)}
        getBillingDetails={() => getBillingDetails()}
        trace_number={details.trace_number}
        patient_id={details.patient_id}
      />
    </Card>
  );
};

export default BillingDetails;
