import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';

class chemistryRef {
    glucose = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    3.85 - 6.05 mmol/L
                </Typography>
            </Box>
        </Box>
    )

    creatinine = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    MALE: 62 - 105 μmol/L
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2">
                    FEMALE: 53 - 97 μmol/L
                </Typography>
            </Box>
        </Box>
    )

    uric = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    MALE: 214 - 488 μmol/L
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2">
                    FEMALE: 137 - 363 μmol/L
                </Typography>
            </Box>
        </Box>
    )

    cholesterol = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    {'<'} 5.17 mmol/L
                </Typography>
            </Box>
        </Box>
    )

    triglyceride = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    {'<'} 2.26 mmol/L
                </Typography>
            </Box>
        </Box>
    )

    // differentialCount = () => ( ) no references 

    hdlcholesterol = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    MALE: 0.9 - 1.4 mmol/L
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2">
                    FEMALE: 1.2 - 1.7 mmol/L
                </Typography>
            </Box>
        </Box>
    )

    ldlcholesterol = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    {'<'} 3.9 mmol/L
                </Typography>
            </Box>
        </Box>
    )

    sgot = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    MALE: {'<'} 45 U/L
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2">
                    FEMALE: {'<'} 34 U/L
                </Typography>
            </Box>
        </Box>
    )

    sgpt = () => (
        <Box display="flex" >
            <Box flexGrow={1}>
                <Typography variant="subtitle2">
                    up to 40 U/L
                </Typography>
            </Box>
        </Box>
    )
}

const ChemistryRef = new chemistryRef();
export default ChemistryRef;