import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, FormHelperText, Paper, TextField, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import NewSpecializationEdit from './NewSpecializationEdit';

const NewSpecialization = ({ close }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [selectedSpecialID, setSelectedSpecialID] = useState(null);
    const [selectedSpecialName, setSelectedSpecialName] = useState(null);
    const [special, setSpecial] = useState([]);

    const getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const getSpecializationList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        Axios.post('gtcadmin/doctors/get-all-specialization', formdata)
            .then((response) => {
                const data = response.data;
                setSpecial(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    useEffect(() => {
        getSpecializationList();
    }, [getSpecializationList])

    return (
        <>
            <Paper
                component={Box}
                p={2}
            >
                <Formik
                    initialValues={{
                        username: username,
                        user_id: user_id,
                        token: token,
                        specialization: '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        specialization: Yup.string().required(),
                        password: Yup.string().required()
                    })}
                    onSubmit={async (values, {
                        setErrors,
                        setSubmitting,
                        resetForm
                    }) => {
                        try {
                            const request = await Axios.post('gtcadmin/doctors/new-specialization-save', getFormData(values))
                            if (request.data === 'pass-invalid') {
                                setErrors({ submit: 'Password is invalid.' });
                                Notify.fieldInvalid('password')
                            }
                            if (request.data === 'specialization-exist') {
                                setErrors({ submit: 'Doctors username taken.' });
                                Notify.customToast('Specialization is already added.')
                            }
                            if (request.data === 'success') {
                                Notify.successRequest('new specialization')
                                resetForm();
                                setSubmitting(true);
                            }
                        } catch (error) {
                            const message = error.message || 'Something went wrong';
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Box mb={2}>
                                <Box flexGrow={1}>
                                    <Box display="flex">
                                        <IconButton
                                            onClick={close}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <Box mt={2}>
                                            <Typography color="textPrimary" variant="subtitle2" >
                                                NEW DOCTOR SPECIALIZATION
                                            </Typography>
                                        </Box>
                                        <Box flexGrow={1} />
                                        <Box>
                                            <IconButton
                                                onClick={() => setOpenModal(true)}
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box mb={2}>
                                <TextField
                                    error={Boolean(touched.specialization && errors.specialization)}
                                    helperText={touched.specialization && errors.specialization}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.specialization}
                                    fullWidth
                                    required
                                    name="specialization"
                                    label="Doctor's Specialization"
                                    variant="outlined"
                                />
                            </Box>

                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    required
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    label="Enter your password"
                                    variant="outlined"
                                    type="password"
                                />
                            </Box>

                            {errors.submit && (
                                <Box mt={3} >
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}

                            <Box mb={2} display="flex">
                                <Box flexGrow={1} />

                                <Button
                                    variant="contained"
                                    color="default"
                                    type="reset"
                                    startIcon={<ClearIcon />}
                                >
                                    Clear
                                </Button>
                                <Box ml={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CheckIcon />}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>

                            <Dialog disableBackdropClick onClose={() => { setOpenModal(false); setSelectedSpecialID(null); setSelectedSpecialName(null) }} aria-labelledby="customized-dialog-title" open={openModal}>
                                <DialogTitle id="customized-dialog-title" onClose={() => { setOpenModal(false); setSelectedSpecialID(null); setSelectedSpecialName(null) }}>
                                    Specialization List
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Specialization</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {special.map((data) => (
                                                <TableRow key={data.spec_id}>
                                                    <TableCell component="th" scope="row">
                                                        {data.specialization}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton
                                                            id={data.spec_id}
                                                            special={data.specialization}
                                                            onClick={(e) => { setOpenModalEdit(true); setSelectedSpecialID(e.currentTarget.id); setSelectedSpecialName(e.currentTarget.getAttribute('special')) }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </DialogContent>
                                <DialogActions>
                                    <Box flexGrow={1} />
                                    <Button
                                        variant="contained"
                                        color="default"
                                        type="reset"
                                        startIcon={<ClearIcon />}
                                        onClick={() => { setOpenModal(false); setSelectedSpecialID(null); setSelectedSpecialName(null) }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog disableBackdropClick onClose={() => { setOpenModalEdit(false); setSelectedSpecialID(null); setSelectedSpecialName(null); }} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                                <DialogTitle id="customized-dialog-title" onClose={() => { setOpenModalEdit(false); setSelectedSpecialID(null); setSelectedSpecialName(null); }}>
                                    Edit Specialization
                                </DialogTitle>
                                <DialogContent dividers>
                                    <NewSpecializationEdit selectedSpecialName={selectedSpecialName} selectedSpecialID={selectedSpecialID} close={() => { setOpenModalEdit(false); setSelectedSpecialID(null); setSelectedSpecialName(null); }} getSpecializationList={() => getSpecializationList()} />
                                </DialogContent>
                            </Dialog>
                        </form>
                    )}
                </Formik>
            </Paper>
        </>
    )
}

export default NewSpecialization; 