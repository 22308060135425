import {
  Button,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  TableFooter,
  InputAdornment,
  TextField,
  TablePagination,
} from "@material-ui/core";
import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Container from "src/layout/Container";
import IsMountedRef from "src/utils/IsMountedRef";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Search from "@material-ui/icons/Search";
import Notify from "src/notification/Notify";

const PharmacyWarehouseInventory = () => {
  const mounted = IsMountedRef();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [inventory, setInventory] = useState({ data: [], ready: false });
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [details, setDetails] = useState({ data: [], ready: false });
  let totalInventory = 0;

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const getInventory = useCallback(async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/getinventory-list",
      {
        params: {
          management_id: management_id,
          token: token,
          user_id: user_id,
        },
      }
    );

    let result = response.data;
    if (mounted.current) {
      setInventory({ data: result, ready: true });
    }
  }, [management_id, token, user_id, mounted]);

  const getProductDetails = async (data) => {
    if (selectedProduct.id === data.id) {
      console.log("selected item duplicated");
      return;
    }
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/product-details",
      {
        params: {
          management_id: management_id,
          token: token,
          product_id: data.product_id,
        },
      }
    );

    setSelectedProduct(data);
    setDetails({ data: response.data, ready: true });
  };

  const calculateTotal = (price, qty) => {
    totalInventory += parseFloat(price) * parseInt(qty);

    return parseFloat(price) * parseInt(qty);
  };

  const handleSearch = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const filteredInventory = searchDrProduct(inventory, query);
  const paginatedInventory = applyPagination(filteredInventory, page, limit);

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Inventory",
        items: [{ name: "Dashboard", path: "/clinic/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Inventory</Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push("/clinic/app/warehouse/inventory/new-invoice")
              }
            >
              New Invoice
            </Button>
          </Box>
        </Box>
      }
    >
      <Paper component={Box} p={2}>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <TextField
              fullWidth
              label="Search product"
              variant="outlined"
              onChange={handleSearch}
              value={query}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell> Product </TableCell>
                <TableCell> Generic </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> Available Qty </TableCell>
                <TableCell> Srp </TableCell>
                <TableCell> Total </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {inventory.ready ? (
                paginatedInventory.length > 0 ? (
                  paginatedInventory.map((data, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              getProductDetails(data);
                            }}
                            color={
                              selectedProduct.id === data.id
                                ? "secondary"
                                : "primary"
                            }
                          >
                            {selectedProduct.id === data.id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell> {data.product_name} </TableCell>
                        <TableCell> {data.product_generic} </TableCell>
                        <TableCell> {data.unit} </TableCell>

                        <TableCell align="right">
                          {data._total_available_qty}
                        </TableCell>

                        <TableCell align="right"> {data.srp} </TableCell>

                        <TableCell align="right">
                          {Notify.convertToNumber(
                            calculateTotal(data.srp, data._total_available_qty)
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={10} style={{ border: 0 }}>
                          <Collapse
                            in={selectedProduct.id === data.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component={Box}
                                flexGrow={1}
                                color="textSecondary"
                              >
                                Batches List
                              </Typography>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell> Batch </TableCell>
                                    <TableCell> Invoice Number </TableCell>
                                    <TableCell> Mfg Date </TableCell>
                                    <TableCell> Exp. Date </TableCell>
                                    <TableCell> Type </TableCell>
                                    <TableCell> Qty </TableCell>
                                    <TableCell> Srp </TableCell>
                                    <TableCell> Amount </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {details.ready ? (
                                    details.data.length > 0 ? (
                                      details.data.map((xdata, xindex) => (
                                        <TableRow key={xindex}>
                                          <TableCell>
                                            {xdata.batch_number}
                                          </TableCell>
                                          <TableCell>
                                            {xdata.invoice_number}
                                          </TableCell>
                                          <TableCell>
                                            {xdata.manufactured_date}
                                          </TableCell>
                                          <TableCell>
                                            {xdata.expiration_date}
                                          </TableCell>
                                          <TableCell align="right">
                                            {xdata.type}
                                          </TableCell>
                                          <TableCell align="right">
                                            {xdata._total_available_qty}
                                          </TableCell>
                                          <TableCell align="right">
                                            {xdata.srp}
                                          </TableCell>
                                          <TableCell align="right">
                                            {Notify.convertToNumber(
                                              xdata.srp *
                                                xdata._total_available_qty
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={8}>
                                          <Typography color="primary">
                                            loading, please wait...
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={8}>
                                        <Typography color="primary">
                                          loading, please wait...
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="center"> Total </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(totalInventory)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>

          <TablePagination
            component={"div"}
            count={filteredInventory.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default PharmacyWarehouseInventory;

const searchDrProduct = (inventory, query) => {
  return inventory.data.filter((xdata) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      if (xdata.product_name.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (clinic, page, limit) => {
  return clinic.slice(page * limit, page * limit + limit);
};
