import React, { useEffect, useState } from "react";
import Notify from "../../../notification/Notify";
import Axios from "axios";
import {
  Button,
  Box,
  Typography,
  Paper,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Autocomplete } from "@material-ui/lab";
import NoInternetConnection from "src/NoInternetConnection";

const ImagingOrderVirtual = ({ patient_id, getCountImaging }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");

  const [vimaging, setVImaging] = useState({ data: [], ready: false });
  const [vorder, setVOrder] = useState({ data: [], ready: false });

  const [selectedImagingManagementId, setSelectedImagingManagementId] =
    useState(null);
  const [isProcess, setIsProcess] = useState(false);

  const [online, setOnline] = useState("checking");

  const [selectedOrder, setSelectedOrder] = useState([]);

  const getVirtualImagingList = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("management_id", management_id);
    formdata.set("connection", "online");

    Axios.post("doctor/imaging/virtual/imaging-virtuallist", formdata)
      .then((response) => {
        const data = response.data;
        setVImaging({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getVirtualImagingOrderList = (e) => {
    var vmanagementId = e.target.value;
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("vmanagementId", vmanagementId);
    formdata.set("connection", "online");

    Axios.post("doctor/imaging/virtual/imaging-orderlist", formdata)
      .then((response) => {
        const data = response.data;
        setVOrder({ data, ready: true });
        setSelectedImagingManagementId(vmanagementId);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleCreateOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("token", token);
    formdata.set("doctors_id", user_id);
    formdata.set("username", username);
    formdata.set("patient_id", patient_id);
    formdata.set("imaging_center", selectedImagingManagementId);
    formdata.set("connection", "online");

    var totalshot = 0;

    for (let i = 0; i < selectedOrder.length; i++) {
      totalshot += parseInt(selectedOrder[i].shots);
      formdata.append("order[]", selectedOrder[i].value);
    }

    formdata.append("totalshot", totalshot);

    if (selectedOrder.length === 0) {
      error = "error";
      Notify.fieldRequired("order");
    }

    if (
      formdata.get("remarks").length === 0 ||
      formdata.get("remarks").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("remarks");
    }

    if (
      formdata.get("imaging_center").length === 0 ||
      formdata.get("imaging_center").trim() === "null"
    ) {
      error = "error";
      Notify.fieldRequired("send to");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("imaging order");
    } else {
      setIsProcess(true);
      Axios.post("imaging/create-order", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-inv") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            getCountImaging();
            Notify.successRequest("imaging order");
          }
          if (data === "db-error") {
            Notify.warnRequest("imaging order");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcess(false));
    }
  };

  const checkinternet = () => {
    setOnline("checking");
    Axios.get("check/internet/connection-status", {
      params: {
        connection: "online",
      },
    })
      .then((response) => {
        const data = response.data;
        setOnline(data.message);
      })
      .catch((error) => {
        setOnline("disconnected");
        console.log("error request:", error.message);
      });
  };

  useEffect(() => {
    checkinternet();

    if (online === "connected") {
      getVirtualImagingList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      <Paper elevation={2} component={Box} p={2}>
        <Box>
          <Typography variant="h6" color="textSecondary">
            Create Virtual Order
          </Typography>
        </Box>

        {online === "checking" && Notify.loading()}
        {online === "disconnected" && (
          <NoInternetConnection xheight={"calc(50vh - 120px)"} />
        )}
        {online === "connected" && (
          <Box>
            <form onSubmit={handleCreateOrder}>
              <Box my={3}>
                <TextField
                  select
                  label="Imaging Center"
                  onChange={(e) => getVirtualImagingOrderList(e)}
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                >
                  <option value=""> Select </option>
                  {vimaging.ready ? (
                    vimaging.data.length > 0 ? (
                      vimaging.data.map((data, index) => (
                        <option key={index} value={data.management_id}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No imaging found
                      </option>
                    )
                  ) : (
                    <option value="" disabled>
                      please wait...
                    </option>
                  )}
                </TextField>
              </Box>

              <Box mb={3}>
                <Autocomplete
                  id="product-select-batch"
                  onChange={(e, data) => {
                    if (data !== null) {
                      setSelectedOrder(data);
                    }
                  }}
                  multiple
                  options={vorder.data}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Select Order"
                      variant="outlined"
                    />
                  )}
                />
              </Box>

              <Box mb={3}>
                <TextField
                  name="remarks"
                  label="Remarks"
                  variant="outlined"
                  fullWidth
                  rows={4}
                />
              </Box>

              <Box mb={3}>
                <TextField
                  name="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                />
              </Box>

              <Box display="flex">
                <Box mr={3}>
                  <Button
                    variant="contained"
                    startIcon={<HighlightOffIcon />}
                    color="default"
                  >
                    No
                  </Button>
                </Box>

                <Button
                  type="submit"
                  disabled={
                    vimaging.data.length > 0 ? (isProcess ? true : false) : true
                  }
                  variant="contained"
                  startIcon={
                    isProcess ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CheckCircleOutlineIcon />
                    )
                  }
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default ImagingOrderVirtual;
