 
 
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box"; 
import { Grid, Typography, useTheme } from "@material-ui/core"; 
import { Line } from 'react-chartjs-2';     
import { isMobile } from "react-device-detect"; 


const Cholesterol = ({ patient_id, connection }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [recordsLength, setRecordsLength] = useState(0)
    const [ready, setReady] = useState(false)

    const [cholesterol, setCholesterol] = useState([])
    const [label, setLabel] = useState([]) 
    const themes = useTheme()
    const chartRef = React.useRef() 
    

    const getCholesterol = () => { 
        var formData = new FormData();
            formData.set('user_id', user_id);
			formData.set('patient_id', patient_id);  
			formData.set('token', token); 
			formData.set('connection', connection); 

        Axios.post('doctor/patient/vitals/graph/get-cholesterol',  formData)
        .then((response) => {
            const data = response.data;
            setRecordsLength(data.length)
            pushDataToChart(data)
            setReady(true)
        })
        .catch(error => {
            Notify.requestError(error);
        })
    } 

    const pushDataToChart = (data) => {   
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {  
                setCholesterol((cholesterol) => cholesterol.concat(data[i].cholesterol === null ? 0 : data[i].cholesterol)) 
                setLabel((label) => label.concat(Notify.dateTimeConvert(data[i].created_at)))
            } 
        }else{
            setCholesterol((cholesterol) => cholesterol.concat(0)) 
            setLabel((label) => label.concat('No record'))
        }
         
    }  
    
    const data = {
        labels: label,
        datasets: [
            {
                label: 'Cholesterol', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                borderColor: themes.palette.primary.main,  
                pointBackgroundColor: themes.palette.primary.main,
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8,
                lineTension: 0,
                data: cholesterol
            }
        ]
    } 

    const options = {
        animationEnabled: true,  
        maintainAspectRatio: false,    
        legend:{
            position: 'bottom',
            cursor: 'pointer',   
            display: false, 
        }, 
        scales:{
            xAxes: [{
                ticks: {
                    display: ! isMobile  //this will remove only the label
                }
            }]
        },
        tooltips: {   
            enabled: true, 
            backgroundColor: themes.palette.primary.main
        },
        animation:{
            onComplete: () => {  
                const copyWidth = chartRef.current.chartInstance.scales['y-axis-0'].width - 10;
                const copyHeight = chartRef.current.chartInstance.scales['y-axis-0'].height + chartRef.current.chartInstance.scales['y-axis-0'].top + 10;
                const targetCtx = document.getElementById("myChartAxis").getContext("2d");
                      targetCtx.canvas.width = copyWidth;
                      targetCtx.drawImage(chartRef.current.chartInstance.canvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight); 
            } 
        }
    }  
    
    const initializeChartWidth = () =>{  
        chartRef.current.chartInstance.canvas.parentNode.style.width = '3500px';     
        chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(90vh - 120px)`;           
    }

    useEffect(() => {
        
        initializeChartWidth()
        getCholesterol()

        return(() =>{
            
            setReady(false)
            
        })

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>   
            <Box mb={2}>
                <Typography color="textSecondary" variant="h6">
                    Cholesterol Monitoring
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    { recordsLength } test recorded
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>  
                    <Box flexGrow={1}  maxWidth={'100%'} overflow={"auto"}> 
                        { ! ready && 
                            <Box align="center"> 
                                <Typography color="primary" variant="subtitle2" >
                                    fetching cholesterol records...
                                </Typography>
                            </Box>
                        }  
                        <div className="chartWrapper">  
                            <div 
                                style={{
                                    width:'100%', 
                                    overflowX: 'auto',
                                    overflowY: 'hidden'
                                }}
                            >   
                                    <Line    
                                        ref = { chartRef }
                                        data = { chartRef && data } 
                                        options={chartRef && options}  
                                    />  
                            </div> 
                            <canvas id="myChartAxis" height="100%" width="0"></canvas> 
                        </div>    
                    </Box>   
                </Grid>
            </Grid> 
        </Fragment>
    );
};

export default Cholesterol;