import React, { Fragment, useState } from "react";
import { Box, Button, FormHelperText, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Notify from "../../notification/Notify";
import Axios from "axios";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { Autocomplete } from "@material-ui/lab";
import { CheckCircle } from "react-feather";

function OverTheCounter({ brandListProps, fetchPuchaseList }) {
  const pharmacy_id = localStorage.getItem("pharmacy_id");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const [batchList, setBatchList] = useState([]);
  const [_product, setProduct] = useState("");
  const [_productid, setProductId] = useState("");
  const [_expiry, setExpiry] = useState("");
  const [_unit, setUnit] = useState("");
  const [_available, setAvailable] = useState("");
  const [_supplier, setSupplier] = useState("");
  const [_srp, setSrp] = useState("");
  const [_desc, setDesc] = useState("");

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("product", _product);
    formData.append("description", _desc);
    formData.append("product_id", _productid);
    formData.append("expiry", _expiry);
    formData.append("unit", _unit);
    formData.append("available", _available);
    formData.append("supplier", _supplier);
    formData.append("srp", _srp);

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getSelectedBatch = (selectedProdID, setFieldValue) => {
    setBatchList([]);
    setFieldValue("batch_no", "");
    setFieldValue("quantity", 0);

    var params = {
      user_id: user_id,
      management_id: management_id,
      pharmacy_id: pharmacy_id,
      token: token,
      product_id: selectedProdID,
    };
    Axios.get("pharmacy/get-batch-list", { params })
      .then((response) => {
        const data = response.data;
        setBatchList(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getBatchInfo = (selectedBatch, selectedProdID) => {
    var params = {
      user_id: user_id,
      management_id: management_id,
      pharmacy_id: pharmacy_id,
      token: token,
      product_id: selectedProdID,
      batch_no: selectedBatch,
    };
    Axios.get("pharmacy/get-batch-info", { params })
      .then((response) => {
        const data = response.data;
        setProduct(data[0].product);
        setDesc(data[0].generic);
        setProductId(data[0].product_id);
        setExpiry(data[0].expiry_date);
        setUnit(data[0].unit);
        setAvailable(data[0].quantity);
        setSupplier(data[0].supplier);
        setSrp(data[0].srp);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          token: token,
          username: username,
          user_id: user_id,
          management_id: management_id,
          pharmacy_id: pharmacy_id,
          brand: "",
          batch_no: "",
          quantity: 0,
        }}
        validationSchema={Yup.object().shape({
          brand: Yup.string().required(),
          batch_no: Yup.string().required("Batch number is a required field."),
          quantity: Yup.number().min(1).max(_available).required(),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
          if (parseInt(values.quantity) > parseInt(_available)) {
            setErrors({ quantity: "Quantity is invalid" });
          } else {
            try {
              const request = await Axios.post(
                "pharmacy/add-purchase",
                getFormData(values)
              );
              if (request.data === "product-exist") {
                setErrors({ brand: "Product already added" });
                Notify.customToast("Product Exist", "Product already added.");
              }
              if (request.data === "success") {
                Notify.successRequest("new purchase");
                fetchPuchaseList();
                resetForm();
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mb={3}>
              <Autocomplete
                id="product-select-id"
                onChange={(e, data) => {
                  if (data !== null) {
                    setFieldValue("brand", data.product);
                    getSelectedBatch(data.product_id, setFieldValue);
                  }
                }}
                options={brandListProps}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.product} - ${option.description}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="Brand"
                    name="brand"
                    variant="outlined"
                    error={Boolean(touched.brand && errors.brand)}
                    helperText={touched.brand && errors.brand}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brand}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Autocomplete
                id="product-select-batch"
                onChange={(e, data) => {
                  setFieldValue("batch_no", data === null ? "" : data.batch_no);
                  if (data !== null) {
                    getBatchInfo(data.batch_no, data.product_id);
                  }
                }}
                options={batchList}
                autoHighlight
                getOptionLabel={(option) => option.batch_no}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="Batch Number"
                    name="batch_no"
                    variant="outlined"
                    error={Boolean(touched.batch_no && errors.batch_no)}
                    helperText={touched.batch_no && errors.batch_no}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.batch_no}
                  />
                )}
              />
            </Box>
            {values.batch_no !== "" && (
              <Fragment>
                <Box mb={3}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    required
                    fullWidth
                    label="Expiry Date"
                    value={_expiry}
                    name="expiry"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    required
                    fullWidth
                    value={_unit}
                    name="unit"
                    label="Unit"
                    variant="outlined"
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    required
                    fullWidth
                    value={_available}
                    name="available"
                    label="Available"
                    variant="outlined"
                  />
                </Box>
              </Fragment>
            )}
            <Box mb={3}>
              <TextField
                required
                fullWidth
                error={Boolean(touched.quantity && errors.quantity)}
                helperText={touched.quantity && errors.quantity}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.quantity}
                name="quantity"
                label="Quantity"
                variant="outlined"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box display="flex">
              <Box flexGrow={1} />

              <Button
                size="large"
                variant="contained"
                color="default"
                onClick={resetForm}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>

              <Box ml={2}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckCircle />}
                  disabled={isSubmitting}
                >
                  Order
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default OverTheCounter;
