import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import Notify from "../../notification/Notify";
import {
  faCircleNotch,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import {
  Box,
  Divider,
  Grid,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Badge,
  Avatar,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Container from "src/layout/Container";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { ForPatientsNotif } from "src/ContextAPI";
import { isMobile } from "react-device-detect";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PersonalBasic = () => {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const [enableEdit, setEnableEdit] = useState(false);
  const [_is_process, set_is_process] = useState(false);
  const [patientInfoRdy, setPatientInfoRdy] = useState(false);
  const [patientInfo, setPatientInfo] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [patientBday, setPatientBday] = useState("");
  const [patientImage, setPatientImage] = useState("");

  const patientinfo = useContext(ForPatientsNotif);

  const fetchPatientInfo = useCallback(async () => {
    try {
      var params = {
        token: token,
        user_id: user_id,
      };
      const request = await Axios.get("patient/get-personal-info-by-id", {
        params,
      });
      setPatientInfo(request.data);
      setPatientId(request.data[0].patient_id);
      setPatientBday(request.data[0].birthday);
      setPatientImage(request.data[0].image);
      setPatientInfoRdy(true);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);

  const handleEditPersonalInfo = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("patient", patientId);

    if (
      formdata.get("firstname").length === 0 ||
      formdata.get("firstname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("firstname");
    }

    if (
      formdata.get("lastname").length === 0 ||
      formdata.get("lastname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("lastname");
    }
    if (
      formdata.get("gender").length === 0 ||
      formdata.get("gender").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("gender");
    }
    if (
      formdata.get("birthday").length === 0 ||
      formdata.get("birthday").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("birthday");
    }
    if (
      formdata.get("street").length === 0 ||
      formdata.get("street").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("street");
    }
    if (
      formdata.get("barangay").length === 0 ||
      formdata.get("barangay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("barangay");
    }
    if (
      formdata.get("city").length === 0 ||
      formdata.get("city").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("city");
    }
    if (
      formdata.get("civil_status").length === 0 ||
      formdata.get("civil_status").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("civil_status");
    }
    if (
      formdata.get("occupation").length === 0 ||
      formdata.get("occupation").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("occupation");
    }
    if (
      formdata.get("religion").length === 0 ||
      formdata.get("religion").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("religion");
    }
    if (
      formdata.get("height").length === 0 ||
      formdata.get("height").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("height");
    }

    if (
      formdata.get("weight").length === 0 ||
      formdata.get("weight").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("weight");
    }

    if (
      formdata.get("blood_type").length === 0 ||
      formdata.get("blood_type").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("blood_type");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("Form has an error, observe please");
    } else {
      set_is_process(true);
      Axios.post("patient/information/personal-update", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setEnableEdit(false);
            fetchPatientInfo();
            patientinfo.renderPatientInfo();
            Notify.successRequest("personal information");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "db-error") {
            Notify.warnRequest("personal information");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          set_is_process(false);
        });
    }
  };

  //new 123456789
  var profileimage = React.useRef();
  const [_uploadprocess, setUploadprocess] = useState(false);

  const handleClickupload = () => {
    profileimage.click();
  };

  const handleUploadImage = (event) => {
    var ext = event.target.files[0].type.split("/")[1];
    var maxfile = event.target.files[0].size / 1024 / 1024;
    var extArray = ["png", "jpeg", "jpg"];
    var resetUpload = event.target;
    if (extArray.indexOf(ext) === -1) {
      Notify.customToast("Image Not Allowed", "Upload PNG and JPEG only.");
      resetUpload.value = "";
    } else if (maxfile.toFixed(2) > 2) {
      Notify.customToast(
        "Image Not Allowed",
        "Image size too big, Upload 2mb or less."
      );
      resetUpload.value = "";
    } else {
      var formdata = new FormData();
      formdata.set("token", token);
      formdata.set("user_id", user_id);
      formdata.set("profile", event.target.files[0]);
      formdata.set(
        "current_profile",
        patientInfo.length > 0
          ? patientImage !== null
            ? patientImage
            : ""
          : ""
      );

      setUploadprocess(true);
      Axios.post("patient/information/personal-uploadnewprofile", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("profile");
            fetchPatientInfo();
            patientinfo.renderPatientInfo();
            resetUpload.value = "";
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setUploadprocess(false);
        });
    }
  };

  useEffect(() => {
    fetchPatientInfo();
  }, [fetchPatientInfo]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "account",
          items: [{ name: "Dashboard", path: "/clinic/app/patient" }],
        }}
        title="My Account"
      >
        {patientInfoRdy && (
          <Box
            component={Paper}
            variant="outlined"
            className={enableEdit ? "" : "gtc-textfield-noborder"}
            p={2}
          >
            <form onSubmit={handleEditPersonalInfo}>
              <Box display="flex" mb={2}>
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    hidden={enableEdit ? true : false}
                    onClick={() => setEnableEdit(true)}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    width={"100%"}
                    height={
                      isMobile
                        ? 200
                        : window.innerWidth <= 959
                        ? 400
                        : window.innerWidth <= 898
                        ? 300
                        : 194
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        _uploadprocess ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <CameraAltIcon
                            style={{
                              width: isMobile
                                ? 30
                                : window.innerWidth >= 899 &&
                                  window.innerWidth <= 959
                                ? 60
                                : window.innerWidth <= 898
                                ? 50
                                : 40,
                              height: isMobile
                                ? 30
                                : window.innerWidth >= 899 &&
                                  window.innerWidth <= 959
                                ? 60
                                : window.innerWidth <= 898
                                ? 50
                                : 40,
                              cursor: "pointer",
                            }}
                            onClick={handleClickupload}
                          />
                        )
                      }
                    >
                      <Avatar
                        style={{
                          width: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 280
                            : window.innerWidth <= 898
                            ? 220
                            : 160,
                          height: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 280
                            : window.innerWidth <= 898
                            ? 220
                            : 160,
                        }}
                        alt="profile-pic"
                        src={`${imageLocation}patients/${patientImage}`}
                      />
                      <input
                        type="file"
                        onChange={handleUploadImage}
                        className="d-none"
                        ref={(ref) => (profileimage = ref)}
                      />
                    </Badge>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Firstname"
                        name="firstname"
                        variant="outlined"
                        defaultValue={patientInfo[0].firstname}
                        InputProps={{ readOnly: !enableEdit }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Lastname"
                        name="lastname"
                        variant="outlined"
                        defaultValue={patientInfo[0].lastname}
                        InputProps={{ readOnly: !enableEdit }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Middle Name"
                        name="middle"
                        variant="outlined"
                        defaultValue={patientInfo[0].middle}
                        InputProps={{ readOnly: !enableEdit }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        select
                        required
                        fullWidth
                        label="Gender"
                        name="gender"
                        variant="outlined"
                        defaultValue={patientInfo[0].gender}
                        SelectProps={{ native: true }}
                        InputProps={{ inputProps: { disabled: !enableEdit } }}
                      >
                        <option value="Male"> Male</option>
                        <option value="Female"> Female</option>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Age"
                        name="age"
                        variant="outlined"
                        defaultValue={
                          patientInfo[0].birthday === null
                            ? "None"
                            : Notify.calculateAge(patientInfo[0].birthday)
                        }
                        InputProps={{ readOnly: !enableEdit }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Birthday"
                        type="date"
                        name="birthday"
                        variant="outlined"
                        defaultValue={patientBday}
                        onChange={(date) => setPatientBday(date)}
                        InputProps={{ readOnly: !enableEdit }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Birthplace"
                    name="birthplace"
                    variant="outlined"
                    defaultValue={patientInfo[0].birthplace}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    multiline
                    required
                    fullWidth
                    label="Street"
                    name="street"
                    variant="outlined"
                    defaultValue={patientInfo[0].street}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Barangay"
                    name="barangay"
                    variant="outlined"
                    defaultValue={patientInfo[0].barangay}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="City"
                    name="city"
                    variant="outlined"
                    defaultValue={patientInfo[0].city}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Civil Status"
                    name="civil_status"
                    variant="outlined"
                    defaultValue={patientInfo[0].civil_status}
                    select
                    InputProps={{ inputProps: { disabled: !enableEdit } }}
                    SelectProps={{ native: true }}
                  >
                    <option value="Single"> Single </option>
                    <option value="Married"> Married </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Occupation"
                    name="occupation"
                    variant="outlined"
                    defaultValue={patientInfo[0].occupation}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Religion"
                    name="religion"
                    variant="outlined"
                    defaultValue={patientInfo[0].religion}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Height (ft)"
                    name="height"
                    variant="outlined"
                    defaultValue={patientInfo[0].height}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 13,
                        step: "0.01",
                      },
                      readOnly: !enableEdit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Weight (kg)"
                    name="weight"
                    variant="outlined"
                    defaultValue={patientInfo[0].weight}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 200,
                        step: "0.01",
                      },
                      readOnly: !enableEdit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Blood Type"
                    name="blood_type"
                    variant="outlined"
                    select
                    defaultValue={patientInfo[0].blood_type}
                    SelectProps={{ native: true }}
                    InputProps={{ inputProps: { disabled: !enableEdit } }}
                  >
                    <option value="A"> A </option>
                    <option value="B"> B </option>
                    <option value="AB"> AB </option>
                    <option value="O"> O </option>
                    <option value="A-negative"> A-Negative </option>
                    <option value="B-positive"> B-Positive </option>
                    <option value="B-negative"> B-Negative </option>
                    <option value="O-positive"> O-Positive </option>
                    <option value="O-negative"> O-Negative </option>
                    <option value="AB-positive">AB-Positive </option>
                    <option value="AB-negative">AB-Negative </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    variant="outlined"
                    defaultValue={patientInfo[0].email}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    variant="outlined"
                    defaultValue={patientInfo[0].mobile}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Telephone"
                    name="telephone"
                    variant="outlined"
                    defaultValue={patientInfo[0].telephone}
                    InputProps={{ readOnly: !enableEdit }}
                  />
                </Grid>
                {enableEdit ? (
                  <Fragment>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Password"
                        name="password"
                        variant="outlined"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Box mb={1}>
                        <Divider />
                      </Box>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Button
                          variant="contained"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                          color="default"
                        >
                          No
                        </Button>
                        <Box ml={2}>
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={_is_process}
                            startIcon={
                              <FontAwesomeIcon
                                icon={
                                  _is_process ? faCircleNotch : faCheckCircle
                                }
                                spin={_is_process}
                              />
                            }
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Fragment>
                ) : null}
              </Grid>
            </form>
          </Box>
        )}
      </Container>
    </Fragment>
  );
};

export default PersonalBasic;
