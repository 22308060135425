import {
  Box,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";

const WarehouseManagement = ({ details }) => {
  const token = localStorage.getItem("token");
  const [dialog, setDialog] = useState(false);
  const [pharmacyList, setPharmacyList] = useState([]);

  const getPharmacyByManagement = async () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);
    formdata.set("pharmacy_category", "warehouse");

    let response = await Axios.post(
      "gtcadmin/management/pharmacy-list",
      formdata
    );
    let result = response.data;

    setPharmacyList(result);
  };

  const handleNewPharmacy = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);
    formdata.set("accountType", "Pharmacy-Warehouse");
    formdata.set("category", "warehouse");

    let response = await Axios.post(
      "gtcadmin/management/pharmacy-add",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      setDialog(false);
      Notify.successRequest("new pharmacy");
      getPharmacyByManagement();
    }
  };

  const handleUpdatePharmacy = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);

    let response = await Axios.post(
      "gtcadmin/management/pharmacy-update",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      setDialog(false);
      Notify.successRequest("update");
      getPharmacyByManagement();
    }
  };

  useEffect(() => {
    getPharmacyByManagement();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Paper component={Box} p={2}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              PharmacyWarehouse Information
            </Typography>
          </Box>
          <Box>
            <Button color="primary" onClick={() => setDialog(true)}>
              New PharmacyWarehouse
            </Button>
          </Box>
        </Box>
        <Box mb={2}>
          {pharmacyList.length > 0 ? (
            <Typography variant="subtitle1" color="primary">
              Fields are ready for update.
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="secondary">
              Phamacy Warehouse is not set.
            </Typography>
          )}
        </Box>

        <Box>
          <form onSubmit={handleUpdatePharmacy}>
            {pharmacyList.length > 0 && (
              <>
                <Box mb={2}>
                  <TextField
                    label="Id"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      pharmacyList.length > 0 ? pharmacyList[0].user_id : ""
                    }
                    name="pharmacy_userid"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      pharmacyList.length > 0 ? pharmacyList[0].name : ""
                    }
                    name="name"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="contact"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      pharmacyList.length > 0 ? pharmacyList[0].contact : ""
                    }
                    name="contact"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="TIN"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      pharmacyList.length > 0 ? pharmacyList[0].tin_number : ""
                    }
                    name="tin"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      pharmacyList.length > 0 ? pharmacyList[0].address : ""
                    }
                    name="address"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="PharmacyWarehouse Username"
                    variant="outlined"
                    fullWidth
                    name="username"
                  />
                </Box>

                <Box>
                  <TextField
                    label="PharmacyWarehouse Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                  />
                </Box>

                <Box display="flex" mt={2}>
                  <Box>
                    <Button variant="contained" color="primary" type="submit">
                      Update
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </form>
        </Box>
      </Paper>

      {/* new pharmacy dialog */}
      <NewPharmacyInManagement
        open={dialog}
        close={() => setDialog(false)}
        handleNewPharmacy={handleNewPharmacy}
      />
    </Box>
  );
};

export default WarehouseManagement;

const NewPharmacyInManagement = ({ open, close, handleNewPharmacy }) => {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle> New PharmacyWarehouse </DialogTitle>
      <form onSubmit={handleNewPharmacy}>
        <DialogContent dividers>
          <Box mb={2}>
            <TextField label="Name" variant="outlined" fullWidth name="name" />
          </Box>

          <Box mb={2}>
            <TextField
              label="contact"
              variant="outlined"
              fullWidth
              name="contact"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="TIN"
              variant="outlined"
              fullWidth
              name="tin"
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="address"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              name="username"
            />
          </Box>

          <Box>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              name="password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={close}>
            No
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
