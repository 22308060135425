import Axios from "axios";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { PushNotification } from "src/ContextAPI";
import firebase from "src/firebase";

const fixedToastId = 555;

const WebPushNotification = ({ localNotif, virtualNotif }) => {
  const messaging = firebase.messaging();
  const [isWebPushReady, setIsWebPushReady] = useState(false);
  const pushNotification = useContext(PushNotification);

  const enableNotification = () => {
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        console.log("token token token token token token token :", token);
        pushNotification.update(token, true);
        setIsWebPushReady(true);
      })
      .catch((error) => {
        console.log("push notification error:", error);
        pushNotification.update(null, false);
      });
  };

  const generateNotification = () => {
    if (localNotif.length > 0) {
      SendNotificationToClient("You have new local notification");
    }

    if (virtualNotif.length > 0) {
      SendNotificationToClient("You have new virtual notification");
    }

    const unreadCount =
      parseInt(localNotif.length) + parseInt(virtualNotif.length);

    if (navigator.setAppBadge) {
      navigator.setAppBadge([unreadCount]).catch((error) => {
        console.log("unable to set badge in desktop icon:", error);
      });
    }
  };

  const SendNotificationToClient = (message) => {
    setTimeout(() => {
      if (
        !Boolean(pushNotification.enable) &&
        !Boolean(pushNotification.token)
      ) {
        console.log("unable to send notification");
        return false;
      }
      Axios.post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            title: `GTC DOC | Notification`,
            body: message,
            data: window.location.href,
          },
          to: pushNotification.token,
        },
        {
          headers: {
            Authorization: `key=${process.env.REACT_APP_PUSH_NOTIFICATION_KEY}`,
          },
        }
      )
        .then(() => {
          // console.log(response)
        })
        .catch((error) => console.log("error", error));
    }, 3000);
  };

  messaging.onMessage(() => {
    toast("You have new notification. ", { toastId: fixedToastId });
  });

  React.useEffect(() => {
    enableNotification();

    if (isWebPushReady) {
      generateNotification();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebPushReady]);

  return null;
};

export default WebPushNotification;
