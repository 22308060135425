import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Container from "src/layout/Container";
import Profile from "./Profile";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import Password from "./Password";
import Username from "./Username";

const EncoderAccount = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [details, setDetails] = useState([]);
  const [detailsRd, setDetailsRd] = useState(false);
  const mounted = IsMountedRef();

  const getProfileInformation = React.useCallback(async () => {
    axios
      .get("encoder/personal/information", {
        params: {
          user_id,
          token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setDetails(data);
        }
      })
      .catch((error) => console.log(error.message || "request has error"))
      .finally(() => setDetailsRd(true));
  }, [user_id, token, mounted]);

  useEffect(() => {
    getProfileInformation();
  }, [getProfileInformation]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "account",
        items: [{ name: "Dashboard", path: "/clinic/app/encoder" }],
      }}
      title={"Account"}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Profile Information" />
            <CardContent>
              <Profile
                details={details}
                detailsRd={detailsRd}
                getProfileInformation={() => getProfileInformation()}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardHeader title="Username" />
            <CardContent>
              <Username
                details={details}
                detailsRd={detailsRd}
                getProfileInformation={() => getProfileInformation()}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardHeader title="Password" />
            <CardContent>
              <Password
                details={details}
                detailsRd={detailsRd}
                getProfileInformation={() => getProfileInformation()}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EncoderAccount;
