
import { Box, TableBody, Table, TableHead, TableCell, TableContainer, TextField, TableRow, Button, Typography, Paper, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import Axios from 'axios';
import React, { useState } from 'react'
import Notify from 'src/notification/Notify'; 

const ImagingTestList = ({ list, getImagingTest }) => { 
    const token  = localStorage.getItem('token')
    const user_id  = localStorage.getItem('user_id')
    const management_id  = localStorage.getItem('management_id')
    const username  = localStorage.getItem('username')

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [edit, setEdit] = useState({
        data: null,
        open: false
    })

    const handleEditTest = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('username', username)
        formdata.append('management_id', management_id)

        var error = [];

        if (formdata.get('rate').trim() === '' || formdata.get('rate').length === 0) {
            error = 'error'
            Notify.fieldRequired('rate')
        }

        if (formdata.get('password').trim() === '' || formdata.get('password').length === 0) {
            error = 'error'
            Notify.fieldRequired('password')
        }

        if (error.length > 0) {
            console.log('form has an error')
        } else {
            setIsSubmitting(true)
            Axios.post('imaging/test/edit-test', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        Notify.successRequest('new doctor')
                        getImagingTest()
                        setEdit({ data: null, open: true })
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => setIsSubmitting(false))
        }
    }

    return (
        <Box component={Paper} variant="outlined" p={2}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"> <strong> Order Desc </strong> </TableCell>
                            <TableCell align="center"> <strong> Rate </strong> </TableCell>
                            <TableCell align="center"> <strong> Shots </strong> </TableCell>
                            <TableCell align="center"> <strong> Action </strong> </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            list.ready ?
                                list.data.length > 0 ?
                                    list.data.map((data, index) => (
                                        <TableRow
                                            key={index}
                                            hover
                                        >
                                            <TableCell> {data.order_desc} </TableCell>
                                            <TableCell align="right"> {data.order_cost} </TableCell>
                                            <TableCell align="right"> {data.order_shots} </TableCell>
                                            <TableCell align="center">
                                                <Tooltip arrow title="Edit Laboratory Test">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            setEdit({
                                                                data: data,
                                                                open: true
                                                            })
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            <Typography variant="subtitle2" color="secondary"> No imaging order added. </Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <Typography variant="subtitle2" color="primary"> Please wait... </Typography>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {/* edit dialog */}
            { edit.data && edit.open && (
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={edit.open}
                    onClose={() => setEdit({ data: null, open: true })}
                >
                    <DialogTitle> Edit Imaging Test </DialogTitle>
                    <form onSubmit={handleEditTest}>
                        <DialogContent dividers>
                            <Box my={2}>
                                <TextField
                                    fullWidth
                                    required
                                    name="id"
                                    label="Order Id"
                                    variant="outlined"
                                    hidden
                                    defaultValue={edit.data.order_id}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    name="order_desc"
                                    label="Order Description"
                                    variant="outlined"
                                    disabled
                                    defaultValue={edit.data.order_desc}
                                />
                            </Box>

                            <Box my={2}>
                                <TextField
                                    fullWidth
                                    required
                                    name="order_shot"
                                    label="Number of shots"
                                    variant="outlined"
                                    disabled
                                    defaultValue={edit.data.order_shots}
                                />
                            </Box>

                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    required
                                    name="rate"
                                    label="Order Rate"
                                    variant="outlined"
                                    type="number"
                                    defaultValue={edit.data.order_cost}
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: 100000,
                                            step: 0.01
                                        }
                                    }}
                                />
                            </Box>

                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    required
                                    name="password"
                                    label="Enter your password"
                                    variant="outlined"
                                    type="password"
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => setEdit({ data: null, open: true })}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                                startIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        </Box>
    )
}

export default ImagingTestList;