import React, { useState } from "react";
import {
  //   Box,
  Divider,
  Grid,
  //   Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //   Paper,
  ListSubheader,
  Card,
  CardContent,
} from "@material-ui/core";
import LaboratoryReport from "./laboratory_report";
// import { makeStyles } from "@material-ui/core/styles";
// import ApartmentIcon from "@material-ui/icons/Apartment";
// import WifiIcon from "@material-ui/icons/Wifi";
// import WifiOffIcon from "@material-ui/icons/WifiOff";
// import LocalPrescriptionReport from "./prescription_report/LocalPrescriptionReport-old";
// import VirtualPrescriptionReport from "./prescription_report/VirtualPrescriptionReport-old";
// import ListAltIcon from "@material-ui/icons/ListAlt";
import Container from "src/layout/Container";
import {
  // ChevronDown, ChevronRight,
  Package,
} from "react-feather";
import ServicesReport from "./services_report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrescriptionBottleAlt,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import ClinicPrescriptionReport from "./prescription_report";

// const useStyles = makeStyles((theme) => ({
//   nested: {
//     paddingLeft: theme.spacing(4),
//   },
// }));

const Report = () => {
  //   const classes = useStyles();
  //   const [open, setOpen] = useState(false);
  const [displayDefault, setDisplayDefault] = useState("laboratory");

  return (
    <>
      <Container
        title="Income Report"
        breadcrumbs={{
          enable: true,
          current: "Report",
          items: [{ name: "Dashboard", path: "/clinic/app/doctor" }],
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <Card>
              <CardContent>
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Menu
                    </ListSubheader>
                  }
                >
                  <Divider />

                  <ListItem
                    button
                    onClick={() => {
                      //   setOpen(false);
                      setDisplayDefault("laboratory");
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faVial} style={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText primary={"Laboratory"} />
                  </ListItem>

                  <Divider />

                  <ListItem
                    button
                    onClick={() => setDisplayDefault("prescription")}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={faPrescriptionBottleAlt}
                        style={{ fontSize: 20 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Prescription"} />
                  </ListItem>
                  {/* <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => setDisplayDefault("prescription")}
                      >
                        <ListItemIcon>
                          <WifiOffIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Local"} />
                      </ListItem>

                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          setDisplayDefault("prescription-virtual")
                        }
                      >
                        <ListItemIcon>
                          <WifiIcon />
                        </ListItemIcon>
                        <ListItemText variant="primary" primary={"Virtual"} />
                      </ListItem>
                    </List>
                  </Collapse> */}

                  <Divider />

                  <ListItem
                    button
                    onClick={() => {
                      //   setOpen(false);
                      setDisplayDefault("services");
                    }}
                  >
                    <ListItemIcon>
                      <Package />
                    </ListItemIcon>
                    <ListItemText primary={"Services"} />
                  </ListItem>
                  <Divider />
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            {displayDefault === "laboratory" && <LaboratoryReport />}
            {displayDefault === "prescription" && <ClinicPrescriptionReport />}

            {/* {displayDefault === "prescription-local" && (
              <LocalPrescriptionReport />
            )}
            {displayDefault === "prescription-virtual" && (
              <VirtualPrescriptionReport />
            )} */}
            {displayDefault === "services" && <ServicesReport />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Report;
