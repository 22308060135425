import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TablePagination,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import Notify from "src/notification/Notify";

const Report = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [completed, setCompleted] = useState({
    data: [],
    ready: false,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getCompleteAppointment = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/appointment/local/completed-list", formdata)
      .then((response) => {
        const data = response.data;
        setCompleted({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getCompleteAppointment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader title="Appointment List" />
      <CardContent>
        <TableContainer>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Patient </TableCell>
                  <TableCell> Appointment Date </TableCell>
                  <TableCell> Complete On </TableCell>
                  <TableCell> Service </TableCell>
                  <TableCell> Fee </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {completed.ready ? (
                  completed.data.length > 0 ? (
                    completed.data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{`${data.fname} ${data.lname}`}</TableCell>
                          <TableCell>
                            {Notify.dateTimeConvert(data.app_date)}
                          </TableCell>
                          <TableCell>
                            {Notify.dateTimeConvert(data.app_date_end)}
                          </TableCell>
                          <TableCell> {data.services} </TableCell>
                          <TableCell align="right"> {data.amount} </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color="secondary">
                          No complete appointment found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="primary">please wait...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>

          <TablePagination
            component="div"
            count={completed.data.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default Report;
