import {
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { CheckCircle, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import axios from "axios";

const EditOrder = ({ details, getAllAdmittingOrders, close }) => {
  const token = localStorage.getItem("token");
  const [isProcess, setIsProcess] = useState(false);

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();

    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("update_id", details.pao_id);

    var error = [];
    if (
      formdata.get("diagnosis").length === 0 ||
      formdata.get("diagnosis").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("diagnosis");
    }
    if (error.length > 0) {
      Notify.consoleLog("Need to fill all required fields");
    } else {
      setIsProcess(true);
      axios
        .post("/doctor/patient/admitting/order-edit", formdata)
        .then((response) => {
          const data = response.data;
          if (data.message === "success") {
            getAllAdmittingOrders();
            Notify.successRequest("create admitting order");
            e.target.reset();
            close();
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcess(false));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="Diagnosis"
                  variant="outlined"
                  fullWidth
                  multiline
                  name={"diagnosis"}
                  defaultValue={
                    details.diagnosis === null ? "" : details.diagnosis
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="Diet"
                  variant="outlined"
                  fullWidth
                  name={"diet"}
                  defaultValue={details.diet === null ? "" : details.diet}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="Venoclysis"
                  variant="outlined"
                  fullWidth
                  name={"venoclysis"}
                  defaultValue={
                    details.venoclysis === null ? "" : details.venoclysis
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label="Vital Sign Mon. Every"
                      variant="outlined"
                      fullWidth
                      name={"vital_sign_mon"}
                      defaultValue={
                        details.vital_sign_mon === null
                          ? ""
                          : details.vital_sign_mon
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={`I&O Every`}
                      variant="outlined"
                      fullWidth
                      name={"i_and_o"}
                      defaultValue={
                        details.i_and_o === null ? "" : details.i_and_o
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="HGT Monitoring"
                  variant="outlined"
                  fullWidth
                  name={"hgt_monitoring"}
                  defaultValue={
                    details.hgt_monitoring === null
                      ? ""
                      : details.hgt_monitoring
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="Diagnostics"
                  variant="outlined"
                  fullWidth
                  multiline
                  name={"diagnostics"}
                  defaultValue={
                    details.diagnostics === null ? "" : details.diagnostics
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="Therapeutics"
                  variant="outlined"
                  fullWidth
                  multiline
                  name={"therapeutics"}
                  defaultValue={
                    details.therapeutics === null ? "" : details.therapeutics
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <TextField
                  label="Note"
                  variant="outlined"
                  fullWidth
                  name={"note"}
                  defaultValue={details.note === null ? "" : details.note}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={close}
          >
            No
          </Button>

          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isProcess}
            startIcon={
              isProcess ? (
                <CircularProgress size={23} color="inherit" />
              ) : (
                <CheckCircle />
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default EditOrder;
