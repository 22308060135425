import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  TextField,
  CardHeader,
  Avatar,
  useTheme,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import Container from "src/layout/Container";
import { ArrowRight, Calendar } from "react-feather";
import Label from "src/utils/Label";
import { useHistory, useParams } from "react-router";
import IsMountedRef from "src/utils/IsMountedRef";

const AppointmentListByStatus = () => {
  const ismounted = IsMountedRef();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [appointment, setAppointment] = useState([]);
  const [appointmentReady, setAppointmentReady] = useState(false);

  const { status } = useParams();
  const newstatus = Boolean(status)
    ? status !== "new" && status !== "completed" && status !== "approved"
      ? "all"
      : status
    : "all";
  const [category, setCategory] = useState(newstatus);

  const getAppointmentListByStatusLocal = async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("user_id", user_id);
      formdata.append("status", category);

      const response = await axios.post(
        "doctor/appointment/local/list-withstatus",
        formdata
      );
      if (ismounted.current) {
        const data = response.data;
        generateData(data);
        setAppointmentReady(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getAppointmentListByStatusVirtual = async() => {
  //     try {
  //         var formdata = new FormData()
  //         formdata.append('token', token)
  //         formdata.append('user_id', user_id)
  //         formdata.append('status', category)

  //         const response = await axios.post('doctor/appointment/virtual/list-withstatus', formdata)
  //         const data = response.data;
  //         if(ismounted.current){
  //             generateData(data)
  //             setTimeout(() => { setAppointmentReady(true) }, 3000);
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  const generateData = (data) => {
    setAppointment((appointment) => appointment.concat(data));
  };

  React.useEffect(() => {
    getAppointmentListByStatusLocal();
    // getAppointmentListByStatusVirtual()

    return () => {
      setAppointment([]);
      setAppointmentReady(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ismounted, category]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "status",
        items: [
          { name: "Dashboard", path: "/clinic/app/doctor" },
          {
            name: "APpointment",
            path: "/clinic/app/doctor/appointment/calendar",
          },
        ],
      }}
      title={"Appointment List"}
    >
      <RenderAppointment
        appointment={appointment}
        appointmentReady={appointmentReady}
        category={category}
        setCategory={setCategory}
      />
    </Container>
  );
};

export default AppointmentListByStatus;

const RenderAppointment = ({
  appointmentReady,
  appointment,
  category,
  setCategory,
}) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <Box>
          <TextField
            select
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            name="gender"
            label="Status"
            variant="outlined"
            margin="dense"
            value={category}
            color="primary"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="all"> All </option>
            <option value="new"> New </option>
            <option value="approved"> Approved </option>
            <option value="completed"> Completed </option>
          </TextField>
        </Box>
      </Box>

      {appointmentReady ? (
        appointment.length > 0 ? (
          <Grid container spacing={2}>
            {appointment.map((data, index) => (
              <Grid key={index} item xs={12} sm>
                <Card
                  component={Box}
                  borderLeft={5}
                  borderColor={
                    Boolean(data.reference_no)
                      ? data.appointment_status === "successful"
                        ? theme.palette.success.light
                        : !data.appointment_status === "new" &&
                          !Boolean(parseInt(data.is_paid_bysecretary))
                        ? theme.palette.warning.light
                        : theme.palette.primary.light
                      : Boolean(parseInt(data.is_complete))
                      ? theme.palette.success.light
                      : !Boolean(parseInt(data.is_complete)) &&
                        !Boolean(parseInt(data.is_paid_bysecretary))
                      ? theme.palette.warning.light
                      : theme.palette.primary.light
                  }
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: Boolean(data.reference_no)
                            ? data.appointment_status === "successful"
                              ? theme.palette.success.light
                              : !data.appointment_status === "new" &&
                                !Boolean(parseInt(data.is_paid_bysecretary))
                              ? theme.palette.warning.light
                              : theme.palette.primary.light
                            : Boolean(parseInt(data.is_complete))
                            ? theme.palette.success.light
                            : !Boolean(parseInt(data.is_complete)) &&
                              !Boolean(parseInt(data.is_paid_bysecretary))
                            ? theme.palette.warning.light
                            : theme.palette.primary.light,
                        }}
                      >
                        <Calendar color="white" />
                      </Avatar>
                    }
                    title={data.services}
                    titleTypographyProps={{ className: "gtc-uppercase" }}
                    subheader={
                      Boolean(data.reference_no)
                        ? Boolean(parseInt(data.is_reschedule))
                          ? Notify.dateTimeConvert(data.is_reschedule_date)
                          : Notify.dateTimeConvert(data.appointment_date)
                        : Boolean(parseInt(data.is_reschedule))
                        ? Notify.dateTimeConvert(data.is_reschedule_date)
                        : Notify.dateTimeConvert(data.app_date)
                    }
                  />
                  <CardContent>
                    <Box mb={1}>
                      <Typography variant="h6">
                        {`${data.lastname}, ${data.firstname}`}
                      </Typography>
                    </Box>

                    <Box mb={1}>
                      From:{" "}
                      <Label
                        color={Boolean(data.reference_no) ? "success" : "error"}
                      >
                        {" "}
                        {Boolean(data.reference_no) ? "Virtual" : "Local"}{" "}
                      </Label>
                    </Box>

                    <Box mb={1}>
                      Status:{" "}
                      <Label
                        color={
                          Boolean(data.reference_no)
                            ? data.appointment_status === "successful"
                              ? "success"
                              : data.appointment_status === "new" &&
                                !Boolean(parseInt(data.is_paid_bysecretary))
                              ? "warning"
                              : "primary"
                            : Boolean(parseInt(data.is_complete))
                            ? "success"
                            : !Boolean(parseInt(data.is_complete)) &&
                              !Boolean(parseInt(data.is_paid_bysecretary))
                            ? "warning"
                            : "primary"
                        }
                      >
                        {Boolean(parseInt(data.is_complete))
                          ? "completed"
                          : !Boolean(parseInt(data.is_complete)) &&
                            !Boolean(parseInt(data.is_paid_bysecretary))
                          ? "new"
                          : "approved"}
                      </Label>
                    </Box>

                    <Box mb={1}>
                      Is Reschedule:{" "}
                      <Label
                        color={
                          Boolean(parseInt(data.is_reschedule))
                            ? "primary"
                            : "success"
                        }
                      >
                        {Boolean(parseInt(data.is_reschedule)) ? "Yes" : "No"}
                      </Label>
                    </Box>

                    <Box mb={1}>
                      Reason:
                      <Typography variant="body1">
                        {Boolean(data.reference_no)
                          ? Boolean(parseInt(data.is_reschedule))
                            ? data.is_reschedule_reason === null
                              ? "None"
                              : data.is_reschedule_reason
                            : data.appointment_reason === null
                            ? "None"
                            : data.appointment_reason
                          : Boolean(parseInt(data.is_reschedule))
                          ? data.is_reschedule_reason === null
                            ? "None"
                            : data.is_reschedule_reason
                          : data.app_reason === null
                          ? "None"
                          : data.app_reason}
                      </Typography>
                    </Box>

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        color="primary"
                        endIcon={<ArrowRight size={20} />}
                        onClick={() =>
                          history.push(
                            `/clinic/app/doctor/appointment/calendar/details/${
                              Boolean(data.reference_no) ? "virtual" : "local"
                            }/${data.appointment_id}`
                          )
                        }
                      >
                        details
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoRecordFound category={category} />
        )
      ) : (
        Notify.loading()
      )}
    </>
  );
};

const NoRecordFound = ({ category }) => (
  <Paper>
    <Box p={2}>
      <Box m1={2}>
        <Typography variant="h3" color="secondary">
          No Record
        </Typography>
      </Box>

      <Box>
        <Typography color="secondary">
          No {category} appointment found.
        </Typography>
      </Box>
    </Box>
  </Paper>
);
