import { faCircle, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Typography,
  useTheme,
  List,
  ListItemText,
  ListItem,
  Grow,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";
import Message from "./Message";

const Chat = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");

  const theme = useTheme();
  const [openChat, setOpenChat] = useState(false);
  const mounted = IsMountedRef();
  const [list, setList] = useState([]);
  const [listRd, setListRd] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [rerender, setRerender] = useState(new Date().getTime());

  const [senderDetails, setSenderDetails] = useState({
    open: false,
    data: null,
  });

  let interval = null;

  const getManagementUsers = React.useCallback(async () => {
    Axios.get("chat/management/get-management-user", {
      params: {
        token,
        user_id,
        management_id,
      },
    })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setList(data);
        }
      })
      .catch((error) => console.log(error.message))
      .finally(() => setListRd(true));
  }, [token, user_id, management_id, mounted]);

  const getUnreadMessageBySenderId = () => {
    Axios.get("chat/management/conversation/message/get-unreadmessage", {
      params: {
        token,
        user_id,
        management_id,
      },
    })
      .then((response) => {
        const data = response.data;
        countTotalUnreadMsg(data);
      })
      .catch((error) => {
        console.log("request has an error:", error.message);
      });
  };

  const countTotalUnreadMsg = (data) => {
    if (data.length > 0) {
      setRerender(new Date().getTime());
      getManagementUsers();
      let xx = data.reduce((a, b) => {
        return parseInt(a) + parseInt(b._unread_msg_count);
      }, 0);

      setUnreadMessageCount(xx);
    } else {
      setUnreadMessageCount(0);
    }
  };

  const initializeInterval = () => {
    interval = setInterval(() => {
      getUnreadMessageBySenderId();
    }, 10000);
  };

  useEffect(() => {
    initializeInterval();
    getUnreadMessageBySenderId();
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getManagementUsers();
  }, [getManagementUsers]);

  return (
    <>
      <Grow
        in={openChat}
        timeout={700}
        style={{ transformOrigin: "right bottom" }}
      >
        <Box
          position="fixed"
          zIndex={9999}
          bottom={160}
          right={20}
          borderRadius={10}
          bgcolor={"background.paper"}
          // border={1}
          // borderColor={theme.palette.primary.main}
          boxShadow={10}
          overflow="hidden"
        >
          {senderDetails.open ? (
            <Message
              senderDetails={senderDetails}
              setSenderDetails={setSenderDetails}
              setRerender={setRerender}
              rerender={rerender}
            />
          ) : (
            <Box>
              <Box
                bgcolor={theme.palette.primary.main}
                color="white"
                p={3}
                display="flex"
              >
                <Typography> Chat </Typography>
              </Box>

              <Box
                py={2}
                width={350}
                height={"calc(70vh - 110px)"}
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <RenderUsersList
                  listRd={listRd}
                  list={list}
                  setSenderDetails={setSenderDetails}
                  theme={theme}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Grow>

      <Box
        position="fixed"
        zIndex={1}
        bottom={80}
        right={20}
        height={60}
        width={60}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={"50%"}
        bgcolor={theme.palette.primary.main}
        color={theme.palette.common.white}
        py={3}
        boxShadow={3}
      >
        <Box
          onClick={() => {
            setOpenChat(!openChat);
            if (!openChat) {
              setSenderDetails({ data: null, open: false });
            }
          }}
          className="pointer"
        >
          <Badge badgeContent={unreadMessageCount} color="secondary">
            {!openChat ? (
              <FontAwesomeIcon
                icon={faCommentAlt}
                flip="horizontal"
                style={{ fontSize: 28 }}
              />
            ) : (
              <ChevronDown size={35} />
            )}
          </Badge>
        </Box>
      </Box>
    </>
  );
};

export default Chat;

const RenderUsersList = ({ listRd, list, setSenderDetails, theme }) => {
  return (
    <Box>
      {listRd ? (
        list.length ? (
          <List component="div">
            {list.map((data, key) => (
              <ListItem
                button
                key={key}
                onClick={() => setSenderDetails({ data: data, open: true })}
              >
                <ListItemText
                  primary={
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle2">
                          {data._account_name}
                        </Typography>
                      </Box>
                      <Box>
                        {Boolean(parseInt(data._unread_message_count)) && (
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              fontSize: 12,
                              color: theme.palette.secondary.dark,
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  }
                  primaryTypographyProps={{ className: "gtc-uppercase" }}
                  secondary={
                    data._last_message === null
                      ? "No message"
                      : data._last_message
                  }
                  secondaryTypographyProps={{
                    style: {
                      marginLeft: 5,
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography color="secondary" align="center">
            No record found.
          </Typography>
        )
      ) : (
        <Typography color="primary" align="center">
          Loading, please wait...
        </Typography>
      )}
    </Box>
  );
};
