import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import Notify from "../../notification/Notify";
import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  Grid,
} from "@material-ui/core";
import IsMountedRef from "src/utils/IsMountedRef";
import AppointmentAction from "../appointment/AppointmentAction";
import AppointmentDetails from "../appointment/AppointmentDetails";
import AppointmentApprovedDetailsMsgForm from "../appointment/AppointmentApprovedDetailsMsgForm";
import AppointmentActionContact from "../appointment/AppointmentActionContact";
import Container from "src/layout/Container";
import { Book, Info, MessageCircle, ThumbsUp, XCircle } from "react-feather";
import NoInternetConnection from "src/NoInternetConnection";

const AppointmentRequestForApproval = () => {
  const ismounted = IsMountedRef();
  const theme = useTheme();
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");

  const [requestAppointment, setRequestAppointment] = useState([]);
  const [requestAppointmentRd, setRequestAppointmentRd] = useState(false);

  const [selectedViewId, setSelectedViewId] = useState(null);
  const [selectedViewDialog, setSelectedViewDialog] = useState(false);
  const [appActionId, setAppActionId] = useState(null);
  const [appAction, setAppAction] = useState(null);
  const [appActionDialog, setAppActionDialog] = useState(false);
  const [referenceNo, setReferenceNo] = useState(null);
  const [messageDialog, setMessageDialog] = useState(false);
  const [appMsgId, setAppMsgId] = useState(null);
  const [appMsgPatient, setAppMsgPatient] = useState(null);
  const [contactDialog, setContactDialog] = useState(false);
  const [isOffline, setIsOffline] = useState(false);

  const getRequestapp = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("connection", "online");

    Axios.post("appointment/doctors/request-appointment-list", formdata)
      .then((response) => {
        const data = response.data;
        if (ismounted.current) {
          if (data.message === "cloud-unreachable") {
            setIsOffline(true);
          }
          if (data.message === "success") {
            setRequestAppointment(data.result);
          }
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setRequestAppointmentRd(true));
  };

  const handleAppDetails = (e) => {
    setSelectedViewId(e.currentTarget.getAttribute("appid"));
    setSelectedViewDialog(true);
  };

  const handleAppAction = (e) => {
    setAppActionId(e.currentTarget.getAttribute("appid"));
    setAppAction(e.currentTarget.getAttribute("appaction"));
    setReferenceNo(e.currentTarget.getAttribute("reference_no"));
    setAppActionDialog(true);
  };

  const closeDailog = () => {
    setSelectedViewDialog(false);
    setSelectedViewId(null);
    setAppActionId(null);
    setAppAction(null);
    setAppActionDialog(false);
  };

  const handleAppMessagePatient = (e) => {
    setMessageDialog(true);
    setAppMsgId(e.currentTarget.getAttribute("appid"));
    setAppMsgPatient(e.currentTarget.getAttribute("patient_id"));
  };

  const handleContactInfo = (e) => {
    setAppMsgPatient(e.currentTarget.getAttribute("patient_id"));
    setContactDialog(true);
  };

  useEffect(() => {
    getRequestapp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ismounted]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Request",
          items: [
            { name: "Dashboard", path: "/clinic/app/doctor" },
            {
              name: "APPOINTMENT",
              path: "/clinic/app/doctor/appointment/calendar",
            },
          ],
        }}
        title="Appointment Request"
      >
        <>
          {requestAppointmentRd ? (
            isOffline ? (
              <NoInternetConnection xheight="calc(50vh - 120px)" />
            ) : requestAppointment.length > 0 ? (
              <Grid container spacing={2}>
                {requestAppointment.map((data, index) => {
                  return (
                    <Grid key={index} item xs={12} sm>
                      <Box
                        p={2}
                        bgcolor={
                          Boolean(index % 2 === 0)
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light
                        }
                        borderRadius={5}
                        color={theme.palette.common.white}
                      >
                        <Box>
                          <Typography className={"gtc-capitalize"} variant="h6">
                            {data.patient_name}
                          </Typography>
                          <Typography variant="subtitle1">
                            Requesting for a {data.appointment_type} on
                            {Boolean(parseInt(data.is_reschedule))
                              ? Notify.dateTimeConvert(data.is_reschedule_date)
                              : Notify.dateTimeConvert(data.appointment_date)}
                            . Would you like to have an appointment with this
                            person?
                          </Typography>
                        </Box>

                        <Box display="flex" justifyContent="flex-end" mt={2}>
                          <Box>
                            <Button
                              color="default"
                              appid={data.appointment_id}
                              appaction="approved"
                              reference_no={data.reference_no}
                              onClick={handleAppAction}
                              variant="contained"
                              startIcon={<ThumbsUp />}
                            >
                              Approve
                            </Button>

                            <Tooltip arrow title="Send message">
                              <IconButton
                                // color="default"
                                appid={data.appointment_id}
                                patient_id={data.patientId}
                                onClick={handleAppMessagePatient}
                              >
                                <MessageCircle color="white" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip arrow title="Request Information">
                              <IconButton
                                // color="default"
                                appid={data.appointment_id}
                                onClick={handleAppDetails}
                              >
                                <Info color="white" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip arrow title="Contact Information">
                              <IconButton
                                // color="default"
                                appid={data.appointment_id}
                                patient_id={data.patient_id}
                                onClick={handleContactInfo}
                              >
                                <Book color="white" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
        </>
      </Container>

      {/* app action dailog */}
      <Dialog
        open={selectedViewDialog}
        onClose={closeDailog}
        fullWidth
        maxWidth={"xs"}
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <DialogTitle>Appointment Details</DialogTitle>
          </Box>
          <Box mr={1} mt={1}>
            <IconButton color="secondary" onClick={() => closeDailog()}>
              <XCircle />
            </IconButton>
          </Box>
        </Box>
        <AppointmentDetails
          appid={selectedViewId}
          close={closeDailog}
          connection={"online"}
        />
      </Dialog>

      {/* view details dailog */}
      <Dialog
        open={appActionDialog}
        onClose={closeDailog}
        fullWidth
        maxWidth={"xs"}
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <DialogTitle>Appointment {appAction}</DialogTitle>
          </Box>
          <Box mr={1} mt={1}>
            <IconButton color="secondary" onClick={() => closeDailog()}>
              <XCircle />
            </IconButton>
          </Box>
        </Box>
        <AppointmentAction
          appid={appActionId}
          reference_no={referenceNo}
          appaction={appAction}
          getRequestapp={() => getRequestapp()}
          closeDialog={closeDailog}
          connection={"online"}
        />
      </Dialog>

      {/* send notification message dialog */}
      <Dialog
        open={messageDialog}
        onClose={() => setMessageDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <DialogTitle>Send Message</DialogTitle>
          </Box>
          <Box mr={1} mt={1}>
            <IconButton
              color="secondary"
              onClick={() => setMessageDialog(false)}
            >
              <XCircle />
            </IconButton>
          </Box>
        </Box>
        <AppointmentApprovedDetailsMsgForm
          patient_id={appMsgPatient}
          appid={appMsgId}
          closeDialog={() => setMessageDialog(false)}
          connection={"online"}
        />
      </Dialog>

      {/* contactinfo dialog */}
      <Dialog
        open={contactDialog}
        onClose={() => setContactDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <DialogTitle>Contact Information</DialogTitle>
          </Box>
          <Box mr={1} mt={1}>
            <IconButton
              color="secondary"
              onClick={() => setContactDialog(false)}
            >
              <XCircle />
            </IconButton>
          </Box>
        </Box>
        <AppointmentActionContact
          patient_id={appMsgPatient}
          close={() => setContactDialog(false)}
          connection={"online"}
        />
      </Dialog>
    </Fragment>
  );
};

export default AppointmentRequestForApproval;
