import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Delete from "@material-ui/icons/Delete";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Notify from "../../../notification/Notify";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DialogActions, DialogContent } from "@material-ui/core";

function TreatmentPlanDelete(props) {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [isprocess, setisprocess] = useState(false);

  const handleDelete = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("id", props.treatment_id);

    var error = [];
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      Notify.fieldRequired("password");
      error = "error";
    }
    if (error.length > 0) {
      console.warn("Unble to process , Form has an error.");
    } else {
      setisprocess(true);
      Axios.post("doctor/patient/patient-treatmentplandelete", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            props.closedialog();
            props.getTreatmentPlan();
            Notify.successRequest("treatment plan");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setisprocess(false);
        });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleDelete}>
        <DialogContent dividers>
          <Box>
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              name="password"
              label="Enter your password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box ml={2}>
            <Button
              variant="contained"
              color="default"
              startIcon={<HighlightOff />}
              onClick={props.closedialog}
            >
              {" "}
              No{" "}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isprocess ? true : false}
            startIcon={
              isprocess ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <Delete />
              )
            }
          >
            {" "}
            Delete{" "}
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
}

export default TreatmentPlanDelete;
