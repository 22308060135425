import React, { Component, Fragment } from 'react';  
import { Form, InputGroup, FormControl, Button, FormGroup, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'; 
import axios from 'axios';
import Notify from '../notification/Notify';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import MUIButton from '@material-ui/core/Button'; 
import CircularProgress from '@material-ui/core/CircularProgress'
import HighlightOff from '@material-ui/icons/HighlightOff'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline' 
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile'; 

const fileLocation = process.env.REACT_APP_API_IMAGE; 
   
export default class OnlinechecupChat extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'), 

            _is_send: false,

            _message: [],
            _message_ready: false,
            _last_messages_id: 0, 

            _message_type: 'text',
            _selecting_file_label: 'none',
            _allow_permission: false,

            _selected_message_id: null
        }

        this.interval = null;
 
    }  

    componentDidMount(){
        this._mounted = true;
        this.getMessages();
         
        this.interval = setInterval(() => {
            this.getNewMessages();
        }, 5000)
    }

    scrollToBottom = () => { 
        this.chatContainer.scrollTop = this.chatContainer.scrollHeight; 
    };

    getMessages(){
        var formdata = new FormData();  
            formdata.set('token', this.state._token); 
            formdata.set('management_id', this.state._management_id);   
            formdata.set('receivers_id', this.state._user_id);  
            formdata.set('username', this.state._username);    
            formdata.set('senders_id', this.props.client_id); 
            formdata.set('connection', 'online');
            
        axios.post('online/checkup/message/get-message-list', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data.length > 0){
                    this.setState({
                        _message: data,
                        _message_ready: true, 
                        _last_messages_id: data[data.length -1 ].id,
                    }) 
                    this.scrollToBottom();
                }else{
                    this.setState({
                        _message: [],
                        _message_ready: true,  
                    })
                }
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentWillUnmount(){
        this._mounted = false;
        clearInterval(this.interval)
    }

    handleSendMsg(e){
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);  
            formdata.set('token', this.state._token);  
            formdata.set('senders_id', this.state._user_id);    
            formdata.set('receivers_id', this.props.client_id);  
            formdata.set('connection', 'online');  

        var error = [];

        if(formdata.get('message').length === 0 || formdata.get('message').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('message');
        } 

        if(error.length > 0){
            Notify.consoleLog('send message')
        }else{
            this.setState({
                _is_send: true
            })  

            axios.post('online/checkup/message/send-message', formdata )
            .then(function(response){   
                if(this._mounted){ 
                    this.setState({
                        _is_send: false
                    })  
                    this.getMessages();
                    e.target.reset();
                } 
            }.bind(this)).catch(error=>{ 
                this.setState({
                    _is_send: false
                })  
                Notify.requestError(error);
            });
        }   
    }

    getNewMessages(){
        var formdata = new FormData();  
            formdata.set('token', this.state._token); 
            formdata.set('management_id', this.state._management_id);   
            formdata.set('user_id', this.state._user_id); 
            formdata.set('receivers_id', this.props.client_id);  
            formdata.set('account_type', this.state._type);    
            formdata.set('_last_msg_id', this.state._last_messages_id);     
            formdata.set('connection', 'online');        
            
        axios.post('online/checkup/message/get-message-new', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data.length > 0){ 
                    this.getMessages(); 
                }
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }  

    resetMessage = (e) =>{
       if(e.target.value.trim().length < 1){
            this.refs.message_attachment.value = null;
            this.setState({ _selecting_file_label: 'none'});
       }
    }

    handleSelectFile =()=> {
        this.refs.message_attachment.click();
    }

    
    onSelectFileChange =(e)=>{
        var file = e.target.files[0].name;
        var lastDot = file.lastIndexOf('.');
        var filename = file.substring(0, lastDot);
        // var ext = file.substring(lastDot + 1);

        this.refs.chat_message.value = filename;
        this.setState({ _selecting_file_label: 'selected' }) // upload icon set to archive
    }

    closePermissionDialog = () => {
        this.setState({
            _allow_permission: false,
            _selected_message_id: null
        })
    }

    handleSelectedPermission = (e) =>{
        this.setState({
            _allow_permission: true ,
            _selected_message_id: e.currentTarget.id
        }) 
    }

    handleAllowPermission = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);  
            formdata.set('token', this.state._token);   
            formdata.set('user_id', this.state._user_id);  
            formdata.set('username', this.state._username);  
            formdata.set('doctors_id', this.props.client_id);  
            formdata.set('id', this.state._selected_message_id);   
            formdata.set('connection', 'online');        

        var error = [] ;
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){  
            Notify.fieldRequired('password')
            error = 'error';
        }
        if(error.length > 0){
            console.warn("Unable to process, Form has an error.")
        }else{
            this.setState({ isprocessperms: true })
            axios.post('online/checkup/message/allow-profilepermission', formdata )
            .then(function(response){ 
                const data = response.data;   
                if(data === 'success'){
                    this.getMessages()
                    this.closePermissionDialog()
                    Notify.successRequest('permission')
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                } 
            }.bind(this)).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() =>{
                this.setState({ isprocessperms: false })
            })
        }
    }

	render(){ 
		return(
			<Fragment>	 
                <Card className=" rounded-0 p-0"
                    style={{
                        height: 'calc(100vh)',
                    }}
                >
                    <Card.Header className="rounded-0"> 
                        <div className={'float-left mt-2 h6'}>
                            <b> CHATS </b> 
                        </div> 
                        <div hidden={ ! this.props.close } className="float-right"> 
                            <IconButton color="secondary" onClick={ this.props.close }>
                                <HighlightOff />
                            </IconButton>
                        </div>
                    </Card.Header>

                    <Card.Body  
                        className="onlineChatContainer"
                        ref={ el => this.chatContainer = el} 
                        style={{ 
                            overflowY: 'auto',
                        }}
                    > 
                        {
                            this.state._message_ready ?
                                this.state._message.length > 0 ? 
                                    this.state._message.map(function(data, index){
                                        return(
                                            <div key={index} className="clearfix"> 
                                                {
                                                    data.senders_id === this.state._user_id ? 
                                                        <div className=" bg-light border p-2 mb-2 rounded float-right">
                                                            {data.message} 
                                                            { 
                                                                data.type === 'file' ? 
                                                                    <a rel="noopener noreferrer" className="pointer" target="_blank" href={fileLocation+'appointment/chat_attachment/'+data.message} download> <FontAwesomeIcon icon={faDownload} /> </a>
                                                                : null
                                                            }
                                                        </div>
                                                    :
                                                        <div className="text-white bg-primary border border-primary p-2 mb-2 rounded float-left">
                                                            {data.message}
                                                            { 
                                                                data.type === 'file' ? 
                                                                    <a rel="noopener noreferrer" className="pointer text-white" target="_blank" href={fileLocation+'appointment/chat_attachment/'+data.message} download> <FontAwesomeIcon icon={faDownload} /> </a>
                                                                : data.type === 'permission' ? 
                                                                    <Fragment>
                                                                        <p className="m-0">
                                                                            <Button variant="success float-right" size="sm" 
                                                                                id={data.id}
                                                                                onClick ={ this.handleSelectedPermission }
                                                                            > Allow </Button>
                                                                        </p>
                                                                    </Fragment>
                                                                :null  
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        )
                                    }.bind(this))
                                :
                                <p className="text-danger text-center">
                                    No message recorded.
                                </p>
                            :
                            <div className="text-center text-primary">
                                <Spinner animation="border" /> <br />
                                Please wait...
                            </div>
                        }
                    </Card.Body>
                    <Card.Footer>  
                        <Form onSubmit={this.handleSendMsg.bind(this)}>
                            <FormGroup className="mb-0">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <Button variant="secondary px-1" onClick={this.handleSelectFile} >
                                            {
                                                this.state._selecting_file_label === 'none' ? 
                                                    <CloudUploadIcon />
                                                : <AttachFileIcon />
                                            }
                                        </Button>
                                        <input type="file" name="message_attachment" ref="message_attachment" style={{display: "none"}} onChange={this.onSelectFileChange} />
                                    </InputGroup.Prepend>

                                    <FormControl name="message" autoComplete="off" placeholder ="Enter message" ref="chat_message" onChange={this.resetMessage} />
                                      
                                    <InputGroup.Append>
                                        <Button type="submit" 
                                            disabled = { this.state._is_send ? true : false }
                                        >
                                        <FontAwesomeIcon 
                                            icon={ this.state._is_send ? faSpinner : faPaperPlane }
                                            spin={ this.state._is_send ? true : false }
                                        /> </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </FormGroup>
                        </Form> 
                    </Card.Footer>
                </Card>   

                {/* allowpermission to view profile */} 
                <Dialog
                    open={ this.state._allow_permission } 
                    onClose={ this.closePermissionDialog } 
                    disableBackdropClick={true}  
                    TransitionComponent={Slide}
                    transitionDuration={600} 
                >    
                    <DialogTitle>
                        Allow Permission
                    </DialogTitle>
                    <form onSubmit={ this.handleAllowPermission }>
                        <DialogContent dividers> 
                            <Box> 
                                <TextField 
                                    type="password" 
                                    variant="outlined" 
                                    fullWidth  
                                    margin="normal
                                        "
                                    name="password" 
                                    label="Enter your password" /> 
                            </Box> 
                        </DialogContent>
                        <DialogActions>
                            <MUIButton 
                                variant="contained" 
                                color="default" 
                                onClick={ this.closePermissionDialog }  
                                startIcon={<HighlightOff />} 
                            > No </MUIButton>  
                            <MUIButton 
                                variant="contained" 
                                color="primary" 
                                type="submit" 
                                disabled={ this.state.isprocessperms ? true : false } 
                                startIcon={ this.state.isprocessperms ? <CircularProgress color="inherit" size={15} /> : <CheckCircleOutline />}
                            > Allow </MUIButton>
                        </DialogActions>
                    </form> 
                </Dialog> 
			</Fragment>
		)
    }
    
}
