import { Box, Typography, useTheme, Slide } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const Convo = ({ sender, rerender, scrollToBottom }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const theme = useTheme();
  const [messages, setMessages] = useState([]);
  const [messagesRd, setMessagesRd] = useState(false);
  const mounted = IsMountedRef();
  const conversationLimit = 100;

  const getConversationMessages = React.useCallback(() => {
    Axios.get("chat/management/conversation", {
      params: {
        receivers_id: user_id,
        senders_id: sender.user_id,
        token: token,
        management_id,
        rerender: rerender,
        conversationLimit,
      },
    })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setMessages(data);
        }
      })
      .catch((error) => {
        console.log("error request:", error.message);
      })
      .finally(() => {
        setMessagesRd(true);
      });
  }, [
    mounted,
    token,
    user_id,
    management_id,
    rerender,
    conversationLimit,
    sender,
  ]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getConversationMessages();
  }, [getConversationMessages]);
  return (
    <Slide in={messagesRd} direction={"right"} timeout={700}>
      <Box>
        {messagesRd ? (
          messages.length > 0 ? (
            messages
              .sort((a, b) => {
                return a.id - b.id;
              })
              .map((data, key) => (
                <Box
                  key={key}
                  mb={1.5}
                  display={"flex"}
                  justifyContent={
                    data.senders_id === user_id ? "flex-end" : "flex-start"
                  }
                >
                  <Box
                    bgcolor={
                      data.senders_id === user_id
                        ? theme.palette.primary.main
                        : "#f5f5f5"
                    }
                    color={data.senders_id === user_id ? "#fff" : "#000"}
                    p={2}
                    borderRadius={5}
                    width={250}
                  >
                    <Typography variant="subtitle2">
                      {" "}
                      {data.message}{" "}
                    </Typography>
                  </Box>
                </Box>
              ))
          ) : (
            <Typography color="secondary" align="center">
              No message found.
            </Typography>
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </Slide>
  );
};

export default Convo;
