import Axios from "axios";
import React, { useEffect, useState } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import SelectManagement from "./SelectManagement";

const Management = () => {
  const token = localStorage.getItem("token");
  const general_management_id = localStorage.getItem("general_management_id");
  const mounted = IsMountedRef();

  const [list, setList] = useState({ data: [], ready: true });

  const getManagementList = React.useCallback(async () => {
    let response = await Axios.get("doctors/management/list-bymainmanagement", {
      params: {
        general_management_id,
        token,
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [token, general_management_id, mounted]);

  useEffect(() => {
    getManagementList();
  }, [getManagementList]);

  return <SelectManagement list={list} />;
};

export default Management;
