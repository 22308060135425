import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { Fragment } from "preact";

const UrinalysisOrder = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("table", "laboratory_urinalysis");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 &&
        details.map((data, key) => (
          <Fragment key={key}>
            <Box p={2}>
              <Typography variant="h6">
                <strong> Urinalysis Order </strong>
              </Typography>

              {Boolean(parseInt(data.urinalysis)) && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">color :</span>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography>{checkResult(data.color)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">reaction :</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.reaction)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">transparency :</span>
                        </Typography>
                      </Box>

                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.transparency)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">sp gravity :</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.sp_gravity)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            albumin (negative) :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.albumin)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            sugar (negative):
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.sugar)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">pus cells:</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.pus_cell)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">R.B.C:</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.rbc)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            epithelial cells :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.epithelial_cell)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            mucus threads :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.mucus_threads)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            Color :renal cells :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.renal_cell)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            Color :yeast cells :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.yeast_cell)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase"> hyaline :</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.hyaline)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">rbc cast :</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.rbc_cast)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">wbc cast :</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.wbc_cast)}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            coarse granular cast :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.coarse_granular_cast)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            fine granular cast :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.fine_granular_cast)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            pus in clumps :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.pus_in_clumps)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            rbc in clumps :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.rbc_in_clumps)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            calcium oxalate :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          {checkResult(data.calcium_oxalate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">uric acid :</span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>{checkResult(data.uric_acid)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            amorphous urate :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.amorphous_urate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            amorphous phosphate :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.amorphous_phosphate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            calcium carbonate :
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.calcium_carbonate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            ammonium biurate:
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.ammonium_biurate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            triple phosphate:
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.triple_phosphate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">spermatozoa:</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.spermatozoa)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">
                            trichomonas vaginalis:
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>
                          {checkResult(data.trichomonas_vaginalis)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">micral test:</span>
                        </Typography>
                      </Box>
                      <Box>
                        {" "}
                        <Typography>{checkResult(data.micral_test)}</Typography>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box mr={1}>
                        <Typography variant="caption">
                          <span className="text-uppercase">urine ketone:</span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          {checkResult(data.urine_ketone)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="caption">
                      <strong> OTHERS: </strong>
                    </Typography>
                    <Typography
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {data.others}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="caption">
                      <strong> REMARKS: </strong>
                    </Typography>
                    <Typography
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {data.remarks}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Fragment>
        ))}
    </>
  );
};

export default UrinalysisOrder;
