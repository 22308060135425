import React, { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faArchive, faBox } from '@fortawesome/free-solid-svg-icons';
import './Registration.css';
import { useHistory } from 'react-router-dom'

import { Avatar, Box, Card as MUICard, CardContent, CardHeader, Divider, Typography } from '@material-ui/core'
import { orange, purple, green } from '@material-ui/core/colors';

const RegistrationPackages = () => {

    const [premiumShadow, setPremiumShadow] = useState(3)
    const [eliteShadow, setEliteShadow] = useState(3)
    const [standardShadow, setStandardShadow] = useState(3)
    const history = useHistory()

    return (
        <Fragment>
            <br />
            <br />
            <br />
            <Col sm={12}>
                <p className="h3 text-primary text-uppercase text-center mt-5 m-0"> <b> Global Telemedecine Corp </b> </p>
                <p className="h5 text-primary text-uppercase text-center m-0 my-2"> Membership Packages </p>

                <p className="text-muted text-center m-0 my-4">
                    The membership packages below are subject to change without prior notice. <br /> Make upgrade when needed.
                </p>
            </Col>

            <Col sm={12} className="mt-5">
                <Row>
                    <Col sm={12} lg={9} className="mx-auto">
                        <Row>
                            <Col xs={12} sm={12}>
                                <Row>
                                    <Col xs={12} sm={12} md={4} className="mb-4 mb-mb-0">
                                        <MUICard
                                            component={Box}
                                            border={1}
                                            borderColor={green[300]}
                                            elevation={standardShadow}
                                            onMouseEnter={() => setStandardShadow(6)}
                                            onMouseLeave={() => setStandardShadow(3)}
                                            style={{
                                                transform: standardShadow > 3 ? 'scale(1.05)' : '',
                                                transition: 'all .2s ease-in-out'
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        style={{ backgroundColor: '#00c853' }}
                                                    >
                                                        <FontAwesomeIcon style={{ color: '#fff' }} icon={faCube} />
                                                    </Avatar>
                                                }
                                                title={
                                                    <Typography variant={'h5'}>
                                                        <strong> STANDARD </strong>
                                                    </Typography>
                                                }
                                                subheader="Standard Package"
                                            />

                                            <CardContent >
                                                <Divider />
                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={green[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> Free </b> Membership </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={green[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> Free </b> 2 Months Subcription </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={green[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> Online Appointment </b> </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={green[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> Only A Doctor Can Update <br /> Patient's Health Status </b> </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderColor={green[300]}
                                                    borderTop={1}
                                                >
                                                    <span className="header-label text-uppercase small"> <b className="text-danger"> ZERO </b> Credit For Appointment </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderColor={green[300]}
                                                    borderTop={1}
                                                />

                                                <Box
                                                    width={'100%'}
                                                    align="center"
                                                >
                                                    <Box
                                                        className={`pointer`}
                                                        width={100}
                                                        p={1}
                                                        border={1}
                                                        borderRadius={5}
                                                        borderColor={green['A700']}
                                                        bgcolor={green['A400']}
                                                        color="white"
                                                        onClick={() => history.push(`/clinic/app/registration/basic-information/standard`)}
                                                    >
                                                        Join Now
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </MUICard>
                                    </Col>

                                    <Col xs={12} sm={12} md={4} className="mb-4 mb-mb-0">

                                        <MUICard
                                            component={Box}
                                            border={1}
                                            borderColor={orange[300]}
                                            elevation={premiumShadow}
                                            onMouseEnter={() => setPremiumShadow(6)}
                                            onMouseLeave={() => setPremiumShadow(3)}
                                            style={{
                                                transform: premiumShadow > 3 ? 'scale(1.05)' : '',
                                                transition: 'all .2s ease-in-out'
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        style={{ backgroundColor: '#ffb74d' }}
                                                    >
                                                        <FontAwesomeIcon style={{ color: '#fff' }} icon={faArchive} />
                                                    </Avatar>
                                                }
                                                title={
                                                    <Typography variant={'h5'}>
                                                        <strong> PREMIUM </strong>
                                                    </Typography>
                                                }
                                                subheader="Premium Package"
                                            />

                                            <CardContent >
                                                <Divider />
                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={orange[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> 200  PHP </b> Membership fee </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={orange[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> 200  PHP </b> Monthly Renewal </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={orange[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> Online Appointment </b> </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={orange[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> Patients can update  <br />  their health status </b> </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderColor={orange[300]}
                                                    borderTop={1}
                                                >
                                                    <span className="header-label text-uppercase small">  <b className="text-danger"> ZERO </b> Credit For Appointment</span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderColor={orange[300]}
                                                    borderTop={1}
                                                />

                                                <Box
                                                    width={'100%'}
                                                    align="center"
                                                >
                                                    <Box
                                                        className={`pointer`}
                                                        width={100}
                                                        p={1}
                                                        border={1}
                                                        borderRadius={5}
                                                        borderColor={orange['A700']}
                                                        bgcolor={orange['A400']}
                                                        color="white"
                                                        onClick={() => history.push(`/clinic/app/registration/basic-information/premium`)}
                                                    >
                                                        Join Now
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </MUICard>
                                    </Col>

                                    <Col xs={12} sm={12} md={4} className="mb-4 mb-mb-0">
                                        <MUICard
                                            component={Box}
                                            border={1}
                                            borderColor={purple[300]}
                                            elevation={eliteShadow}
                                            onMouseEnter={() => setEliteShadow(6)}
                                            onMouseLeave={() => setEliteShadow(3)}
                                            style={{
                                                transform: eliteShadow > 3 ? 'scale(1.05)' : '',
                                                transition: 'all .2s ease-in-out'
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        style={{ backgroundColor: '#ba68c8' }}
                                                    >
                                                        <FontAwesomeIcon style={{ color: '#fff' }} icon={faBox} />
                                                    </Avatar>
                                                }
                                                title={
                                                    <Typography variant={'h5'}>
                                                        <strong> ELITE </strong>
                                                    </Typography>
                                                }
                                                subheader="Elite Package"
                                            />

                                            <CardContent
                                                className="blurrytext"
                                            >
                                                <Divider />
                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={purple[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> -  PHP </b> Membership fee </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={purple[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> -  PHP </b> Membership fee </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={purple[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> -  PHP </b> Monthly Renewal </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderColor={purple[300]}
                                                >
                                                    <span className="header-label text-uppercase small"> <b> -  PHP </b> Membership <br /> feesdfsdfsdfsssssssssss </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderTop={1}
                                                    borderBottom={1}
                                                    borderColor={purple[300]}
                                                >
                                                    <span className="header-label text-uppercase small">  ZERO  Credit For Appointment </span>
                                                </Box>

                                                <Box
                                                    p={2}
                                                    borderColor={purple[300]}
                                                    borderTop={1}
                                                />

                                                <Box
                                                    width={'100%'}
                                                    align="center"
                                                >
                                                    <Box
                                                        width={100}
                                                        className="cursor-not-allowed"
                                                        p={1}
                                                        border={1}
                                                        borderRadius={5}
                                                        borderColor={purple['A700']}
                                                        bgcolor={purple['A400']}
                                                        color="white"
                                                    >
                                                        Join Now
                                                    </Box>
                                                </Box>

                                            </CardContent>
                                        </MUICard>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <br />
            <br />
        </Fragment>
    )
}

export default RegistrationPackages;
