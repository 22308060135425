import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import GTCValidateLS from "src/notification/GTCValidateLS";
import Radiologist from "src/radiologist/Radiologist";
import Sidebar from "src/layout/Sidebar";
import { Home, Users, FileText as List } from "react-feather";
import axios from "axios";
import Badge from "@material-ui/core/Badge";
import RadiologistPatientReviewed from "src/radiologist/RadiologistPatientReviewed";
import GTCNewsFullImage from "src/gtcnews/GTCNewsFullImage";

const RouteRadiologist = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [count, setCount] = useState(0);
  var interval = null;

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get(
        "radiologist/sidebar/header-infomartion",
        { params }
      );
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const getPatientForReview = React.useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("user_id", user_id);
      const request = await axios.post(
        "radiologist/patients/getpatient-forreview",
        formdata
      );
      setCount(request.data.length);
    } catch (error) {
      console.log("error sample");
    }
  }, [token, user_id]);

  const initializedInterval = () => {
    interval = setInterval(() => {
      getPatientForReview();
    }, 150000);
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    initializedInterval();
    getPatientForReview();
    GTCValidateLS.verifyLocalStorage();

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidbarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app/radiologist",
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge badgeContent={count} color="secondary">
          <Users color="white" />
        </Badge>
      ),
      path: "/clinic/app/radiologist/patients",
    },
    {
      name: "Reports",
      show: true,
      icon: <List color="white" />,
      path: "/clinic/app/radiologist/reports",
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <Sidebar
          notification={{
            enable: false,
            local: [],
            virtual: [],
            owner: "radiologist",
          }}
          header={sidebarHeader}
          routes={sidbarRoute}
          module={"radiologist"}
        />

        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app/radiologist" />}
          />
          <Route
            exact
            path="/clinic/"
            component={() => <Redirect to="/clinic/app/radiologist" />}
          />
          <Route
            exact
            path="/clinic/app"
            component={() => <Redirect to="/clinic/app/radiologist" />}
          />
          <Route
            exact
            path="/clinic/app/radiologist"
            component={GTCNewsFullImage}
          />
          <Route
            exact
            path="/clinic/app/radiologist/patients"
            component={Radiologist}
          />
          <Route
            exact
            path="/clinic/app/radiologist/reports"
            component={RadiologistPatientReviewed}
          />
          <Route exact path="/clinic/app/logout" component={Logout} />
          <Route render={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteRadiologist;
